import React, { Fragment, useEffect, useRef } from "react";
import Loader from "../layout/loader";
import { ToastContainer } from "react-toastify";

const AppAuth: React.FC = ({ children }) => {
  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;
    return () => { isMountedRef.current = false; }
  }, []);

  return (
    <Fragment>
      <Loader />
      <ToastContainer />
      {children}
    </Fragment>
  );
};

export default AppAuth;
