import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import dotenv from "dotenv";
import { Provider } from "react-redux";
import store from "./store";
import { AuthProvider } from "./hooks/auth";
import Routes from "./routes";
import { IntlProviderWrapper } from "./utils/context/Internationalization";
import { ApolloProvider } from "./services/apollo.service";
import "./index.scss";
import { BrowserRouter } from "react-router-dom";

// Init dotenv
dotenv.config();

const Root: React.FC = (props) => {
  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;
    return () => { isMountedRef.current = false; }
  }, []);

  return (
    <Provider store={store}>
      <ApolloProvider>
        <AuthProvider>
          <IntlProviderWrapper>
            <BrowserRouter basename="/">
              <Routes />
            </BrowserRouter>
          </IntlProviderWrapper>
        </AuthProvider>
      </ApolloProvider>
    </Provider>
  );
}

// Render document
ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
