import React, { Fragment, useEffect, useRef } from "react";
import Loader from "../layout/loader";
import Header from "../layout/header";
import Sidebar from "../layout/sidebar";
import Footer from "../layout/footer";
import ThemeCustomize from "../layout/theme-customizer";
import { ToastContainer } from "react-toastify";
import Taptop from "../layout/tap-top";
import { withRouter } from "react-router-dom";
import { gql, useSubscription } from "@apollo/client";
import {
  isPushNotificationSupported,
  sendNotification,
  initializePushNotifications,
} from "../push-notifications";
import message from "@davistran86/notification";
import { Info } from "react-feather";
import { ICombineReducers } from "../redux";
import { useSelector } from "react-redux";

const NOTIFICATION_SUBSCRIPTION = gql`
  subscription subscriptionMessage {
    subscriptionMessage {
      id
      title
      image
      message
      date
      user {
        id
        name
        surname
      }
    }
  }
`;

const App: React.FC = ({ children }) => {
  const isMountedRef = useRef(null);
  const credentials = useSelector((state: ICombineReducers) => state.DataSet.credentials);
  const { data, loading } = useSubscription(NOTIFICATION_SUBSCRIPTION);
  const pushNotificationSuported = isPushNotificationSupported();

  useEffect(() => {
    isMountedRef.current = true;
    if (pushNotificationSuported)
      initializePushNotifications();
    if (!loading && !!data?.subscriptionMessage?.message && isMountedRef.current) {
      if (data.subscriptionMessage?.user && (data.subscriptionMessage?.user?.id === credentials?.user?.id)) {
        if (pushNotificationSuported) {
          initializePushNotifications().then(function (consent) {
            if (consent === 'granted') {
              sendNotification(
                data.subscriptionMessage.id,
                data.subscriptionMessage.title,
                data.subscriptionMessage.message,
                data.subscriptionMessage.image
              );
            } else {
              message.open(
                {
                  title: data.subscriptionMessage.title,
                  description: data.subscriptionMessage.message
                },
                {
                  position: "bottom-right",
                  duration: 5000,
                  icon: <Info />,
                }
              );
            }
          });
        } else {
          message.open(
            {
              title: data.subscriptionMessage.title,
              description: data.subscriptionMessage.message
            },
            {
              position: "bottom-right",
              duration: 5000,
              icon: <Info />,
            }
          );
        }
      }
    }
    return () => { isMountedRef.current = false; }
  });

  return (
    <Fragment>
      <Loader />
      <Taptop />
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <Header />
        <div className="page-body-wrapper sidebar-icon">
          <Sidebar />
          <div className="page-body">{children}</div>
          <Footer />
        </div>
      </div>
      <ThemeCustomize />
      <ToastContainer />
    </Fragment>
  );
};

export default withRouter(App);
