import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import reducers, { initialReducersState } from "../redux/index";
import { composeWithDevTools } from "redux-devtools-extension";

// Redux
const composeEnhancers = composeWithDevTools({});
const store = createStore(
  reducers,
  initialReducersState,
  composeEnhancers(applyMiddleware(thunkMiddleware))
);


export default store;
