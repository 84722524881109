import React from "react";
// eslint-disable-next-line
import { DollarSign, Globe, Home, Calendar, Monitor, Package, Pocket, ShoppingCart, Truck, User, Users, Target, Filter, Database, Lock, Paperclip, Bell, PieChart, BarChart, BarChart2, Inbox, Shuffle } from "react-feather";

export interface IMainMenu {
  mainmenu: MainMenu[];
}

export interface MenuItem {
  title: string;
  path?: string;
  icon?: React.FC<any>;
  type: string;
  active?: boolean;
  hidden?: boolean;
  scopes?: string[];
  badge?: string;
  badgeTxt?: string;
  children?: MenuItem[];
  bookmark?: boolean;
  tooltipOpen?: boolean;
}

export interface MainMenu {
  menuTitle: string;
  menuContent?: string;
  items?: MenuItem[];
  scopes?: string[];
}

const MAINMENU: MainMenu[] = [
  {
    menuTitle: "menu.general.title",
    menuContent: "menu.general.description",
    items: [
      {
        title: "general.dashboards",
        icon: Monitor,
        type: "sub",
        children: [
          {
            title: "general.home",
            path: "/",
            type: "link",
          },
          {
            title: "general.sales",
            path: "/sales",
            type: "link",
          }
        ]
      },
    ],
  },
  {
    menuTitle: "menu.salles.title",
    menuContent: "menu.salles.description",
    scopes: [
      "Leads:Leads",
      "Leads:Create",
      "Budgets:Budgets",
      "Budgets:Create",
      "Orders:Orders",
      "Calendars:Calendars",
    ],
    items: [
      {
        title: "pages.calendars.calendars",
        icon: Calendar,
        type: "sub",
        scopes: ["Calendars:Calendars", "Calendars:Create",],
        children: [
          {
            title: "pages.calendars.list",
            type: "link",
            scopes: ["Calendars:Calendars", "Calendars:Create",],
            path: "/calendar",
          }
        ]
      },
      {
        title: "pages.leads.leads",
        icon: PieChart,
        type: "sub",
        scopes: ["Leads:Leads", "Leads:Create",],
        children: [
          {
            title: "pages.leads.list",
            path: "/leads",
            type: "link",
            scopes: ["Leads:Leads"],
          },
          {
            title: "pages.leads.create",
            path: "/leads/manage",
            type: "link",
            scopes: ["Leads:Create"],
          },
        ],
      },
      {
        title: "pages.budgets.budgets",
        icon: ShoppingCart,
        type: "sub",
        scopes: ["Budgets:Budgets", "Budgets:Create",],
        children: [
          {
            title: "pages.budgets.funnel2",
            path: "/budgets/funnel",
            type: "link",
            scopes: ["Budgets:Budgets"],
          },
          {
            title: "pages.budgets.list",
            path: "/budgets",
            type: "link",
            scopes: ["Budgets:Budgets"],
          },
          {
            title: "pages.budgets.create",
            path: "/budgets/client",
            type: "link",
            scopes: ["Budgets:Create"],
          },
        ],
      },
      {
        title: "pages.orders.orders",
        icon: Inbox,
        type: "sub",
        scopes: ["Orders:Orders"],
        children: [
          {
            title: "pages.orders.list",
            path: "/orders",
            type: "link",
            scopes: ["Orders:Orders"],
          },
        ],
      },
    ]
  },
  {
    menuTitle: "menu.records.title",
    menuContent: "menu.records.description",
    scopes: [
      "Clients:Clients",
      "Clients:Create",
      "Products:Products",
      "BudgetStatus:BudgetStatuses",
      "BudgetStatus:Create",
      "Carriers:Carriers",
      "Carriers:Create",
      "PaymentConditions:PaymentConditions",
      "PaymentConditions:Create",
      "SaleTeams:SaleTeams",
      "SaleTeams:Create",
      "Notifications:Notifications",
      "Notifications:Create",
    ],
    items: [
      {
        title: "pages.clients.clients",
        icon: Pocket,
        type: "sub",
        scopes: ["Clients:Clients", "Clients:Create",],
        children: [
          {
            title: "pages.clients.list",
            path: "/clients",
            type: "link",
            scopes: ["Clients:Clients"],
          },
          {
            title: "pages.clients.create",
            path: "/clients/manage",
            type: "link",
            scopes: ["Clients:Create"],
          },
        ],
      },
      {
        title: "pages.products.products",
        icon: Package,
        type: "sub",
        scopes: ["Products:Products"],
        children: [
          {
            title: "pages.products.list",
            path: "/products",
            type: "link",
            scopes: ["Products:Products"],
          },
        ],
      },
      {
        title: "pages.funnels.funnels",
        icon: Filter,
        type: "sub",
        scopes: ["Funnel:Funnels", "Funnel:Create",],
        children: [
          {
            title: "pages.funnels.list",
            path: "/funnels",
            type: "link",
            scopes: ["Funnel:Funnels"],
          },
          {
            title: "pages.funnels.create",
            path: "/funnels/manage",
            type: "link",
            scopes: ["Funnel:Create"],
          },
        ],
      },
      {
        title: "pages.carriers.carriers",
        icon: Truck,
        type: "sub",
        scopes: ["Carriers:Carriers", "Carriers:Create",],
        children: [
          {
            title: "pages.carriers.list",
            path: "/carriers",
            type: "link",
            scopes: ["Carriers:Carriers"],
          },
          {
            title: "pages.carriers.create",
            path: "/carriers/manage",
            type: "link",
            scopes: ["Carriers:Create"],
          },
        ],
      },
      {
        title: "pages.paymentConditions.paymentConditions",
        icon: DollarSign,
        type: "sub",
        scopes: ["PaymentConditions:PaymentConditions", "PaymentConditions:Create",],
        children: [
          {
            title: "pages.paymentConditions.list",
            path: "/payment-conditions",
            type: "link",
            scopes: ["PaymentConditions:PaymentConditions"],
          },
          {
            title: "pages.paymentConditions.create",
            path: "/payment-conditions/manage",
            type: "link",
            scopes: ["PaymentConditions:Create"],
          },
        ],
      },
      {
        title: "pages.saleTeams.saleTeams2",
        icon: Users,
        type: "sub",
        scopes: ["SaleTeams:SaleTeams", "SaleTeams:Create",],
        children: [
          {
            title: "pages.saleTeams.list",
            path: "/sale-teams",
            type: "link",
            scopes: ["SaleTeams:SaleTeams"],
          },
          {
            title: "pages.saleTeams.create",
            path: "/sale-teams/manage",
            type: "link",
            scopes: ["SaleTeams:Create"],
          },
        ],
      },
      {
        title: "pages.notifications.notifications",
        icon: Bell,
        type: "sub",
        scopes: ["Notifications:Notifications", "Notifications:Create",],
        children: [
          {
            title: "pages.notifications.list",
            path: "/notifications",
            type: "link",
            scopes: ["Notifications:Notifications"],
          },
          {
            title: "pages.notifications.create",
            path: "/notifications/manage",
            type: "link",
            scopes: ["Notifications:Create"],
          },
        ],
      },
    ],
  },
  {
    menuTitle: "menu.security.title",
    menuContent: "menu.security.description",
    scopes: [
      "Users:Users",
      "Users:Create",
      "Roles:Roles",
      "Roles:Create",
      "RequestLogs:RequestLogs",
      "Logs:Logs",
    ],
    items: [
      {
        title: "pages.users.users",
        icon: User,
        type: "sub",
        scopes: ["Users:Users", "Users:Create",],
        children: [
          {
            title: "pages.users.list",
            path: "/users",
            type: "link",
            scopes: ["Users:Users"],
          },
          {
            title: "pages.users.create",
            path: "/users/manage",
            type: "link",
            scopes: ["Users:Create"],
          },
        ],
      },
      {
        title: "pages.roles.roles",
        icon: Lock,
        type: "sub",
        scopes: ["Roles:Roles", "Roles:Create",],
        children: [
          {
            title: "pages.roles.list",
            path: "/roles",
            type: "link",
            scopes: ["Roles:Roles"],
          },
          {
            title: "pages.roles.create",
            path: "/roles/manage",
            type: "link",
            scopes: ["Roles:Create"],
          },
        ],
      },
      {
        title: "pages.logs.logMenu",
        icon: Database,
        type: "sub",
        scopes: ["RequestLogs:RequestLogs", "Logs:Logs"],
        children: [
          {
            title: "pages.logs.logs",
            path: "/logs",
            type: "link",
            scopes: ["Logs:Logs"],
          },
          {
            title: "pages.requestLogs.requestLogs",
            path: "/request-logs",
            type: "link",
            scopes: ["Roles:Roles"],
          },
        ],
      },
    ],
  },
];

/*
for (let m = 0; m < MAINMENU.length; m++) {
  for (let r = 0; r < MAINMENU[m].items.length; r++) {
    if (MAINMENU[m].items[r].path) {
      let rouParts = MAINMENU[m].items[r].path.split("/");
      for (let i = 0; i < rouParts?.length; i++) {
        if (rouParts[i].substring(0, 1) === ":") {
          if (i <= locParts.length) {
            rouParts[i] = locParts[i];
          }
        }
      }
      MAINMENU[m].items[r].path = rouParts.join("/");
    }
    if (MAINMENU[m].items[r].children?.length) {
      for (let c1 = 0; c1 < MAINMENU[m].items[r].children.length; c1++) {
        if (MAINMENU[m].items[r].children[c1].path) {
          let subRouParts = MAINMENU[m].items[r].children[c1].path.split("/");
          for (let o = 0; o < subRouParts?.length; o++) {
            if (subRouParts[o].substring(0, 1) === ":") {
              if (o <= locParts.length) {
                subRouParts[o] = locParts[o];
              }
            }
          }
          MAINMENU[m].items[r].children[c1].path = subRouParts.join("/");
        }
        if (MAINMENU[m].items[r].children[c1].children?.length) {
          for (let c2 = 0; c2 < MAINMENU[m].items[r].children.length; c2++) {
            if (MAINMENU[m].items[r].children[c1].children[c2].path) {
              let subSubRouParts = MAINMENU[m].items[r].children[c1].children[
                c2
              ].path.split("/");
              for (let p = 0; p < subSubRouParts?.length; p++) {
                if (subSubRouParts[p].substring(0, 1) === ":") {
                  if (p <= locParts.length) {
                    subSubRouParts[p] = locParts[p];
                  }
                }
              }
              MAINMENU[m].items[r].children[c1].children[
                c2
              ].path = subSubRouParts.join("/");
            }
          }
        }
      }
    }
  }
}
/**/

export default MAINMENU;
