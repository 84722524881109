import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Input, Label, Card, CardBody, CardHeader } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useApollo } from "../../services/apollo.service";
import { SITE_TITLE } from "../../configs/constants";
import { gql } from "@apollo/client";
import Board, { moveCard } from "@lourenci/react-kanban";
import Kanban from "../../models/kanban";
import Funnel from "../../models/funnel";
import '@lourenci/react-kanban/dist/styles.css'
import { useHistory } from "react-router-dom";
import Loading from "../../components/loading";

interface BudgetsKanban {
  budgetsKanban: Kanban;
}

const BudgetsKanban: React.FC = () => {
  const isMountedRef = useRef(null);
  const history = useHistory();
  const intl = useIntl();
  const { apollo, error } = useApollo();
  const [boards, setBoards] = useState<Kanban>(() => {
    const board: Kanban = {
      columns: []
    }
    return board;
  });
  const [funnels, setFunnels] = useState<Funnel[]>([]);
  const [funnelIds, setFunnelIds] = useState<string[]>([]);
  const [selectedFunnels, setSelectedFunnels] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const handleFunnels = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const index = funnelIds.indexOf(value);
    //const kanbanIndex = boards.columns.findIndex(e => e.id === value);
    if (index >= 0) {
      funnelIds.splice(index, 1);
      setFunnelIds(funnelIds);
    } else {
      funnelIds.push(value);
      setFunnelIds(funnelIds);
    }
    setSelectedFunnels({ ...selectedFunnels, [value]: e.target.checked });
  };

  const handleCardMove = async (_card: any, source: any, destination: any) => {
    const oldBoards = boards;
    const updatedBoards: Kanban = moveCard(boards, source, destination);
    setBoards(updatedBoards);

    let ids: string[] = [];
    updatedBoards.columns?.filter(e => e.id === destination.toColumnId)?.map(e => e.cards?.map(e => ids.push(e.id)));

    await Promise.all([
      apollo.mutate({
        mutation: gql`
          mutation budgetKanbanMoveCard ($id: String!, $source: String!, $destination: String!, $sort: Int) {
            budgetKanbanMoveCard (id: $id, source: $source, destination: $destination, sort: $sort) {
              id
              sortOrder
              funnel {
                id
                name
              }
            }
          }
        `,
        variables: {
          id: _card.id,
          source: source.fromColumnId,
          destination: destination.toColumnId,
          sort: destination.toPosition
        }
      }),
      apollo.mutate({
        mutation: gql`
          mutation budgetKanbanMoveCards ($ids: [String!]!) {
            budgetKanbanMoveCards (ids: $ids) {
              id
              sortOrder
              funnel {
                id
                name
              }
            }
          }
        `,
        variables: {
          ids
        }
      })
    ])
      .then(res => {
      })
      .catch(err => {
        error(err);
        setBoards(oldBoards);
      });
  };

  const handleData = useCallback(async () => {
    setLoading(true);
    await Promise.all([
      apollo.query({
        query: gql`
          query funnelsDropdown {
            funnelsDropdown{
              id
              name
              sortOrder
              isHidden
            }
          }
        `,
      }),
      apollo.query<BudgetsKanban>({
        query: gql`
          query budgetsKanban {
            budgetsKanban {
              columns {
                id
                title
                cards {
                  id
                  title
                  date
                  code
                  sortOrder
                }
              }
            }
          }
        `,
      }),
    ])
      .then(res => {
        if (isMountedRef.current) {
          setFunnels(res[0].data.funnelsDropdown);
          setFunnelIds(res[0].data.funnelsDropdown?.filter((e: Funnel) => e.isHidden === false)?.map((funnel: Funnel) => funnel.id));
          let funnelsObj = {};
          for (const funnel of res[0].data.funnelsDropdown) {
            if (!funnel.isHidden) {
              // const storageFunnels = localStorage.getItem("kanban_funnels");
              // let storageFunnelIds: string[] = [];
              // if (!!storageFunnels) {
              //   storageFunnelIds = JSON.parse(storageFunnels);
              // }
              funnelsObj = { ...funnelsObj, [funnel.id]: false };
            }
          }
          setSelectedFunnels(funnelsObj);
          setBoards(res[1].data.budgetsKanban);
        }
      })
      .catch(err => error(err));
    setLoading(false);
  }, [apollo, error]);

  useEffect(() => {
    isMountedRef.current = true;
    if (isMountedRef.current) {
      document.title = `${SITE_TITLE
        } :: ${intl.formatMessage({
          id: "pages.budgets.funnel2",
        })}`;
      handleData();
    }
    return () => { isMountedRef.current = false; }
  }, [intl, handleData]);

  return (
    <Fragment>
      <Breadcrumb parent="general.home" title="pages.budgets.budgets" />
      <Container fluid={true} className="hidden">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>
                  <FormattedMessage id="pages.budgets.funnel2" />
                </h5>
              </CardHeader>
              <CardBody>
                <Row>
                  {funnels?.map((funnel) => {
                    return (
                      <Col
                        md="3"
                        key={`col-funnel-${funnel.id}`}
                        className="checkbox m-b-5"
                      >
                        <Input
                          key={`input-funnel-${funnel.id}`}
                          id={`funnel-${funnel.id}`}
                          type="checkbox"
                          value={funnel.id}
                          checked={
                            selectedFunnels.hasOwnProperty(
                              funnel.id
                            )
                              ? selectedFunnels[funnel.id]
                              : false
                          }
                          onChange={handleFunnels}
                        />
                        <Label
                          key={`label-funnel-${funnel.id}`}
                          className="d-block"
                          for={`funnel-${funnel.id}`}
                        >
                          {funnel.name}
                        </Label>
                      </Col>
                    );
                  })}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid={true} className="jkanban-container">
        {loading ? <Loading /> : (
          <Row>
            <Col>
              <div id="boards-kanban-1">
                <div className="kanban-container">
                  <div className="kanban-board">
                    <main className="kanban-drag">
                      {boards.columns.length ? (<Board
                        disableColumnDrag
                        onCardDragEnd={async (_card: any, source: any, destination: any) => await handleCardMove(_card, source, destination)}
                        renderCard={(card: any) => (
                          <div key={card.id} className="kanban-item" onClick={() => {
                            history.push(`/budgets/preview/${card.id}?backTo=/budgets/funnel`);
                          }}>
                            <a className="kanban-box" href="#javascript">
                              <span className="date">{card.date}</span>
                              <h6>{card.code} - {card.title}</h6>
                            </a>
                          </div>
                        )}
                      >
                        {boards}
                      </Board>)
                        : ""}
                    </main>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </Fragment>
  );
};

export default BudgetsKanban;
