import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Form, Row } from "reactstrap";
import { X } from "react-feather";
import LeftHeader from "./leftbar";
import RightHeader from "./rightbar";
import { Link } from "react-router-dom";
import MENUITEMS, { MenuItem } from "../../configs/menu";
import { FormattedMessage, useIntl } from "react-intl";
import { useAuth } from "../../hooks/auth";
import { useDispatch, useSelector } from "react-redux";
import { ICombineReducers } from "../../redux";
import { toggleResponsiveSearch } from "../../redux/actions";

const Header: React.FC = (props) => {
  // eslint-disable-next-line
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [searchValue, setSearchValue] = useState<string>("");
  // eslint-disable-next-line
  const [searchResult, setSearchResult] = useState<MenuItem[]>([]);
  // eslint-disable-next-line
  const [searchResultEmpty, setSearchResultEmpty] = useState<boolean>(false);
  const { hasScopes } = useAuth();
  const state = useSelector((state: ICombineReducers) => state.State);
  const layout_type = useSelector((content: ICombineReducers) => content.Customizer.layout)
  const layout_version = useSelector((content: ICombineReducers) => content.Customizer.mix_background_layout)
  const dispatch = useDispatch();
  const intl = useIntl();

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setSearchValue("");
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const handleSearchKeyword = (keyword: string) => {
    keyword ? addFix() : removeFix();
    const items: MenuItem[] = [];
    setSearchValue(keyword);
    mainmenu.map((menuItems) => {
      menuItems.items.filter((mItems) => {
        if (
          mItems.title.toLowerCase().includes(keyword) &&
          mItems.type === "link" &&
          mItems.hidden !== true
        ) {
          items.push(mItems);
        }
        if (!mItems.children) return false;
        mItems.children.filter((subItems) => {
          if (
            subItems.title.toLowerCase().includes(keyword) &&
            subItems.type === "link" &&
            subItems.hidden !== true
          ) {
            subItems.icon = mItems.icon;
            items.push(subItems);
          }
          if (!subItems.children) return false;
          subItems.children.filter((suSubItems) => {
            if (
              suSubItems.title.toLowerCase().includes(keyword) &&
              suSubItems.hidden !== true
            ) {
              suSubItems.icon = mItems.icon;
              items.push(suSubItems);
            }
            return suSubItems;
          });
          return subItems;
        });
        checkSearchResultEmpty(items);
        setSearchResult(items);
        return mItems;
      });
      return menuItems;
    });
  };

  const checkSearchResultEmpty = (items: MenuItem[]) => {
    if (!items.length) {
      setSearchResultEmpty(true);
      document.querySelector(".empty-menu").classList.add("is-open");
    } else {
      setSearchResultEmpty(false);
      document.querySelector(".empty-menu").classList.remove("is-open");
    }
  };

  const addFix = () => {
    setSearchResult([]);
    document.querySelector(".Typeahead-menu").classList.add('is-open');
    document.body.className = `${layout_version} ${layout_type} offcanvas`
  };

  const removeFix = () => {
    setSearchResult([]);
    setSearchValue("");
    document.querySelector(".Typeahead-menu").classList.remove('is-open');
    document.body.className = `${layout_version} ${layout_type}`
  };

  const handleClose = () => {
    dispatch(toggleResponsiveSearch(!state.responsiveSearch));
    document.querySelector(".search-full").classList.remove("open");
  };

  return (
    <Fragment>
      <div className="page-header">
        <Row className="header-wrapper m-0">
          <Form className="form-inline search-full" action="#" method="get">
            <div className="form-group w-100">
              <div className="Typeahead Typeahead--twitterUsers">
                <div className="u-posRelative">
                  <input
                    className="Typeahead-input form-control-plaintext w-100"
                    id="header-input-search"
                    type="search"
                    placeholder={
                      intl.formatMessage({ id: "inputs.search" }) + ".."
                    }
                    defaultValue={searchValue}
                    onChange={(e) => handleSearchKeyword(e.target.value)}
                  />
                  <div
                    className="spinner-border Typeahead-spinner"
                    role="status"
                  >
                    <span className="sr-only">
                      <FormattedMessage id="general.loading" />
                      ...
                    </span>
                  </div>
                  <X className="close-search" onClick={handleClose} />
                </div>
                <div
                  className="Typeahead-menu custom-scrollbar"
                  id="search-outer"
                >
                  {searchResult
                    ? searchResult.map((data, index) => {
                      if (
                        !data.scopes ||
                        (data.scopes && hasScopes(data.scopes))
                      )
                        return (
                          <div className="ProfileCard u-cf" key={index}>
                            <div className="ProfileCard-avatar">
                              <data.icon />
                            </div>
                            <div className="ProfileCard-details">
                              <div className="ProfileCard-realName">
                                <Link
                                  to={data.path}
                                  className="realname"
                                  onClick={removeFix}
                                >
                                  <FormattedMessage id={data.title} />
                                </Link>
                              </div>
                            </div>
                          </div>
                        );
                      return "";
                    })
                    : ""}
                </div>
                <div className="Typeahead-menu empty-menu">
                  <div className="tt-dataset tt-dataset-0">
                    <div className="EmptyMessage">
                      <FormattedMessage id="search.noResults" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
          <LeftHeader />
          <RightHeader />
        </Row>
      </div>
    </Fragment>
  );
};

export default Header;
