import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Breadcrumb from "../../layout/breadcrumb";
import { CrudParam } from "../../configs/route";
import { useApollo } from "../../services/apollo.service";
import { convert_to_date, escapedNewLineToLineBreakTag, SITE_TITLE } from "../../configs/constants";
import Bowser from "bowser";
import { useAuth } from "../../hooks/auth";
import { toast } from "react-toastify";
import { gql } from "@apollo/client";
import { useSelector } from "react-redux";
import { ICombineReducers } from "../../redux";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import pt_BR from "date-fns/locale/pt-BR";
import Loading from "../../components/loading";

const Manage: React.FC = () => {
  const isMountedRef = useRef(null);
  const { apollo, error } = useApollo();
  const { id } = useParams<CrudParam>();
  const [token, setToken] = useState<string>("");
  const [ipAddress, setIpAddress] = useState<string>("");
  const [userAgent, setUserAgent] = useState<string>("");
  const [requestBody, setRequestBody] = useState<string>("");
  const [origin, setOrigin] = useState<string>("");
  const [user, setUser] = useState<string>("");
  const [referrer, setReferrer] = useState<string>("");
  const [createdAt, setCreatedAt] = useState<Date>(null);
  const [preview] = useState<boolean>(() => { if (id) return true; else return false; });
  const locale = useSelector((state: ICombineReducers) => state.State.locale);
  const history = useHistory();
  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>(false);

  const { hasScope } = useAuth();
  const [canView] = useState<boolean>(() => {
    return hasScope("RequestLogs:RequestLog");
  });
  const [canCreate] = useState<boolean>(() => {
    return hasScope("RequestLogs:Create");
  });
  const [canUpdate] = useState<boolean>(() => {
    return hasScope("RequestLogs:Update");
  });

  const handleData = useCallback(async () => {
    if (id) {
      if (preview && !canView) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }) + '1');
        history.push("/request-logs");
        return;
      } else if (!preview && !canUpdate) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }) + '2');
        history.push("/request-logs");
        return;
      }
      setLoading(true);
      await apollo.query({
        query: gql`
            query requestLog($id: String!) {
              requestLog(id: $id) {
                id
                createdAt
                updatedAt
                token
                ipAddress
                userAgent
                referrer
                requestBody
                origin
                user {
                  id
                  name
                  surname
                }
              }
            }
          `,
        variables: {
          id
        },
      })
        .then((res) => {
          if (isMountedRef.current) {
            setCreatedAt(res.data.requestLog.createdAt ? convert_to_date(res.data.requestLog.createdAt) : null);
            setToken(res.data.requestLog.token || "");
            setIpAddress(res.data.requestLog.ipAddress || "");
            setRequestBody(res.data.requestLog.requestBody || "");
            setOrigin(res.data.requestLog.origin || "");
            if (res.data.requestLog.user)
              setUser(`${res.data.requestLog.user.name} ${res.data.requestLog.user.surname}`);
            setReferrer(res.data.requestLog.referrer || "");
            if (res.data.requestLog.userAgent) {
              const browser = Bowser.parse(res.data.requestLog.userAgent);
              let userAgent = "";
              if (browser.browser.name)
                userAgent += `${browser.browser.name} `;
              if (browser.browser.version)
                userAgent += `${browser.browser.version}`;
              if (browser.browser.name && browser.os.name)
                userAgent += " / ";
              if (browser.os.name)
                userAgent += `${browser.os.name} `;
              if (browser.os.version)
                userAgent += `${browser.os.version} `;
              if (browser.os.versionName)
                userAgent += `(${browser.os.versionName})`;
              setUserAgent(userAgent);
            }
          }
        })
        .catch((err) => error(err));
      setLoading(false);
    } else {
      if (!preview && !canCreate) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }) + "0");
        history.push("/request-logs");
        return;
      }
    }
  }, [intl, id, preview, apollo, error, history, canView, canCreate, canUpdate]);

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: `pages.requestLogs.${preview ? "preview" : id ? "update" : "create"}`,
      })}`;
    if (locale === "pt") {
      registerLocale("pt-BR", pt_BR);
      setDefaultLocale("pt-BR");
    }
    handleData();
    return () => { isMountedRef.current = false; }
  }, [intl, id, preview, handleData, locale]);

  return (
    <Fragment>
      <Breadcrumb
        parent="pages.requestLogs.requestLogs"
        title={`pages.requestLogs.${preview ? "preview" : id ? "update" : "create"}`}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>
                  <FormattedMessage
                    id={`pages.requestLogs.${preview ? "preview" : id ? "update" : "create"
                      }`}
                  />
                </h5>
              </CardHeader>
              <CardBody className="tabbed-card">
                <div className="form-content">
                  <Form onSubmit={(e) => e.preventDefault()}>
                    <Row className="m-b-30">
                      <Col>
                        {loading ? <Loading /> : (
                          <React.Fragment>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.token" />
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={token}
                                    disabled={preview}
                                    required
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.ipAddress" />
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={ipAddress}
                                    disabled={preview}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.userAgent" />
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={userAgent}
                                    disabled={preview}
                                    required
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.origin" />
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={origin}
                                    disabled={preview}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.user" />
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    value={user}
                                    disabled={preview}
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.referrer" />
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={referrer}
                                    disabled={preview}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.createdAt" />
                                  </Label>
                                  <DatePicker
                                    className="form-control"
                                    showPopperArrow={false}
                                    disabled={preview}
                                    selected={createdAt}
                                    onChange={(date: Date) => setCreatedAt(date)}
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={30}
                                    autoComplete="off"
                                    timeInputLabel="Hora"
                                    required
                                  />
                                </FormGroup>
                              </Col>
                              <Col></Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.requestBody" />
                                  </Label>
                                  <div style={{ whiteSpace: "pre-wrap" }}>
                                    <code style={{ display: "block", borderRadius: "10px", border: "1px solid #e6e6e6", padding: "10px" }}>{escapedNewLineToLineBreakTag(requestBody)}</code>
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          type="button"
                          color="danger"
                          onClick={() => history.push("/request-logs")}
                        >
                          <i className="fa fa-arrow-left"></i>
                          <FormattedMessage id="buttons.cancel" />
                        </Button>
                      </Col>
                      <Col className="text-right">

                      </Col>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Manage;
