import React, {
  Fragment,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
  CSSProperties,
} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Form, FormGroup, Input, Label } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { IDataTableColumn } from "react-data-table-component";
import SweetAlert from "sweetalert2";
import { useApollo } from "../../services/apollo.service";
import { useHistory } from "react-router-dom";
import Client from "../../models/client";
import { RECORDS_PER_PAGE, SITE_TITLE } from "../../configs/constants";
import Listing from "../../components/listing";
import { useAuth } from "../../hooks/auth";
import { gql } from "@apollo/client";
import { useDispatch } from "react-redux";
import { dataTableLoading } from "../../redux/actions";
import ClientProfile from "./client-profile";
import formatter from "../../utils/formatter";
import State from "../../models/state";
import City from "../../models/city";

interface ClientFilters {
  code?: string | null;
  companyName?: string | null;
  fantasyName?: string | null;
  contact?: string | null;
  document?: string | null;
  stateId?: string | null;
  cityId?: string | null;
}

const Clients: React.FC = () => {
  const isMountedRef = useRef(null);
  const dispatch = useDispatch();
  const { apollo, error } = useApollo();
  const intl = useIntl();
  const history = useHistory();
  const [selectedRows, setSelectedRows] = useState<Client[]>([]);
  const [toggleCleared, setToggleCleared] = useState<boolean>(false);
  const [data, setData] = useState<Client[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(RECORDS_PER_PAGE);
  const [sortBy, setSortBy] = useState<string>("createdAt");
  const [sortDir, setSortDir] = useState<string>("DESC");
  const [filters, setFilters] = useState<ClientFilters>();
  const [filters2, setFilters2] = useState<ClientFilters>();
  const [states, setStates] = useState<State[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [disableSubmitSearch, setDisableSubmitSearch] = useState<boolean>(false);

  const { hasScope } = useAuth();
  const [canDelete] = useState<boolean>(() => {
    return hasScope("Clients:Delete");
  });

  const handleCities = useCallback(async (id: string) => {
    setFilters({ ...filters, stateId: id, cityId: "" });
    if (id) {
      setDisableSubmitSearch(true);
      await apollo.query({
        query: gql`
        query stateCities($id: String!, $withClients: Boolean) {
          stateCities(id: $id, withClients: $withClients) {
            id
            name
            code
            state {
              id
              name
              fc
            }
          }
        }
      `,
        variables: {
          id,
          withClients: true
        }
      })
        .then(res => {
          setCities(res.data.stateCities);
        })
        .catch(err => error(err));
      setDisableSubmitSearch(false);
    } else {
      setCities([]);
    }
  }, [apollo, error, filters]);

  const formatDocument = (document: string): string => {
    document = document.trim().replace(/\D/gm, "");

    // Formata o documento do usuário
    if (document.length === 11) {
      document = formatter.formatCpf(document);
    } else if (document.length === 14) {
      document = formatter.formatCnpj(document);
    }
    return document;
  }

  const handleData = useCallback(async () => {
    dispatch(dataTableLoading(true));
    await Promise.all([
      apollo.query({
        query: gql`
          query countryStates($iso: String, $withClients: Boolean) {
            countryStates(iso: $iso, withClients: $withClients) {
              id
              name
              fc
            }
          }
        `,
        variables: {
          iso: "BR",
          withClients: true
        }
      }),
      apollo.query({
        query: gql`
          query clients ($sortDir: String, $sortBy: String, $perPage: Int, $page: Int,  $includeDashboards: Boolean, $dashboardProductBestSellersLimit: Int, $filterByCode: String, $filterByContact: String, $filterByFantasyName: String, $filterByCompanyName: String, $filterByDocument: String, $filterByState: String, $filterByCity: String) {
            clients (sortDir: $sortDir, sortBy: $sortBy, perPage: $perPage, page: $page, includeDashboards: $includeDashboards, dashboardProductBestSellersLimit: $dashboardProductBestSellersLimit, filterByCode: $filterByCode, filterByContact: $filterByContact, filterByFantasyName: $filterByFantasyName, filterByCompanyName: $filterByCompanyName, filterByDocument: $filterByDocument, filterByState: $filterByState, filterByCity: $filterByCity) {
              paging {
                total
                pages
                perPage
                currentPage
              }
              list {
                id
                createdAt
                updatedAt
                companyName
                fantasyName
                clientKind
                personKind
                taxGroup
                document
                sr
                mr
                idCard
                phone
                mobile
                email
                site
                billingEmail
                howKnowUs
                contact
                foundationDate
                notes
                discount
                cnae {
                  id
                  code
                  description
                }
                merchandiseDestination
                isTaxPayerSt
                isTaxPayerIcms
                isNationalSimple
                companyOrServiceSize
                suframa
                creditScore
                creditLimit
                creditLimitExpires
                unpaidOrders
                dangerLevel
                latestOrder
                nextSchedule
                address
                addressNumber
                addressComplement
                addressNeighborhood
                addressPostcode
                billingAddress
                billingAddressNumber
                billingAddressComplement
                billingAddressNeighborhood
                billingAddressPostcode
                deliveryAddress
                deliveryAddressNumber
                deliveryAddressComplement
                deliveryAddressNeighborhood
                deliveryAddressPostcode
                protheusId
                isActivated
                dashboards {
                  productBestSellers {
                    product {
                      id
                      name
                      protheusId
                    }
                    total
                  }
                }
                addressCountry {
                  id
                  name
                }
                addressState {
                  id
                  name
                  fc
                  code
                }
                addressCity {
                  id
                  name
                  code
                }
                billingAddressCountry {
                  id
                  name
                }
                billingAddressState {
                  id
                  name
                  fc
                  code
                }
                billingAddressCity {
                  id
                  name
                  code
                }
                deliveryAddressCountry {
                  id
                  name
                }
                deliveryAddressState {
                  id
                  name
                  fc
                  code
                }
                deliveryAddressCity {
                  id
                  name
                  code
                }
                areaCode {
                  id
                  code
                  protheusId
                }
              }
            }
          }
        `,
        variables: {
          page,
          perPage,
          sortBy,
          sortDir,
          includeDashboards: true,
          dashboardProductBestSellersLimit: 10,
          filterByContact: filters2?.contact,
          filterByFantasyName: filters2?.fantasyName,
          filterByCompanyName: filters2?.companyName,
          filterByCode: filters2?.code,
          filterByDocument: filters2?.document,
          filterByState: filters2?.stateId,
          filterByCity: filters2?.cityId,
        }
      })
    ])
      .then(res => {
        if (isMountedRef.current) {
          setStates(res[0].data.countryStates);
          setTotal(res[1].data.clients.paging.total);
          setPage(res[1].data.clients.paging.currentPage);
          setPerPage(res[1].data.clients.paging.perPage);
          setData(res[1].data.clients.list);
        }
      })
      .catch(err => error(err));
    dispatch(dataTableLoading(false));
  }, [apollo, error, page, perPage, sortBy, sortDir, dispatch, filters2]);

  const handleSearch = () => {
    setPage(1);
    setFilters2(filters);
  };

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: "pages.clients.clients",
      })}`;
    handleData();
    return () => { isMountedRef.current = false; }
  }, [intl, handleData]);

  const style: CSSProperties = {
    color: "rgba(0, 0, 0, 0.3)",
    fontWeight: 300,
    fontStyle: "italic"
  };

  const tableColumns: IDataTableColumn<Client>[] = [
    {
      name: intl.formatMessage({ id: "inputs.protheusId" }),
      selector: "protheusId",
      sortable: true,
      center: false,
      format: (row) => {
        return row.protheusId || "-";
      },
      conditionalCellStyles: [
        {
          when: (row) => !row.isActivated,
          style
        }
      ],
    },
    {
      name: intl.formatMessage({ id: "inputs.companyName" }),
      selector: "companyName",
      sortable: true,
      center: false,
      conditionalCellStyles: [
        {
          when: (row) => !row.isActivated,
          style
        }
      ],
    },
    {
      name: intl.formatMessage({ id: "inputs.fantasyName" }),
      selector: "fantasyName",
      sortable: true,
      center: false,
      conditionalCellStyles: [
        {
          when: (row) => !row.isActivated,
          style
        }
      ],
    },
    {
      name: intl.formatMessage({ id: "inputs.contact" }),
      selector: "contact",
      sortable: true,
      center: false,
      conditionalCellStyles: [
        {
          when: (row) => !row.isActivated,
          style
        }
      ],
    },
    {
      name: intl.formatMessage({ id: "inputs.cpfCnpj" }),
      selector: "document",
      sortable: true,
      center: false,
      format: (row) => {
        return formatDocument(row.document);
      },
      conditionalCellStyles: [
        {
          when: (row) => !row.isActivated,
          style
        }
      ],
    },
    {
      name: intl.formatMessage({ id: "inputs.state" }),
      selector: "addressState",
      sortable: true,
      center: false,
      format: (row) => {
        return row.addressState?.fc || "-";
      },
      conditionalCellStyles: [
        {
          when: (row) => !row.isActivated,
          style
        }
      ],
    },
    {
      name: intl.formatMessage({ id: "inputs.city" }),
      selector: "addressCity",
      sortable: true,
      center: false,
      format: (row) => {
        return row.addressCity?.name || "-";
      },
      conditionalCellStyles: [
        {
          when: (row) => !row.isActivated,
          style
        }
      ],
    },
    {
      name: intl.formatMessage({ id: "inputs.isActivated" }),
      selector: "isActivated",
      format: (row) => {
        return row.isActivated
          ? intl.formatMessage({ id: "general.yes" })
          : intl.formatMessage({ id: "general.no" });
      },
      sortable: true,
      center: false,
      conditionalCellStyles: [
        {
          when: (row) => !row.isActivated,
          style
        }
      ],
    },
  ];

  const handleUpdateRecord = (row: Client) => {
    if (hasScope("Clients:Client"))
      history.push(`/clients/manage/${row.id}`);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      SweetAlert.fire({
        title: intl.formatMessage({ id: "general.exclusion" }),
        text: intl.formatMessage({ id: "flash.confirm.delete" }),
        icon: "error",
        cancelButtonText: intl.formatMessage({ id: "general.no" }),
        confirmButtonText: intl.formatMessage({ id: "general.yes" }),
        reverseButtons: true,
        showCancelButton: true,
      })
        .then(async ({ isConfirmed }) => {
          if (isConfirmed) {
            for (let row of selectedRows) {
              await apollo.mutate({
                mutation: gql`
                  mutation deleteClient($id: String!) {
                    deleteClient(id: $id) {
                        id
                        companyName
                    }
                  }
                `,
                variables: {
                  id: row.id
                },
              })
                .then(res => {
                  toast.success(
                    intl.formatMessage(
                      { id: "flash.success.deletedParam" },
                      { description: row.companyName }
                    )
                  );
                })
                .catch(err => error(err));
            }
            setToggleCleared(!toggleCleared);
            handleData();
          }
        });
    };

    return (
      <button key="delete" className="btn btn-danger" onClick={handleDelete}>
        <FormattedMessage id="buttons.delete" />
      </button>
    );
  }, [selectedRows, apollo, error, toggleCleared, intl, handleData]);

  return (
    <Fragment>
      <Breadcrumb parent="general.home" title="pages.clients.clients" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>
                  <FormattedMessage id="pages.clients.list" />
                </h5>
              </CardHeader>
              <CardBody>
                <Form onSubmit={async (e) => {
                  e.preventDefault();
                  setDisableSubmitSearch(true);
                  handleSearch();
                  setDisableSubmitSearch(false);
                }}>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label">
                          <FormattedMessage id="inputs.code" />
                        </Label>
                        <Input
                          key="code"
                          className="form-control"
                          type="text"
                          value={filters?.code || ""}
                          disabled={disableSubmitSearch}
                          onChange={(e) => {
                            setFilters({ ...filters, code: e.target.value });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label">
                          <FormattedMessage id="inputs.companyName" />
                        </Label>
                        <Input
                          key="companyName"
                          className="form-control"
                          type="text"
                          value={filters?.companyName || ""}
                          disabled={disableSubmitSearch}
                          onChange={(e) => {
                            setFilters({ ...filters, companyName: e.target.value });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label">
                          <FormattedMessage id="inputs.fantasyName" />
                        </Label>
                        <Input
                          key="fantasyName"
                          className="form-control"
                          type="text"
                          value={filters?.fantasyName || ""}
                          disabled={disableSubmitSearch}
                          onChange={(e) => {
                            setFilters({ ...filters, fantasyName: e.target.value });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label">
                          <FormattedMessage id="inputs.contact" />
                        </Label>
                        <Input
                          key="contact"
                          className="form-control"
                          type="text"
                          value={filters?.contact || ""}
                          disabled={disableSubmitSearch}
                          onChange={(e) => {
                            setFilters({ ...filters, contact: e.target.value });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label">
                          <FormattedMessage id="inputs.cpfCnpj" />
                        </Label>
                        <Input
                          key="document"
                          className="form-control"
                          type="text"
                          value={filters?.document || ""}
                          disabled={disableSubmitSearch}
                          onChange={(e) => {
                            setFilters({ ...filters, document: e.target.value });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label">
                          <FormattedMessage id="inputs.state" />
                        </Label>
                        <Input
                          key="state"
                          className="form-control"
                          type="select"
                          value={filters?.stateId || ""}
                          disabled={disableSubmitSearch}
                          onChange={(e) => {
                            handleCities(e.target.value);
                          }}
                        >
                          <option value="">-</option>
                          {states?.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label">
                          <FormattedMessage id="inputs.city" />
                        </Label>
                        <Input
                          key="city"
                          className="form-control"
                          type="select"
                          value={filters?.cityId || ""}
                          disabled={disableSubmitSearch}
                          onChange={(e) => {
                            setFilters({ ...filters, cityId: e.target.value });
                          }}
                        >
                          <option value="">-</option>
                          {cities?.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col className="text-right">
                      <FormGroup>
                        <Label className="col-form-label" style={{ display: "block", width: "100%" }}>
                          &nbsp;
                        </Label>
                        <div>
                          <Button type="submit" disabled={disableSubmitSearch} color="secondary">
                            <i className="fa fa-search"></i> Filtrar
                          </Button>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                <Listing
                  data={data}
                  total={total}
                  selectable={canDelete}
                  rowsPerPage={perPage}
                  onChangePage={(page: number) => {
                    setPage(page);
                  }}
                  onChangeRowsPerPage={(rowsPerPage: number) => {
                    setPage(1);
                    setPerPage(rowsPerPage);
                  }}
                  onSort={(
                    column,
                    sortDir
                  ) => {
                    setSortBy(column.selector.toString());
                    setSortDir(sortDir);
                  }}
                  expandableRows
                  expandableRowsComponent={<ClientProfile />}
                  columns={tableColumns}
                  onRowClicked={handleUpdateRecord}
                  contextActions={contextActions}
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleCleared}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Clients;
