import React, { Fragment, useEffect, useRef, useState } from "react";
import { BrowserRouter, Redirect, Route, RouteChildrenProps, Switch, useLocation } from "react-router-dom";
import Login from "./views/authentication/login";
import ForgotPassword from "./views/authentication/forgetpwd";
import ResetPassword from "./views/authentication/resetpwd";
import { useAuth } from "./hooks/auth";
import ConfigDB from "./configs/theme";
import routes from "./configs/route";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import App from "./components/app";
import NotAuthorized from "./views/errors/notauthorized";
import NotFound from "./views/errors/notfound";
import Register from "./views/authentication/register";

const Routes: React.FC = () => {
  const isMountedRef = useRef(null);
  const { hasAnyScopes, logged } = useAuth();
  const location = useLocation();
  const animation =
    localStorage.getItem("animation") ||
    ConfigDB.data.router_animation ||
    "fade";
  const [anim, setAnim] = useState(animation);

  let routesWithParam: string[] = [];
  for (let r = 0; r < routes.length; r++) {
    let locParts = location.pathname.split("/");
    let rouParts = routes[r].path.split("/");

    for (let i = 0; i < rouParts.length; i++) {
      if (rouParts[i].substring(0, 1) === ":") {
        if (i <= locParts.length) {
          rouParts[i] = locParts[i];
        }
      }
    }
    routesWithParam.push(rouParts.join("/"));
  }

  useEffect(() => {
    isMountedRef.current = true;
    if (isMountedRef.current)
      setAnim(animation);
    return () => { isMountedRef.current = false; }
  }, [animation, anim]);

  return (
    <Fragment>
      <BrowserRouter basename="/">
        <Switch>
          <Route path={`${process.env.PUBLIC_URL}/login`} exact component={Login} />
          <Route path={`${process.env.PUBLIC_URL}/register`} exact component={Register} />
          <Route path={`${process.env.PUBLIC_URL}/forgot-password`} exact component={ForgotPassword} />
          <Route path={`${process.env.PUBLIC_URL}/reset-password`} exact component={ResetPassword} />
          {logged ? (
            <App>
              <TransitionGroup>
                <Switch>
                  {routes?.map(({ path, scopes, Component }) => (
                    <Route
                      key={path}
                      exact
                      path={`${process.env.PUBLIC_URL}${path}`}
                      children={(props: RouteChildrenProps<any>) => {
                        if (scopes) {
                          if (!hasAnyScopes(scopes))
                            return (
                              <CSSTransition
                                in={props.match !== null}
                                timeout={100}
                                classNames={anim}
                                unmountOnExit
                              >
                                <div>
                                  <NotAuthorized />
                                </div>
                              </CSSTransition>
                            );
                        }
                        return (
                          <CSSTransition
                            in={props.match !== null}
                            timeout={100}
                            classNames={anim}
                            unmountOnExit
                          >
                            <div>
                              <Component {...props} />
                            </div>
                          </CSSTransition>
                        );
                      }}
                    />
                  ))}
                  <Route component={NotFound} />
                </Switch>
              </TransitionGroup>
            </App>
          )
            :
            <Redirect to={`${process.env.PUBLIC_URL}/login`} />
          }
        </Switch>
      </BrowserRouter>
    </Fragment>
  );
};

export default Routes;
