import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Row, Col, Form, FormGroup, Input, Label, Button } from "reactstrap";
import AppAuth from "../../components/app.auth";
import { SITE_TITLE } from "../../configs/constants";
import { useAuth } from "../../hooks/auth";

const Forgetpwd: React.FC = () => {
  const isMountedRef = useRef(null);
  const intl = useIntl();
  const { recoverPassword } = useAuth();
  const history = useHistory();
  const [email, setEmail] = useState<string>("")
  const [submitDisable, setSubmitDisable] = useState<boolean>(false);

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: "pages.forgetPassword.title",
      })}`;
    return () => { isMountedRef.current = false; }
  }, [intl]);


  return (
    <AppAuth>
      <Container fluid={true} className="p-0">
        <Row className="m-0">
          <Col xs="12" className="p-0">
            <div className="login-card">
              <div>
                <div>
                  <a className="logo" href="#javascript">
                    <img className="img-fluid for-light" src={require("../../assets/images/logo/logo_stark.png")} alt="" />
                    <img className="img-fluid for-dark" src={require("../../assets/images/logo/logo_dark_stark.png")} alt="" />
                  </a>
                </div>
                <div className="login-main">
                  <Form className="theme-form" onSubmit={async (e) => {
                    e.preventDefault();
                    setSubmitDisable(true);
                    await recoverPassword(email)
                      .then(res => {
                        if (res) {
                          toast.success(
                            intl.formatMessage({
                              id: "flash.success.forgotPassword",
                            })
                          );
                          setTimeout(() => history.push("/login"), 1500);
                        }
                      });
                    setSubmitDisable(false);
                  }}>
                    <h4><FormattedMessage id="pages.forgetPassword.title" /></h4>
                    <p><FormattedMessage id="pages.forgetPassword.description" /></p>
                    <FormGroup>
                      <Label className="col-form-label"><FormattedMessage id="inputs.email" /></Label>
                      <Input className="form-control" type="email" value={email} onChange={(e) => setEmail(e.target.value?.trim()?.toLowerCase())} required />
                    </FormGroup>

                    <div className="form-group mb-0">
                      <Button color="primary" type="submit" disabled={submitDisable} className="btn-block" ><FormattedMessage id="buttons.send" /></Button>
                    </div>
                    <p className="mt-4 mb-0">Lembrou-se da sua senha?<Link className="ml-2" to={`${process.env.PUBLIC_URL}/login`}><FormattedMessage id="buttons.enter" /></Link></p>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </AppAuth>
  );
}

export default Forgetpwd;
