import React, { Fragment, useState, useEffect, useRef } from "react";
import { LogOut, Search, User } from "react-feather";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useAuth } from "../../hooks/auth";
import userIcon from "../../assets/images/user/user.svg";
import { IntlContext } from "../../utils/context/Internationalization";
import { ICombineReducers } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { toggleResponsiveSearch } from "../../redux/actions";
import { GRAPHQL_SERVER, DISPLAY_LOCALE } from "../../configs/constants";

const Rightbar: React.FC = (props) => {
  const isMountedRef = useRef(null);

  const history = useHistory();
  const intl = useIntl();
  const { signOut, hasScope } = useAuth();
  const [moonlight, setMoonlight] = useState<boolean>(false);
  const [langDropdown, setLangDropdown] = useState<boolean>(false);
  const state = useSelector((state: ICombineReducers) => state.State);
  const dispatch = useDispatch();
  const credentials = useSelector((state: ICombineReducers) => state.DataSet.credentials);

  useEffect(() => {
    isMountedRef.current = true;
    dispatch(toggleResponsiveSearch(state.responsiveSearch));
    if (localStorage.getItem("layout_version") === "dark-only") {
      setMoonlight(true);
    }
    return () => { isMountedRef.current = false; }
  }, [dispatch, state]);

  const handleResposiveSearch = (state: boolean) => {
    if (!state) {
      document?.querySelector(".search-full")?.classList?.add("open");
      document?.querySelector(".more_lang")?.classList?.remove("active");
    } else {
      document?.querySelector(".search-full")?.classList?.remove("open");
    }
    dispatch(toggleResponsiveSearch(!state));
  };

  const languageSelection = (language: boolean) => {
    if (language) {
      setLangDropdown(!language);
    } else {
      setLangDropdown(!language);
    }
  };

  const moonlightToggle = (light: boolean) => {
    if (light) {
      setMoonlight(!light);
      document.body.className = "light";
      localStorage.setItem("layout_version", "light");
    } else {
      setMoonlight(!light);
      document.body.className = "dark-only";
      localStorage.setItem("layout_version", "dark-only");
    }
  };

  return (
    <Fragment>
      <div className="nav-right col-8 pull-right right-header p-0">
        <ul className="nav-menus">
          <IntlContext.Consumer>
            {(context) => {
              return (
                <li
                  className={
                    DISPLAY_LOCALE ? "language-nav" : "language-nav hidden"
                  }
                >
                  <div
                    className={`translate_wrapper ${langDropdown ? "active" : ""
                      }`}
                  >
                    <div className="current_lang">
                      <div
                        className="lang"
                        onClick={() => languageSelection(langDropdown)}
                      >
                        <i
                          className={`flag-icon flag-icon-${context.locale === "en"
                            ? "us"
                            : context.locale === "pt"
                              ? "br"
                              : context.locale
                            }`}
                        ></i>
                        <span className="lang-txt">{context.locale}</span>
                      </div>
                    </div>
                    <div
                      className={` more_lang ${langDropdown ? "active" : ""}`}
                    >
                      <div
                        className="lang"
                        onClick={() => context.switchLanguage("en")}
                      >
                        <i className="flag-icon flag-icon-us"></i>
                        <span className="lang-txt">
                          English<span> (US)</span>
                        </span>
                      </div>
                      <div
                        className="lang"
                        onClick={() => context.switchLanguage("pt")}
                      >
                        <i className="flag-icon flag-icon-br"></i>
                        <span className="lang-txt">
                          Português<span> (BR)</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              );
            }}
          </IntlContext.Consumer>
          <li>
            <div
              className="header-search"
              onClick={() => handleResposiveSearch(state.responsiveSearch)}
            >
              <Search />
            </div>
          </li>
          <li>
            <div className="mode" onClick={() => moonlightToggle(moonlight)}>
              <i
                className={`fa ${moonlight ? "fa-lightbulb-o" : "fa-moon-o"}`}
              ></i>
            </div>
          </li>
          <li className="onhover-dropdown p-0">
            <div className="media profile-media">
              {credentials?.user?.path ? (<img className="b-r-10" src={`${GRAPHQL_SERVER}/upload/${credentials?.user?.path}`} alt={credentials?.user?.name} />) : (<img className="b-r-10" src={userIcon} alt={credentials?.user?.name} />)}
              <div className="media-body">
                <span>{credentials?.user?.name}</span>
                <p className="mb-0 font-roboto">
                  {credentials?.user?.isSuperAdmin ? "Super-administrador" : (credentials?.user?.roles?.map((role) => role.description).join(", "))}
                  <i className="middle fa fa-angle-down"></i>
                </p>
              </div>
            </div>
            <ul className="profile-dropdown onhover-show-div">
              {hasScope("Users:Profile") ? (
                <li onClick={() => history.push("/profile")}>
                  <User />
                  <span>
                    <FormattedMessage id="header.myAccount" />
                  </span>
                </li>
              ) : ""}
              <li
                onClick={() => {
                  signOut(intl.formatMessage({ id: "flash.success.signOut" }));
                  history.push("/login");
                }}
              >
                <LogOut />
                <span>
                  <FormattedMessage id="header.signOut" />
                </span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};
export default Rightbar;
