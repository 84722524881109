import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { Container, Row, Col } from "reactstrap";
import { SITE_TITLE } from "../../configs/constants";

const Footer: React.FC = (props) => {
  return (
    <Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md="6" className="footer-copyright">
              <p className="mb-0">
                <FormattedMessage
                  id="footer.copyright"
                  values={{
                    year: new Date().getFullYear(),
                    siteName: SITE_TITLE,
                  }}
                />
              </p>
            </Col>
            <Col md="6">
              <p className="pull-right mb-0">
                <FormattedMessage
                  id="footer.credits"
                  values={{
                    icon: <i className="fa fa-heart font-secondary"></i>,
                  }}
                />
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </Fragment>
  );
};

export default Footer;
