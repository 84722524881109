import React, {
  Fragment,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Form, FormGroup, Input, Label } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { IDataTableColumn } from "react-data-table-component";
import User from "../../models/user";
import SweetAlert from "sweetalert2";
import { useApollo } from "../../services/apollo.service";
import { useHistory } from "react-router-dom";
import { DATE_FORMAT_BR, DATE_TIME_FORMAT_BR, format_date, RECORDS_PER_PAGE, SITE_TITLE } from "../../configs/constants";
import Listing from "../../components/listing";
import { useAuth } from "../../hooks/auth";
import { gql } from "@apollo/client";
import { useDispatch } from "react-redux";
import { dataTableLoading } from "../../redux/actions";
import formatter from "../../utils/formatter";
import UserDetails from "./user-details";

interface UserFilter {
  name?: string;
  surname?: string;
  document?: string;
  email?: string;
}

const Users: React.FC = () => {
  const isMountedRef = useRef(null);
  const dispatch = useDispatch();
  const { apollo, error } = useApollo();
  const intl = useIntl();
  const history = useHistory();
  const [selectedRows, setSelectedRows] = useState<User[]>([]);
  const [toggleCleared, setToggleCleared] = useState<boolean>(false);
  const [data, setData] = useState<User[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(RECORDS_PER_PAGE);
  const [sortBy, setSortBy] = useState<string>("createdAt");
  const [sortDir, setSortDir] = useState<string>("DESC");

  const [filters, setFilters] = useState<UserFilter>({});
  const [filters2, setFilters2] = useState<UserFilter>({});
  const [disableSubmitSearch, setDisableSubmitSearch] = useState<boolean>(false);

  const { hasScope } = useAuth();
  const [canDelete] = useState<boolean>(() => {
    return hasScope("Users:Delete");
  });

  const handleSearch = () => {
    setPage(1);
    setFilters2(filters);
  };

  const handleData = useCallback(async () => {
    dispatch(dataTableLoading(true));
    setPage(1);
    await apollo.query({
      query: gql`
        query users($sortDir: String, $sortBy: String, $perPage: Int, $page: Int, $filterByName: String, $filterBySurname: String, $filterByEmail: String, $filterByDocument: String) {
          users(sortDir: $sortDir, sortBy: $sortBy, perPage: $perPage, page: $page, filterByName: $filterByName, filterBySurname: $filterBySurname, filterByEmail: $filterByEmail, filterByDocument: $filterByDocument) {
            paging {
              total
              pages
              perPage
              currentPage
            }
            list {
              id
              createdAt
              updatedAt
              deletedAt
              name
              surname
              document
              birthDate
              email
              password
              phone
              mobile
              isActivated
              isSuperAdmin
              protheusId
              path
              roles {
                id
                name
                description
              }
              areaCodes {
                id
                code
                protheusId
                state {
                  id
                  name
                  fc
                }
              }
              cities {
                id
                name
                code
              }
              claims {
                claimType
                claimValue
              }
              metas {
                id
                total
                divider
                name
                startAt
                endAt
                isActivated
              }
            }
          }
        }
      `,
      variables: {
        page, perPage, sortBy, sortDir,
        filterByName: filters2?.name,
        filterBySurname: filters2?.surname,
        filterByEmail: filters2?.email,
        filterByDocument: filters2?.document,
      }
    })
      .then(res => {
        if (isMountedRef.current) {
          setTotal(res.data.users.paging.total);
          setPage(res.data.users.paging.currentPage);
          setPerPage(res.data.users.paging.perPage);
          setData(res.data.users.list);
        }
      })
      .catch(err => error(err));
    dispatch(dataTableLoading(false));
  }, [apollo, error, page, perPage, sortBy, sortDir, dispatch, filters2]);

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: "pages.users.users",
      })}`;
    handleData();
    return () => { isMountedRef.current = false; }
  }, [intl, handleData]);

  const formatDocument = (document: string): string => {
    document = document.trim().replace(/\D/gm, "");

    // Formata o documento do usuário
    if (document.length === 11) {
      document = formatter.formatCpf(document);
    } else if (document.length === 14) {
      document = formatter.formatCnpj(document);
    }
    return document;
  }

  const tableColumns: IDataTableColumn<User>[] = [
    {
      name: "Código",
      selector: "protheusId",
      format: (row) => {
        return row.protheusId ? row.protheusId : "-"
      },
      sortable: true,
      center: false,
    },
    {
      name: intl.formatMessage({ id: "inputs.name" }),
      selector: "name",
      sortable: true,
      center: false,
    },
    {
      name: intl.formatMessage({ id: "inputs.surname" }),
      selector: "surname",
      sortable: true,
      center: false,
    },
    {
      name: intl.formatMessage({ id: "inputs.document" }),
      selector: "document",
      sortable: true,
      center: false,
      format: (row) => {
        return row.document ? formatDocument(row.document) : "-";
      }
    },
    {
      name: intl.formatMessage({ id: "inputs.email" }),
      selector: "email",
      sortable: true,
      center: false,
    },
    {
      name: intl.formatMessage({ id: "inputs.birthDate" }),
      selector: "birthDate",
      sortable: true,
      center: false,
      format: (row) => {
        return row.birthDate ? format_date(row.birthDate, DATE_FORMAT_BR) : '-';
      }
    },
    {
      name: intl.formatMessage({ id: "inputs.roles" }),
      selector: "roles",
      sortable: false,
      center: false,
      format: (row) => {
        return row.isSuperAdmin ? "Super-administrador" : (row.roles?.length ? row.roles?.map((role) => role.description)?.join(", ") : '-');
      },
    },
    {
      name: intl.formatMessage({ id: "inputs.isActivated" }),
      selector: "isActivated",
      format: (row) => {
        return row.isActivated
          ? intl.formatMessage({ id: "general.yes" })
          : intl.formatMessage({ id: "general.no" });
      },
      sortable: true,
      center: false,
    },
    {
      name: intl.formatMessage({ id: "inputs.createdAt" }),
      selector: "createdAt",
      sortable: true,
      center: false,
      format: (row) => {
        return row.createdAt ? format_date(row.createdAt, DATE_TIME_FORMAT_BR) : '-';
      }
    },
  ];

  const handleUpdateRecord = (row: User) => {
    if (hasScope("Users:User"))
      history.push(`/users/manage/${row.id}`);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      SweetAlert.fire({
        title: intl.formatMessage({ id: "general.exclusion" }),
        text: intl.formatMessage({ id: "flash.confirm.delete" }),
        icon: "error",
        cancelButtonText: intl.formatMessage({ id: "general.no" }),
        confirmButtonText: intl.formatMessage({ id: "general.yes" }),
        reverseButtons: true,
        showCancelButton: true,
      })
        .then(async ({ isConfirmed }) => {
          if (isConfirmed) {
            for (let row of selectedRows) {
              await apollo.mutate({
                mutation: gql`
                  mutation deleteUser($id: String!) {
                    deleteUser(id: $id) {
                        id
                        name
                    }
                  }
                `,
                variables: {
                  id: row.id
                },
              })
                .then(res => {
                  toast.success(
                    intl.formatMessage(
                      { id: "flash.success.deletedParam" },
                      { description: row.name + " " + row.surname }
                    )
                  );
                })
                .catch(err => error(err));
            }
            setToggleCleared(!toggleCleared);
            handleData();
          }
        });
    };

    return (
      <button key="delete" className="btn btn-danger" onClick={handleDelete}>
        <FormattedMessage id="buttons.delete" />
      </button>
    );
  }, [selectedRows, apollo, error, toggleCleared, intl, handleData]);

  return (
    <Fragment>
      <Breadcrumb parent="general.home" title="pages.users.users" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>
                  <FormattedMessage id="pages.users.list" />
                </h5>
              </CardHeader>
              <CardBody>
                <Form onSubmit={async (e) => {
                  e.preventDefault();
                  setDisableSubmitSearch(true);
                  await handleSearch();
                  setDisableSubmitSearch(false);
                }}>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label">
                          <FormattedMessage id="inputs.name" />
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          value={filters?.name || ""}
                          disabled={disableSubmitSearch}
                          onChange={(e) => {
                            setFilters({ ...filters, name: e.target.value });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label">
                          <FormattedMessage id="inputs.surname" />
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          value={filters?.surname || ""}
                          disabled={disableSubmitSearch}
                          onChange={(e) => {
                            setFilters({ ...filters, surname: e.target.value });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label">
                          <FormattedMessage id="inputs.email" />
                        </Label>
                        <Input
                          className="form-control"
                          type="email"
                          value={filters?.email || ""}
                          disabled={disableSubmitSearch}
                          onChange={(e) => {
                            setFilters({ ...filters, email: e.target.value });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label">
                          <FormattedMessage id="inputs.document" />
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          value={filters?.document || ""}
                          disabled={disableSubmitSearch}
                          onChange={(e) => {
                            setFilters({ ...filters, document: formatDocument(e.target.value) });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                    <Col className="text-right">
                      <FormGroup>
                        <Label className="col-form-label" style={{ display: "block", width: "100%" }}>
                          &nbsp;
                        </Label>
                        <div>
                          <Button type="submit" disabled={disableSubmitSearch} color="secondary">
                            <i className="fa fa-search"></i> Filtrar
                          </Button>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                <Listing
                  data={data}
                  total={total}
                  selectable={canDelete}
                  rowsPerPage={perPage}
                  onChangePage={(page: number) => {
                    setPage(page);
                  }}
                  onChangeRowsPerPage={(rowsPerPage: number) => {
                    setPage(1);
                    setPerPage(rowsPerPage);
                  }}
                  onSort={(
                    column,
                    sortDir
                  ) => {
                    setSortBy(column.selector.toString());
                    setSortDir(sortDir);
                  }}
                  expandableRows
                  expandableRowsComponent={<UserDetails />}
                  columns={tableColumns}
                  onRowClicked={handleUpdateRecord}
                  contextActions={contextActions}
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleCleared}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Users;
