import React, { useEffect, useRef } from "react";
import sad from "../../assets/images/other-images/sad.png";
import { Container, Media, Col } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { SITE_TITLE } from "../../configs/constants";

const NotFound: React.FC = () => {
  const isMountedRef = useRef(null);
  const intl = useIntl();

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: "errors.404.title",
      })}`;
    return () => { isMountedRef.current = false; }
  }, [intl]);

  return (
    <div className="page-wrapper">
      <div className="error-wrapper">
        <Container>
          <Media body className="img-100" src={sad} alt="" />
          <Col md="8 offset-md-2">
            <p className="sub-content">
              <FormattedMessage id="errors.404.description" />
            </p>
          </Col>
        </Container>
      </div>
    </div>
  );
};

export default NotFound;
