import React, {
  CSSProperties,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import SweetAlert from "sweetalert2";
import Breadcrumb from "../../layout/breadcrumb";
import { CrudParam } from "../../configs/route";
import { useApollo } from "../../services/apollo.service";
import { BUDGET_SYNC_WITH_ID, convert_to_date, date, DATE_FORMAT, GRAPHQL_SERVER, RECORDS_PER_PAGE, SITE_TITLE } from "../../configs/constants";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/auth";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import pt_BR from "date-fns/locale/pt-BR";
import { useDispatch, useSelector } from "react-redux";
import { ICombineReducers } from "../../redux";
import Carrier from "../../models/carrier";
import PaymentCondition from "../../models/paymentCondition";
import Funnel from "../../models/funnel";
import Client from "../../models/client";
import ProductGroup from "../../models/productGroup";
import Product from "../../models/product";
import { IDataTableColumn } from "react-data-table-component";
import Listing, { ExpandedComponentProps } from "../../components/listing";
import styled from "styled-components";
import BudgetProduct, { BudgetProductInput } from "../../models/budgetProduct";
import { gql } from "@apollo/client";
import ProductTax, { ProductTaxInput } from "../../models/productTax";
import { dataTableLoading } from "../../redux/actions";
import Loading from "../../components/loading";
import NumberFormat from "react-number-format";
import protheusService from "../../services/protheus.service";

const Expanded = styled.div`
  padding: 20px;
`;

export function ExpandedComponent<T = any>(props: ExpandedComponentProps<Product>): React.ReactElement {
  const intl = useIntl();
  return (
    <Expanded>
      <div>
        <strong>Código:</strong> {props.data.protheusId}
      </div>
      <div>
        <strong>Nome:</strong> {props.data.name}
      </div>
      <div>
        <strong>Estoque:</strong> {props.data.stock}
      </div>
      <div>
        <strong>Preço de venda:</strong> R$ {intl.formatNumber(props.data.lastSalePrice, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
      </div>
    </Expanded>
  );
};

export function ExpandedComponent2<T = any>(props: ExpandedComponentProps<BudgetProduct>): React.ReactElement {
  const intl = useIntl();
  return (
    <Expanded>
      <div>
        <strong>Código:</strong> {props.data.protheusId}
      </div>
      <div>
        <strong>Nome:</strong> {props.data.name}
      </div>
      <div>
        <strong>Estoque:</strong> {props.data.stock}
      </div>
      <div>
        <strong>Quantidade:</strong> {props.data.qty}
      </div>
      <div>
        <strong>Preço de venda:</strong> R$ {intl.formatNumber(props.data.grossPrice, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
      </div>
      <div>
        <strong>%IPI:</strong> {intl.formatNumber(props.data.ipiRate)}%
      </div>
      <div>
        <strong>ICMS ST:</strong> R$ {intl.formatNumber(props.data.icmsStCost, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
      </div>
      <div>
        <strong>Preço unitário:</strong> R$ {intl.formatNumber(props.data.fiscalCost, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
      </div>
      <div>
        <strong>Total:</strong> R$ {intl.formatNumber(props.data.fiscalTotal, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
      </div>
    </Expanded>
  );
};

interface Filters {
  name?: string | null,
  code?: string | null,
  group?: string | null
}

interface CalculateProductTaxes {
  calculateProductTaxes: ProductTax;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Manage: React.FC = () => {
  const isMountedRef = useRef(null);
  const { apollo, error } = useApollo();
  const location = useLocation();
  const query = useQuery();
  const { id } = useParams<CrudParam>();
  const dispatch = useDispatch();
  const locale = useSelector((state: ICombineReducers) => state.State.locale);
  const credentials = useSelector((state: ICombineReducers) => state.DataSet.credentials);
  const [maximumDiscount, setMaximumDiscount] = useState<number>(100);

  const [activeTab, setActiveTab] = useState<number>(1);
  const [expiresAt, setExpiresAt] = useState<Date>(convert_to_date(date().add(10, "days").format(DATE_FORMAT)));
  const [clientKind, setClientKind] = useState<string>("");
  const [code, setCode] = useState<number>();
  const [fiscalPercentNote, setFiscalPercentNote] = useState<number>(100);
  const [discount, setDiscount] = useState<number>(0);
  const [freight, setFreight] = useState<number>(0);
  const [weight, setWeight] = useState<number>(0);
  const [volumes, setVolumes] = useState<number>(0);
  const [carrierId, setCarrierId] = useState<string>("");
  const [protheusId, setProtheusId] = useState<string>("");
  const [paymentConditionId, setPaymentConditionId] = useState<string>("");
  const [funnelId, setFunnelId] = useState<string>("");
  const [clientId, setClientId] = useState<string>("");
  const [freightKind, setFreightKind] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [store, setStore] = useState<string>("01");

  const [address, setAddress] = useState<string>("");
  const [addressNumber, setAddressNumber] = useState<string>("");
  const [addressComplement, setAddressComplement] = useState<string>("");
  const [addressPostcode, setAddressPostcode] = useState<string>("");
  const [addressNeighborhood, setAddressNeighborhood] = useState<string>("");
  const [addressState, setAddressState] = useState<string>("");
  const [addressCity, setAddressCity] = useState<string>("");

  const [deliveryAddress, setDeliveryAddress] = useState<string>("");
  const [deliveryAddressNumber, setDeliveryAddressNumber] = useState<string>("");
  const [deliveryAddressComplement, setDeliveryAddressComplement] = useState<string>("");
  const [deliveryAddressPostcode, setDeliveryAddressPostcode] = useState<string>("");
  const [deliveryAddressNeighborhood, setDeliveryAddressNeighborhood] = useState<string>("");
  const [deliveryAddressState, setDeliveryAddressState] = useState<string>("");
  const [deliveryAddressCity, setDeliveryAddressCity] = useState<string>("");

  const [quantityProductName, setQuantityProductName] = useState<string>("");
  const [quantityProductQty, setQuantityProductQty] = useState<number>(0);
  const [quantityProductQtyMultiplier, setQuantityProductQtyMultiplier] = useState<number>(1);
  const [quantityProductId, setQuantityProductId] = useState<string>("");

  const [carriers, setCarriers] = useState<Carrier[]>([]);
  const [payments, setPayments] = useState<PaymentCondition[]>([]);
  const [clientData, setClientData] = useState<Client>();
  const [funnels, setFunnels] = useState<Funnel[]>([]);

  // Data table products
  const [products, setProducts] = useState<BudgetProduct[]>([]);
  const [calculatedTaxes, setCalculatedTaxes] = useState<ProductTax>();
  const [calculateProducts, setCalculateProducts] = useState<ProductTaxInput[]>([]);
  const [selectedRowsProducts, setSelectedRowsProducts] = useState<BudgetProduct[]>([]);
  const [toggleClearedProducts, setToggleClearedProducts] = useState<boolean>(false);
  const [totalProducts, setTotalProducts] = useState<number>(0);
  // eslint-disable-next-line
  const [pageProducts, setPageProducts] = useState<number>(1);
  const [perPageProducts, setPerPageProducts] = useState<number>(RECORDS_PER_PAGE);

  // Data table products search results
  const [selectedRows, setSelectedRows] = useState<Product[]>([]);
  const [toggleCleared, setToggleCleared] = useState<boolean>(false);
  const [data, setData] = useState<Product[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(RECORDS_PER_PAGE);

  const [productsSearch, setProductsSearch] = useState<Filters>();
  // eslint-disable-next-line
  const [groups, setGroups] = useState<ProductGroup[]>([]);

  const [modal, setModal] = useState<boolean>(false);
  const [quantityModal, setQuantityModal] = useState<boolean>(false);
  const [quantityModalOk, setQuantityModalOk] = useState<boolean>(false);
  const toggle = () => setModal(!modal);

  const [printModal, setPrintModal] = useState<boolean>(false);
  const [printModalPdf, setPrintModalPdf] = useState<string>("");
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [disableSubmitSearch, setDisableSubmitSearch] = useState<boolean>(false);
  const [disableSyncButton, setDisableSyncButton] = useState<boolean>(false);
  const history = useHistory();
  const intl = useIntl();

  const togglePrintModal = () => setPrintModal(!printModal);

  const { hasScope } = useAuth();
  const [preview] = useState<boolean>(() => {
    if (location.pathname.split("/").slice(2)[0] === "preview") return true; else return false;
  });

  const [loading, setLoading] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(true);

  const [action] = useState<string>(() => {
    return location.pathname.split("/").slice(2)[0];
  });

  const [canPreview] = useState<boolean>(() => {
    return hasScope("Budgets:Budget");
  });
  const [canUpdate] = useState<boolean>(() => {
    return hasScope("Budgets:Update");
  });
  const [canCreate] = useState<boolean>(() => {
    return hasScope("Budgets:Create");
  });

  // Gera um PDF do orçamento
  const handlePrint = () => window.open(printModalPdf, "printPdf");

  // Faz a busca do orçamento
  const handleProductsSearch = useCallback(async (page: number, perPage: number, sortBy: string = "name", sortDir: string = "asc") => {
    dispatch(dataTableLoading(true));
    await apollo.query({
      query: gql`
        query products($sortDir: String, $sortBy: String, $perPage: Int, $page: Int, $syncronizeStock: Boolean, $filterByName: String, $filterByCode: String, $filterByGroup: String, $filterOnlyWithTaxGroup: Boolean) {
          products(sortDir: $sortDir, sortBy: $sortBy, perPage: $perPage, page: $page, syncronizeStock: $syncronizeStock, filterByName: $filterByName, filterByCode: $filterByCode, filterByGroup: $filterByGroup, filterOnlyWithTaxGroup: $filterOnlyWithTaxGroup) {
            paging {
              total
              pages
              perPage
              currentPage
            }
            list {
              id
              createdAt
              updatedAt
              name
              barCode
              notes
              unit
              stock
              lastSalePrice
              lastCostPrice
              path
              cmn
              icmsRate
              ipiRate
              taxGroup
              protheusId
              isActivated
              multiplier
              boxes
              liquidWeight
              group {
                id
                name
                protheusId
              }
            }
          }
        }
      `,
      variables: {
        page,
        perPage,
        sortBy,
        sortDir,
        syncronizeStock: false,
        filterByName: productsSearch?.name?.trim() || null,
        filterByCode: productsSearch?.code?.trim() || null,
        filterByGroup: productsSearch?.group || null,
        filterOnlyWithTaxGroup: true,
      }
    })
      .then(res => {
        setTotal(res.data.products.paging.total);
        setPage(res.data.products.paging.currentPage);
        setPerPage(res.data.products.paging.perPage);
        setData(res.data.products.list);
      })
      .catch(err => error(err));
    dispatch(dataTableLoading(false));
  }, [apollo, error, productsSearch, dispatch]);

  // Estilos das linhas da tabela de pesquisa/adição de produtos quando o produto está inativado
  const style: CSSProperties = {
    color: "rgba(0, 0, 0, 0.3)",
    fontWeight: 300,
    fontStyle: "italic"
  };

  const tableColumns: IDataTableColumn<Product>[] = [
    {
      name: intl.formatMessage({ id: "inputs.protheusId" }),
      selector: "protheusId",
      sortable: true,
      center: false,
      conditionalCellStyles: [
        {
          when: (row) => !row.isActivated,
          style
        }
      ],
    },
    {
      name: intl.formatMessage({ id: "inputs.name" }),
      selector: "name",
      sortable: true,
      center: false,
      width: "330px",
      conditionalCellStyles: [
        {
          when: (row) => !row.isActivated,
          style
        }
      ],
    },
    {
      name: intl.formatMessage({ id: "inputs.stock" }),
      selector: "stock",
      sortable: false,
      center: true,
      conditionalCellStyles: [
        {
          when: (row) => !row.isActivated,
          style
        }
      ],
    },
    {
      name: intl.formatMessage({ id: "inputs.minimumSell" }),
      selector: "multiplier",
      sortable: true,
      center: true,
      conditionalCellStyles: [
        {
          when: (row) => !row.isActivated,
          style
        }
      ],
    },
  ];

  const tableColumnsProducts: IDataTableColumn<BudgetProduct>[] = [
    {
      name: intl.formatMessage({ id: "inputs.protheusId" }),
      selector: "protheusId",
      sortable: false,
      center: true,
    },
    {
      name: intl.formatMessage({ id: "inputs.name" }),
      selector: "name",
      sortable: false,
      center: false,
      width: "300px"
    },
    {
      name: intl.formatMessage({ id: "inputs.qty" }),
      selector: "qty",
      sortable: false,
      center: true,
    },
    {
      name: intl.formatMessage({ id: "inputs.stock" }),
      selector: "stock",
      sortable: false,
      center: true,
      format: (row) => {
        return row.stock;
      }
    },
    {
      name: intl.formatMessage({ id: "inputs.grossPrice" }),
      selector: "grossDiscountPrice",
      sortable: false,
      right: true,
      format: (row) => {
        return `R$${intl.formatNumber(row.grossDiscountPrice, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`;
      },
    },
    {
      name: intl.formatMessage({ id: "inputs.ipiRate" }),
      selector: "ipiRate",
      sortable: false,
      center: true,
      format: (row) => {
        return `${intl.formatNumber(row.ipiRate)}%`;
      },
    },
    {
      name: intl.formatMessage({ id: "inputs.icmsStCost" }),
      selector: "icmsStCost",
      sortable: false,
      right: true,
      format: (row) => {
        return `R$${intl.formatNumber(row.icmsStCost * row.qty, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`;
      },
    },
    {
      name: intl.formatMessage({ id: "inputs.unitPrice" }),
      selector: "fiscalCost",
      sortable: false,
      right: true,
      format: (row) => {
        return `R$${intl.formatNumber(row.fiscalCost, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`;
      },
    },
    {
      name: intl.formatMessage({ id: "inputs.total" }),
      selector: "fiscalTotal",
      sortable: false,
      right: true,
      format: (row) => {
        return `R$${intl.formatNumber(row.fiscalTotal, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`;
      },
    },
  ];

  const handleUpdateRecord = () => false;

  const toggleQuantity = useCallback(() => {
    setQuantityModal(!quantityModal);
  }, [quantityModal]);

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleRowSelectedProducts = useCallback((state) => {
    setSelectedRowsProducts(state.selectedRows);
  }, []);

  const handleCalculateTaxes = useCallback(async (products: ProductTaxInput[], _fiscalPercentNote: number = 0, _freight: number = 0, _discount: number = 0, _clientId = null) => {
    if (!_fiscalPercentNote)
      _fiscalPercentNote = fiscalPercentNote;
    if (!_freight)
      _freight = freight;
    if (!_discount)
      _discount = discount;
    if (!_clientId)
      _clientId = clientId;

    if (products?.length) {
      for (const product of products) {
        if (product.qty < product?.multiplier) {
          toast.error(`A quantidade do item ${product.name} deve ser mais ou igual à ${product.multiplier}`);
          return;
        }
      }
      setQuantityModal(false);
      dispatch(dataTableLoading(true));

      await apollo.query<CalculateProductTaxes>({
        query: gql`
          query calculateProductTaxes(
            $products: [ProductTaxInput!]!,
            $freight: Float!,
            $discount: Float!,
            $fiscalPercentNote: Int!,
            $client: String!
          ) {
            calculateProductTaxes(
              products: $products,
              freight: $freight,
              discount: $discount,
              fiscalPercentNote: $fiscalPercentNote,
              client: $client
            ) {
              client {
                id
                protheusId
                companyName
                document
              }
              fiscalPercentNote
              discount
              freight
              freightApportionment
              quantityTotal
              weightTotal
              grossTotal
              fiscalTotal
              freightTotal
              discountTotal
              productsFiscalTotal
              liquidTotal
              ipiTotal
              icmsStTotal
              products {
                id
                createdAt
                updatedAt
                name
                protheusId
                unit
                qty
                stock
                discount
                grossPrice
                fiscalPercentNote
                grossDiscountPrice
                grossFiscalPercentNotePrice
                grossFreightApportionmentPrice
                ipiRate
                ipiCost
                ivaRate
                ivaCost
                icmsStCost
                icmsOriginRate
                icmsOriginCost
                icmsDestinationRate
                icmsDestinationCost
                fiscalCost
                fiscalTotal
                total
                weight
                multiplier
                product {
                  id
                  protheusId
                  multiplier
                }
              }
            }
          }
        `,
        variables: {
          client: _clientId,
          discount: _discount,
          fiscalPercentNote: _fiscalPercentNote,
          freight: _freight,
          products,
          syncronizeStock: false
        },
      })
        .then(res => {
          setCalculatedTaxes(res.data.calculateProductTaxes);
          if (res.data.calculateProductTaxes.products.length) {
            const calculatedProducts: ProductTaxInput[] = res.data.calculateProductTaxes.products.map(item => {
              const productTaxInput: ProductTaxInput = {
                qty: item.qty,
                productId: item.product.id,
                budgetProductId: item?.budget?.id,
                name: item?.name,
                multiplier: item?.multiplier,
                createdAt: convert_to_date(item.createdAt),
                updatedAt: convert_to_date(item.updatedAt)
              };
              return productTaxInput;
            });
            setCalculateProducts(calculatedProducts);
          }
          setTotalProducts(res.data.calculateProductTaxes.products.length || 0);
          setProducts(res.data.calculateProductTaxes.products || []);
          setWeight(res.data.calculateProductTaxes.weightTotal || 0);
        })
        .catch(err => error(err));

      dispatch(dataTableLoading(false));
    }
  }, [apollo, error, discount, fiscalPercentNote, freight, clientId, dispatch]);

  const contextActions = useMemo(() => {
    const handleAddProducts = async () => {
      let selectedProducts: ProductTaxInput[] = [];
      for (const row of selectedRows) {
        if (row.isActivated) {
          if (!products?.map(pro => pro.product.id)?.includes(row.id)) {
            const productTaxInput: ProductTaxInput = {
              qty: row.multiplier,
              multiplier: row.multiplier,
              name: row.name,
              productId: row.id,
            };
            selectedProducts.push(productTaxInput);
            if (row.stock === 0)
              toast.warn(`Produto ${row.name} foi adicionado, mas não há saldo disponível.`);
            else
              toast.success(`Produto ${row.name} adicionado com sucesso.`);
          } else {
            toast.error(`Você já adicionou o produto ${row.name}.`);
          }
        } else {
          toast.error(`Não foi possível adicionar o produto ${row.name} ao orçamento, pois o mesmo se encontra bloqueado no sistema.`);
        }
      }
      selectedProducts = [...calculateProducts, ...selectedProducts];
      setToggleCleared(!toggleCleared);
      await handleCalculateTaxes(selectedProducts);
    };

    return (
      <Button color="primary" onClick={handleAddProducts}>
        <i className="fa fa-plus"></i> Adicionar
      </Button>
    );
  }, [toggleCleared, selectedRows, products, handleCalculateTaxes, calculateProducts]);

  // eslint-disable-next-line
  const cantUpdateWarning = () => {
    SweetAlert.fire({ title: "Aviso!", text: "Este pedido já foi sincronizado com o Protheus. Não será possível alterá-lo novamente.", icon: "warning" });
  };

  const contextActionsProducts = useMemo(() => {
    const handleDelete = () => {
      SweetAlert.fire({
        title: intl.formatMessage({ id: "general.exclusion" }),
        text: intl.formatMessage({ id: "flash.confirm.delete" }),
        icon: "error",
        cancelButtonText: intl.formatMessage({ id: "general.no" }),
        confirmButtonText: intl.formatMessage({ id: "general.yes" }),
        reverseButtons: true,
        showCancelButton: true,
      })
        .then(async ({ isConfirmed }) => {
          if (isConfirmed) {
            for (let row of selectedRowsProducts) {
              const index = products.findIndex(e => e.id === row.id);
              if (index !== -1) {
                if (row?.id) {
                  await apollo.mutate({
                    mutation: gql`
                      mutation deleteBudgetProduct($id: String!) {
                        deleteBudgetProduct(id: $id) {
                          id
                        }
                      }
                    `,
                    variables: {
                      id: row.id
                    }
                  })
                    .then(res => {
                      products.splice(index, 1);
                    })
                    .catch(err => error(err));
                } else {
                  products.splice(index, 1);
                }
              }
            }
            setToggleClearedProducts(!toggleClearedProducts);
            setProducts(products);
            toast.success("Produtos removidos com sucesso.");
          }
        });
    };

    return (
      <Button color="danger" disabled={preview} onClick={handleDelete}>
        <i className="fa fa-minus"></i> Remover
      </Button>
    );
  }, [selectedRowsProducts, setToggleClearedProducts, intl, products, toggleClearedProducts, preview, apollo, error]);

  const handleData = useCallback(async () => {
    if (preview && !canPreview) {
      toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
      history.push("/budgets");
      return;
    }
    if (!preview && !canUpdate) {
      toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
      history.push("/budgets");
      return;
    }
    if (!preview && !canCreate) {
      toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
      history.push("/budgets");
      return;
    }
    if (id && action === "create") {
      if (isMountedRef.current) {
        setDisabled(false);
        setLoading(true);
        await Promise.all([
          apollo.query({
            query: gql`
            query carriersDropdown {
              carriersDropdown{
                id
                name
                alias
                isActivated
                protheusId
              }
            }
          `,
          }),
          apollo.query({
            query: gql`
            query paymentConditionsDropdown {
              paymentConditionsDropdown{
                id
                name
                description1
                description2
                isActivated
                protheusId
              }
            }
          `,
          }),
          apollo.query({
            query: gql`
            query funnelsDropdown {
              funnelsDropdown{
                id
                name
                sortOrder
                isHidden
              }
            }
          `,
          }),
          apollo.query({
            query: gql`
            query client ($id: String!) {
              client (id: $id){
                id
                createdAt
                updatedAt
                companyName
                fantasyName
                clientKind
                personKind
                taxGroup
                document
                sr
                mr
                idCard
                phone
                mobile
                email
                site
                store
                billingEmail
                howKnowUs
                contact
                foundationDate
                notes
                address
                addressNumber
                addressComplement
                addressNeighborhood
                addressPostcode
                billingAddress
                billingAddressNumber
                billingAddressComplement
                billingAddressNeighborhood
                billingAddressPostcode
                deliveryAddress
                deliveryAddressNumber
                deliveryAddressComplement
                deliveryAddressNeighborhood
                deliveryAddressPostcode
                protheusId
                isActivated
                discount
                addressCountry {
                  id
                  name
                }
                addressState {
                  id
                  name
                  fc
                  code
                }
                addressCity {
                  id
                  name
                  code
                }
                billingAddressCountry {
                  id
                  name
                }
                billingAddressState {
                  id
                  name
                  fc
                  code
                }
                billingAddressCity {
                  id
                  name
                  code
                }
                deliveryAddressCountry {
                  id
                  name
                }
                deliveryAddressState {
                  id
                  name
                  fc
                  code
                }
                deliveryAddressCity {
                  id
                  name
                  code
                }
                areaCode {
                  id
                  code
                  protheusId
                }
              }
            }
          `,
            variables: {
              id
            },
          }),
          apollo.query({
            query: gql`
            query productGroupsDropdown {
              productGroupsDropdown {
                id
                name
                protheusId
              }
            }
          `,
          }),
        ])
          .then(res => {
            if (isMountedRef.current) {
              if (res[0].data.carriersDropdown)
                setCarriers(res[0].data.carriersDropdown);
              if (res[1].data.paymentConditionsDropdown)
                setPayments(res[1].data.paymentConditionsDropdown);
              if (res[2].data.funnelsDropdown)
                setFunnels(res[2].data.funnelsDropdown);
              if (res[3].data.client) {
                setClientId(res[3].data.client?.id || "");
                setStore(res[3].data.client?.store || "01");
                setAddress(res[3].data.client.address || "");
                setAddressNumber(res[3].data.client.addressNumber || "");
                setAddressComplement(res[3].data.client.addressComplement || "");
                setAddressNeighborhood(res[3].data.client.addressNeighborhood || "");
                setAddressPostcode(res[3].data.client.addressPostcode || "");
                setAddressState(res[3].data.client.addressState?.fc || "");
                setAddressCity(res[3].data.client.addressCity?.name || "");
                setDeliveryAddress(res[3].data.client.deliveryAddress || "");
                setDeliveryAddressNumber(res[3].data.client.deliveryAddressNumber || "");
                setDeliveryAddressComplement(res[3].data.client.deliveryAddressComplement || "");
                setDeliveryAddressNeighborhood(res[3].data.client.deliveryAddressNeighborhood || "");
                setDeliveryAddressPostcode(res[3].data.client.deliveryAddressPostcode || "");
                setDeliveryAddressState(res[3].data.client.deliveryAddressState?.fc || "");
                setDeliveryAddressCity(res[3].data.client.deliveryAddressCity?.name || "");
                setClientData(res[3].data.client);
                setClientKind(res[3].data.client?.clientKind || "");
                if (res[3].data.client?.discount > 0)
                  setDiscount(res[3].data.client.discount || 0);
              }
              if (res[4].data.productGroupsDropdown)
                setGroups(res[4].data.productGroupsDropdown || []);
            }
          })
          .catch(err => error(err));
        setLoading(false);
      }
    } else if (id && (action === "preview" || action === "update")) {
      if (isMountedRef.current) {
        setLoading(true);
        await Promise.all([
          apollo.query({
            query: gql`
            query carriersDropdown {
              carriersDropdown{
                id
                name
                alias
                isActivated
                protheusId
              }
            }
          `,
          }),
          apollo.query({
            query: gql`
            query paymentConditionsDropdown {
              paymentConditionsDropdown{
                id
                name
                description1
                description2
                isActivated
                protheusId
              }
            }
          `,
          }),
          apollo.query({
            query: gql`
            query funnelsDropdown {
              funnelsDropdown{
                id
                name
                sortOrder
                isHidden
              }
            }
          `,
          }),
          apollo.query({
            query: gql`
            query budget ($id: String!, $syncronizeStock: Boolean) {
              budget (id: $id, syncronizeStock: $syncronizeStock){
                id
                code
                createdAt
                updatedAt
                expiresAt
                clientKind
                fiscalPercentNote
                discount
                freight
                weight
                volumes
                protheusId
                freightKind
                notes
                client {
                  id
                  companyName
                  fantasyName
                  contact
                  clientKind
                  protheusId
                  document
                  address
                  addressNumber
                  addressComplement
                  addressNeighborhood
                  addressPostcode
                  addressState {
                    fc
                  }
                  addressCity {
                    name
                  }
                  deliveryAddress
                  deliveryAddressNumber
                  deliveryAddressComplement
                  deliveryAddressNeighborhood
                  deliveryAddressPostcode
                  deliveryAddressState {
                    fc
                  }
                  deliveryAddressCity {
                    name
                  }
                }
                carrier {
                  id
                  name
                  alias
                }
                paymentCondition {
                  id
                  name
                  description1
                  description2
                }
                funnel {
                  id
                  name
                }
                taxes {
                  client {
                    id
                    protheusId
                    companyName
                    document
                  }
                  fiscalPercentNote
                  discount
                  freight
                  freightApportionment
                  quantityTotal
                  weightTotal
                  grossTotal
                  fiscalTotal
                  freightTotal
                  discountTotal
                  productsFiscalTotal
                  liquidTotal
                  ipiTotal
                  icmsStTotal
                  products {
                    id
                    createdAt
                    updatedAt
                    name
                    protheusId
                    unit
                    qty
                    stock
                    discount
                    grossPrice
                    fiscalPercentNote
                    grossDiscountPrice
                    grossFiscalPercentNotePrice
                    grossFreightApportionmentPrice
                    ipiRate
                    ipiCost
                    ivaRate
                    ivaCost
                    icmsStCost
                    icmsOriginRate
                    icmsOriginCost
                    icmsDestinationRate
                    icmsDestinationCost
                    fiscalCost
                    fiscalTotal
                    total
                    weight
                    multiplier
                    product {
                      id
                      protheusId
                      stock
                      multiplier
                    }
                  }
                }
              }
            }
          `,
            variables: {
              id,
              syncronizeStock: false
            },
          }),
          apollo.query({
            query: gql`
            query productGroupsDropdown {
              productGroupsDropdown {
                id
                name
                protheusId
              }
            }
          `,
          }),
          apollo.query({
            query: gql`
              query budgetPrint($id: String!, $syncronizeStock: Boolean) {
                budgetPrint(id: $id, syncronizeStock: $syncronizeStock) {
                  id
                  path
                }
              }
            `,
            variables: {
              id,
              syncronizeStock: false
            }
          })
        ])
          .then(res => {
            if (isMountedRef.current) {
              if (res[0].data.carriersDropdown)
                setCarriers(res[0].data.carriersDropdown);
              if (res[1].data.paymentConditionsDropdown)
                setPayments(res[1].data.paymentConditionsDropdown);
              if (res[2].data.funnelsDropdown)
                setFunnels(res[2].data.funnelsDropdown);
              if (res[3].data.budget) {
                setClientData(res[3].data.budget.client);
                setProtheusId(res[3].data.budget?.protheusId || "");
                setDisabled(res[3].data.budget?.protheusId ? true : false);
                setCode(res[3].data.budget.code || "");
                setClientId(res[3].data.budget.client.id || "");
                setFreightKind(res[3].data.budget.freightKind || "");
                setNotes(res[3].data.budget.notes || "");
                setClientKind(res[3].data.budget.client.clientKind || "");
                setExpiresAt(convert_to_date(res[3].data.budget.expiresAt));
                setFiscalPercentNote(Number(res[3].data.budget.fiscalPercentNote) || 100);
                setFreight(Number(res[3].data.budget.freight) || 0);
                setWeight(Number(res[3].data.budget.weight) || 0);
                setVolumes(Number(res[3].data.budget.volumes) || 1);
                setDiscount(Number(res[3].data.budget.discount) || 0);
                setPaymentConditionId(res[3].data.budget.paymentCondition?.id || "");
                setCarrierId(res[3].data.budget.carrier?.id || "");
                setFunnelId(res[3].data.budget.funnel?.id || "");
                setAddress(res[3].data.budget.client.address || "");
                setAddressNumber(res[3].data.budget.client.addressNumber || "");
                setAddressComplement(res[3].data.budget.client.addressComplement || "");
                setAddressNeighborhood(res[3].data.budget.client.addressNeighborhood || "");
                setAddressPostcode(res[3].data.budget.client.addressPostcode || "");
                setAddressState(res[3].data.budget.client.addressState?.fc || "");
                setAddressCity(res[3].data.budget.client.addressCity?.name || "");
                setDeliveryAddress(res[3].data.budget.client.deliveryAddress || "");
                setDeliveryAddressNumber(res[3].data.budget.client.deliveryAddressNumber || "");
                setDeliveryAddressComplement(res[3].data.budget.client.deliveryAddressComplement || "");
                setDeliveryAddressNeighborhood(res[3].data.budget.client.deliveryAddressNeighborhood || "");
                setDeliveryAddressPostcode(res[3].data.budget.client.deliveryAddressPostcode || "");
                setDeliveryAddressState(res[3].data.budget.client.deliveryAddressState?.fc || "");
                setDeliveryAddressCity(res[3].data.budget.client.deliveryAddressCity?.name || "");
              }
              if (res[4].data.productGroupsDropdown)
                setGroups(res[4].data.productGroupsDropdown || []);
              setCalculatedTaxes(res[3].data.budget.taxes);
              const calculatedProducts: ProductTaxInput[] = res[3].data.budget.taxes.products.map((item: BudgetProduct) => {
                const productTaxInput: ProductTaxInput = {
                  qty: item.qty,
                  productId: item.product.id,
                  budgetProductId: item?.budget?.id,
                  createdAt: convert_to_date(item.createdAt),
                  updatedAt: convert_to_date(item.updatedAt)
                };
                return productTaxInput;
              });
              setCalculateProducts(calculatedProducts);
              setTotalProducts(res[3].data.budget.taxes.products.length || 0);
              setProducts(res[3].data.budget.taxes.products || []);
              setWeight(res[3].data.budget.taxes.weightTotal || 0);
              if (res[5].data.budgetPrint.path) {
                const url = `${GRAPHQL_SERVER}${res[5].data.budgetPrint.path}`;
                setPrintModalPdf(url);
              }
            }
          })
          .catch(err => error(err));
        setLoading(false);
      }
    }
  }, [intl, id, apollo, error, history, canCreate, canUpdate, canPreview, preview, action]);

  const handleQuantity = useCallback((row: BudgetProduct) => {
    const multiplier = row.multiplier || 1;
    setQuantityProductName(`${row.protheusId} - ${row.name} x ${multiplier}`);
    setQuantityProductQtyMultiplier(multiplier);
    setQuantityProductQty(row.qty);
    setQuantityProductId(row.product.id);

    if (row.qty % 5 === multiplier) {
      setQuantityModalOk(true);
    } else {
      setQuantityModalOk(false);
    }


    if (!preview)
      toggleQuantity();
  }, [toggleQuantity, preview]);

  useEffect(() => {
    isMountedRef.current = true;
    if (isMountedRef.current) {
      document.title = `${SITE_TITLE
        } :: ${intl.formatMessage({
          id: `pages.budgets.${action}`,
        })}${code ? ' (' + code + ')' : ""}`;
      if (locale === "pt") {
        registerLocale("pt-BR", pt_BR);
        setDefaultLocale("pt-BR");
      }
      handleData();
      if (credentials?.user) {
        if (!credentials.user.isSuperAdmin) {
          const discounts: number[] = credentials.user.roles?.map(e => e.maximumDiscount) || [0];
          const max: number = Math.max(...discounts);
          setMaximumDiscount(max);
        }
      }
    }
    return () => { isMountedRef.current = false; }
  }, [intl, locale, id, handleData, action, credentials, code]);

  const handleSubmitForm = async () => {
    let productsArr: BudgetProductInput[] = [];
    if (products?.length) {
      for (let product of products) {
        let pro: BudgetProductInput = {} as BudgetProductInput;
        pro = Object.assign(pro, product);
        pro.productId = product.product.id;
        delete pro.id;
        delete pro.stock;
        delete pro.product;
        delete pro.productStock;
        delete pro.createdAt;
        delete pro.updatedAt;
        delete pro.__typename;

        productsArr.push(pro);
      }
    }
    const data = {
      clientKind,
      expiresAt,
      fiscalPercentNote,
      discount,
      freight,
      weight,
      volumes,
      freightKind,
      notes,
      clientId,
      paymentConditionId,
      carrierId,
      funnelId,
      protheusId,
      products: productsArr
    };

    for (const product of productsArr) {
      if (product.qty < product?.multiplier) {
        toast.error(`A quantidade do item ${product.name} deve ser mais ou igual à ${product.multiplier}.`);
        return;
      }
      if (product?.stock <= 0) {
        toast.error(`O item ${product.name} não possui saldo em estoque.`);
        return;
      }
      if (product.qty > product?.stock) {
        toast.error(`A quantidade do item ${product.name} deve ser menor ou igual à ${product.stock}.`);
        return;
      }
    }

    if (!data.paymentConditionId) {
      toast.error("Por favor, selecione a condição de pagamento.");
      return;
    }
    if (!data.carrierId) {
      toast.error("Por favor, selecione a transportadora.");
      return;
    }
    if (!data.funnelId) {
      toast.error("Por favor, selecione a etapa do funil.");
      return;
    }
    if (!data.products?.length) {
      toast.error("Por favor, adicione ao menos um produto ao orçamento.");
      return;
    }
    setDisableSubmit(true);

    if (action === "create") {
      await apollo.mutate({
        mutation: gql`
          mutation createBudget($data: BudgetInput!) {
            createBudget(data: $data) {
                id
            }
          }
        `,
        variables: {
          data
        },
      })
        .then(res => {
          toast.success(intl.formatMessage({ id: "flash.success.created" }));
          history.push(`/budgets/preview/${res.data.createBudget.id}`);
        })
        .catch(err => error(err));
    } else if (action === "update") {
      await apollo.mutate({
        mutation: gql`
          mutation updateBudget($id: String!, $data: BudgetInput!) {
            updateBudget(id: $id, data: $data) {
                id
            }
          }
        `,
        variables: {
          id,
          data
        },
      })
        .then(res => {
          toast.success(intl.formatMessage({ id: "flash.success.updated" }));
          history.push(`/budgets`);
        })
        .catch(err => error(err));
    }
    setDisableSubmit(false);
  };

  const syncProtheus = () => {
    if (id)
      SweetAlert.fire({
        title: intl.formatMessage({ id: "general.confirmation" }),
        text: intl.formatMessage({ id: "flash.confirm.syncBudgetProtheus" }),
        icon: "question",
        cancelButtonText: intl.formatMessage({ id: "general.no" }),
        confirmButtonText: intl.formatMessage({ id: "general.yes" }),
        reverseButtons: true,
        showCancelButton: true,
      })
        .then(async ({ isConfirmed }) => {
          if (isConfirmed) {
            const paymentIndex = payments?.findIndex(e => e.id === paymentConditionId);
            const carrierIndex = carriers?.findIndex(e => e.id === carrierId);

            const data = {
              tipopedido: "N",
              cliente: clientData.protheusId || "",
              loja: store || "01",
              condpag: paymentIndex !== -1 ? payments[paymentIndex].protheusId : "",
              transportadora: carrierIndex !== -1 ? carriers[carrierIndex].protheusId : "",
              etapa: "03",
              tipocli: clientData.clientKind || "",
              natureza: "01.01.001",
              mensagem: notes || "",
              items: products?.map(item => {
                return {
                  produto: item.protheusId,
                  quantidade: item.qty,
                  preco: Number(item.fiscalCost.toFixed(2))
                };
              }) || []
            };

            if (!data.cliente.trim()) {
              toast.error("O cliente não foi selecionado.");
              return;
            }
            if (!data.loja.trim()) {
              toast.error("O cadastro do cliente está incompleto, loja não informada.");
              return;
            }
            if (!data.tipocli.trim()) {
              toast.error("O cadastro do cliente está incompleto, tipo de cliente não informado.");
              return;
            }
            if (!data.condpag.trim()) {
              toast.error("A condição de pagamento não foi selecionada.");
              return;
            }
            if (!data.transportadora.trim()) {
              toast.error("A transportadora não foi selecionada.");
              return;
            }

            setDisableSyncButton(true);
            console.log(JSON.stringify(data));

            await protheusService.request({
              url: `protheus/orders/${id}`,
              method: protheusId?.trim() ? "PUT" : "POST",
              data,
            })
              .then(res => {
                setProtheusId(res.data.protheusId);
                SweetAlert.fire({
                  title: "Sucesso!",
                  text: "Pedido sincronizado com sucesso.",
                  icon: "success",
                });
              })
              .catch((err) => {
                console.log(err?.response?.data?.message || err);
                SweetAlert.fire({
                  title: "Erro!",
                  html: err?.response?.data?.message ? `<pre style="background-color: #fff; padding: 0; margin: 0; font-size: 16px; font-family: Rubik, sans-serif; color: #2b2b2b; text-align: left;">${err?.response?.data?.message}</pre>` : "Ocorreu um erro ao tentar sincronizar o cliente com o Protheus.",
                  icon: "error",
                });
              });
            setDisableSyncButton(false);
          }
        });
  }

  return (
    <Fragment>
      <Breadcrumb
        parent="pages.budgets.budgets"
        title={`pages.budgets.${action}`}
      />
      <Modal isOpen={printModal} toggle={togglePrintModal} centered size="lg">
        <ModalHeader toggle={togglePrintModal}>Imprimir orçamento</ModalHeader>
        <ModalBody>
          {printModalPdf ? <iframe src={printModalPdf} width="100%" height="600" title="PDF Document" style={{ border: "1px solid #e2e2e2" }} /> : <Loading />}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={togglePrintModal}>Fechar</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={quantityModal} toggle={toggleQuantity} centered size="sm">
        <ModalHeader toggle={toggleQuantity}>Alterar quantidade</ModalHeader>
        <Form onSubmit={async (e) => {
          e.preventDefault();
          await handleCalculateTaxes(calculateProducts);
        }}>
          <ModalBody>
            <Container fluid={true}>
              <Row>
                <Col>{quantityProductName}</Col>
              </Row>
              <Row className="m-t-10">
                <Col>
                  <FormGroup>
                    <Input
                      className="form-control"
                      type="number"
                      min="0"
                      placeholder="Quantidade"
                      value={quantityProductQty}
                      disabled={preview || disabled}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        const productIndex = products.findIndex(e => e.product.id === quantityProductId);
                        const productTaxIndex = calculateProducts.findIndex(e => e.productId === quantityProductId);
                        console.log(value, products[productIndex].multiplier);
                        if (productIndex !== -1 && productTaxIndex !== -1) {
                          const multiplier = quantityProductQtyMultiplier;
                          if (isNaN(value)) {
                            products[productIndex].qty = multiplier;
                            calculateProducts[productTaxIndex].qty = multiplier;
                            setQuantityProductQty(1);
                          } else {
                            setQuantityProductQty(value);
                            products[productIndex].qty = value;
                            calculateProducts[productTaxIndex].qty = value;
                            if (value <= 0) {
                              setQuantityModalOk(true);
                            } else {
                              if (value % multiplier === 0) {
                                setQuantityModalOk(false);
                              } else {
                                setQuantityModalOk(true);
                              }
                            }
                          }
                          setProducts([...products]);
                          setCalculateProducts([...calculateProducts]);
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" disabled={preview || quantityModalOk || disabled} color="primary">Ok</Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modal} toggle={toggle} centered size="lg">
        <ModalHeader toggle={toggle}>Adicionar Produtos</ModalHeader>
        <ModalBody>
          <Form onSubmit={async (e) => {
            e.preventDefault();
            setDisableSubmitSearch(true);
            await handleProductsSearch(page, perPage);
            setDisableSubmitSearch(false);
          }}>
            <Container fluid={true}>
              <Row>
                <Col>
                  <FormGroup>
                    <Label className="col-form-label">
                      <FormattedMessage id="inputs.name" />
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      value={productsSearch?.name || ""}
                      disabled={disableSubmitSearch || preview}
                      onChange={async (e) => {
                        setPage(1);
                        setProductsSearch({ ...productsSearch, name: e.target.value });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label className="col-form-label">
                      <FormattedMessage id="inputs.protheusId" />
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      value={productsSearch?.code || ""}
                      disabled={disableSubmitSearch || preview}
                      onChange={async (e) => {
                        setPage(1);
                        setProductsSearch({ ...productsSearch, code: e.target.value });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label className="col-form-label">
                      <FormattedMessage id="inputs.productGroup" />
                    </Label>
                    <Input
                      className="form-control"
                      type="select"
                      value={productsSearch?.group || ""}
                      disabled={disableSubmitSearch || preview}
                      onChange={(e) => {
                        setPage(1);
                        setProductsSearch({ ...productsSearch, group: (e.target.value) });
                      }}
                    >
                      <option value="">
                        {intl.formatMessage({
                          id: "inputs.selectAny",
                        })}
                      </option>
                      {groups?.map(e => (<option key={`groupo-${e.id}`} value={e.id}>{e.name}</option>))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label className="col-form-label" style={{ display: "block", width: "100%" }}>
                      &nbsp;
                    </Label>
                    <div>
                      <Button type="submit" disabled={disableSubmitSearch || preview} color="secondary">
                        <i className="fa fa-search"></i> Filtrar
                      </Button>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Listing
                    data={data}
                    total={total}
                    selectable={true}
                    rowsPerPage={perPage}
                    onChangePage={async (page: number) => {
                      setPage(page);
                      await handleProductsSearch(page, perPage);
                    }}
                    onChangeRowsPerPage={async (rowsPerPage: number) => {
                      setPage(1);
                      setPerPage(rowsPerPage);
                      await handleProductsSearch(page, perPage);
                    }}
                    onSort={async (
                      column,
                      sortDir
                    ) => {
                      await handleProductsSearch(page, perPage, column.selector?.toString(), sortDir);
                    }}
                    expandableRows
                    expandableRowsComponent={<ExpandedComponent />}
                    columns={tableColumns}
                    onRowClicked={handleUpdateRecord}
                    contextActions={contextActions}
                    onSelectedRowsChange={handleRowSelected}
                    clearSelectedRows={toggleCleared}
                  />
                </Col>
              </Row>
            </Container>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>Fechar</Button>
        </ModalFooter>
      </Modal>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>
                  <FormattedMessage
                    id={`pages.budgets.${action}`}
                  />
                  {code ? ` (${code})` : ""}
                </h5>
              </CardHeader>
              <CardBody className="tabbed-card">
                <div className="form-content">
                  <Form onSubmit={(e) => e.preventDefault()}>
                    <Nav className="nav-pills nav-primary">
                      <NavItem>
                        <NavLink
                          className={activeTab === 1 ? "active" : ""}
                          onClick={() => setActiveTab(1)}
                        >
                          <i className="fa fa-file-text-o"></i>
                          <FormattedMessage id="tabs.data" />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={activeTab === 2 ? "active" : ""}
                          onClick={() => setActiveTab(2)}
                        >
                          <i className="fa fa-truck"></i>
                          Endereço
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={activeTab === 3 ? "active" : ""}
                          onClick={() => setActiveTab(3)}
                        >
                          <i className="fa fa-cubes"></i>
                          <FormattedMessage id="tabs.products" />
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <Row className="m-b-30">
                      <Col>
                        <TabContent activeTab={activeTab.toString()}>
                          <TabPane tabId="1">
                            {loading ? <Loading /> : (<Fragment>
                              <Row>
                                <Col>
                                  <FormGroup>
                                    <Label className="col-form-label">
                                      <FormattedMessage id="inputs.clientId" />
                                    </Label>
                                    <Input
                                      className="form-control"
                                      type="text"
                                      value={`${clientData?.protheusId || clientData?.document} - ${clientData?.companyName}`}
                                      disabled
                                    />
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <FormGroup>
                                    <Label className="col-form-label">
                                      <FormattedMessage id="inputs.clientKind" />
                                    </Label>
                                    <Input
                                      className="form-control"
                                      type="select"
                                      value={clientKind || clientData?.clientKind}
                                      onChange={(e) =>
                                        setClientKind(e.target.value)
                                      }
                                      disabled
                                    >
                                      <option value="">
                                        {intl.formatMessage({
                                          id: "inputs.selectAny",
                                        })}
                                      </option>
                                      <option value="F">F - Consumidor final</option>
                                      <option value="L">L - Produtor rural</option>
                                      <option value="R">R - Revendedor</option>
                                      <option value="S">S - Solidário</option>
                                      <option value="X">X - Exportação</option>
                                    </Input>
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <FormGroup>
                                    <Label className="col-form-label">
                                      <FormattedMessage id="inputs.store" />
                                    </Label>
                                    <Input
                                      className="form-control"
                                      type="text"
                                      value={store}
                                      disabled
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <FormGroup>
                                    <Label className="col-form-label">
                                      <FormattedMessage id="inputs.expiresAt" />
                                    </Label>
                                    <DatePicker
                                      className="form-control"
                                      selected={expiresAt}
                                      onChange={(e: Date) => setExpiresAt(e)}
                                      dateFormat="dd/MM/yyyy"
                                      disabled={preview || disabled}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <FormGroup>
                                    <Label className="col-form-label">
                                      Regra
                                    </Label>
                                    <Input
                                      className="form-control"
                                      type="select"
                                      value={fiscalPercentNote}
                                      onChange={async (e) => {
                                        const value = Number(e.target.value);
                                        setFiscalPercentNote(value);
                                        await handleCalculateTaxes(calculateProducts, value, freight, discount);
                                      }}
                                      disabled={preview || disabled}
                                    >
                                      <option value="">
                                        {intl.formatMessage({
                                          id: "inputs.selectAny",
                                        })}
                                      </option>
                                      <option value="30" disabled={!credentials?.user?.isSuperAdmin || !credentials?.user?.roles?.map(e => e.name)?.includes("admin")}>30%</option>
                                      <option value="50">50%</option>
                                      <option value="100">100%</option>
                                    </Input>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <FormGroup>
                                    <Label className="col-form-label">
                                      <FormattedMessage id="inputs.discount" />
                                    </Label>
                                    <NumberFormat
                                      className="form-control"
                                      value={discount}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      decimalScale={2}
                                      fixedDecimalScale
                                      allowNegative={false}
                                      thousandsGroupStyle="thousand"
                                      disabled={preview || disabled}
                                      onValueChange={async ({ floatValue }) => {
                                        if (floatValue > maximumDiscount)
                                          setDiscount(maximumDiscount);

                                        setDiscount(floatValue);
                                        await handleCalculateTaxes(calculateProducts, fiscalPercentNote, freight, floatValue);
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <FormGroup>
                                    <Label className="col-form-label">
                                      <FormattedMessage id="inputs.freightKind" />
                                    </Label>
                                    <Input
                                      className="form-control"
                                      type="select"
                                      value={freightKind}
                                      onChange={(e) => setFreightKind(e.target.value || "")}
                                      disabled={preview}
                                    >
                                      <option value="">-</option>
                                      <option value="CIF">CIF</option>
                                      <option value="FOB">FOB</option>
                                    </Input>
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <FormGroup>
                                    <Label className="col-form-label">
                                      Valor do frete
                                    </Label>
                                    <NumberFormat
                                      className="form-control"
                                      value={freight}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      decimalScale={2}
                                      fixedDecimalScale
                                      allowNegative={false}
                                      thousandsGroupStyle="thousand"
                                      disabled={preview || disabled}
                                      onValueChange={async ({ floatValue }) => {
                                        setFreight(floatValue);
                                        await handleCalculateTaxes(calculateProducts, fiscalPercentNote, floatValue, discount);
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <FormGroup>
                                    <Label className="col-form-label">
                                      <FormattedMessage id="inputs.notes" />
                                    </Label>
                                    <Input
                                      className="form-control"
                                      type="textarea"
                                      rows="3"
                                      value={notes}
                                      onChange={(e) => setNotes(e.target.value || "")}
                                      disabled={preview || disabled}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <FormGroup>
                                    <Label className="col-form-label">
                                      <FormattedMessage id="inputs.weight" />
                                    </Label>
                                    <NumberFormat
                                      className="form-control"
                                      value={weight}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      decimalScale={2}
                                      fixedDecimalScale
                                      allowNegative={false}
                                      thousandsGroupStyle="thousand"
                                      disabled={preview || disabled}
                                      onValueChange={async ({ floatValue }) => {
                                        setWeight(floatValue);
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <FormGroup>
                                    <Label className="col-form-label">
                                      <FormattedMessage id="inputs.volumes" />
                                    </Label>
                                    <NumberFormat
                                      className="form-control"
                                      value={volumes}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      decimalScale={2}
                                      fixedDecimalScale
                                      allowNegative={false}
                                      thousandsGroupStyle="thousand"
                                      disabled={preview || disabled}
                                      onValueChange={async ({ floatValue }) => {
                                        setVolumes(floatValue);
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <FormGroup>
                                    <Label className="col-form-label">
                                      <FormattedMessage id="inputs.paymentConditionId" />
                                    </Label>
                                    <Input
                                      className="form-control"
                                      type="select"
                                      value={paymentConditionId}
                                      onChange={(e) =>
                                        setPaymentConditionId((e.target.value))
                                      }
                                      disabled={preview}
                                    >
                                      <option value="">
                                        {intl.formatMessage({
                                          id: "inputs.selectAny",
                                        })}
                                      </option>
                                      {payments?.map(item => {
                                        let enabledPaymentConditions: string[] = [];
                                        if (credentials?.user?.roles?.length) {
                                          for (const _role of credentials?.user?.roles) {
                                            if (_role.paymentConditions?.length) {
                                              for (const _paymentCondition of _role.paymentConditions) {
                                                enabledPaymentConditions.push(_paymentCondition.id);
                                              }
                                            }
                                          }
                                        }//
                                        if (credentials?.user?.isSuperAdmin || credentials?.user?.roles?.map(e => e.name === "admin") || enabledPaymentConditions.includes(item.id)) {
                                          return (
                                            <option key={`payments-${item.id}`} value={item.id}>
                                              {item.protheusId} - {item.description1} ({item.description2})
                                            </option>
                                          );
                                        }
                                        return "";
                                      })}
                                    </Input>
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <FormGroup>
                                    <Label className="col-form-label">
                                      <FormattedMessage id="inputs.carrierId" />
                                    </Label>
                                    <Input
                                      className="form-control"
                                      type="select"
                                      value={carrierId}
                                      onChange={(e) =>
                                        setCarrierId((e.target.value))
                                      }
                                      disabled={preview}
                                    >
                                      <option value="">
                                        {intl.formatMessage({
                                          id: "inputs.selectAny",
                                        })}
                                      </option>
                                      {carriers?.map(item => {
                                        return (
                                          <option key={`carriers-${item.id}`} value={item.id}>
                                            {item.name}
                                          </option>
                                        );
                                      })}
                                    </Input>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <FormGroup>
                                    <Label className="col-form-label">
                                      <FormattedMessage id="inputs.funnelStep" />
                                    </Label>
                                    <Input
                                      className="form-control"
                                      type="select"
                                      value={funnelId}
                                      onChange={(e) =>
                                        setFunnelId((e.target.value))
                                      }
                                      disabled={preview || disabled}
                                    >
                                      <option value="">
                                        {intl.formatMessage({
                                          id: "inputs.selectAny",
                                        })}
                                      </option>
                                      {funnels?.map(item => {
                                        return (
                                          <option key={`funnels-${item.id}`} value={item.id}>
                                            {item.name}
                                          </option>
                                        );
                                      })}
                                    </Input>
                                  </FormGroup>
                                </Col>
                                {protheusId ? (<Col>
                                  <FormGroup>
                                    <Label className="col-form-label">
                                      Código do pedido no Protheus
                                    </Label>
                                    <Input
                                      className="form-control"
                                      type="text"
                                      disabled
                                      value={protheusId}
                                    />
                                  </FormGroup>
                                </Col>) : <Col></Col>}
                              </Row>
                            </Fragment>)}
                          </TabPane>
                          <TabPane tabId="2">
                            {loading ? <Loading /> : (<Fragment>
                              <fieldset>
                                <legend>
                                  <FormattedMessage id="inputs.addressTitle" />
                                </legend>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.addressPostcode" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={addressPostcode?.trim()?.replace(/\D/gm, "")}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col></Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.address" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={address}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.addressNumber" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={addressNumber}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.addressComplement" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={addressComplement}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.addressNeighborhood" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={addressNeighborhood}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.state" />
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control digits"
                                        value={addressState?.toString()}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.city" />
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control digits"
                                        value={addressCity?.toString()}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </fieldset>
                              <fieldset>
                                <legend>
                                  <FormattedMessage id="inputs.deliveryAddressTitle" />
                                </legend>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.addressPostcode" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        maxLength={8}
                                        value={deliveryAddressPostcode?.trim()?.replace(/\D/gm, "")}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col></Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.address" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={deliveryAddress}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.addressNumber" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={deliveryAddressNumber}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.addressComplement" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={deliveryAddressComplement}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.addressNeighborhood" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={deliveryAddressNeighborhood}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.state" />
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control digits"
                                        value={deliveryAddressState?.toString()}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.city" />
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control digits"
                                        value={deliveryAddressCity?.toString()}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </fieldset>
                            </Fragment>)}
                          </TabPane>
                          <TabPane tabId="3">
                            {loading ? <Loading /> : (<Fragment>
                              <Row>
                                <Col>
                                  <Button type="button" onClick={() => toggle()} disabled={preview || disabled} color="primary"><i className="fa fa-plus"></i> Adicionar</Button>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  &nbsp;
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Listing
                                    data={products}
                                    total={totalProducts}
                                    selectable={!preview && !disabled}
                                    sortServer={false}
                                    paginationServer={false}
                                    rowsPerPage={perPageProducts}
                                    columns={tableColumnsProducts}
                                    onChangePage={(page: number) => {
                                      setPageProducts(page);
                                    }}
                                    onChangeRowsPerPage={(rowsPerPage: number) => {
                                      setPageProducts(1);
                                      setPerPageProducts(rowsPerPage);
                                    }}
                                    onRowClicked={handleQuantity}
                                    contextActions={contextActionsProducts}
                                    onSelectedRowsChange={handleRowSelectedProducts}
                                    clearSelectedRows={toggleClearedProducts}
                                    expandableRows
                                    expandableRowsComponent={<ExpandedComponent2 />}
                                  >
                                    <div style={{ paddingTop: "20px" }}>
                                      <div><strong>Quantidade total:</strong> {intl.formatNumber(calculatedTaxes?.quantityTotal || 0, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}</div>
                                      {fiscalPercentNote < 100 ? <div><strong>Entrada:</strong> R${intl.formatNumber((calculatedTaxes?.liquidTotal - calculatedTaxes?.fiscalTotal) || 0, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div> : ""}
                                      <div><strong>Total:</strong> R${intl.formatNumber(calculatedTaxes?.liquidTotal || 0, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                    </div>
                                  </Listing>
                                </Col>
                              </Row>
                            </Fragment>)}
                          </TabPane>
                        </TabContent>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          type="button"
                          color="danger"
                          onClick={() => history.push(action === "create" ? "/budgets/client" : (query.get("backTo") ? query.get("backTo") : "/budgets"))}
                        >
                          <i className="fa fa-arrow-left"></i>
                          <FormattedMessage id="buttons.cancel" />
                        </Button>
                      </Col>
                      <Col className="text-right">
                        {preview && !loading ? (
                          <Button
                            type="button"
                            color="secondary"
                            disabled={disableSubmit}
                            onClick={handlePrint}
                          >
                            <i className="fa fa-print"></i>
                            <FormattedMessage id="buttons.print" />
                          </Button>
                        ) : ""}
                        {(canUpdate || canCreate) ? (
                          <Fragment>
                            {preview ? (
                              <Fragment>
                                {BUDGET_SYNC_WITH_ID === funnelId && !disabled && !protheusId ? (
                                  <Button
                                    type="button"
                                    color="success"
                                    disabled={disabled || disableSyncButton || loading}
                                    onClick={() => !disabled ? syncProtheus() : false}
                                  >
                                    <i className="fa fa-cloud-upload"></i>
                                    <FormattedMessage id="buttons.sync" />
                                  </Button>
                                ) : ""}
                                <Button
                                  type="button"
                                  color="primary"
                                  disabled={loading}
                                  onClick={() => history.push(`/budgets/update/${id}`)}
                                >
                                  <i className="fa fa-edit"></i>
                                  <FormattedMessage id="buttons.edit" />
                                </Button>
                              </Fragment>
                            ) : (
                              <Button type="button" disabled={disableSubmit || loading} onClick={handleSubmitForm} color="primary">
                                <i className="fa fa-save"></i>
                                <FormattedMessage id="buttons.save" />
                              </Button>
                            )}
                          </Fragment>) : ""}
                      </Col>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Manage;
