import React, {
  ChangeEvent,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { Accordion } from "react-bootstrap";
import Breadcrumb from "../../layout/breadcrumb";
import { CrudParam } from "../../configs/route";
import { useApollo } from "../../services/apollo.service";
import { RoleClaim } from "../../models/role";
import Policy from "../../models/policy";
import { SITE_TITLE } from "../../configs/constants";
import { useAuth } from "../../hooks/auth";
import { gql } from "@apollo/client";
import PaymentCondition from "../../models/paymentCondition";
import NumberFormat from "react-number-format";
import Funnel from "../../models/funnel";
import Loading from "../../components/loading";

const Manage: React.FC = () => {
  const isMountedRef = useRef(null);
  const { apollo, error } = useApollo();
  const history = useHistory();
  const intl = useIntl();
  const { id } = useParams<CrudParam>();
  const [activeTab, setActiveTab] = useState<number>(1);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [maximumDiscount, setMaximumDiscount] = useState<number>(0);
  const [papeis, setPapeis] = useState<string[]>([]);
  const [claims, setClaims] = useState<Policy[]>([]);
  const [paymentConditionIds, setPaymentConditionIds] = useState<string[]>([]);
  const [paymentConditions, setPaymentConditions] = useState<PaymentCondition[]>([]);
  const [selectedPaymentConditions, setSelectedPaymentConditions] = useState<any>({});

  const [funnelIds, setFunnelIds] = useState<string[]>([]);
  const [funnels, setFunnels] = useState<Funnel[]>([]);
  const [selectedFunnels, setSelectedFunnels] = useState<any>({});

  const [papeisSelecionados, setPapeisSelecionados] = useState<any>({});
  const [preview, setPreview] = useState<boolean>(() => { if (id) return true; else return false; });
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { hasScope } = useAuth();
  const [canView] = useState<boolean>(() => {
    return hasScope("Roles:Role");
  });
  const [canCreate] = useState<boolean>(() => {
    return hasScope("Roles:Create");
  });
  const [canUpdate] = useState<boolean>(() => {
    return hasScope("Roles:Update");
  });

  const handleData = useCallback(async () => {
    if (id) {
      if (preview && !canView) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/roles");
        return;
      } else if (!preview && !canUpdate) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/roles");
        return;
      }
      setLoading(true);
      await Promise.all([
        apollo.query({
          query: gql`
            query policies {
              policies {
                module
                policies
              }
            }
          `,
        }),
        apollo.query({
          query: gql`
            query role ($id: String!) {
              role (id: $id) {
                id
                name
                description
                maximumDiscount
                paymentConditions {
                  id
                }
                funnels {
                  id
                }
                claims {
                  claimType
                  claimValue
                }
              }
            }
          `,
          variables: {
            id
          },
        }),
        apollo.query({
          query: gql`
          query paymentConditionsDropdown {
            paymentConditionsDropdown {
              id
              name
              description1
              description2
              protheusId
            }
          }
        `,
        }),
        apollo.query({
          query: gql`
          query funnelsDropdown {
            funnelsDropdown {
              id
              name
              isHidden
            }
          }
        `,
        }),
      ])
        .then(res => {
          if (isMountedRef.current) {
            if (res[0].data.policies)
              setClaims(res[0].data.policies)
            if (res[1].data.role) {
              setName(res[1].data.role.name || "");
              setDescription(res[1].data.role.description || "");
              setMaximumDiscount(res[1].data.role.maximumDiscount || 0);
              if (res[1].data.role.claims?.length > 0) {
                let claimsObj = {};
                let claimsArray: string[] = [];
                for (const claim of res[1].data.role.claims) {
                  claimsArray.push(`${claim.claimType}:${claim.claimValue}`);
                  claimsObj = { ...claimsObj, [`${claim.claimType}:${claim.claimValue}`]: true };
                }
                setPapeis(claimsArray);
                setPapeisSelecionados(claimsObj);
              }
              if (res[1].data.role.paymentConditions?.length > 0) {
                let paymentConditionsObj = {};
                let paymentConditionsArray: string[] = [];
                for (const paymentCondition of res[1].data.role.paymentConditions) {
                  paymentConditionsArray.push(paymentCondition.id);
                  paymentConditionsObj = { ...paymentConditionsObj, [paymentCondition.id]: true };
                }
                setPaymentConditionIds(paymentConditionsArray);
                setSelectedPaymentConditions(paymentConditionsObj);
              }
              if (res[1].data.role.funnels?.length > 0) {
                let funnelsObj = {};
                let funnelsArray: string[] = [];
                for (const funnel of res[1].data.role.funnels) {
                  funnelsArray.push(funnel.id);
                  funnelsObj = { ...funnelsObj, [funnel.id]: true };
                }
                setPaymentConditionIds(funnelsArray);
                setSelectedFunnels(funnelsObj);
              }
            }
            if (res[2].data.paymentConditionsDropdown)
              setPaymentConditions(res[2].data.paymentConditionsDropdown);
            if (res[3].data.funnelsDropdown)
              setFunnels(res[3].data.funnelsDropdown);
          }
        })
        .catch(err => error(err));
      setLoading(false);
    } else {
      if (!preview && !canCreate) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/roles");
        return;
      }
      setLoading(true);
      await Promise.all([
        apollo.query({
          query: gql`
          query policies {
            policies {
              module
              policies
            }
          }
        `,
        }),
        apollo.query({
          query: gql`
          query paymentConditionsDropdown {
            paymentConditionsDropdown {
              id
              name
              description1
              description2
              protheusId
            }
          }
        `,
        }),
        apollo.query({
          query: gql`
          query funnelsDropdown {
            funnelsDropdown {
              id
              name
              isHidden
            }
          }
        `,
        }),
      ])
        .then(res => {
          if (isMountedRef.current) {
            if (res[0].data)
              setClaims(res[0].data.policies)
            if (res[1].data.paymentConditionsDropdown)
              setPaymentConditions(res[1].data.paymentConditionsDropdown);
            if (res[2].data.funnelsDropdown)
              setFunnels(res[2].data.funnelsDropdown);
          }
        })
        .catch(err => error(err));
      setLoading(false);
    }
  }, [intl, id, preview, apollo, error, history, canView, canCreate, canUpdate]);

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: `pages.roles.${preview ? "preview" : id ? "update" : "create"}`,
      })}`;
    handleData();
    return () => { isMountedRef.current = false; }
  }, [intl, id, preview, handleData]);

  const handleSubmitForm = async () => {
    setDisableSubmit(true);
    let claims: RoleClaim[] = [];
    if (papeis.length) {
      for (const papel of papeis) {
        const ex = papel.split(":");
        const claimType = ex[0];
        const claimValue = ex[1];
        if (claimType && claimValue)
          claims.push({
            claimType,
            claimValue,
          } as RoleClaim);
      }
    }

    const data = {
      name,
      description,
      maximumDiscount: (maximumDiscount <= 100 ? maximumDiscount : 0),
      paymentConditions: paymentConditionIds,
      funnels: funnelIds,
      claims,
    };

    if (!id) {
      await apollo.mutate({
        mutation: gql`
          mutation createRole($data: RoleInput!) {
            createRole(data: $data) {
                id
            }
          }
        `,
        variables: {
          data
        },
      })
        .then(res => {
          toast.success(intl.formatMessage({ id: "flash.success.created" }));
          history.push(`/roles/manage/${res.data.createRole.id}`);
        })
        .catch(err => error(err));
    } else {
      await apollo.mutate({
        mutation: gql`
          mutation updateRole($id: String!, $data: RoleInput!) {
            updateRole(id: $id, data: $data) {
                id
            }
          }
        `,
        variables: {
          id,
          data
        },
      })
        .then(res => {
          toast.success(intl.formatMessage({ id: "flash.success.updated" }));
          history.push(`/roles`);
        })
        .catch(err => error(err));
    }
    setDisableSubmit(false);
  };

  const handleClaims = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const index = papeis.indexOf(value);
    if (index >= 0) {
      papeis.splice(index, 1);
      setPapeis(papeis);
    } else {
      papeis.push(value);
      setPapeis(papeis);
    }
    setPapeisSelecionados({ ...papeisSelecionados, [value]: e.target.checked });
  };

  const handlePaymentConditions = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const index = paymentConditionIds.indexOf(value);
    if (index >= 0) {
      paymentConditionIds.splice(index, 1);
      setPaymentConditionIds(paymentConditionIds);
    } else {
      paymentConditionIds.push(value);
      setPaymentConditionIds(paymentConditionIds);
    }
    setSelectedPaymentConditions({ ...selectedPaymentConditions, [value]: e.target.checked });
  };

  const handleFunnels = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const index = funnelIds.indexOf(value);
    if (index >= 0) {
      funnelIds.splice(index, 1);
      setFunnelIds(funnelIds);
    } else {
      funnelIds.push(value);
      setFunnelIds(funnelIds);
    }
    setSelectedFunnels({ ...selectedFunnels, [value]: e.target.checked });
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="pages.roles.roles"
        title={`pages.roles.${preview ? "preview" : id ? "update" : "create"}`}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>
                  <FormattedMessage
                    id={`pages.roles.${preview ? "preview" : id ? "update" : "create"
                      }`}
                  />
                </h5>
              </CardHeader>
              <CardBody className="tabbed-card">
                <div className="form-content">
                  <Form onSubmit={(e) => e.preventDefault()}>
                    <Nav className="nav-pills nav-primary">
                      <NavItem>
                        <NavLink
                          className={activeTab === 1 ? "active" : ""}
                          onClick={() => setActiveTab(1)}
                        >
                          <i className="fa fa-file-text-o"></i>
                          <FormattedMessage id="tabs.data" />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={activeTab === 2 ? "active" : ""}
                          onClick={() => setActiveTab(2)}
                        >
                          <i className="icofont icofont-papers"></i>
                          <FormattedMessage id="tabs.claims" />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={activeTab === 3 ? "active" : ""}
                          onClick={() => setActiveTab(3)}
                        >
                          <i className="icofont icofont-money"></i>
                          Condições de pagamento
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={activeTab === 4 ? "active" : ""}
                          onClick={() => setActiveTab(4)}
                        >
                          <i className="fa fa-filter"></i>
                          Funís
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <Row className="m-b-30">
                      <Col>
                        <TabContent activeTab={activeTab.toString()}>
                          <TabPane tabId="1">
                            {loading ? <Loading /> : (
                              <React.Fragment>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.name" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={name}
                                        disabled={!!id || preview}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.description" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={description}
                                        disabled={preview}
                                        onChange={(e) =>
                                          setDescription(e.target.value)
                                        }
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.maximumDiscount" />
                                      </Label>
                                      <NumberFormat
                                        className="form-control"
                                        value={maximumDiscount}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale
                                        allowNegative={false}
                                        thousandsGroupStyle="thousand"
                                        disabled={preview}
                                        onValueChange={async ({ floatValue }) => {
                                          if (isNaN(floatValue))
                                            setMaximumDiscount(0);
                                          else if (floatValue < 0)
                                            setMaximumDiscount(0);
                                          else if (floatValue >= 100)
                                            setMaximumDiscount(100);
                                          else
                                            setMaximumDiscount(floatValue);
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col></Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </TabPane>
                          <TabPane tabId="2">
                            {loading ? <Loading /> : (
                              <Accordion defaultActiveKey="0">
                                <div className="default-according" id="accordion">
                                  {claims?.map(
                                    (item, index) => {
                                      return (
                                        <Card key={item.module}>
                                          <CardHeader>
                                            <h5 className="mb-0">
                                              <Accordion.Toggle
                                                className="btn btn-link"
                                                color="default"
                                                eventKey={index.toString()}
                                              >
                                                <FormattedMessage
                                                  id={`app.modules.${item.module.toLowerCase()}`}
                                                />
                                              </Accordion.Toggle>
                                            </h5>
                                          </CardHeader>
                                          <Accordion.Collapse
                                            eventKey={index.toString()}
                                          >
                                            <CardBody>
                                              <Row>
                                                {item?.policies?.map(
                                                  (policy, ind) => {
                                                    return (
                                                      <Col
                                                        key={`col-claim-${ind}`}
                                                        md="3"
                                                        className="checkbox m-b-5"
                                                      >
                                                        <Input
                                                          key={`input-claim-${ind}`}
                                                          id={`${item.module}:${policy}`}
                                                          type="checkbox"
                                                          value={`${item.module}:${policy}`}
                                                          disabled={
                                                            name === "admin" ||
                                                            name === "common" ||
                                                            preview
                                                          }
                                                          checked={
                                                            papeisSelecionados.hasOwnProperty(
                                                              `${item.module}:${policy}`
                                                            )
                                                              ? papeisSelecionados[
                                                              `${item.module}:${policy}`
                                                              ]
                                                              : false
                                                          }
                                                          onChange={handleClaims}
                                                        />
                                                        <Label
                                                          key={`label-claim-${ind}`}
                                                          className="d-block"
                                                          for={`${item.module}:${policy}`}
                                                        >
                                                          <FormattedMessage
                                                            id={`app.policies.${policy.toLowerCase()}`}
                                                          />
                                                        </Label>
                                                      </Col>
                                                    );
                                                  }
                                                )}
                                              </Row>
                                            </CardBody>
                                          </Accordion.Collapse>
                                        </Card>
                                      );
                                    }
                                  )}
                                </div>
                              </Accordion>
                            )}
                          </TabPane>
                          <TabPane tabId="3">
                            {loading ? <Loading /> : (
                              <Row>
                                {paymentConditions?.map(
                                  (paymentCondition, index) => {
                                    return (
                                      <Col
                                        key={`col-paymentCondition-${index}`}
                                        md="3"
                                        className="checkbox m-b-5"
                                      >
                                        <Input
                                          key={`input-paymentCondition-${index}`}
                                          id={paymentCondition.id}
                                          type="checkbox"
                                          value={paymentCondition.id}
                                          disabled={preview}
                                          checked={
                                            selectedPaymentConditions.hasOwnProperty(paymentCondition.id)
                                              ? selectedPaymentConditions[paymentCondition.id]
                                              : false
                                          }
                                          onChange={handlePaymentConditions}
                                        />
                                        <Label
                                          key={`label-paymentCondition-${index}`}
                                          className="d-block"
                                          for={paymentCondition.id}
                                        >
                                          {paymentCondition.protheusId} - {paymentCondition.description1}
                                        </Label>
                                      </Col>
                                    );
                                  }
                                )}
                              </Row>
                            )}
                          </TabPane>
                          <TabPane tabId="4">
                            {loading ? <Loading /> : (
                              <Row>
                                {funnels?.map(
                                  (funnel, index) => {
                                    return (
                                      <Col
                                        key={`col-funnel-${index}`}
                                        md="3"
                                        className="checkbox m-b-5"
                                      >
                                        <Input
                                          key={`input-funnel-${index}`}
                                          id={funnel.id}
                                          type="checkbox"
                                          value={funnel.id}
                                          disabled={preview}
                                          checked={
                                            selectedFunnels.hasOwnProperty(funnel.id)
                                              ? selectedFunnels[funnel.id]
                                              : false
                                          }
                                          onChange={handleFunnels}
                                        />
                                        <Label
                                          key={`label-funnel-${index}`}
                                          className="d-block"
                                          for={funnel.id}
                                        >
                                          {funnel.name}
                                        </Label>
                                      </Col>
                                    );
                                  }
                                )}
                              </Row>
                            )}
                          </TabPane>
                        </TabContent>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          type="button"
                          color="danger"
                          onClick={() => history.push("/roles")}
                        >
                          <i className="fa fa-arrow-left"></i>
                          <FormattedMessage id="buttons.cancel" />
                        </Button>
                      </Col>
                      {canUpdate ? (
                        <Col className="text-right">
                          {preview ? (
                            <Button
                              type="button"
                              color="primary"
                              disabled={loading}
                              onClick={() => setPreview(!preview)}
                            >
                              <i className="fa fa-edit"></i>
                              <FormattedMessage id="buttons.edit" />
                            </Button>
                          ) : (
                            <Button type="button" disabled={disableSubmit || loading} onClick={handleSubmitForm} color="primary">
                              <i className="fa fa-save"></i>
                              <FormattedMessage id="buttons.save" />
                            </Button>
                          )}
                        </Col>) : ""}
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Manage;
