import produce from "immer";
import { ApplicationAction, UserCustomizedState } from "../types";

let kanban = [];
const storageKanban = localStorage.getItem("kanban");
if (storageKanban)
  kanban = JSON.parse(storageKanban);

export const initialUserCustomizedState: UserCustomizedState = {
  kanban,
};

const reducer = (
  state: UserCustomizedState = initialUserCustomizedState,
  action: ApplicationAction
): UserCustomizedState => {
  switch (action.type) {
    case "CHANGE_KANBAN":
      return produce(state, (draft) => {
        draft.kanban = action.payload;
      });
    default:
      return { ...state };
  }
};

export default reducer;
