import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, CardBody, FormGroup, Label, Form, Button, Input } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { IDataTableColumn } from "react-data-table-component";
import { useApollo } from "../../services/apollo.service";
import { useHistory } from "react-router-dom";
import Listing from "../../components/listing";
import { RECORDS_PER_PAGE, SITE_TITLE } from "../../configs/constants";
import { useAuth } from "../../hooks/auth";
import Product from "../../models/product";
import { gql } from "@apollo/client";
import { useDispatch } from "react-redux";
import { dataTableLoading } from "../../redux/actions";
import ExportToExcel from "../../components/export-to-excel";

interface ProductFilters {
  code?: string | null;
  name?: string | null;
}

const Products: React.FC = () => {
  const isMountedRef = useRef(null);
  const dispatch = useDispatch();
  const { apollo, error } = useApollo();
  const intl = useIntl();
  const history = useHistory();
  const [data, setData] = useState<Product[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(RECORDS_PER_PAGE);
  const [sortBy, setSortBy] = useState<string>("name");
  const [sortDir, setSortDir] = useState<string>("ASC");
  const [filters, setFilters] = useState<ProductFilters>();
  const [filters2, setFilters2] = useState<ProductFilters>();
  const [disableSubmitSearch, setDisableSubmitSearch] = useState<boolean>(false);

  const { hasScope } = useAuth();

  const handleSearch = () => {
    setPage(1);
    setFilters2(filters);
  };

  const handleData = useCallback(async () => {
    dispatch(dataTableLoading(true));
    await apollo.query({
      query: gql`
        query products($sortDir: String, $sortBy: String, $perPage: Int, $page: Int, $filterByCode: String, $filterByName: String) {
          products(sortDir: $sortDir, sortBy: $sortBy, perPage: $perPage, page: $page, filterByCode: $filterByCode, filterByName: $filterByName) {
            paging {
              total
              pages
              perPage
              currentPage
            }
            list {
              id
              createdAt
              updatedAt
              name
              barCode
              notes
              unit
              stock
              boxes
              boxSize
              boxCubic
              cartoon
              cartoonSize
              cartoonCubic
              multiplier
              lastSalePrice
              lastCostPrice
              path
              cmn
              icmsRate
              ipiRate
              taxGroup
              protheusId
              isActivated
              group {
                id
                name
                protheusId
              }
            }
          }
        }
      `,
      variables: {
        page,
        perPage,
        sortBy,
        sortDir,
        filterByName: filters2?.name,
        filterByCode: filters2?.code,
      }
    })
      .then(res => {
        if (isMountedRef.current) {
          setTotal(res.data.products.paging.total);
          setPage(res.data.products.paging.currentPage);
          setPerPage(res.data.products.paging.perPage);
          setData(res.data.products.list);
        }
      })
      .catch(err => error(err));
    dispatch(dataTableLoading(false));
  }, [apollo, error, page, perPage, sortBy, sortDir, dispatch, filters2]);

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: "pages.products.products",
      })}`;
    handleData();
    return () => { isMountedRef.current = false; }
  }, [intl, handleData]);

  const tableColumns: IDataTableColumn<Product>[] = [
    {
      name: intl.formatMessage({ id: "inputs.protheusId" }),
      selector: "protheusId",
      sortable: true,
      center: false,
    },
    {
      name: intl.formatMessage({ id: "inputs.name" }),
      selector: "name",
      sortable: true,
      center: false,
    },
    {
      name: intl.formatMessage({ id: "inputs.minimumSell" }),
      selector: "multiplier",
      sortable: true,
      center: true,
    },
    {
      name: intl.formatMessage({ id: "inputs.boxes" }),
      selector: "boxes",
      sortable: true,
      center: true,
    },
    {
      name: intl.formatMessage({ id: "inputs.isActivated" }),
      selector: "isActivated",
      format: (row) => {
        return row.isActivated
          ? intl.formatMessage({ id: "general.yes" })
          : intl.formatMessage({ id: "general.no" });
      },
      sortable: true,
      center: false,
    },
  ];

  const dataExport = [];
  if (data) {
    for (const row of data) {
      dataExport.push({
        "Código": row.protheusId,
        "Produto": row.name,
        "Preço de venda": intl.formatNumber(row.lastSalePrice, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
        "Estoque": row.stock,
        "Caixa qtd.": row.boxes,
        "Caixa tam.": intl.formatNumber(row.boxSize, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
        "Caixa cub.": intl.formatNumber(row.boxCubic, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
        "Cartoon qtd.": intl.formatNumber(row.cartoon, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
        "Cartoon tam.": intl.formatNumber(row.cartoonSize, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
        "Cartoon cub.": intl.formatNumber(row.cartoonCubic, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
        "Qtd. mínima venda": row.multiplier,
      });
    }
  }

  const handleUpdateRecord = (row: Product) => {
    if (hasScope("Products:Product"))
      history.push(`/products/manage/${row.id}`);
  };

  return (
    <Fragment>
      <Breadcrumb parent="general.home" title="pages.products.products" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>
                  <FormattedMessage id="pages.products.list" />
                </h5>
              </CardHeader>
              <CardBody>
                <Form onSubmit={async (e) => {
                  e.preventDefault();
                  setDisableSubmitSearch(true);
                  await handleSearch();
                  setDisableSubmitSearch(false);
                }}>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label">
                          <FormattedMessage id="inputs.code" />
                        </Label>
                        <Input
                          key="code"
                          className="form-control"
                          type="text"
                          value={filters?.code || ""}
                          disabled={disableSubmitSearch}
                          onChange={(e) => {
                            setFilters({ ...filters, code: e.target.value });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label">
                          <FormattedMessage id="inputs.name" />
                        </Label>
                        <Input
                          key="name"
                          className="form-control"
                          type="text"
                          value={filters?.name || ""}
                          disabled={disableSubmitSearch}
                          onChange={(e) => {
                            setFilters({ ...filters, name: e.target.value });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="text-right">
                      <FormGroup>
                        <Label className="col-form-label" style={{ display: "block", width: "100%" }}>
                          &nbsp;
                        </Label>
                        <div>
                          <Button type="submit" disabled={disableSubmitSearch} color="secondary">
                            <i className="fa fa-search"></i> Filtrar
                          </Button>
                          <ExportToExcel data={dataExport} fileName="Produtos.xlsx" disabled={disableSubmitSearch} />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                <Listing
                  data={data}
                  total={total}
                  selectable={false}
                  rowsPerPage={perPage}
                  onChangePage={(page: number) => {
                    setPage(page);
                  }}
                  onChangeRowsPerPage={(rowsPerPage: number) => {
                    setPage(1);
                    setPerPage(rowsPerPage);
                  }}
                  onSort={(
                    column,
                    sortDir
                  ) => {
                    setSortBy(column.selector.toString());
                    setSortDir(sortDir);
                  }}
                  columns={tableColumns}
                  onRowClicked={handleUpdateRecord}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Products;
