import React from "react";
import { useIntl } from "react-intl";
import { Row, Col, Card, CardBody, CardHeader, Container, Table } from "reactstrap";
import { ExpandedComponentProps } from "../../components/listing";
import { date, DATE_FORMAT_BR, DATE_TIME_FORMAT_BR, escapedNewLineToLineBreakTag } from "../../configs/constants";
import Client from "../../models/client";
import formatter from "../../utils/formatter";

const formatDocument = (document: string): string => {
  document = document.trim().replace(/\D/gm, "");

  // Formata o documento do usuário
  if (document.length === 11) {
    document = formatter.formatCpf(document);
  } else if (document.length === 14) {
    document = formatter.formatCnpj(document);
  }
  return document;
}

export default function ClientProfile<T = any>(props: ExpandedComponentProps<Client>): React.ReactElement {
  const intl = useIntl();

  const personKinds: any = {
    "F": "Física",
    "J": "Jurídica",
  };
  const clientKinds: any = {
    "F": "Consumidor final",
    "L": "Produtor rural",
    "R": "Revendedor",
    "S": "Solidário",
    "X": "Exportação"
  };
  return (
    <Container fluid={true}>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <h5>
                Cadastro
                </h5>
            </CardHeader>
            <CardBody className="tabbed-card">
              <Row>
                <Col>
                  <div>
                    <strong>Código:</strong> {props.data.protheusId}
                  </div>
                  <div>
                    <strong>Razão Social:</strong> {props.data.companyName?.trim()?.toUpperCase()}
                  </div>
                  <div>
                    <strong>Nome fantasia:</strong> {props.data.fantasyName?.trim()?.toUpperCase()}
                  </div>
                  <div>
                    <strong>CPF/CNPJ:</strong> {formatDocument(props.data.document)}
                  </div>
                  <div>
                    <strong>Tipo pessoa:</strong> {personKinds[props.data.personKind]?.trim()?.toUpperCase()}
                  </div>
                  <div>
                    <strong>Tipo cliente:</strong> {clientKinds[props.data.clientKind]?.trim()?.toUpperCase()}
                  </div>
                  {props.data.sr ? (<div>
                    <strong>IE:</strong> {props.data.sr}
                  </div>) : ""}
                  {props.data.idCard ? (<div>
                    <strong>RG:</strong> {props.data.idCard}
                  </div>) : ""}
                  {props.data.address?.trim() ? (
                    <div>
                      <strong>Endereço:</strong> {props.data.address?.trim()?.toUpperCase()}{props.data.addressNumber?.trim() ? `, ${props.data.addressNumber?.trim()?.toUpperCase()}` : ""}{props.data.addressComplement?.trim() ? `, ${props.data.addressComplement?.trim()?.toUpperCase()}` : ""}{props.data.addressCity?.name ? ` - ${props.data.addressCity.name?.toUpperCase()}` : ""}{props.data.addressState?.fc ? `/${props.data.addressState.fc}` : ""}{props.data.addressPostcode ? `, CEP: ${props.data.addressPostcode}` : ""}
                    </div>
                  ) : ""}
                  {props.data.deliveryAddress?.trim() ? (
                    <div>
                      <strong>Endereço de entrega:</strong> {props.data.deliveryAddress?.trim()?.toUpperCase()}{props.data.deliveryAddressNumber?.trim() ? `, ${props.data.deliveryAddressNumber?.trim()?.toUpperCase()}` : ""}{props.data.deliveryAddressComplement?.trim() ? `, ${props.data.deliveryAddressComplement?.trim()?.toUpperCase()}` : ""}{props.data.deliveryAddressCity?.name ? ` - ${props.data.deliveryAddressCity.name?.toUpperCase()}` : ""}{props.data.deliveryAddressState?.fc ? `/${props.data.deliveryAddressState.fc}` : ""}{props.data.deliveryAddressPostcode ? `, CEP: ${props.data.deliveryAddressPostcode}` : ""}
                    </div>
                  ) : ""}
                  {props.data.billingEmail?.trim() ? (
                    <div>
                      <strong>E-mail de cobrança:</strong> {props.data.billingEmail?.trim()}
                    </div>
                  ) : ""}
                  {props.data.merchandiseDestination?.trim() ? (
                    <div>
                      <strong>Destino da mercadoria:</strong> {props.data.merchandiseDestination?.trim()?.toUpperCase()}
                    </div>
                  ) : ""}
                  {props.data.isNationalSimple ? (
                    <div>
                      <strong>Optante Simples:</strong> {props.data.isNationalSimple ? "SIM" : "NÃO"}
                    </div>
                  ) : ""}
                  {props.data.isTaxPayerIcms ? (
                    <div>
                      <strong>Contribuinte ICMS:</strong> {props.data.isTaxPayerIcms ? "SIM" : "NÃO"}
                    </div>
                  ) : ""}
                  <br />
                  {props.data.contact?.trim() ? (
                    <div>
                      <strong>Contato:</strong> {props.data.contact?.trim()?.toUpperCase()}
                    </div>
                  ) : ""}
                  {props.data.email?.trim() ? (
                    <div>
                      <strong>E-mail:</strong> {props.data.email?.trim()}
                    </div>
                  ) : ""}
                  {props.data.phone?.trim() ? (
                    <div>
                      <strong>Telefone:</strong> {props.data.phone?.trim()}
                    </div>
                  ) : ""}
                  {props.data.mobile?.trim() ? (
                    <div>
                      <strong>Celular:</strong> {props.data.mobile?.trim()}
                    </div>
                  ) : ""}
                  {props.data.notes?.trim() ? (
                    <div>
                      <strong>Observações:</strong> {escapedNewLineToLineBreakTag(props.data.notes?.trim()?.toUpperCase())}
                    </div>
                  ) : ""}
                  <div>
                    <strong>Ativo:</strong> {props.data.isActivated ? "SIM" : "NÃO"}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <h5>
                Histórico Financeiro
                </h5>
            </CardHeader>
            <CardBody className="tabbed-card">
              <Row>
                <Col>
                  <div>
                    <strong>Score:</strong> {props.data.creditScore ? props.data.creditScore : "-"}
                  </div>
                  <div>
                    <strong>Nível de risco:</strong> {props.data.dangerLevel ? props.data.dangerLevel : "-"}
                  </div>
                  <div>
                    <strong>Data de fundação:</strong> {props.data.foundationDate ? date(props.data.foundationDate).format(DATE_FORMAT_BR) : "-"}
                  </div>
                  <div>
                    <strong>Limite de crédito:</strong> R${intl.formatNumber(props.data.creditLimit, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                  </div>
                  <div>
                    <strong>Vencimento do limite de crédito:</strong> {props.data.creditLimitExpires ? date(props.data.creditLimitExpires).format(DATE_FORMAT_BR) : "-"}
                  </div>
                  <div>
                    <strong>Desconto padrão:</strong> {props.data.discount}%
                    </div>
                  <div>
                    <strong>Saldo de pedidos em aberto:</strong> R${intl.formatNumber(props.data.unpaidOrders, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardHeader>
              <h5>
                Compras
                </h5>
            </CardHeader>
            <CardBody className="tabbed-card">
              <Row>
                <Col>
                  <div>
                    <strong>Último pedido:</strong> {props.data.latestOrder ? date(props.data.latestOrder).format(DATE_FORMAT_BR) : "-"}
                  </div>
                </Col>
                <Col>
                  <div>
                    <strong>Próximo agendamento:</strong> {props.data.nextSchedule ? date(props.data.nextSchedule).format(DATE_TIME_FORMAT_BR) : "-"}
                  </div>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col><br />
                  <h5>Produtos mais comprados</h5>
                </Col>
              </Row>
              <Row>
                <Col><br />
                  <div className="table-responsive">
                    <Table striped>
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Código</th>
                          <th scope="col">Produto</th>
                          <th scope="col">Qtd.</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.data.dashboards.productBestSellers?.length ? props.data.dashboards.productBestSellers.map((item, index) => (
                          <tr key={item.product.id}>
                            <th scope="row">{index + 1}</th>
                            <td>{item.product.protheusId}</td>
                            <td>{item.product.name}</td>
                            <td>{item.total}</td>
                          </tr>
                        )) : (
                            <tr key={`product-no-data-${props.data.id}`}>
                              <td colSpan={4} className="text-center">Nenhum pedido foi encontrado.</td>
                            </tr>
                          )}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
