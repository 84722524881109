import React, {
  Fragment,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Form, FormGroup, Input, Label } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { IDataTableColumn } from "react-data-table-component";
import SweetAlert from "sweetalert2";
import { useApollo } from "../../services/apollo.service";
import { useHistory } from "react-router-dom";
import Client from "../../models/client";
import Budget from "../../models/budget";
import Listing from "../../components/listing";
import { format_date, LIST_DATE_TIME_FORMAT, RECORDS_PER_PAGE, SITE_TITLE } from "../../configs/constants";
import { useAuth } from "../../hooks/auth";
import { gql } from "@apollo/client";
import { useDispatch } from "react-redux";
import { dataTableLoading } from "../../redux/actions";

interface BudgetFilters {
  clientId?: string;
}

const Budgets: React.FC = () => {
  const isMountedRef = useRef(null);
  const dispatch = useDispatch();
  const { apollo, error } = useApollo();
  const intl = useIntl();
  const history = useHistory();
  const [selectedRows, setSelectedRows] = useState<Budget[]>([]);
  const [toggleCleared, setToggleCleared] = useState<boolean>(false);
  const [data, setData] = useState<Budget[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(RECORDS_PER_PAGE);
  const [sortBy, setSortBy] = useState<string>("createdAt");
  const [sortDir, setSortDir] = useState<string>("DESC");
  const [filters, setFilters] = useState<BudgetFilters>();
  const [filters2, setFilters2] = useState<BudgetFilters>();
  const [disableSubmitSearch, setDisableSubmitSearch] = useState<boolean>(false);

  const { hasScope } = useAuth();
  const [canDelete] = useState<boolean>(() => {
    return hasScope("Budgets:Delete");
  });

  const handleSearch = () => {
    setPage(1);
    setFilters2(filters);
  };

  const handleData = useCallback(async () => {
    dispatch(dataTableLoading(true));
    await Promise.all([
      apollo.query({
        query: gql`
          query clientsDropdown ($withBudgets: Boolean) {
            clientsDropdown(withBudgets: $withBudgets) {
              id
              companyName
              protheusId
            }
          }
        `,
        variables: {
          withBudgets: true
        }
      }),
      apollo.query({
        query: gql`
          query budgets($sortDir: String, $sortBy: String, $perPage: Int, $page: Int, $filterByClient: String) {
            budgets(sortDir: $sortDir, sortBy: $sortBy, perPage: $perPage, page: $page, filterByClient: $filterByClient) {
              paging {
                total
                pages
                perPage
                currentPage
              }
              list {
                id
                code
                createdAt
                updatedAt
                expiresAt
                clientKind
                fiscalPercentNote
                discount
                freight
                weight
                volumes
                protheusId
                total
                client {
                  id
                  companyName
                  fantasyName
                  contact
                  clientKind
                  protheusId
                  document
                }
                carrier {
                  id
                  name
                  alias
                }
                paymentCondition {
                  id
                  name
                  description1
                  description2
                }
                funnel {
                  id
                  name
                }
                taxes {
                  client {
                    id
                    protheusId
                    companyName
                    document
                  }
                  fiscalPercentNote
                  discount
                  freight
                  freightApportionment
                  quantityTotal
                  weightTotal
                  grossTotal
                  fiscalTotal
                  freightTotal
                  discountTotal
                  productsFiscalTotal
                  liquidTotal
                  ipiTotal
                  icmsStTotal
                  products {
                    id
                    createdAt
                    updatedAt
                    name
                    protheusId
                    unit
                    qty
                    stock
                    discount
                    grossPrice
                    fiscalPercentNote
                    grossDiscountPrice
                    grossFiscalPercentNotePrice
                    grossFreightApportionmentPrice
                    ipiRate
                    ipiCost
                    ivaRate
                    ivaCost
                    icmsStCost
                    icmsOriginRate
                    icmsOriginCost
                    icmsDestinationRate
                    icmsDestinationCost
                    fiscalCost
                    fiscalTotal
                    total
                    weight
                    product {
                      id
                      protheusId
                      stock
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          page, perPage, sortBy, sortDir, filterByClient: filters2?.clientId
        },
      }),
    ])
      .then(res => {
        if (isMountedRef.current) {
          setClients(res[0].data.clientsDropdown || []);
          setTotal(res[1].data.budgets.paging.total);
          setPage(res[1].data.budgets.paging.currentPage);
          setPerPage(res[1].data.budgets.paging.perPage);
          setData(res[1].data.budgets.list);
        }
      })
      .catch(err => error(err));
    dispatch(dataTableLoading(false));
  }, [apollo, error, page, perPage, sortBy, sortDir, dispatch, filters2]);

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: "pages.budgets.budgets",
      })}`;
    handleData();
    return () => { isMountedRef.current = false; }
  }, [intl, handleData]);

  const tableColumns: IDataTableColumn<Budget>[] = [
    {
      name: intl.formatMessage({ id: "inputs.code" }),
      selector: "code",
      sortable: true,
      center: false,
    },
    {
      name: "Pedido",
      selector: "protheusId",
      sortable: true,
      center: false,
      format: (row) => {
        return row.protheusId || "-";
      },
    },
    {
      name: intl.formatMessage({ id: "inputs.companyName" }),
      selector: "clientId",
      format: (row) => {
        return row?.client?.companyName;
      },
      sortable: false,
      center: false,
    },
    {
      name: intl.formatMessage({ id: "inputs.fantasyName" }),
      selector: "clientId",
      format: (row) => {
        return row?.client?.fantasyName;
      },
      sortable: false,
      center: false,
    },
    {
      name: intl.formatMessage({ id: "inputs.funnelStep" }),
      selector: "funnelId",
      format: (row) => {
        return row?.funnel?.name;
      },
      sortable: true,
      center: false,
    },
    {
      name: intl.formatMessage({ id: "inputs.contact" }),
      selector: "clientId",
      format: (row) => {
        return row?.client?.contact;
      },
      sortable: true,
      center: false,
    },
    {
      name: intl.formatMessage({ id: "inputs.total" }),
      selector: "id",
      format: (row) => `R$${intl.formatNumber(row.total, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`,
      sortable: true,
      center: false,
    },
    {
      name: intl.formatMessage({ id: "inputs.createdAt" }),
      selector: "createdAt",
      format: (row) => {
        return format_date(row.createdAt, LIST_DATE_TIME_FORMAT);
      },
      sortable: true,
      center: false,
    },
  ];

  const handleUpdateRecord = (row: Client) => {
    if (hasScope("Budgets:Budget"))
      history.push(`/budgets/preview/${row.id}`);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      SweetAlert.fire({
        title: intl.formatMessage({ id: "general.exclusion" }),
        text: intl.formatMessage({ id: "flash.confirm.delete" }),
        icon: "error",
        cancelButtonText: intl.formatMessage({ id: "general.no" }),
        confirmButtonText: intl.formatMessage({ id: "general.yes" }),
        reverseButtons: true,
        showCancelButton: true,
      })
        .then(async ({ isConfirmed }) => {
          if (isConfirmed) {
            for (let row of selectedRows) {
              await apollo.mutate({
                mutation: gql`
                  mutation deleteBudget($id: String!) {
                    deleteBudget(id: $id) {
                        id
                    }
                  }
                `,
                variables: {
                  id: row.id
                },
              })
                .then(res => {
                  toast.success(
                    intl.formatMessage(
                      { id: "flash.success.deletedParam" },
                      { description: row.id }
                    )
                  );
                })
                .catch(err => error(err));
            }
            setToggleCleared(!toggleCleared);
            handleData();
          }
        });
    };

    return (
      <button key="delete" className="btn btn-danger" onClick={handleDelete}>
        <FormattedMessage id="buttons.delete" />
      </button>
    );
  }, [selectedRows, apollo, error, toggleCleared, intl, handleData]);

  return (
    <Fragment>
      <Breadcrumb parent="general.home" title="pages.budgets.budgets" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>
                  <FormattedMessage id="pages.budgets.list" />
                </h5>
              </CardHeader>
              <CardBody>
                <Form onSubmit={async (e) => {
                  e.preventDefault();
                  setDisableSubmitSearch(true);
                  await handleSearch();
                  setDisableSubmitSearch(false);
                }}>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label">
                          <FormattedMessage id="inputs.client" />
                        </Label>
                        <Input
                          key="client"
                          className="form-control"
                          type="select"
                          value={filters?.clientId || ""}
                          disabled={disableSubmitSearch}
                          onChange={(e) => {
                            setFilters({ ...filters, clientId: e.target.value });
                          }}
                        >
                          <option value="">-</option>
                          {clients?.map((client: Client) => <option key={client.id} value={client.id}>{client.protheusId} - {client.companyName}</option>)}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col></Col>
                    <Col className="text-right">
                      <FormGroup>
                        <Label className="col-form-label" style={{ display: "block", width: "100%" }}>
                          &nbsp;
                        </Label>
                        <div>
                          <Button type="submit" disabled={disableSubmitSearch} color="secondary">
                            <i className="fa fa-search"></i> Filtrar
                          </Button>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                <Listing
                  data={data}
                  total={total}
                  selectable={canDelete}
                  rowsPerPage={perPage}
                  onChangePage={(page: number) => {
                    setPage(page);
                  }}
                  onChangeRowsPerPage={(rowsPerPage: number) => {
                    setPage(1);
                    setPerPage(rowsPerPage);
                  }}
                  onSort={(
                    column,
                    sortDir
                  ) => {
                    setSortBy(column.selector.toString());
                    setSortDir(sortDir);
                  }}
                  columns={tableColumns}
                  onRowClicked={handleUpdateRecord}
                  contextActions={contextActions}
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleCleared}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Budgets;
