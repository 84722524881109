import React from "react";
import copy from "copy-to-clipboard";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import { FormattedMessage, useIntl } from "react-intl";

interface Props {
  children?: React.ReactNode;
  text: string;
}

const CopyToClipboard: React.FC<Props> = ({ text, children }) => {
  const intl = useIntl();
  const handleCopyToClipboard = () => {
    copy(text);
    toast.success(intl.formatMessage({ id: "flash.success.copy" }), {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  return (
    <React.Fragment>
      <Button
        color="primary"
        className="notification"
        onClick={handleCopyToClipboard}
      >
        <FormattedMessage id="buttons.copy" />
      </Button>
      {children}
    </React.Fragment>
  );
};

export default CopyToClipboard;
