import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Row, Col, Form, FormGroup, Input, Label, Button } from "reactstrap";
import AppAuth from "../../components/app.auth";
import { SITE_TITLE } from "../../configs/constants";
import { useAuth } from "../../hooks/auth";
import queryString from "query-string";

const Resetpwd: React.FC = () => {
  const isMountedRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const { resetPassword } = useAuth();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const [submitDisable, setSubmitDisable] = useState<boolean>(false);
  const [togglePassword, setTogglePassword] = useState<boolean>(false);
  const [togglePasswordConfirm, setTogglePasswordConfirm] = useState<boolean>(false);
  const toggleShowPassword = (display: boolean) => setTogglePassword(!display);
  const toggleShowPasswordConfirm = (display: boolean) => setTogglePasswordConfirm(!display);

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: "pages.resetPassword.title",
      })}`;
    const query = queryString.parse(location.search);
    const email: string = query?.email?.toString() || "";
    let token: string = query?.code?.toString() || "";
    token = encodeURIComponent(token);
    setEmail(email || "");
    setToken(token || "");
    return () => { isMountedRef.current = false; }
  }, [intl, location]);


  return (
    <AppAuth>
      <Container fluid={true} className="p-0">
        <Row className="m-0">
          <Col xs="12" className="p-0">
            <div className="login-card">
              <div>
                <div>
                  <a className="logo" href="#javascript">
                    <img className="img-fluid for-light" src={require("../../assets/images/logo/logo_stark.png")} alt="" />
                    <img className="img-fluid for-dark" src={require("../../assets/images/logo/logo_dark_stark.png")} alt="" />
                  </a>
                </div>
                <div className="login-main">
                  <Form className="theme-form" onSubmit={async (e) => {
                    e.preventDefault();
                    setSubmitDisable(true);
                    await resetPassword(
                      email,
                      password,
                      passwordConfirm,
                      token
                    )
                      .then(res => {
                        if (res) {
                          toast.success(
                            intl.formatMessage({
                              id: "flash.success.resetPassword",
                            })
                          );
                          setTimeout(() => history.push("/login"), 1500);
                        }
                      });
                    setSubmitDisable(false);
                  }}>
                    <h4><FormattedMessage id="pages.resetPassword.title" /></h4>
                    <p><FormattedMessage id="pages.resetPassword.description" /></p>
                    <FormGroup>
                      <Label className="col-form-label"><FormattedMessage id="inputs.email" /></Label>
                      <Input className="form-control" type="email" value={email} onChange={(e) => setEmail(e.target.value?.trim()?.toLowerCase())} required />
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label"><FormattedMessage id="inputs.password" /></Label>
                      <Input className="form-control" type={togglePassword ? "text" : "password"} value={password} onChange={(e) => setPassword(e.target.value?.trim())} required />
                      <div className="show-hide" onClick={() => toggleShowPassword(togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label"><FormattedMessage id="inputs.passwordConfirm" /></Label>
                      <Input className="form-control" type={togglePasswordConfirm ? "text" : "password"} value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value?.trim())} required />
                      <div className="show-hide" onClick={() => toggleShowPasswordConfirm(togglePasswordConfirm)}><span className={togglePasswordConfirm ? "" : "show"}></span></div>
                    </FormGroup>

                    <div className="form-group mb-0">
                      <Button color="primary" type="submit" disabled={submitDisable} className="btn-block" ><FormattedMessage id="buttons.resetPassword" /></Button>
                    </div>
                    <p className="mt-4 mb-0">Já possui uma senha?<Link className="ml-2" to={`${process.env.PUBLIC_URL}/login`}><FormattedMessage id="buttons.enter" /></Link></p>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </AppAuth>
  );
}

export default Resetpwd;
