import React, { Fragment, useEffect, useRef, useState } from "react";
import Breadcrumb from "../layout/breadcrumb";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { ICombineReducers } from "../redux";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { useApollo } from "../services/apollo.service";
import pt_BR from "date-fns/locale/pt-BR";
import { SITE_TITLE } from "../configs/constants";
import Chart from 'react-apexcharts'
import { apexAreaChart, areaSpaline, apexBarChart, apexColumnChartsone, apex3DbubbleCharts, apexSteplineChart, apexLineWithAnnotationCharts, apexPieChart, apexDonutCharts, apexMixedCharts, apexCandleStickCharts, apexRadarPolygonfillCharts, apexRadialBarChart } from "../configs/apexData";
import { BasicAreaChart, AreaSpalineChart, BarChart, ColumnChart, PieChart, DonutChart, MixedChart, CandlestickChart, RadarChart, RadialBarChart, BubbleChart, SteplineChart } from "../constant";
import Loading from "../components/loading";

const Home: React.FC = () => {
  // eslint-disable-next-line
  const { apollo, error } = useApollo();
  const isMountedRef = useRef(null);
  const intl = useIntl();
  const locale = useSelector((state: ICombineReducers) => state.State.locale);
  // eslint-disable-next-line
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: "general.sales",
      })}`;
    if (locale === "pt") {
      registerLocale("pt-BR", pt_BR);
      setDefaultLocale("pt-BR");
    }

    return () => { isMountedRef.current = false; }
  }, [intl, locale]);

  return (
    <Fragment>
      <Breadcrumb parent="general.dashboards" title="general.sales" />
      <Container fluid={true}>
        {loading ? <Loading /> : (
          <React.Fragment>
            <Row>
              <Col sm="12" xl="6">
                <Card>
                  <CardHeader>
                    <h5>{BasicAreaChart} </h5>
                  </CardHeader>
                  <CardBody>
                    <div id="basic-apex">
                      <Chart options={apexAreaChart.options} series={apexAreaChart.series} type="area" height={350} />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="12" xl="6">
                <Card>
                  <CardHeader>
                    <h5>{AreaSpalineChart} </h5>
                  </CardHeader>
                  <CardBody>
                    <Chart options={areaSpaline.options} series={areaSpaline.series} height="350" type="area" />
                  </CardBody>
                </Card>
              </Col>
              <Col sm="12" xl="6">
                <Card>
                  <CardHeader>
                    <h5>{BarChart}</h5>
                  </CardHeader>
                  <CardBody>
                    <div id="basic-bar">
                      <Chart options={apexBarChart.options} series={apexBarChart.series} type="bar" height={350} />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="12" xl="6">
                <Card>
                  <CardHeader>
                    <h5>{ColumnChart} </h5>
                  </CardHeader>
                  <CardBody>
                    <div id="column-chart">
                      <Chart options={apexColumnChartsone.options} series={apexColumnChartsone.series} type="bar" height={350} />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="12" xl="6">
                <Card>
                  <CardHeader>
                    <h5>{BubbleChart} </h5>
                  </CardHeader>
                  <CardBody>
                    <div id="chart-bubble">
                      <Chart options={apex3DbubbleCharts.options} series={apex3DbubbleCharts.series} type="bubble" height={350} />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="12" xl="6">
                <Card>
                  <CardHeader>
                    <h5>{SteplineChart} </h5>
                  </CardHeader>
                  <CardBody>
                    <div id="stepline">
                      <Chart options={apexSteplineChart.options} series={apexSteplineChart.series} type="line" height={350} />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="12">
                <Card>
                  <CardHeader>
                    <h5>{ColumnChart}</h5>
                  </CardHeader>
                  <CardBody>
                    <div id="annotationchart">
                      <Chart options={apexLineWithAnnotationCharts.options} series={apexLineWithAnnotationCharts.series} type="line" height={350} />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="12" xl="6">
                <Card>
                  <CardHeader>
                    <h5>{PieChart} </h5>
                  </CardHeader>
                  <CardBody className="apex-chart">
                    <div id="piechart">
                      <Chart options={apexPieChart.options} series={apexPieChart.series} type="pie" width={380} />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="12" xl="6">
                <Card>
                  <CardHeader>
                    <h5>{DonutChart}</h5>
                  </CardHeader>
                  <CardBody className="apex-chart">
                    <div id="donutchart">
                      <Chart options={apexDonutCharts.options} series={apexDonutCharts.series} type="donut" />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="12">
                <Card>
                  <CardHeader>
                    <h5>{MixedChart}</h5>
                  </CardHeader>
                  <CardBody>
                    <div id="mixedchart">
                      <Chart options={apexMixedCharts.options} series={apexMixedCharts.series} type="line" height={350} />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="12">
                <Card>
                  <CardHeader>
                    <h5>{CandlestickChart} </h5>
                  </CardHeader>
                  <CardBody>
                    <div id="candlestick">
                      <Chart options={apexCandleStickCharts.options} series={apexCandleStickCharts.series} type="candlestick" height={350} />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="12" xl="6">
                <Card>
                  <CardHeader>
                    <h5>{RadarChart} </h5>
                  </CardHeader>
                  <CardBody>
                    <div id="radarchart">
                      <Chart options={apexRadarPolygonfillCharts.options} series={apexRadarPolygonfillCharts.series} type="radar" height={350} />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="12" xl="6">
                <Card>
                  <CardHeader>
                    <h5>{RadialBarChart}</h5>
                  </CardHeader>
                  <CardBody>
                    <div id="circlechart">
                      <Chart options={apexRadialBarChart.options} series={apexRadialBarChart.series} type="radialBar" height={320} />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </Container>
    </Fragment >
  );
};

export default Home;
