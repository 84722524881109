import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Home from "../views/home";
import Users from "../views/users";
import ManageUser from "../views/users/manage";
import ManageRole from "../views/roles/manage";
import Roles from "../views/roles";
import Clients from "../views/clients";
import ManageClient from "../views/clients/manage";
import Products from "../views/products";
import ManageProduct from "../views/products/manage";
import Carriers from "../views/carriers";
import ManageCarrier from "../views/carriers/manage";
import PaymentConditions from "../views/payment-conditions";
import ManagePaymentCondition from "../views/payment-conditions/manage";
import Funnel from "../views/funnels";
import ManageFunnel from "../views/funnels/manage";
import RequestLogs from "../views/request-logs";
import ManageRequestLog from "../views/request-logs/manage";
import Budgets from "../views/budgets";
import BudgetsKanban from "../views/budgets/kanban";
import ManageBudget from "../views/budgets/manage";
import SelectClientBudget from "../views/budgets/client";
import Profile from "../views/profile";
import Calendars from "../views/calendars";
import SaleTeams from "../views/sale-teams";
import ManageSaleTeams from "../views/sale-teams/manage";
import Notifications from "../views/notifications";
import ManageNotification from "../views/notifications/manage";
import Logs from "../views/logs";
import ManageLog from "../views/logs/manage";
import Orders from "../views/orders";
import ManageOrder from "../views/orders/manage";
import Sales from "../views/sales";
import Leads from "../views/leads";
import ManageLead from "../views/leads/manage";

export interface Route {
  path: string;
  scopes?: string[];
  Component:
  | React.ComponentType<RouteComponentProps<any>>
  | React.ComponentType<any>;
}
export interface CrudParam {
  id?: string;
}

export interface URLString {
  backTo?: string;
}

const routes: Route[] = [
  { path: `${process.env.PUBLIC_URL}/`, Component: Home },
  { path: `${process.env.PUBLIC_URL}/sales`, Component: Sales },

  { path: `${process.env.PUBLIC_URL}/calendar`, scopes: ["Calendars:Calendars"], Component: Calendars },

  { path: `${process.env.PUBLIC_URL}/clients`, scopes: ["Clients:Clients"], Component: Clients },
  { path: `${process.env.PUBLIC_URL}/clients/manage`, scopes: ["Clients:Create"], Component: ManageClient },
  { path: `${process.env.PUBLIC_URL}/clients/manage/:id`, scopes: ["Clients:Client", "Clients:Update"], Component: ManageClient },

  { path: `${process.env.PUBLIC_URL}/budgets`, scopes: ["Budgets:Budgets"], Component: Budgets },
  { path: `${process.env.PUBLIC_URL}/budgets/funnel`, scopes: ["Budgets:Budgets"], Component: BudgetsKanban },
  { path: `${process.env.PUBLIC_URL}/budgets/client`, scopes: ["Budgets:Create"], Component: SelectClientBudget },
  { path: `${process.env.PUBLIC_URL}/budgets/preview/:id`, scopes: ["Budgets:Budget"], Component: ManageBudget },
  { path: `${process.env.PUBLIC_URL}/budgets/create/:id`, scopes: ["Budgets:Create"], Component: ManageBudget },
  { path: `${process.env.PUBLIC_URL}/budgets/update/:id`, scopes: ["Budgets:Budget", "Budgets:Update"], Component: ManageBudget },

  { path: `${process.env.PUBLIC_URL}/products`, scopes: ["Products:Products"], Component: Products },
  { path: `${process.env.PUBLIC_URL}/products/manage/:id`, scopes: ["Products:Product"], Component: ManageProduct },

  { path: `${process.env.PUBLIC_URL}/orders`, scopes: ["Orders:Orders"], Component: Orders },
  { path: `${process.env.PUBLIC_URL}/orders/manage/:id`, scopes: ["Orders:Order"], Component: ManageOrder },

  { path: `${process.env.PUBLIC_URL}/leads`, scopes: ["Leads:Leads"], Component: Leads },
  { path: `${process.env.PUBLIC_URL}/leads/manage`, scopes: ["Leads:Create"], Component: ManageLead },
  { path: `${process.env.PUBLIC_URL}/leads/manage/:id`, scopes: ["Leads:Lead", "Leads:Update"], Component: ManageLead },

  { path: `${process.env.PUBLIC_URL}/carriers`, scopes: ["Carriers:Carriers"], Component: Carriers },
  { path: `${process.env.PUBLIC_URL}/carriers/manage`, scopes: ["Carriers:Create"], Component: ManageCarrier },
  { path: `${process.env.PUBLIC_URL}/carriers/manage/:id`, scopes: ["Carriers:Carrier", "Carriers:Update"], Component: ManageCarrier },

  { path: `${process.env.PUBLIC_URL}/sale-teams`, scopes: ["SaleTeams:SaleTeams"], Component: SaleTeams },
  { path: `${process.env.PUBLIC_URL}/sale-teams/manage`, scopes: ["SaleTeams:Create"], Component: ManageSaleTeams },
  { path: `${process.env.PUBLIC_URL}/sale-teams/manage/:id`, scopes: ["SaleTeams:SaleTeams", "SaleTeams:Update"], Component: ManageSaleTeams },

  { path: `${process.env.PUBLIC_URL}/notifications`, scopes: ["Notifications:Notifications"], Component: Notifications },
  { path: `${process.env.PUBLIC_URL}/notifications/manage`, scopes: ["Notifications:Create"], Component: ManageNotification },
  { path: `${process.env.PUBLIC_URL}/notifications/manage/:id`, scopes: ["Notifications:Notifications", "Notifications:Update"], Component: ManageNotification },

  { path: `${process.env.PUBLIC_URL}/funnels`, scopes: ["Funnel:Funnels"], Component: Funnel },
  { path: `${process.env.PUBLIC_URL}/funnels/manage`, scopes: ["Funnel:Create"], Component: ManageFunnel },
  { path: `${process.env.PUBLIC_URL}/funnels/manage/:id`, scopes: ["Funnel:Funnel", "Funnel:Update"], Component: ManageFunnel },

  { path: `${process.env.PUBLIC_URL}/payment-conditions`, scopes: ["PaymentConditions:PaymentConditions"], Component: PaymentConditions },
  { path: `${process.env.PUBLIC_URL}/payment-conditions/manage`, scopes: ["PaymentConditions:Create"], Component: ManagePaymentCondition },
  { path: `${process.env.PUBLIC_URL}/payment-conditions/manage/:id`, scopes: ["PaymentConditions:PaymentCondition", "PaymentConditions:Update"], Component: ManagePaymentCondition },

  { path: `${process.env.PUBLIC_URL}/request-logs`, scopes: ["RequestLogs:RequestLogs"], Component: RequestLogs },
  { path: `${process.env.PUBLIC_URL}/request-logs/manage/:id`, scopes: ["RequestLogs:RequestLog"], Component: ManageRequestLog },

  { path: `${process.env.PUBLIC_URL}/logs`, scopes: ["Logs:Logs"], Component: Logs },
  { path: `${process.env.PUBLIC_URL}/logs/manage/:id`, scopes: ["Logs:Log"], Component: ManageLog },

  { path: `${process.env.PUBLIC_URL}/profile`, scopes: ["Users:Profile"], Component: Profile },
  { path: `${process.env.PUBLIC_URL}/users`, scopes: ["Users:Users"], Component: Users },
  { path: `${process.env.PUBLIC_URL}/users/manage`, scopes: ["Users:Create"], Component: ManageUser },
  { path: `${process.env.PUBLIC_URL}/users/manage/:id`, scopes: ["Users:User", "Users:Update"], Component: ManageUser },

  { path: `${process.env.PUBLIC_URL}/roles`, scopes: ["Roles:Roles"], Component: Roles },
  { path: `${process.env.PUBLIC_URL}/roles/manage`, scopes: ["Roles:Create"], Component: ManageRole },
  { path: `${process.env.PUBLIC_URL}/roles/manage/:id`, scopes: ["Roles:Role", "Roles:Update"], Component: ManageRole },
];

export default routes;
