import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Breadcrumb from "../../layout/breadcrumb";
import { CrudParam } from "../../configs/route";
import { useApollo } from "../../services/apollo.service";
import { SITE_TITLE } from "../../configs/constants";
import { toast } from "react-toastify";
import FunnelAction from "../../models/funnelAction";
import SweetAlert from "sweetalert2";
import { useAuth } from "../../hooks/auth";
import { gql } from "@apollo/client";
import Loading from "../../components/loading";

const Manage: React.FC = () => {
  const isMountedRef = useRef(null);
  const { apollo, error } = useApollo();
  const { id } = useParams<CrudParam>();
  const [activeTab, setActiveTab] = useState<number>(1);
  const [name, setName] = useState<string>("");
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const [actions, setActions] = useState<FunnelAction[]>([]);
  const [sortOrder, setSortOrder] = useState<number>(0);
  const [preview, setPreview] = useState<boolean>(() => { if (id) return true; else return false; });
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const intl = useIntl();

  const { hasScope } = useAuth();
  const [canView] = useState<boolean>(() => {
    return hasScope("Funnel:Funnel");
  });
  const [canCreate] = useState<boolean>(() => {
    return hasScope("Funnel:Create");
  });
  const [canUpdate] = useState<boolean>(() => {
    return hasScope("Funnel:Update");
  });

  const handleData = useCallback(async () => {
    if (id) {
      if (preview && !canView) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/funnels");
        return;
      } else if (!preview && !canUpdate) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/funnels");
        return;
      }
      setLoading(true);
      await apollo.query({
        query: gql`
          query funnel($id: String!) {
            funnel(id: $id) {
              id
              name
              sortOrder
              isHidden
              actions {
                id
                name
                type
                sortOrder
                isActivated
              }
            }
          }
        `,
        variables: {
          id
        },
      })
        .then(res => {
          if (isMountedRef.current) {
            setName(res.data.funnel.name || "");
            setIsHidden(res.data.funnel.isHidden || false);
            setActions(res.data.funnel.actions || []);
            setSortOrder(res.data.funnel.sortOrder || 0);
          }
        })
        .catch(err => error(err));
      setLoading(false);
    } else {
      if (!preview && !canCreate) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/funnels");
        return;
      }
    }
  }, [intl, id, preview, apollo, error, history, canView, canCreate, canUpdate]);

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: `pages.funnels.${preview ? "preview" : id ? "update" : "create"}`,
      })}`;
    handleData();
    return () => { isMountedRef.current = false; }
  }, [intl, id, preview, handleData]);

  const addActionLine = () => {
    let action: FunnelAction = {
      name: "",
      type: "",
      isActivated: false,
    } as FunnelAction;
    setActions([
      ...actions,
      action
    ]);
  };

  const handleSubmitForm = async () => {
    setDisableSubmit(true);
    const data = {
      name,
      sortOrder,
      isHidden,
      actions,
    };

    if (!id) {
      await apollo.mutate({
        mutation: gql`
          mutation createFunnel($data: FunnelInput!) {
            createFunnel(data: $data) {
                id
            }
          }
        `,
        variables: {
          data
        },
      })
        .then(res => {
          toast.success(intl.formatMessage({ id: "flash.success.created" }));
          history.push(`/funnels/manage/${res.data.createFunnel.id}`);
        })
        .catch(err => error(err));
    } else {
      await apollo.mutate({
        mutation: gql`
          mutation updateFunnel($id: String!, $data: FunnelInput!) {
            updateFunnel(id: $id, data: $data) {
                id
            }
          }
        `,
        variables: {
          id,
          data
        },
      })
        .then(res => {
          toast.success(intl.formatMessage({ id: "flash.success.updated" }));
          history.push("/funnels");
        })
        .catch(err => error(err));
    }
    setDisableSubmit(false);
  };

  const handleDeleteAction = (action: FunnelAction, index: number) => {
    if (action.id) {
      SweetAlert.fire({
        title: intl.formatMessage({ id: "general.exclusion" }),
        text: intl.formatMessage({ id: "flash.confirm.deleteOne" }),
        icon: "error",
        cancelButtonText: intl.formatMessage({ id: "general.no" }),
        confirmButtonText: intl.formatMessage({ id: "general.yes" }),
        reverseButtons: true,
        showCancelButton: true,
      })
        .then(async ({ isConfirmed }) => {
          if (isConfirmed) {
            await apollo.mutate({
              mutation: gql`
                mutation deleteFunnelAction($id: String!) {
                  deleteFunnelAction(id: $id) {
                      id
                  }
                }
              `,
              variables: {
                id: action.id
              },
            })
              .then(res => {
                toast.success("Ação removida com sucesso.");
                actions.splice(index, 1);
                setActions([...actions]);
              })
              .catch(err => error(err));
          }
        });
    } else {
      actions.splice(index, 1);
      setActions([...actions]);
    }
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="pages.funnels.funnels"
        title={`pages.funnels.${preview ? "preview" : id ? "update" : "create"}`}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>
                  <FormattedMessage
                    id={`pages.funnels.${preview ? "preview" : id ? "update" : "create"
                      }`}
                  />
                </h5>
              </CardHeader>
              <CardBody className="tabbed-card">
                <div className="form-content">
                  <Form onSubmit={(e) => e.preventDefault()}>
                    <Nav className="nav-pills nav-primary">
                      <NavItem>
                        <NavLink
                          className={activeTab === 1 ? "active" : ""}
                          onClick={() => setActiveTab(1)}
                        >
                          <i className="fa fa-file-text-o"></i>
                          <FormattedMessage id="tabs.data" />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={activeTab === 2 ? "active" : ""}
                          onClick={() => setActiveTab(2)}
                        >
                          <i className="fa fa-flash"></i>
                          <FormattedMessage id="tabs.actions" />
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <Row className="m-b-30">
                      <Col>
                        <TabContent activeTab={activeTab.toString()}>
                          <TabPane tabId="1">
                            {loading ? <Loading /> : (
                              <React.Fragment>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.name" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={name}
                                        disabled={preview}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.sortOrder" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={sortOrder}
                                        disabled={preview}
                                        onChange={(e) => setSortOrder(Number(e.target.value))}
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.isHidden" />
                                      </Label>
                                      <Col className="checkbox">
                                        <Input
                                          key="isHidden-input"
                                          id="isHidden"
                                          type="checkbox"
                                          value={isHidden ? 1 : 0}
                                          checked={isHidden}
                                          disabled={preview}
                                          onChange={(e) => setIsHidden(!isHidden)}
                                        />
                                        <Label
                                          key="isHidden-label"
                                          for="isHidden"
                                          className="d-block"
                                        >Sim</Label>
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </TabPane>
                          <TabPane tabId="2">
                            {loading ? <Loading /> : (
                              <React.Fragment>
                                <Row>
                                  <Col>
                                    {actions?.map((acao, index) => {
                                      return (
                                        <Card key={index}>
                                          <CardBody>
                                            <Row>
                                              <Col>
                                                <FormGroup>
                                                  <Label className="col-form-label">
                                                    <FormattedMessage id="inputs.name" />
                                                  </Label>
                                                  <Input
                                                    className="form-control"
                                                    type="text"
                                                    value={acao.name}
                                                    disabled={preview}
                                                    onChange={(e) => {
                                                      actions[index].name = e.target.value;
                                                      setActions([
                                                        ...actions
                                                      ])
                                                    }}
                                                    required
                                                  />
                                                </FormGroup>
                                              </Col>
                                              <Col>
                                                <FormGroup>
                                                  <Label className="col-form-label">
                                                    <FormattedMessage id="inputs.type" />
                                                  </Label>
                                                  <Input
                                                    className="form-control digits"
                                                    type="select"
                                                    value={acao.type}
                                                    disabled={preview}
                                                    onChange={(e) => {
                                                      actions[index].type = e.target.value;
                                                      setActions([
                                                        ...actions
                                                      ])
                                                    }}
                                                    required
                                                  >
                                                    <option value="">
                                                      {intl.formatMessage({
                                                        id: "inputs.selectAny",
                                                      })}
                                                    </option>
                                                    <option value="enviar-email-orcamento">
                                                      {intl.formatMessage({
                                                        id: "inputs.actionsTypeOptions.enviar_email_orcamento",
                                                      })}
                                                    </option>
                                                  </Input>
                                                </FormGroup>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col>
                                                <FormGroup>
                                                  <Label className="col-form-label">
                                                    <FormattedMessage id="inputs.isActivated" />
                                                  </Label>
                                                  <Col className="checkbox">
                                                    <Input
                                                      key="isActivated-input"
                                                      id="isActivated"
                                                      type="checkbox"
                                                      value={acao.isActivated ? 1 : 0}
                                                      disabled={preview}
                                                      onChange={(e) => {
                                                        actions[index].isActivated = Number(e.target.value) === 1 ? true : false;
                                                        setActions([
                                                          ...actions
                                                        ])
                                                      }}
                                                    />
                                                    <Label
                                                      key="isActivated-label"
                                                      for="isActivated"
                                                      className="d-block"
                                                    ></Label>
                                                  </Col>
                                                </FormGroup>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col className="text-right">
                                                <span className="task-action-btn">
                                                  <Button type="button" disabled={preview} className="danger action-box large delete-btn" title="Remover ação" onClick={() => handleDeleteAction(acao, index)} >
                                                    <i className="icon"><i className="icon-trash"></i></i>
                                                  </Button>
                                                </span>
                                              </Col>
                                            </Row>
                                          </CardBody>
                                        </Card>
                                      );
                                    })}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <Button type="button" disabled={preview} onClick={addActionLine} color="primary">
                                      <i className="icon-plus"></i>&nbsp;&nbsp;
                                      Adicionar ação
                                    </Button>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </TabPane>
                        </TabContent>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          type="button"
                          color="danger"
                          onClick={() => history.push("/funnels")}
                        >
                          <i className="fa fa-arrow-left"></i>
                          <FormattedMessage id="buttons.cancel" />
                        </Button>
                      </Col>
                      {canUpdate ? (
                        <Col className="text-right">
                          {preview ? (
                            <Button
                              type="button"
                              color="primary"
                              disabled={loading}
                              onClick={() => setPreview(!preview)}
                            >
                              <i className="fa fa-edit"></i>
                              <FormattedMessage id="buttons.edit" />
                            </Button>
                          ) : (
                            <Button type="button" disabled={disableSubmit || loading} onClick={handleSubmitForm} color="primary">
                              <i className="fa fa-save"></i>
                              <FormattedMessage id="buttons.save" />
                            </Button>
                          )}
                        </Col>) : ""}
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Manage;
