import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Breadcrumb from "../../layout/breadcrumb";
import { CrudParam } from "../../configs/route";
import { useApollo } from "../../services/apollo.service";
import { convert_to_date, SITE_TITLE } from "../../configs/constants";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/auth";
import { gql } from "@apollo/client";
import User from "../../models/user";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import pt_BR from "date-fns/locale/pt-BR";
import { useSelector } from "react-redux";
import { ICombineReducers } from "../../redux";
import Meta from "../../models/meta";
import SweetAlert from "sweetalert2";
import NumberFormat from "react-number-format";
import Loading from "../../components/loading";

const Manage: React.FC = () => {
  const isMountedRef = useRef(null);
  const locale = useSelector((state: ICombineReducers) => state.State.locale);
  const credentials = useSelector((state: ICombineReducers) => state.DataSet.credentials);
  const { apollo, error } = useApollo();
  const { id } = useParams<CrudParam>();
  const [activeTab, setActiveTab] = useState<number>(1);
  const [name, setName] = useState<string>("");
  const [userId, setUserId] = useState<string>(() => {
    if (credentials?.user?.id?.trim())
      return credentials.user.id;
    return "";
  });
  const [isActivated, setIsActivated] = useState<boolean>(false);
  const [usersIds, setUsersIds] = useState<string[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<any>({});
  const [users, setUsers] = useState<User[]>([]);
  const [metas, setMetas] = useState<Meta[]>([]);
  const [preview, setPreview] = useState<boolean>(() => { if (id) return true; else return false; });
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const intl = useIntl();

  const { hasScope } = useAuth();
  const [canView] = useState<boolean>(() => {
    return hasScope("SaleTeams:SaleTeam");
  });
  const [canCreate] = useState<boolean>(() => {
    return hasScope("SaleTeams:Create");
  });
  const [canUpdate] = useState<boolean>(() => {
    return hasScope("SaleTeams:Update");
  });

  const handleData = useCallback(async () => {
    if (id) {
      if (preview && !canView) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/sale-teams");
        return;
      } else if (!preview && !canUpdate) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/sale-teams");
        return;
      }
      setLoading(true);
      await Promise.all([
        apollo.query({
          query: gql`
            query saleTeam($id: String!) {
              saleTeam(id: $id) {
                id
                createdAt
                updatedAt
                name
                isActivated
                users {
                  id
                  name
                  surname
                }
                user {
                  id
                  name
                  surname
                }
                metas {
                  total
                  divider
                  name
                  startAt
                  endAt
                  isActivated
                }
              }
            }
          `,
          variables: {
            id
          },
        }),
        apollo.query({
          query: gql`
            query usersDropdown {
              usersDropdown {
                id
                name
                surname
              }
            }
          `,
        }),
      ])
        .then(res => {
          if (isMountedRef.current) {
            setName(res[0].data.saleTeam.name || "");
            setIsActivated(res[0].data.saleTeam.isActivated || false);
            setUserId(res[0].data.saleTeam?.user?.id || "");
            setMetas(res[0].data.saleTeam.metas || []);
            if (res[0].data.saleTeam.users.length) {
              setUsersIds(res[0].data.saleTeam.users?.map((entity: User) => entity.id));
              let selectedAreaCodesObj = {};
              res[0].data.saleTeam.users?.map((entity: User) => {
                selectedAreaCodesObj = { ...selectedAreaCodesObj, [entity.id]: true };
                return entity;
              });
              setSelectedUsers(selectedAreaCodesObj);
            }
            setUsers(res[1].data.usersDropdown);
          }
        })
        .catch(err => error(err));
      setLoading(false);
    } else {
      if (!preview && !canCreate) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/sale-teams");
        return;
      }
      setLoading(true);
      await apollo.query({
        query: gql`
          query usersDropdown {
            usersDropdown {
              id
              name
              surname
            }
          }
        `,
      })
        .then(res => {
          if (isMountedRef.current) {
            setUsers(res.data.usersDropdown);
          }
        })
        .catch(err => error(err));
      setLoading(false);
    }
  }, [intl, id, preview, apollo, error, history, canView, canCreate, canUpdate]);

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: `pages.saleTeams.${preview ? "preview" : id ? "update" : "create"}`,
      })}`;
    if (locale === "pt") {
      registerLocale("pt-BR", pt_BR);
      setDefaultLocale("pt-BR");
    }
    handleData();
    return () => { isMountedRef.current = false; }
  }, [intl, id, preview, handleData, locale]);

  const handleSubmitForm = async () => {
    setDisableSubmit(true);

    let metasArray: any[] = [];
    if (metas.length) {
      for (const meta of metas) {
        metasArray.push({
          name: meta.name,
          total: meta.total,
          divider: meta.divider,
          startAt: meta.startAt,
          endAt: meta.endAt,
          isActivated: meta.isActivated,
        })
      }
    }

    const data = {
      name,
      isActivated,
      userId,
      users: usersIds,
      metas: metasArray,
    };

    if (!id) {
      await apollo.mutate({
        mutation: gql`
          mutation createSaleTeam($data: SaleTeamInput!) {
            createSaleTeam(data: $data) {
                id
            }
          }
        `,
        variables: {
          data
        },
      })
        .then(res => {
          toast.success(intl.formatMessage({ id: "flash.success.created" }));
          history.push(`/sale-teams/manage/${res.data.createSaleTeam.id}`);
        })
        .catch(err => error(err));
    } else {
      await apollo.mutate({
        mutation: gql`
          mutation updateSaleTeam($id: String!, $data: SaleTeamInput!) {
            updateSaleTeam(id: $id, data: $data) {
                id
            }
          }
        `,
        variables: {
          id,
          data
        },
      })
        .then(res => {
          toast.success(intl.formatMessage({ id: "flash.success.updated" }));
          history.push(`/sale-teams`);
        })
        .catch(err => error(err));
    }
    setDisableSubmit(false);
  };

  const handleSelectedUsers = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const index = usersIds.indexOf(value);
    if (index >= 0) {
      usersIds.splice(index, 1);
      setUsersIds(usersIds);
    } else {
      usersIds.push(value);
      setUsersIds(usersIds);
    }
    setSelectedUsers({ ...selectedUsers, [value]: e.target.checked });
  };

  const addMetaLine = () => {
    let meta: Meta = {
      total: 0,
      divider: 1,
      name: "",
      startAt: null,
      endAt: null,
      isActivated: false,
    } as Meta;
    setMetas([
      ...metas,
      meta
    ]);
  };

  const handleDeleteMeta = (action: Meta, index: number) => {
    SweetAlert.fire({
      title: intl.formatMessage({ id: "general.exclusion" }),
      text: intl.formatMessage({ id: "flash.confirm.deleteOne" }),
      icon: "error",
      cancelButtonText: intl.formatMessage({ id: "general.no" }),
      confirmButtonText: intl.formatMessage({ id: "general.yes" }),
      reverseButtons: true,
      showCancelButton: true,
    })
      .then(({ isConfirmed }) => {
        if (isConfirmed) {
          toast.success("Meta removida com sucesso.");
          metas.splice(index, 1);
          setMetas([...metas]);
        }
      });
  };
  return (
    <Fragment>
      <Breadcrumb
        parent="pages.saleTeams.saleTeams"
        title={`pages.saleTeams.${preview ? "preview" : id ? "update" : "create"}`}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>
                  <FormattedMessage
                    id={`pages.saleTeams.${preview ? "preview" : id ? "update" : "create"
                      }`}
                  />
                </h5>
              </CardHeader>
              <CardBody className="tabbed-card">
                <div className="form-content">
                  <Form onSubmit={(e) => e.preventDefault()}>
                    <Nav className="nav-pills nav-primary">
                      <NavItem>
                        <NavLink
                          className={activeTab === 1 ? "active" : ""}
                          onClick={() => setActiveTab(1)}
                        >
                          <i className="fa fa-file-text-o"></i>
                          <FormattedMessage id="tabs.data" />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={activeTab === 2 ? "active" : ""}
                          onClick={() => setActiveTab(2)}
                        >
                          <i className="fa fa-group"></i>
                          <FormattedMessage id="tabs.users" />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={activeTab === 3 ? "active" : ""}
                          onClick={() => setActiveTab(3)}
                        >
                          <i className="fa fa-bar-chart"></i>
                          Metas
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <Row className="m-b-30">
                      <Col>
                        <TabContent activeTab={activeTab.toString()}>
                          <TabPane tabId="1">
                            {loading ? <Loading /> : (
                              <React.Fragment>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.name" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={name}
                                        disabled={preview}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                  {!id ? (
                                    <Col>
                                      <FormGroup>
                                        <Label className="col-form-label">
                                          <FormattedMessage id="inputs.isActivated" />
                                        </Label>
                                        <Col className="checkbox">
                                          <Input
                                            key="isActivated-input"
                                            id="isActivated"
                                            type="checkbox"
                                            value={isActivated ? 1 : 0}
                                            checked={isActivated}
                                            disabled={preview}
                                            onChange={(e) => setIsActivated(!isActivated)}
                                          />
                                          <Label
                                            key="isActivated-label"
                                            for="isActivated"
                                            className="d-block"
                                          >Sim</Label>
                                        </Col>
                                      </FormGroup>
                                    </Col>
                                  ) : (
                                    <Col>
                                      <FormGroup>
                                        <Label className="col-form-label">
                                          <FormattedMessage id="inputs.owner" />
                                        </Label>
                                        <Input
                                          className="form-control"
                                          type="select"
                                          value={userId}
                                          disabled={preview}
                                          onChange={(e) => setUserId(e.target.value)}
                                        >
                                          <option value="">-</option>
                                          {users?.map((user) => <option key={`owner-${user.id}`} value={user.id}>{user.name} {user.surname}</option>)}
                                        </Input>
                                      </FormGroup>
                                    </Col>
                                  )}
                                </Row>
                                {id ? (
                                  <Row>
                                    <Col>
                                      <FormGroup>
                                        <Label className="col-form-label">
                                          <FormattedMessage id="inputs.isActivated" />
                                        </Label>
                                        <Col className="checkbox">
                                          <Input
                                            key="isActivated-input"
                                            id="isActivated"
                                            type="checkbox"
                                            value={isActivated ? 1 : 0}
                                            checked={isActivated}
                                            disabled={preview}
                                            onChange={(e) => setIsActivated(!isActivated)}
                                          />
                                          <Label
                                            key="isActivated-label"
                                            for="isActivated"
                                            className="d-block"
                                          >Sim</Label>
                                        </Col>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                ) : ""}
                              </React.Fragment>
                            )}
                          </TabPane>
                          <TabPane tabId="2">
                            {loading ? <Loading /> : (
                              <Row>
                                {users?.map((user) => {
                                  return (
                                    <Col
                                      md="3"
                                      key={`col-role-${user.id}`}
                                      className="checkbox m-b-5"
                                    >
                                      <Input
                                        key={`input-user-${user.id}`}
                                        id={`user-${user.id}`}
                                        type="checkbox"
                                        value={user.id}
                                        checked={
                                          selectedUsers.hasOwnProperty(
                                            user.id
                                          )
                                            ? selectedUsers[user.id]
                                            : false
                                        }
                                        disabled={preview}
                                        onChange={handleSelectedUsers}
                                      />
                                      <Label
                                        key={`label-user-${user.id}`}
                                        className="d-block"
                                        for={`user-${user.id}`}
                                      >
                                        {user.name} {user.surname}
                                      </Label>
                                    </Col>
                                  );
                                })}
                              </Row>
                            )}
                          </TabPane>
                          <TabPane tabId="3">
                            {loading ? <Loading /> : (
                              <React.Fragment>
                                <Row>
                                  <Col>
                                    {metas?.map((meta, index) => {
                                      return (
                                        <Card key={index}>
                                          <CardBody>
                                            <Row>
                                              <Col>
                                                <FormGroup>
                                                  <Label className="col-form-label">
                                                    <FormattedMessage id="inputs.name" />
                                                  </Label>
                                                  <Input
                                                    className="form-control"
                                                    type="text"
                                                    value={meta.name}
                                                    disabled={preview}
                                                    onChange={(e) => {
                                                      metas[index].name = e.target.value;
                                                      setMetas([
                                                        ...metas
                                                      ])
                                                    }}
                                                    required
                                                  />
                                                </FormGroup>
                                              </Col>
                                              <Col>
                                                <FormGroup>
                                                  <Label className="col-form-label">
                                                    <FormattedMessage id="inputs.total" />
                                                  </Label>
                                                  <NumberFormat
                                                    className="form-control"
                                                    value={meta.total}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    allowNegative={false}
                                                    thousandsGroupStyle="thousand"
                                                    disabled={preview}
                                                    onValueChange={async ({ floatValue }) => {
                                                      metas[index].total = floatValue;
                                                      setMetas([
                                                        ...metas
                                                      ])
                                                    }}
                                                    required
                                                  />
                                                </FormGroup>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col>
                                                <FormGroup>
                                                  <Label className="col-form-label">
                                                    <FormattedMessage id="inputs.startAt" />
                                                  </Label>
                                                  <DatePicker
                                                    className="form-control"
                                                    selected={meta.startAt ? convert_to_date(meta.startAt) : null}
                                                    onChange={(date: Date) => {
                                                      metas[index].startAt = date;
                                                      setMetas([
                                                        ...metas
                                                      ])
                                                    }}
                                                    dateFormat="dd/MM/yyyy"
                                                    disabled={preview}
                                                  />
                                                </FormGroup>
                                              </Col>
                                              <Col>
                                                <FormGroup>
                                                  <Label className="col-form-label">
                                                    <FormattedMessage id="inputs.endAt" />
                                                  </Label>
                                                  <DatePicker
                                                    className="form-control"
                                                    selected={meta.endAt ? convert_to_date(meta.endAt) : null}
                                                    onChange={(date: Date) => {
                                                      metas[index].endAt = date;
                                                      setMetas([
                                                        ...metas
                                                      ])
                                                    }}
                                                    dateFormat="dd/MM/yyyy"
                                                    disabled={preview}
                                                  />
                                                </FormGroup>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col>
                                                <FormGroup>
                                                  <Label className="col-form-label">
                                                    <FormattedMessage id="inputs.divider" />
                                                  </Label>
                                                  <Input
                                                    className="form-control"
                                                    type="number"
                                                    value={meta.divider}
                                                    disabled={preview}
                                                    onChange={(e) => {
                                                      let value = Number(e.target.value);
                                                      if (isNaN(value))
                                                        value = 0;
                                                      metas[index].divider = value;
                                                      setMetas([
                                                        ...metas
                                                      ])
                                                    }}
                                                    required
                                                  />
                                                </FormGroup>
                                              </Col>
                                              <Col>
                                                <FormGroup>
                                                  <Label className="col-form-label">
                                                    <FormattedMessage id="inputs.isActivated" />
                                                  </Label>
                                                  <Col className="checkbox">
                                                    <Input
                                                      key={`isAcivated-input-${index}`}
                                                      id={`isActivated-${index}`}
                                                      type="checkbox"
                                                      value={1}
                                                      checked={metas[index].isActivated}
                                                      disabled={preview}
                                                      onChange={(e) => {
                                                        metas[index].isActivated = e.target.checked;
                                                        setMetas([
                                                          ...metas
                                                        ])
                                                      }}
                                                    />
                                                    <Label
                                                      key={`isActivated-label-${index}`}
                                                      for={`isActivated-${index}`}
                                                      className="d-block"
                                                    ></Label>
                                                  </Col>
                                                </FormGroup>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col className="text-right">
                                                <span className="task-action-btn">
                                                  <Button type="button" disabled={preview} className="danger action-box large delete-btn" title="Remover meta" onClick={() => handleDeleteMeta(metas[index], index)} >
                                                    <i className="icon"><i className="icon-trash"></i></i>
                                                  </Button>
                                                </span>
                                              </Col>
                                            </Row>
                                          </CardBody>
                                        </Card>
                                      );
                                    })}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <Button type="button" disabled={preview} onClick={addMetaLine} color="primary">
                                      <i className="icon-plus"></i>&nbsp;&nbsp;
                                      Adicionar meta
                                    </Button>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </TabPane>
                        </TabContent>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          type="button"
                          color="danger"
                          onClick={() => history.push("/sale-teams")}
                        >
                          <i className="fa fa-arrow-left"></i>
                          <FormattedMessage id="buttons.cancel" />
                        </Button>
                      </Col>
                      {canUpdate ? (
                        <Col className="text-right">
                          {preview ? (
                            <Button
                              type="button"
                              color="primary"
                              disabled={loading}
                              onClick={() => setPreview(!preview)}
                            >
                              <i className="fa fa-edit"></i>
                              <FormattedMessage id="buttons.edit" />
                            </Button>
                          ) : (
                            <Button type="button" disabled={disableSubmit || loading} onClick={handleSubmitForm} color="primary">
                              <i className="fa fa-save"></i>
                              <FormattedMessage id="buttons.save" />
                            </Button>
                          )}
                        </Col>) : ""}
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Manage;
