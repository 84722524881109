import React, {
  Fragment,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Form, FormGroup, Input, Label } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { IDataTableColumn } from "react-data-table-component";
import SweetAlert from "sweetalert2";
import { useApollo } from "../../services/apollo.service";
import { useHistory } from "react-router-dom";
import Notification from "../../models/notification";
import Listing from "../../components/listing";
import { format_date, LIST_DATE_TIME_FORMAT, RECORDS_PER_PAGE, SITE_TITLE } from "../../configs/constants";
import { useAuth } from "../../hooks/auth";
import { gql } from "@apollo/client";
import { useDispatch } from "react-redux";
import { dataTableLoading } from "../../redux/actions";

interface NotificationFilter {
  subject?: string;
  description?: string;
}

const Notifications: React.FC = () => {
  const isMountedRef = useRef(null);
  const dispatch = useDispatch();
  const { apollo, error } = useApollo();
  const intl = useIntl();
  const history = useHistory();
  const [selectedRows, setSelectedRows] = useState<Notification[]>([]);
  const [toggleCleared, setToggleCleared] = useState<boolean>(false);
  const [data, setData] = useState<Notification[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(RECORDS_PER_PAGE);
  const [sortBy, setSortBy] = useState<string>("notificatedAt");
  const [sortDir, setSortDir] = useState<string>("DESC");

  const [filters, setFilters] = useState<NotificationFilter>({});
  const [filters2, setFilters2] = useState<NotificationFilter>({});
  const [disableSubmitSearch, setDisableSubmitSearch] = useState<boolean>(false);

  const { hasScope } = useAuth();
  const [canDelete] = useState<boolean>(() => {
    return hasScope("Notifications:Delete");
  });

  const handleSearch = () => {
    setPage(1);
    setFilters2(filters);
  };

  const handleData = useCallback(async () => {
    dispatch(dataTableLoading(true));
    await apollo.query({
      query: gql`
        query notifications($sortDir: String, $sortBy: String, $perPage: Int, $page: Int, $filterBySubject: String, $filterByDescription: String) {
          notifications(sortDir: $sortDir, sortBy: $sortBy, perPage: $perPage, page: $page, filterBySubject: $filterBySubject, filterByDescription: $filterByDescription) {
            paging {
              total
              pages
              perPage
              currentPage
            }
            list {
              id
              createdAt
              updatedAt
              subject
              isFeatured
              notificatedAt
            }
          }
        }
      `,
      variables: {
        page, perPage, sortBy, sortDir,
        filterBySubject: filters2?.subject,
        filterByDescription: filters2?.description,
      }
    })
      .then(res => {
        if (isMountedRef.current) {
          setTotal(res.data.notifications.paging.total);
          setPage(res.data.notifications.paging.currentPage);
          setPerPage(res.data.notifications.paging.perPage);
          setData(res.data.notifications.list);
        }
      })
      .catch(err => error(err));
    dispatch(dataTableLoading(false));
  }, [apollo, error, page, perPage, sortBy, sortDir, dispatch, filters2]);

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: "pages.notifications.notifications",
      })}`;
    handleData();
  }, [intl, handleData]);

  const tableColumns: IDataTableColumn<Notification>[] = [
    {
      name: intl.formatMessage({ id: "inputs.subject" }),
      selector: "subject",
      sortable: true,
      center: false,
    },
    {
      name: intl.formatMessage({ id: "inputs.isFeatured" }),
      selector: "isFeatured",
      format: (row) => {
        return row.isFeatured
          ? intl.formatMessage({ id: "general.yes" })
          : intl.formatMessage({ id: "general.no" });
      },
      sortable: true,
      center: false,
    },
    {
      name: intl.formatMessage({ id: "inputs.notificatedAt" }),
      selector: "notificatedAt",
      format: (row) => {
        return format_date(row.notificatedAt, LIST_DATE_TIME_FORMAT);
      },
      sortable: true,
      center: false,
    },
  ];

  const handleUpdateRecord = (row: Notification) => {
    if (hasScope("Notifications:Notification"))
      history.push(`/notifications/manage/${row.id}`);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      SweetAlert.fire({
        title: intl.formatMessage({ id: "general.exclusion" }),
        text: intl.formatMessage({ id: "flash.confirm.delete" }),
        icon: "error",
        cancelButtonText: intl.formatMessage({ id: "general.no" }),
        confirmButtonText: intl.formatMessage({ id: "general.yes" }),
        reverseButtons: true,
        showCancelButton: true,
      })
        .then(async ({ isConfirmed }) => {
          if (isConfirmed) {
            for (let row of selectedRows) {
              await apollo.mutate({
                mutation: gql`
                  mutation deleteNotification($id: String!) {
                    deleteNotification(id: $id) {
                        id
                        subject
                    }
                  }
                `,
                variables: {
                  id: row.id
                },
              })
                .then(res => {
                  toast.success(
                    intl.formatMessage(
                      { id: "flash.success.deletedParam" },
                      { description: row.subject }
                    )
                  );
                })
                .catch(err => error(err));
            }
            setToggleCleared(!toggleCleared);
            handleData();
          }
        });
    };

    return (
      <button key="delete" className="btn btn-danger" onClick={handleDelete}>
        <FormattedMessage id="buttons.delete" />
      </button>
    );
  }, [selectedRows, apollo, error, toggleCleared, intl, handleData]);

  return (
    <Fragment>
      <Breadcrumb parent="general.home" title="pages.notifications.notifications" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>
                  <FormattedMessage id="pages.notifications.list" />
                </h5>
              </CardHeader>
              <CardBody>
                <Form onSubmit={async (e) => {
                  e.preventDefault();
                  setDisableSubmitSearch(true);
                  await handleSearch();
                  setDisableSubmitSearch(false);
                }}>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label">
                          <FormattedMessage id="inputs.subject" />
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          value={filters?.subject || ""}
                          disabled={disableSubmitSearch}
                          onChange={(e) => {
                            setPage(1);
                            setFilters({ ...filters, subject: e.target.value });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label">
                          <FormattedMessage id="inputs.description" />
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          value={filters?.description || ""}
                          disabled={disableSubmitSearch}
                          onChange={(e) => {
                            setPage(1);
                            setFilters({ ...filters, description: e.target.value });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="text-right">
                      <FormGroup>
                        <Label className="col-form-label" style={{ display: "block", width: "100%" }}>
                          &nbsp;
                        </Label>
                        <div>
                          <Button type="submit" disabled={disableSubmitSearch} color="secondary">
                            <i className="fa fa-search"></i> Filtrar
                          </Button>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                <Listing
                  data={data}
                  total={total}
                  selectable={canDelete}
                  rowsPerPage={perPage}
                  onChangePage={(page: number) => {
                    setPage(page);
                  }}
                  onChangeRowsPerPage={(rowsPerPage: number) => {
                    setPage(1);
                    setPerPage(rowsPerPage);
                  }}
                  onSort={(
                    column,
                    sortDir
                  ) => {
                    setSortBy(column.selector.toString());
                    setSortDir(sortDir);
                  }}
                  columns={tableColumns}
                  onRowClicked={handleUpdateRecord}
                  contextActions={contextActions}
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleCleared}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Notifications;
