import { combineReducers } from "redux";
import Customizer, { initialCustomizerState } from "./reducers/customizer";
import State, { initialCommonState } from "./reducers/common";
import { CustomizerState, CommonState, DataSetState, UserCustomizedState } from "./types";
import DataSet, { initialDataSetState } from "./reducers/dataset";
import UserCustomized, { initialUserCustomizedState } from "./reducers/user-customized";

export interface ICombineReducers {
  State: CommonState;
  DataSet: DataSetState;
  Customizer: CustomizerState;
  UserCustomized: UserCustomizedState;
}

export const initialReducersState: ICombineReducers = {
  State: initialCommonState,
  Customizer: initialCustomizerState,
  DataSet: initialDataSetState,
  UserCustomized: initialUserCustomizedState,
};

const reducers = combineReducers<ICombineReducers>({
  State,
  Customizer,
  DataSet,
  UserCustomized
});

export default reducers;
