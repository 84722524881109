import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Breadcrumb from "../../layout/breadcrumb";
import { CrudParam } from "../../configs/route";
import { useApollo } from "../../services/apollo.service";
import { convert_to_date, SITE_TITLE } from "../../configs/constants";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/auth";
import { gql } from "@apollo/client";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import pt_BR from "date-fns/locale/pt-BR";
import { ICombineReducers } from "../../redux";
import { useSelector } from "react-redux";
import SimpleMDE from "react-simplemde-editor";
import ReactMarkdown from "react-markdown";
import Loading from "../../components/loading";

const Manage: React.FC = () => {
  const locale = useSelector((state: ICombineReducers) => state.State.locale);
  const isMountedRef = useRef(null);
  const { apollo, error } = useApollo();
  const { id } = useParams<CrudParam>();
  const [subject, setSubject] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [notificatedAt, setNotificatedAt] = useState<Date>();
  const [isFeatured, setIsFeatured] = useState<boolean>(false);
  const [preview, setPreview] = useState<boolean>(() => { if (id) return true; else return false; });
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const intl = useIntl();

  const { hasScope } = useAuth();
  const [canView] = useState<boolean>(() => {
    return hasScope("Notifications:Notification");
  });
  const [canCreate] = useState<boolean>(() => {
    return hasScope("Notifications:Create");
  });
  const [canUpdate] = useState<boolean>(() => {
    return hasScope("Notifications:Update");
  });

  const handleData = useCallback(async () => {
    if (id) {
      if (preview && !canView) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/notifications");
        return;
      } else if (!preview && !canUpdate) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/notifications");
        return;
      }
      setLoading(true);
      await apollo.query({
        query: gql`
          query notification($id: String!) {
            notification(id: $id) {
              id
              createdAt
              updatedAt
              subject
              description
              isFeatured
              notificatedAt
            }
          }
        `,
        variables: {
          id
        },
      })
        .then(res => {
          if (isMountedRef.current) {
            setSubject(res.data.notification.subject || "");
            setDescription(res.data.notification.description || "");
            setNotificatedAt(convert_to_date(res.data.notification.notificatedAt));
            setIsFeatured(res.data.notification.isFeatured || false);
          }
        })
        .catch(err => error(err));
      setLoading(false);
    } else {
      if (!preview && !canCreate) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/notifications");
        return;
      }
    }
  }, [intl, id, preview, apollo, error, history, canView, canCreate, canUpdate]);

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: `pages.notifications.${preview ? "preview" : id ? "update" : "create"}`,
      })}`;
    if (locale === "pt") {
      registerLocale("pt-BR", pt_BR);
      setDefaultLocale("pt-BR");
    }
    handleData();
    return () => { isMountedRef.current = false; }
  }, [intl, id, preview, handleData, locale]);

  const handleSubmitForm = async () => {
    setDisableSubmit(true);
    const data = {
      subject,
      description,
      notificatedAt,
      isFeatured,
    };

    if (!id) {
      await apollo.mutate({
        mutation: gql`
          mutation createNotification($data: NotificationInput!) {
            createNotification(data: $data) {
                id
            }
          }
        `,
        variables: {
          data
        },
      })
        .then(res => {
          toast.success(intl.formatMessage({ id: "flash.success.created" }));
          history.push(`/notifications/manage/${res.data.createNotification.id}`);
        })
        .catch(err => error(err));
    } else {
      await apollo.mutate({
        mutation: gql`
          mutation updateNotification($id: String!, $data: NotificationInput!) {
            updateNotification(id: $id, data: $data) {
                id
            }
          }
        `,
        variables: {
          id,
          data
        },
      })
        .then(res => {
          toast.success(intl.formatMessage({ id: "flash.success.updated" }));
          history.push(`/notifications`);
        })
        .catch(err => error(err));
    }
    setDisableSubmit(false);
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="pages.notifications.notifications"
        title={`pages.notifications.${preview ? "preview" : id ? "update" : "create"}`}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>
                  <FormattedMessage
                    id={`pages.notifications.${preview ? "preview" : id ? "update" : "create"
                      }`}
                  />
                </h5>
              </CardHeader>
              <CardBody className="tabbed-card">
                <div className="form-content">
                  <Form onSubmit={(e) => e.preventDefault()}>
                    <Row className="m-b-30">
                      <Col>
                        {loading ? <Loading /> : (
                          <React.Fragment>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.subject" />
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={subject}
                                    disabled={preview}
                                    onChange={(e) => setSubject(e.target.value)}
                                    required
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.notificatedAt" />
                                  </Label>
                                  <div className="datepicker-time">
                                    <DatePicker
                                      className="form-control digits"
                                      showPopperArrow={true}
                                      selected={notificatedAt}
                                      disabled={preview}
                                      onChange={(date: Date) => setNotificatedAt(date)}
                                      dateFormat="dd/MM/yyyy HH:mm"
                                      showTimeSelect
                                      timeFormat="HH:mm"
                                      timeIntervals={30}
                                      autoComplete="off"
                                      timeInputLabel="Hora"
                                      required
                                    />
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.description" />
                                  </Label>
                                  {preview ? <ReactMarkdown className="react-markdown disabled">{description}</ReactMarkdown> : (<SimpleMDE
                                    id="description"
                                    onChange={(value) => setDescription(value)}
                                    value={description}
                                    options={{
                                      autofocus: true,
                                      spellChecker: false
                                    }}
                                  />)}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.isFeatured" />
                                  </Label>
                                  <Col className="checkbox">
                                    <Input
                                      key="isFeatured-input"
                                      id="isFeatured"
                                      type="checkbox"
                                      value={1}
                                      checked={isFeatured}
                                      disabled={preview}
                                      onChange={(e) => setIsFeatured(!isFeatured)}
                                    />
                                    <Label
                                      key="isFeatured-label"
                                      for="isFeatured"
                                      className="d-block"
                                    >Sim</Label>
                                  </Col>
                                </FormGroup>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          type="button"
                          color="danger"
                          onClick={() => history.push("/notifications")}
                        >
                          <i className="fa fa-arrow-left"></i>
                          <FormattedMessage id="buttons.cancel" />
                        </Button>
                      </Col>
                      {canUpdate ? (
                        <Col className="text-right">
                          {preview ? (
                            <Button
                              type="button"
                              color="primary"
                              disabled={loading}
                              onClick={() => setPreview(!preview)}
                            >
                              <i className="fa fa-edit"></i>
                              <FormattedMessage id="buttons.edit" />
                            </Button>
                          ) : (
                            <Button type="button" disabled={disableSubmit || loading} onClick={handleSubmitForm} color="primary">
                              <i className="fa fa-save"></i>
                              <FormattedMessage id="buttons.save" />
                            </Button>
                          )}
                        </Col>) : ""}
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Manage;
