import { gql } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Row, Col, Form, FormGroup, Input, Label, Button, NavItem, NavLink, Nav, TabContent, TabPane, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import AppAuth from "../../components/app.auth";
import { convert_to_date, SITE_TITLE } from "../../configs/constants";
import { useAuth } from "../../hooks/auth";
import { ICombineReducers } from "../../redux";
import { useApollo } from "../../services/apollo.service";
import formatter from "../../utils/formatter";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import pt_BR from "date-fns/locale/pt-BR";

const Login: React.FC = () => {
  const isMountedRef = useRef(null);
  const intl = useIntl();
  const { signIn } = useAuth();
  const { apollo, error } = useApollo();
  const history = useHistory();
  const locale = useSelector((state: ICombineReducers) => state.State.locale);

  const [togglePassword, setTogglePassword] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [remember, setRemember] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>("apollo");
  const [submitDisable, setSubmitDisable] = useState<boolean>(false);

  const [modal, setModal] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>("");
  const [fullname, setFullname] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [documento, setDocument] = useState<string>("");
  const [birthDate, setBirthDate] = useState<Date>(null);

  const toggleShowPassword = (display: boolean) => setTogglePassword(!display);

  const toggleModal = () => {
    const value = !modal;
    setModal(value);
    if (!value) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    }
  };

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: "pages.login.title",
      })}`;
    if (isMountedRef.current) {
      if (locale === "pt") {
        registerLocale("pt-BR", pt_BR);
        setDefaultLocale("pt-BR");
      }
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    }
    return () => { isMountedRef.current = false; }
  }, [intl, locale]);

  const handleDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\D/, "");

    if (value.length === 11) {
      value = formatter.formatCpf(value);
    } else if (value.length === 14) {
      value = formatter.formatCnpj(value);
    }

    setDocument(value);
  };

  const formatDocument = (document: string) => {
    let value = document.replace(/\D/, "");

    if (value.length === 11) {
      value = formatter.formatCpf(value);
    } else if (value.length === 14) {
      value = formatter.formatCnpj(value);
    }
    return value;
  };

  const handleUpdateProfile = async () => {
    const data = {
      name,
      surname,
      phone,
      mobile,
      email,
      password: newPassword.trim(),
      document: documento.trim().replace(/\D/gm, ""),
      birthDate: birthDate,
    };

    if (newPassword.trim() !== newPasswordConfirm.trim()) {
      toast.error("As senhas não conferem.");
      return;
    }

    if (newPassword.trim().length < 6)
      toast.error("A senha deve conter no mínimo 6 caracteres.");

    if (newPassword.trim() === "123456" || newPassword.trim() === "123456789" || newPassword.trim() === "987654321" || newPassword.trim() === "654321" || newPassword.trim() === "abc123" || newPassword.trim() === "abc123456" || newPassword.trim() === "a1s2d3f4") {
      toast.error("Por favor, utilize uma senha mais segura. Tente usar letras maiúsculas, minúsculas, números e carácteres especiais para tornar a senha mais segura.");
      return;
    }

    if (!documento.trim()) {
      toast.error("Documento é obrigatório.");
      return;
    }

    if (!birthDate) {
      toast.error("Data de nascimento é obrigatória.");
      return;
    }

    let _document = documento.trim().replace(/\D/, "");
    if (!formatter.isCpfValid(_document) && !formatter.isCnpjValid(_document)) {
      toast.error("Documento informado é inválido.");
      return;
    }

    await apollo.mutate({
      mutation: gql`
        mutation profile($data: ProfileInput!) {
          profile(data: $data) {
            id
            createdAt
            updatedAt
            deletedAt
            name
            surname
            document
            birthDate
            email
            phone
            mobile
            isActivated
            isSuperAdmin
            protheusId
            path
            roles {
              id
              name
              description
            }
            areaCodes {
              id
              code
              protheusId
            }
            cities {
              id
              name
              code
            }
            claims {
              claimType
              claimValue
            }
          }
        }
      `,
      variables: {
        data
      },
    })
      .then(async res => {
        toast.success("Seus dados foram atualizados com sucesso.");
        await signIn(
          email,
          newPassword,
          remember
        )
          .then((res) => {
            if (res) {
              toast.success("Login efetuado com sucesso.");
              setTimeout(() => history.push("/"), 1500);
            }
          });
      })
      .catch(err => error(err));
    setSubmitDisable(false);
  };

  return (
    <AppAuth>
      <Modal isOpen={modal} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>Atualize Seus Dados</ModalHeader>
        <Form onSubmit={async (e) => {
          e.preventDefault();
          await handleUpdateProfile();
        }}>
          <ModalBody>
            <Container fluid={true}>
              <Row>
                <Col>Olá {fullname.trim()}, seja bem-vindo(a). Antes de proceguirmos será nacessário alterar seus dados e a sua senha atual. Por favor, informe os dados abaixo:</Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label className="col-form-label">
                      <FormattedMessage id="inputs.name" />
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      value={name || ""}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label className="col-form-label">
                      <FormattedMessage id="inputs.surname" />
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      value={surname || ""}
                      onChange={(e) => setSurname(e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label className="col-form-label">
                      <FormattedMessage id="inputs.cpfCnpj" />
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      value={documento ? formatDocument(documento) : ""}
                      onChange={handleDocument}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label className="col-form-label">
                      <FormattedMessage id="inputs.birthDate" />
                    </Label>
                    <DatePicker
                      className="form-control"
                      selected={birthDate || null}
                      onChange={(date: Date) => setBirthDate(date)}
                      dateFormat="dd/MM/yyyy"
                      autoComplete="off"
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label className="col-form-label">
                      <FormattedMessage id="inputs.password" />
                    </Label>
                    <Input
                      className="form-control"
                      type="password"
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label className="col-form-label">
                      <FormattedMessage id="inputs.passwordConfirm" />
                    </Label>
                    <Input
                      className="form-control"
                      type="password"
                      onChange={(e) => setNewPasswordConfirm(e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>Fechar</Button>
            <Button type="submit" color="primary">Alterar</Button>
          </ModalFooter></Form>
      </Modal>
      <Container fluid={true} className="p-0">
        <Row className="m-0">
          <Col xs="12" className="p-0">
            <div className="login-card">
              <div>
                <div>
                  <a className="logo" href="#javascript">
                    <img className="img-fluid for-light" src={require("../../assets/images/logo/logo_stark.png")} alt="" />
                    <img className="img-fluid for-dark" src={require("../../assets/images/logo/logo_dark_stark.png")} alt="" />
                  </a>
                </div>
                <div className="login-main login-tab">
                  <Nav className="border-tab flex-column" tabs>
                    <NavItem>
                      <NavLink className={selected === "apollo" ? "active" : ""} onClick={() => setSelected("apollo")}>
                        <img src={require("../../assets/images/logo/logo-icon-stark.png")} alt="" />
                        <span>{SITE_TITLE}</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={selected} className="content-login">
                    <TabPane className="fade show" tabId={selected === "apollo" ? "apollo" : ""}>
                      <Form className="theme-form" onSubmit={async (e) => {
                        e.preventDefault();
                        setSubmitDisable(true);
                        if (password.trim() === "123456" || password.trim() === "123456789" || password.trim() === "987654321" || password.trim() === "654321" || password.trim() === "abc123" || password.trim() === "abc123456" || password.trim() === "a1s2d3f4") {
                          await apollo.query({
                            query: gql`
                              query login($email: String!, $password: String!, $remember: Boolean) {
                                login(email: $email, password: $password, remember: $remember) {
                                  user {
                                    id
                                    createdAt
                                    updatedAt
                                    deletedAt
                                    name
                                    surname
                                    document
                                    birthDate
                                    email
                                    phone
                                    mobile
                                    isActivated
                                    isSuperAdmin
                                    protheusId
                                    path
                                    roles {
                                      id
                                      name
                                      description
                                      maximumDiscount
                                      paymentConditions {
                                        id
                                        name
                                        description1
                                        description2
                                        protheusId
                                      }
                                    }
                                    claims {
                                      id
                                      claimType
                                      claimValue
                                    }
                                    areaCodes {
                                      id
                                      code
                                      protheusId
                                      state {
                                        id
                                        code
                                      }
                                    }
                                    cities {
                                      name
                                      code
                                      state {
                                        id
                                        name
                                      }
                                    }
                                  }
                                  token
                                  expires
                                }
                              }
                            `,
                            variables: {
                              email: email.trim().toLowerCase(),
                              password: password.trim(),
                              remember: false,
                            },
                          })
                            .then(res => {
                              setFullname(`${res.data.login.user.name} ${res.data.login.user.surname}`);
                              setName(res.data.login.user.name || "");
                              setSurname(res.data.login.user.surname || "");
                              setPhone(res.data.login.user.phone || "");
                              setMobile(res.data.login.user.mobile || "");
                              setDocument(res.data.login.user.document || "");
                              setBirthDate(res.data.login.user.birthDate ? convert_to_date(res.data.login.user.birthDate) : null);
                              localStorage.setItem("token", res.data.login.token);
                              toggleModal();
                            })
                            .catch(err => error(err));
                        } else {
                          await signIn(
                            email,
                            password,
                            remember
                          )
                            .then((res) => {
                              if (res) {
                                toast.success("Login efetuado com sucesso.");
                                setTimeout(() => history.push("/"), 1500);
                              }
                            });
                        }
                        setSubmitDisable(false);
                      }}>
                        <h4><FormattedMessage id="pages.login.title" /></h4>
                        <p><FormattedMessage id="pages.login.description" /></p>
                        <FormGroup>
                          <Label className="col-form-label"><FormattedMessage id="inputs.email" /></Label>
                          <Input className="form-control" type="email" value={email} onChange={(e) => setEmail(e.target.value?.trim()?.toLowerCase())} required />
                        </FormGroup>
                        <FormGroup>
                          <Label className="col-form-label"><FormattedMessage id="inputs.password" /></Label>
                          <Input className="form-control" type={togglePassword ? "text" : "password"} value={password} onChange={(e) => setPassword(e.target.value?.trim())} required />
                          <div className="show-hide" onClick={() => toggleShowPassword(togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
                        </FormGroup>
                        <div className="form-group mb-0">
                          <div className="checkbox ml-3">
                            <Input id="remember-me" value={1} checked={remember} onChange={() => setRemember(!remember)} type="checkbox" />
                            <Label className="text-muted" for="remember-me"><FormattedMessage id="inputs.rememberMe" /></Label>
                          </div>
                          <Link className="link" to={`${process.env.PUBLIC_URL}/forgot-password`}><FormattedMessage id="pages.login.forgotPassword" /></Link>
                          <Button color="primary" type="submit" disabled={submitDisable} className="btn-block" ><FormattedMessage id="buttons.enter" /></Button>
                        </div>
                        <p className="mt-4 mb-0">Ainda não possui uma conta?<Link className="ml-2" to={`${process.env.PUBLIC_URL}/register`}><FormattedMessage id="buttons.signUp" /></Link></p>
                      </Form>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </AppAuth>
  );
}

export default Login;
