import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Bowser from "bowser";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Form, FormGroup, Input, Label } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { IDataTableColumn } from "react-data-table-component";
import { useApollo } from "../../services/apollo.service";
import { useHistory } from "react-router-dom";
import RequestLog from "../../models/requestLog";
import Listing from "../../components/listing";
import { format_date, LIST_DATE_TIME_FORMAT, RECORDS_PER_PAGE, SITE_TITLE } from "../../configs/constants";
import { useAuth } from "../../hooks/auth";
import { gql } from "@apollo/client";
import { useDispatch } from "react-redux";
import { dataTableLoading } from "../../redux/actions";
import User from "../../models/user";

interface RequestLogFilter {
  ipAddress?: string;
  userId?: string;
  body?: string;
}

const RequestLogs: React.FC = () => {
  const isMountedRef = useRef(null);
  const dispatch = useDispatch();
  const { apollo, error } = useApollo();
  const intl = useIntl();
  const history = useHistory();
  const [data, setData] = useState<RequestLog[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(RECORDS_PER_PAGE);
  const [sortBy, setSortBy] = useState<string>("createdAt");
  const [sortDir, setSortDir] = useState<string>("DESC");
  const { hasScope } = useAuth();

  const [users, setUsers] = useState<User[]>([]);
  const [filters, setFilters] = useState<RequestLogFilter>({});
  const [filters2, setFilters2] = useState<RequestLogFilter>({});
  const [disableSubmitSearch, setDisableSubmitSearch] = useState<boolean>(false);

  const handleSearch = () => {
    setPage(1);
    setFilters2(filters);
  };

  const handleData = useCallback(async () => {
    dispatch(dataTableLoading(true));
    await Promise.all([
      apollo.query({
        query: gql`
          query usersDropdown {
            usersDropdown {
              id
              name
              surname
            }
          }
        `,
      }),
      apollo.query({
        query: gql`
          query requestLogs($sortDir: String, $sortBy: String, $perPage: Int, $page: Int, $filterByIp: String, $filterByUser: String, $filterByBody: String) {
            requestLogs(sortDir: $sortDir, sortBy: $sortBy, perPage: $perPage, page: $page, filterByIp: $filterByIp, filterByUser: $filterByUser, filterByBody: $filterByBody) {
              paging {
                total
                pages
                perPage
                currentPage
              }
              list {
                id
                createdAt
                updatedAt
                token
                ipAddress
                userAgent
                referrer
                requestBody
                origin
                user {
                  id
                  name
                  surname
                }
              }
            }
          }
        `,
        variables: {
          page, perPage, sortBy, sortDir,
          filterByIp: filters2?.ipAddress,
          filterByUser: filters2?.userId,
          filterByBody: filters2?.body,
        }
      }),
    ])
      .then(res => {
        if (isMountedRef.current) {
          setUsers(res[0].data.usersDropdown || []);
          setTotal(res[1].data.requestLogs.paging.total);
          setPage(res[1].data.requestLogs.paging.currentPage);
          setPerPage(res[1].data.requestLogs.paging.perPage);
          setData(res[1].data.requestLogs.list);
        }
      })
      .catch(err => error(err));
    dispatch(dataTableLoading(false));
  }, [apollo, error, page, perPage, sortBy, sortDir, dispatch, filters2]);

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: "pages.requestLogs.requestLogs",
      })}`;
    handleData();
    return () => { isMountedRef.current = false; }
  }, [intl, handleData]);

  const tableColumns: IDataTableColumn<RequestLog>[] = [
    {
      name: intl.formatMessage({ id: "inputs.ipAddress" }),
      selector: "ipAddress",
      sortable: true,
      center: false,
    },
    {
      name: intl.formatMessage({ id: "inputs.userAgent" }),
      selector: "userAgent",
      sortable: true,
      center: false,
      format: (row) => {
        if (row.userAgent) {
          let userAgent = "";
          const browser = Bowser.parse(row.userAgent);
          if (browser.browser.name)
            userAgent += `${browser.browser.name} `;
          if (browser.browser.version)
            userAgent += `${browser.browser.version}`;
          if (browser.browser.name && browser.os.name)
            userAgent += " / ";
          if (browser.os.name)
            userAgent += `${browser.os.name} `;
          if (browser.os.version)
            userAgent += `${browser.os.version} `;
          if (browser.os.versionName)
            userAgent += `(${browser.os.versionName})`;

          return userAgent;
        }
        return row.userAgent;
      },
    },
    {
      name: intl.formatMessage({ id: "inputs.userId" }),
      selector: "user",
      sortable: true,
      center: false,
      format: (row) => {
        return row.user ? `${row.user?.name} ${row.user?.surname}` : "-";
      }
    },
    {
      name: intl.formatMessage({ id: "inputs.createdAt" }),
      selector: "createdAt",
      format: (row) => {
        return format_date(row.createdAt, LIST_DATE_TIME_FORMAT);
      },
      sortable: true,
      center: false,
    },
  ];

  const handleUpdateRecord = (row: RequestLog) => {
    if (hasScope("RequestLogs:RequestLog"))
      history.push(`/request-logs/manage/${row.id}`);
  };

  return (
    <Fragment>
      <Breadcrumb parent="general.home" title="pages.requestLogs.requestLogs" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>
                  <FormattedMessage id="pages.requestLogs.list" />
                </h5>
              </CardHeader>
              <CardBody>
                <Form onSubmit={async (e) => {
                  e.preventDefault();
                  setDisableSubmitSearch(true);
                  await handleSearch();
                  setDisableSubmitSearch(false);
                }}>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label">
                          <FormattedMessage id="inputs.ipAddress" />
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          value={filters?.ipAddress || ""}
                          disabled={disableSubmitSearch}
                          onChange={(e) => {
                            setFilters({ ...filters, ipAddress: e.target.value });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label">
                          <FormattedMessage id="inputs.user" />
                        </Label>
                        <Input
                          className="form-control"
                          type="select"
                          value={filters?.userId || ""}
                          disabled={disableSubmitSearch}
                          onChange={(e) => {
                            setFilters({ ...filters, userId: e.target.value });
                          }}
                        >
                          <option value="">-</option>
                          {users?.map(user => <option key={user.id} value={user.id}>{user.name} {user.surname}</option>)}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label">
                          <FormattedMessage id="inputs.requestBody" />
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          value={filters?.body || ""}
                          disabled={disableSubmitSearch}
                          onChange={(e) => {
                            setFilters({ ...filters, body: e.target.value });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="text-right">
                      <FormGroup>
                        <Label className="col-form-label" style={{ display: "block", width: "100%" }}>
                          &nbsp;
                        </Label>
                        <div>
                          <Button type="submit" disabled={disableSubmitSearch} color="secondary">
                            <i className="fa fa-search"></i> Filtrar
                          </Button>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                <Listing
                  data={data}
                  total={total}
                  selectable
                  rowsPerPage={perPage}
                  onChangePage={(page: number) => {
                    setPage(page);
                  }}
                  onChangeRowsPerPage={(rowsPerPage: number) => {
                    setPage(1);
                    setPerPage(rowsPerPage);
                  }}
                  onSort={(
                    column,
                    sortDir
                  ) => {
                    setSortBy(column.selector.toString());
                    setSortDir(sortDir);
                  }}
                  columns={tableColumns}
                  onRowClicked={handleUpdateRecord}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default RequestLogs;
