import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Form, FormGroup, Input, Label } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { IDataTableColumn } from "react-data-table-component";
import { useApollo } from "../../services/apollo.service";
import { useHistory } from "react-router-dom";
import Log from "../../models/log";
import Listing from "../../components/listing";
import { format_date, LIST_DATE_TIME_FORMAT, RECORDS_PER_PAGE, SITE_TITLE } from "../../configs/constants";
import { useAuth } from "../../hooks/auth";
import { gql } from "@apollo/client";
import { useDispatch } from "react-redux";
import { dataTableLoading } from "../../redux/actions";

interface LogFilter {
  level?: string;
  message?: string;
}

const Logs: React.FC = () => {
  const isMountedRef = useRef(null);
  const dispatch = useDispatch();
  const { apollo, error } = useApollo();
  const intl = useIntl();
  const history = useHistory();
  const [data, setData] = useState<Log[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(RECORDS_PER_PAGE);
  const [sortBy, setSortBy] = useState<string>("createdAt");
  const [sortDir, setSortDir] = useState<string>("DESC");
  const { hasScope } = useAuth();

  const [filters, setFilters] = useState<LogFilter>({});
  const [filters2, setFilters2] = useState<LogFilter>({});
  const [disableSubmitSearch, setDisableSubmitSearch] = useState<boolean>(false);

  const handleSearch = () => {
    setPage(1);
    setFilters2(filters);
  };

  const handleData = useCallback(async () => {
    dispatch(dataTableLoading(true));
    await apollo.query({
      query: gql`
          query logs($sortDir: String, $sortBy: String, $perPage: Int, $page: Int, $filterByLogLevel: String, $filterByMessage: String) {
            logs(sortDir: $sortDir, sortBy: $sortBy, perPage: $perPage, page: $page, filterByLogLevel: $filterByLogLevel, filterByMessage: $filterByMessage) {
              paging {
                total
                pages
                perPage
                currentPage
              }
              list {
                id
                createdAt
                updatedAt
                level
                message
              }
            }
          }
        `,
      variables: {
        page, perPage, sortBy, sortDir,
        filterByLogLevel: filters2?.level,
        filterByMessage: filters2?.message,
      }
    })
      .then(res => {
        if (isMountedRef.current) {
          setTotal(res.data.logs.paging.total);
          setPage(res.data.logs.paging.currentPage);
          setPerPage(res.data.logs.paging.perPage);
          setData(res.data.logs.list);
        }
      })
      .catch(err => error(err));
    dispatch(dataTableLoading(false));
  }, [apollo, error, page, perPage, sortBy, sortDir, dispatch, filters2]);

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: "pages.logs.logs",
      })}`;
    handleData();
    return () => { isMountedRef.current = false; }
  }, [intl, handleData]);

  const tableColumns: IDataTableColumn<Log>[] = [
    {
      name: intl.formatMessage({ id: "inputs.level" }),
      selector: "level",
      sortable: true,
      center: false,
      width: "80px",
      format: (row) => {
        return row.level.toUpperCase();
      }
    },
    {
      name: intl.formatMessage({ id: "inputs.message" }),
      selector: "message",
      sortable: true,
      center: false,
      format: (row) => {
        return row.message.length < 110 ? row.message : row.message.substr(0, 110) + "...";
      }
    },
    {
      name: intl.formatMessage({ id: "inputs.createdAt" }),
      selector: "createdAt",
      format: (row) => {
        return format_date(row.createdAt, LIST_DATE_TIME_FORMAT);
      },
      sortable: true,
      center: false,
      width: "180px"
    },
  ];

  const handleUpdateRecord = (row: Log) => {
    if (hasScope("Logs:Log"))
      history.push(`/logs/manage/${row.id}`);
  };

  return (
    <Fragment>
      <Breadcrumb parent="general.home" title="pages.logs.logs" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>
                  <FormattedMessage id="pages.logs.list" />
                </h5>
              </CardHeader>
              <CardBody>
                <Form onSubmit={async (e) => {
                  e.preventDefault();
                  setDisableSubmitSearch(true);
                  await handleSearch();
                  setDisableSubmitSearch(false);
                }}>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label">
                          <FormattedMessage id="inputs.level" />
                        </Label>
                        <Input
                          className="form-control"
                          type="select"
                          value={filters?.level || ""}
                          disabled={disableSubmitSearch}
                          onChange={(e) => {
                            setPage(1);
                            setFilters({ ...filters, level: e.target.value });
                          }}
                        >
                          <option value="">-</option>
                          <option value="debug">DEBUG</option>
                          <option value="error">ERROR</option>
                          <option value="info">INFO</option>
                          <option value="warn">WARN</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label">
                          <FormattedMessage id="inputs.message" />
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          value={filters?.message || ""}
                          disabled={disableSubmitSearch}
                          onChange={(e) => {
                            setPage(1);
                            setFilters({ ...filters, message: e.target.value });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="text-right">
                      <FormGroup>
                        <Label className="col-form-label" style={{ display: "block", width: "100%" }}>
                          &nbsp;
                        </Label>
                        <div>
                          <Button type="submit" disabled={disableSubmitSearch} color="secondary">
                            <i className="fa fa-search"></i> Filtrar
                          </Button>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                <Listing
                  data={data}
                  total={total}
                  selectable
                  rowsPerPage={perPage}
                  onChangePage={(page: number) => {
                    setPage(page);
                  }}
                  onChangeRowsPerPage={(rowsPerPage: number) => {
                    setPage(1);
                    setPerPage(rowsPerPage);
                  }}
                  onSort={(
                    column,
                    sortDir
                  ) => {
                    setSortBy(column.selector.toString());
                    setSortDir(sortDir);
                  }}
                  columns={tableColumns}
                  onRowClicked={handleUpdateRecord}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Logs;
