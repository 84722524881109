import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Row, Col, Form, FormGroup, Input, Label, Button } from "reactstrap";
import AppAuth from "../../components/app.auth";
import { SITE_TITLE } from "../../configs/constants";
import { useAuth } from "../../hooks/auth";
import { ICombineReducers } from "../../redux";
import formatter from "../../utils/formatter";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import pt_BR from "date-fns/locale/pt-BR";

const Register: React.FC = () => {
  const isMountedRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const { signUp } = useAuth();
  const locale = useSelector((state: ICombineReducers) => state.State.locale);

  const [name, setName] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [documento, setDocument] = useState<string>("");
  const [birthDate, setBirthDate] = useState<Date>(null);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const [submitDisable, setSubmitDisable] = useState<boolean>(false);
  const [togglePassword, setTogglePassword] = useState<boolean>(false);
  const [togglePasswordConfirm, setTogglePasswordConfirm] = useState<boolean>(false);

  const toggleShowPassword = (display: boolean) => setTogglePassword(!display);
  const toggleShowPasswordConfirm = (display: boolean) => setTogglePasswordConfirm(!display);

  const handleDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\D/, "");

    if (value.length === 11) {
      value = formatter.formatCpf(value);
    } else if (value.length === 14) {
      value = formatter.formatCnpj(value);
    }
    setDocument(value);
  };

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: "pages.login.signUp.title",
      })}`;
    if (locale === "pt") {
      registerLocale("pt-BR", pt_BR);
      setDefaultLocale("pt-BR");
    }
    return () => { isMountedRef.current = false; }
  }, [intl, location, locale]);


  return (
    <AppAuth>
      <Container fluid={true} className="p-0">
        <Row className="m-0">
          <Col xs="12" className="p-0">
            <div className="login-card">
              <div>
                <div>
                  <a className="logo" href="#javascript">
                    <img className="img-fluid for-light" src={require("../../assets/images/logo/logo_stark.png")} alt="" />
                    <img className="img-fluid for-dark" src={require("../../assets/images/logo/logo_dark_stark.png")} alt="" />
                  </a>
                </div>
                <div className="login-main">
                  <Form className="theme-form" onSubmit={async (e) => {
                    e.preventDefault();
                    setSubmitDisable(true);
                    await signUp(
                      name,
                      surname,
                      documento.trim().replace(/\D/gm, ""),
                      phone,
                      mobile,
                      birthDate,
                      email,
                      password,
                      passwordConfirm
                    )
                      .then((res) => {
                        if (res) {
                          toast.success("Usuário registrado com sucesso.");
                          setName("");
                          setSurname("");
                          setPhone("");
                          setMobile("");
                          setDocument("");
                          setEmail("");
                          setPassword("");
                          setPasswordConfirm("");
                          setBirthDate(null);
                          setTimeout(() => history.push("/login"), 1500);
                        }
                      });
                    setSubmitDisable(false);
                  }}>
                    <h4><FormattedMessage id="pages.login.signUp.title" /></h4>
                    <p><FormattedMessage id="pages.login.signUp.description" /></p>
                    <FormGroup>
                      <Label className="col-form-label pt-0">Seu nome</Label>
                      <div className="form-row">
                        <Col xs="6">
                          <Input className="form-control" type="text" value={name} onChange={(e) => setName(e.target.value?.trim())} placeholder="Fixo" required />
                        </Col>
                        <Col xs="6">
                          <Input className="form-control" type="text" value={surname} onChange={(e) => setSurname(e.target.value?.trim())} placeholder={intl.formatMessage({ id: "inputs.surname" })} required />
                        </Col>
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label"><FormattedMessage id="inputs.cpfCnpj" /></Label>
                      <Input className="form-control" type="text" value={documento} onChange={handleDocument} required />
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label"><FormattedMessage id="inputs.birthDate" /></Label>
                      <DatePicker
                        className="form-control"
                        selected={birthDate}
                        onChange={(e: Date) => setBirthDate(e)}
                        dateFormat="dd/MM/yyyy"
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label pt-0">Telefones</Label>
                      <div className="form-row">
                        <Col xs="6">
                          <Input className="form-control" type="text" value={phone} onChange={(e) => setPhone(e.target.value?.trim())} placeholder={intl.formatMessage({ id: "inputs.phone" })} />
                        </Col>
                        <Col xs="6">
                          <Input className="form-control" type="text" value={mobile} onChange={(e) => setMobile(e.target.value?.trim())} placeholder={intl.formatMessage({ id: "inputs.mobile" })} />
                        </Col>
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label"><FormattedMessage id="inputs.email" /></Label>
                      <Input className="form-control" type="email" value={email} onChange={(e) => setEmail(e.target.value?.trim()?.toLowerCase())} required />
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label"><FormattedMessage id="inputs.password" /></Label>
                      <Input className="form-control" type={togglePassword ? "text" : "password"} value={password} onChange={(e) => setPassword(e.target.value?.trim())} required />
                      <div className="show-hide" onClick={() => toggleShowPassword(togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label"><FormattedMessage id="inputs.passwordConfirm" /></Label>
                      <Input className="form-control" type={togglePasswordConfirm ? "text" : "password"} value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value?.trim())} required />
                      <div className="show-hide" onClick={() => toggleShowPasswordConfirm(togglePasswordConfirm)}><span className={togglePasswordConfirm ? "" : "show"}></span></div>
                    </FormGroup>

                    <div className="form-group mb-0">
                      <Button color="primary" type="submit" disabled={submitDisable} className="btn-block" ><FormattedMessage id="buttons.signUp" /></Button>
                    </div>
                    <p className="mt-4 mb-0">Já possui uma conta?<Link className="ml-2" to={`${process.env.PUBLIC_URL}/login`}><FormattedMessage id="buttons.enter" /></Link></p>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </AppAuth>
  );
}

export default Register;
