import {
  CustomizerColor,
  CustomizerDatabase,
  CustomizerSidebar,
} from "../../configs/theme";
import {
  AddCustomizer,
  AddLayout,
  AddSidebarTypes,
  AddSidebarSettings,
  AddColor,
  AddMixBackgroundLayout,
  AddRouterAnimation,
  ToggleResponsiveSearch,
  ChangeLocale,
  LoadCredentials,
  Credentials,
  DataTableLoading,
  ChangeKanban,
} from "../types";

export const addCostomizer = (
  customizer: CustomizerDatabase
): AddCustomizer => ({
  type: "ADD_CUSTOMIZER",
  customizer,
});

export const addLayout = (layout: string): AddLayout => ({
  type: "ADD_LAYOUT",
  layout,
});

export const addSidebarTypes = (
  sidebar_types: CustomizerSidebar
): AddSidebarTypes => ({
  type: "ADD_SIDEBAR_TYPES",
  sidebar_types,
});

export const addSidebarSettings = (settings: string): AddSidebarSettings => ({
  type: "ADD_SIDEBAR_SETTINGS",
  settings,
});

export const addColor = (color: CustomizerColor): AddColor => ({
  type: "ADD_COLOR",
  color,
});

export const addMixBackgroundLayout = (
  mix_background_layout: string
): AddMixBackgroundLayout => ({
  type: "ADD_MIX_BACKGROUND_LAYOUT",
  mix_background_layout,
});

export const addRouterAnimation = (animation: string): AddRouterAnimation => ({
  type: "ROUTER_ANIMATION",
  animation,
});

export const toggleResponsiveSearch = (
  responsiveSearch: boolean
): ToggleResponsiveSearch => ({
  type: "TOGGLE_RESPONSIVE_SEARCH",
  responsiveSearch,
});

export const changeLocale = (locale: string): ChangeLocale => ({
  type: "CHANGE_LOCALE",
  locale,
});

export const loadCredentials = (payload: Credentials): LoadCredentials => {
  localStorage.setItem("user", JSON.stringify(payload.user));
  localStorage.setItem("token", payload.token.token);
  return {
    type: "LOAD_CREDENTIALS",
    payload,
  }
};

export const changeKanban = (payload: string[]): ChangeKanban => {
  localStorage.setItem("kanban", JSON.stringify(payload))
  return {
    type: "CHANGE_KANBAN",
    payload,
  }
};

export const dataTableLoading = (payload: boolean): DataTableLoading => ({
  type: "CHANGE_LOADING",
  payload,
});
