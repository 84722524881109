import React from "react";
import { useIntl } from "react-intl";
import { Row, Col, Card, CardBody, CardHeader, Container } from "reactstrap";
import { ExpandedComponentProps } from "../../components/listing";
import { date, DATE_FORMAT_BR } from "../../configs/constants";
import User from "../../models/user";
import formatter from "../../utils/formatter";

export default function UserDetails<T = any>(props: ExpandedComponentProps<User>): React.ReactElement {
  const intl = useIntl();
  const formatDocument = (document: string): string => {
    document = document.trim().replace(/\D/gm, "");

    // Formata o documento do usuário
    if (document.length === 11) {
      document = formatter.formatCpf(document);
    } else if (document.length === 14) {
      document = formatter.formatCnpj(document);
    }
    return document;
  }

  return (
    <Container fluid={true}>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <h5>
                Cadastro
                </h5>
            </CardHeader>
            <CardBody className="tabbed-card">
              <Row>
                <Col>
                  {props.data.protheusId ? (
                    <div>
                      <strong>Código:</strong> {props.data.protheusId}
                    </div>
                  )
                    : ""}
                  <div>
                    <strong>Nome:</strong> {props.data.name}
                  </div>
                  {props.data.surname ? (
                    <div>
                      <strong>Sobrenome:</strong> {props.data.surname}
                    </div>
                  )
                    : ""}
                  <div>
                    <strong>E-mail:</strong> {props.data.email}
                  </div>
                  {props.data.phone ? (
                    <div>
                      <strong>Telefone:</strong> {props.data.phone}
                    </div>
                  )
                    : ""}
                  {props.data.mobile ? (
                    <div>
                      <strong>Celular/WhatsApp:</strong> {props.data.mobile}
                    </div>
                  )
                    : ""}
                  {props.data.document ? (
                    <div>
                      <strong>CPF/CNPJ:</strong> {formatDocument(props.data.document)}
                    </div>
                  )
                    : ""}
                  {props.data.birthDate ? (
                    <div>
                      <strong>Data de nascimento:</strong> {date(props.data.birthDate).format(DATE_FORMAT_BR)}
                    </div>
                  ) : ""}
                  <div>
                    <strong>Super-administrador:</strong> {props.data.isSuperAdmin ? "SIM" : "NÃO"}
                  </div>
                  <div>
                    <strong>Ativo:</strong> {props.data.isActivated ? "SIM" : "NÃO"}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <h5>
                Regras
                </h5>
            </CardHeader>
            <CardBody className="tabbed-card">
              <Row>
                {props.data.roles?.length ? props.data.roles.map((role) => {
                  return (
                    <Col
                      md="6"
                      key={`col-role-${role.id}`}
                      className="checkbox m-b-5"
                    >
                      {role.description}
                    </Col>
                  );
                }) : (<Col>Nenhuma regra foi registrada.</Col>)}
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardHeader>
              <h5>
                Regiões
                </h5>
            </CardHeader>
            <CardBody className="tabbed-card">
              <Row>
                {props.data.areaCodes?.length ? props.data.areaCodes.map((areaCode) => {
                  return (
                    <Col
                      md="6"
                      key={`col-area-code-${areaCode.id}`}
                      className="checkbox m-b-5"
                    >
                      {areaCode.code} - {areaCode.state.name} ({areaCode.state.fc})
                    </Col>
                  );
                }) : (<Col>Nenhum código de área foi registrado.</Col>)}
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <h5>
                Metas
                </h5>
            </CardHeader>
            <CardBody className="tabbed-card">
              <Row>
                {props.data.metas?.length ? props.data.metas.map((meta) => {
                  return (
                    <Col
                      md="6"
                      key={`col-meta-${meta.id}`}
                      className="checkbox m-b-5"
                    >
                      <div>
                        <strong>Nome:</strong> {meta.name}
                      </div>
                      <div>
                        <strong>Divisor:</strong> {meta.divider}x
                      </div>
                      <div>
                        <strong>Valor:</strong> R${intl.formatNumber(meta.total, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                      </div>
                      <div>
                        <strong>Inicia em:</strong> {date(meta.startAt).format(DATE_FORMAT_BR)}
                      </div>
                      <div>
                        <strong>Finaliza em:</strong> {date(meta.endAt).format(DATE_FORMAT_BR)}
                      </div>
                    </Col>
                  );
                }) : (<Col>Nenhuma meta foi registrado.</Col>)}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
