import React, { Fragment, useEffect, useRef } from "react";
import sad from "../../assets/images/other-images/sad.png";
import { Container, Media, Col } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { SITE_TITLE } from "../../configs/constants";

const NotAuthorized: React.FC = () => {
  const isMountedRef = useRef(null);
  const intl = useIntl();

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: "errors.401.title",
      })}`;
    return () => { isMountedRef.current = false; }
  }, [intl]);

  return (
    <Fragment>
      <div className="error-wrapper">
        <Container>
          <Media body className="img-100" src={sad} alt="" />
          <Col md="8 offset-md-2">
            <p className="sub-content">
              <FormattedMessage id="errors.401.description" />
            </p>
          </Col>
        </Container>
      </div>
    </Fragment>
  );
};

export default NotAuthorized;
