import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Breadcrumb from "../../layout/breadcrumb";
import { CrudParam } from "../../configs/route";
import { useSelector } from "react-redux";
import { ICombineReducers } from "../../redux";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import pt_BR from "date-fns/locale/pt-BR";
import State from "../../models/state";
import City from "../../models/city";
import { useApollo } from "../../services/apollo.service";
import { convert_to_date, date, DATE_FORMAT_BR, SITE_TITLE } from "../../configs/constants";
import StateAreaCode from "../../models/stateAreaCode";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useAuth } from "../../hooks/auth";
import { gql } from "@apollo/client";
import correios from "../../services/correios.service";
import Cnae from "../../models/cnae";
import protheusService from "../../services/protheus.service";
import formatter from "../../utils/formatter";
import NumberFormat from "react-number-format";
import SweetAlert from "sweetalert2";
import Loading from "../../components/loading";

const Manage: React.FC = () => {
  const isMountedRef = useRef(null);
  const { apollo, error } = useApollo();
  const locale = useSelector((state: ICombineReducers) => state.State.locale);
  const history = useHistory();
  const intl = useIntl();
  const { register, handleSubmit, errors } = useForm();
  const [modal, setModal] = useState<boolean>(false);

  const [start, setStart] = useState<Date>();
  const [end, setEnd] = useState<Date>();
  const [disableDates, setDisableDates] = useState<boolean>(false);
  const [allDay, setAllDay] = useState<boolean>(false);
  const [clientId, setClientId] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [disableButton, setDisableButton] = useState<boolean>(false);

  const { id } = useParams<CrudParam>();
  const [loading, setLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(1);
  const [store, setStore] = useState<string>("01");
  const [companyName, setCompanyName] = useState<string>("");
  const [fantasyName, setFantasyName] = useState<string>("");
  const [personKind, setPersonKind] = useState<string>("");
  const [documento, setDocument] = useState<string>("");
  const [clientKind, setClientKind] = useState<string>("");
  const [sr, setSr] = useState<string>("");
  const [mr, setMr] = useState<string>("");
  const [protheusId, setProtheusId] = useState<string>("");
  const [idCard, setIdcard] = useState<string>("");
  const [areaCodeId, setAreaCodeId] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [site, setSite] = useState<string>("");
  const [taxGroup, setTaxGroup] = useState<string>("");
  const [billingEmail, setBillingEmail] = useState<string>("");
  const [foundationDate, setFoudationDate] = useState<Date>(null);
  const [howKnowUs, setHowKnowUs] = useState<string>("");
  const [contact, setContact] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [sellerId, setSellerId] = useState<string>("");
  const [hunterId, setHunterId] = useState<string>("");
  const [isActivated, setIsActivated] = useState<boolean>(false);
  const [address, setAddress] = useState<string>("");
  const [addressNumber, setAddressNumber] = useState<string>("");
  const [addressComplement, setAddressComplement] = useState<string>("");
  const [addressPostcode, setAddressPostcode] = useState<string>("");
  const [addressNeighborhood, setAddressNeighborhood] = useState<string>("");
  const [addressStateId, setAddressStateId] = useState<string>("");
  const [addressCityId, setAddressCityId] = useState<string>("");
  const [billingAddress, setBillingAddress] = useState<string>("");
  const [billingAddressNumber, setBillingAddressNumber] = useState<string>("");
  const [billingAddressComplement, setBillingAddressComplement] = useState<string>("");
  const [billingAddressPostcode, setBillingAddressPostcode] = useState<string>("");
  const [billingAddressNeighborhood, setBillingAddressNeighborhood] = useState<string>("");
  const [billingAddressStateId, setBillingAddressStateId] = useState<string>("");
  const [billingAddressCityId, setBillingAddressCityId] = useState<string>("");
  const [deliveryAddress, setDeliveryAddress] = useState<string>("");
  const [deliveryAddressNumber, setDeliveryAddressNumber] = useState<string>("");
  const [deliveryAddressComplement, setDeliveryAddressComplement] = useState<string>("");
  const [deliveryAddressPostcode, setDeliveryAddressPostcode] = useState<string>("");
  const [deliveryAddressNeighborhood, setDeliveryAddressNeighborhood] = useState<string>("");
  const [deliveryAddressStateId, setDeliveryAddressStateId] = useState<string>("");
  const [deliveryAddressCityId, setDeliveryAddressCityId] = useState<string>("");
  const [discount, setDiscount] = useState<number>(0);
  const [cnaeId, setCnaeId] = useState<string>("");
  const [merchandiseDestination, setMerchandiseDestination] = useState<string>("");
  const [isTaxPayerSt, setIsTaxPayerSt] = useState<boolean>(false);
  const [isTaxPayerIcms, setIsTaxPayerIcms] = useState<boolean>(false);
  const [isNationalSimple, setIsNationalSimple] = useState<boolean>(false);
  const [companyOrServiceSize, setCompanyOrServiceSize] = useState<string>("");
  const [suframa, setSuframa] = useState<string>("");

  const [creditScore, setCreditScore] = useState<number>(0);
  const [creditLimit, setCreditLimit] = useState<number>(0);
  const [unpaidOrders, setUnpaidOrders] = useState<number>(0);
  const [creditLimitExpires, setCreditLimitExpires] = useState<Date>(null);
  const [latestOrder, setLatestOrder] = useState<Date>(null);
  const [dangerLevel, setDangerLevel] = useState<string>("");

  const [states, setStates] = useState<State[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [billingCities, setBillingCities] = useState<City[]>([]);
  const [deliveryCities, setDeliveryCities] = useState<City[]>([]);
  const [areaCodes, setAreaCodes] = useState<StateAreaCode[]>([]);
  const [cnaes, setCnaes] = useState<Cnae[]>([]);
  const [preview, setPreview] = useState<boolean>(() => { if (id) return true; else return false; });
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

  const toggle = () => setModal(!modal);

  const clientDanger = [
    "A", "B", "C", "D", "E", "Z",
  ]

  const { hasScope } = useAuth();
  const [canView] = useState<boolean>(() => {
    return hasScope("Clients:Client");
  });
  const [canCreate] = useState<boolean>(() => {
    return hasScope("Clients:Create");
  });
  const [canUpdate] = useState<boolean>(() => {
    return hasScope("Clients:Update");
  });

  const handleCities = useCallback(async (id: string) => {
    setAddressStateId(id);
    await apollo.query({
      query: gql`
        query stateCities($id: String!) {
          stateCities(id: $id) {
            id
            name
            code
            state {
              id
              name
              fc
            }
          }
        }
      `,
      variables: {
        id
      }
    })
      .then(res => {
        setCities(res.data.stateCities);
      })
      .catch(err => error(err));
  }, [apollo, error]);

  const handleBillingCities = useCallback(
    async (id: string) => {
      setBillingAddressStateId(id);
      await apollo.query({
        query: gql`
          query stateCities($id: String!) {
            stateCities(id: $id) {
              id
              name
              code
              state {
                id
                name
                fc
              }
            }
          }
        `,
        variables: {
          id
        },
      })
        .then(res => {
          setBillingCities(res.data.stateCities);
        })
        .catch(err => error(err));
    },
    [apollo, error]
  );

  const handleDeliveryCities = useCallback(
    async (id: string) => {
      setDeliveryAddressStateId(id);
      await apollo.query({
        query: gql`
          query stateCities($id: String!) {
            stateCities(id: $id) {
              id
              name
              code
              state {
                id
                name
                fc
              }
            }
          }
        `,
        variables: {
          id
        },
      })
        .then(res => {
          setDeliveryCities(res.data.stateCities);
        })
        .catch(err => error(err));
    },
    [apollo, error]
  );

  const formatDocument = (document: string): string => {
    document = document.trim().replace(/\D/gm, "");

    // Formata o documento do usuário
    if (document.length === 11) {
      document = formatter.formatCpf(document);
    } else if (document.length === 14) {
      document = formatter.formatCnpj(document);
    }
    return document;
  }

  const handleData = useCallback(async () => {
    if (id) {
      if (preview && !canView) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/clients");
        return;
      } else if (!preview && !canUpdate) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/clients");
        return;
      }
      setLoading(true);
      await Promise.all([
        apollo.query({
          query: gql`
            query countryStates($iso: String!) {
              countryStates(iso: $iso) {
                id
                name
                code
                fc
              }
            }
          `,
          variables: {
            iso: "BR"
          },
        }),
        apollo.query({
          query: gql`
            query stateAreaCodesDropdown {
              stateAreaCodesDropdown {
                id
                code
                protheusId
                state {
                  id
                  name
                  fc
                }
              }
            }
          `,
        }),
        apollo.query({
          query: gql`
            query client ($id: String!) {
              client (id: $id) {
                id
                store
                companyName
                fantasyName
                clientKind
                personKind
                taxGroup
                document
                sr
                mr
                idCard
                phone
                mobile
                email
                site
                billingEmail
                howKnowUs
                contact
                foundationDate
                notes
                cnae {
                  id
                  code
                  description
                }
                sellers {
                  id
                  name
                  surname
                }
                hunters {
                  id
                  name
                  surname
                }
                merchandiseDestination
                isTaxPayerSt
                isTaxPayerIcms
                isNationalSimple
                companyOrServiceSize
                suframa
                creditScore
                creditLimit
                creditLimitExpires
                unpaidOrders
                dangerLevel
                latestOrder
                discount
                sellerId
                hunterId
                address
                addressNumber
                addressComplement
                addressNeighborhood
                addressPostcode
                billingAddress
                billingAddressNumber
                billingAddressComplement
                billingAddressNeighborhood
                billingAddressPostcode
                deliveryAddress
                deliveryAddressNumber
                deliveryAddressComplement
                deliveryAddressNeighborhood
                deliveryAddressPostcode
                protheusId
                isActivated
                addressCountry {
                  id
                  name
                }
                addressState {
                  id
                  name
                }
                addressCity {
                  id
                  name
                  state {
                    id
                    name
                  }
                }
                billingAddressCountry {
                  id
                  name
                }
                billingAddressState {
                  id
                  name
                }
                billingAddressCity {
                  id
                  name
                  state {
                    id
                    name
                  }
                }
                deliveryAddressCountry {
                  id
                  name
                }
                deliveryAddressState {
                  id
                  name
                }
                deliveryAddressCity {
                  id
                  name
                  state {
                    id
                    name
                  }
                }
                areaCode {
                  id
                  code
                }
              }
            }
          `,
          variables: {
            id
          },
        }),
        apollo.query({
          query: gql`
            query cnaesDropdown {
              cnaesDropdown {
                id
                code
                description
              }
            }
          `,
        }),
      ])
        .then(res => {
          if (isMountedRef.current) {
            if (res[0].data.countryStates)
              setStates(res[0].data.countryStates);
            if (res[1].data.stateAreaCodesDropdown)
              setAreaCodes(res[1].data.stateAreaCodesDropdown);
            if (res[2].data.client) {
              setAreaCodeId(res[2].data.client.areaCode?.id || "");
              setStore(res[2].data.client.store || "");
              setProtheusId(res[2].data.client.protheusId || "");
              setClientKind(res[2].data.client.clientKind || "");
              setPersonKind(res[2].data.client.personKind || "");
              setSellerId(res[2].data.client.sellers?.length ? res[2].data.client.sellers.map(seller => `${seller.name} ${seller.surname}`).join(", ") : "");
              setHunterId(res[2].data.client.hunters?.length ? res[2].data.client.hunters.map(hunter => `${hunter.name} ${hunter.surname}`).join(", ") : "");
              setDocument(res[2].data.client.document || "");
              setSr(res[2].data.client.sr || "ISENTO");
              setMr(res[2].data.client.mr || "");
              setIdcard(res[2].data.client.idCard || "");
              setPhone(res[2].data.client.phone || "");
              setMobile(res[2].data.client.mobile || "");
              setEmail(res[2].data.client.email || "");
              setSite(res[2].data.client.site || "");
              setTaxGroup(res[2].data.client.taxGroup || "");
              setBillingEmail(res[2].data.client.billingEmail || "");
              if (res[2].data.client.foundationDate)
                setFoudationDate(convert_to_date(res[2].data.client.foundationDate));
              setHowKnowUs(res[2].data.client.howKnowUs || "");
              setContact(res[2].data.client.contact || "");
              setNotes(res[2].data.client.notes || "");
              setDiscount(res[2].data.client.discount || 0);
              setCreditScore(res[2].data.client.creditScore || 0);
              setCreditLimit(res[2].data.client.creditLimit || 0);
              setUnpaidOrders(res[2].data.client.unpaidOrders || 0);
              setDangerLevel(res[2].data.client.dangerLevel);
              if (res[2].data.client.creditLimitExpires)
                setCreditLimitExpires(convert_to_date(res[2].data.client.creditLimitExpires));
              if (res[2].data.client.latestOrder)
                setLatestOrder(convert_to_date(res[2].data.client.latestOrder));
              setCnaeId(res[2].data.client.cnae?.id || "");
              setMerchandiseDestination(res[2].data.client.merchandiseDestination || "");
              setIsActivated(res[2].data.client.isActivated || false);
              setIsTaxPayerSt(res[2].data.client.isTaxPayerSt || false);
              setIsTaxPayerIcms(res[2].data.client.isTaxPayerIcms || false);
              setIsNationalSimple(res[2].data.client.isNationalSimple || false);
              setCompanyOrServiceSize(res[2].data.client.companyOrServiceSize || "");
              setSuframa(res[2].data.client.suframa || "");
              setCompanyName(res[2].data.client.companyName || "");
              setFantasyName(res[2].data.client.fantasyName || "");
              setAddress(res[2].data.client.address || "");
              setAddressNumber(res[2].data.client.addressNumber || "");
              setAddressComplement(res[2].data.client.addressComplement || "");
              setAddressNeighborhood(res[2].data.client.addressNeighborhood || "");
              setAddressPostcode(res[2].data.client.addressPostcode || "");
              setAddressStateId(res[2].data.client.addressCity?.state?.id || "");
              setAddressCityId(res[2].data.client.addressCity?.id || "");
              setBillingAddress(res[2].data.client.billingAddress || "");
              setBillingAddressNumber(res[2].data.client.billingAddressNumber || "");
              setBillingAddressComplement(res[2].data.client.billingAddressComplement || "");
              setBillingAddressNeighborhood(res[2].data.client.billingAddressNeighborhood || "");
              setBillingAddressPostcode(res[2].data.client.billingAddressPostcode || "");
              setBillingAddressStateId(res[2].data.client.billingAddressState?.id || "");
              setBillingAddressCityId(res[2].data.client.billingAddressCity?.id || "");
              setDeliveryAddress(res[2].data.client.deliveryAddress || "");
              setDeliveryAddressNumber(res[2].data.client.deliveryAddressNumber || "");
              setDeliveryAddressComplement(res[2].data.client.deliveryAddressComplement || "");
              setDeliveryAddressNeighborhood(res[2].data.client.deliveryAddressNeighborhood || "");
              setDeliveryAddressPostcode(res[2].data.client.deliveryAddressPostcode || "");
              setDeliveryAddressStateId(res[2].data.client.deliveryAddressState?.id || "");
              setDeliveryAddressCityId(res[2].data.client.deliveryAddressCity?.id || "");
              if (res[2].data.client.addressState?.id)
                handleCities(res[2].data.client.addressState?.id);
              if (res[2].data.client.billingAddressState?.id)
                handleBillingCities(res[2].data.client.billingAddressState?.id);
              if (res[2].data.client.deliveryAddressState?.id)
                handleDeliveryCities(res[2].data.client.deliveryAddressState?.id);
            }
            if (res[3].data.cnaesDropdown)
              setCnaes(res[3].data.cnaesDropdown);
          }
        })
        .catch(err => error(err));
      setLoading(false);
    } else {
      if (!preview && !canCreate) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/clients");
        return;
      }
      setLoading(true);
      await Promise.all([
        apollo.query({
          query: gql`
            query countryStates($iso: String!) {
              countryStates(iso: $iso) {
                id
                name
                code
                fc
              }
            }
          `,
          variables: {
            iso: "BR"
          },
        }),
        apollo.query({
          query: gql`
            query stateAreaCodesDropdown {
              stateAreaCodesDropdown {
                id
                code
                protheusId
                state {
                  id
                  name
                  fc
                }
              }
            }
          `,
        }),
        apollo.query({
          query: gql`
            query cnaesDropdown {
              cnaesDropdown {
                id
                code
                description
              }
            }
          `,
        }),
      ])
        .then(res => {
          if (isMountedRef.current) {
            if (res[0].data.countryStates)
              setStates(res[0].data.countryStates);
            if (res[1].data.stateAreaCodesDropdown)
              setAreaCodes(res[1].data.stateAreaCodesDropdown);
            if (res[2].data.cnaesDropdown)
              setCnaes(res[2].data.cnaesDropdown);
          }
        })
        .catch(err => error(err));
      setLoading(false);
    }
  }, [intl, id, preview, apollo, error, handleCities, handleBillingCities, handleDeliveryCities, history, canView, canCreate, canUpdate,]);

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: `pages.clients.${preview ? "preview" : id ? "update" : "create"}`,
      })}`;
    if (locale === "pt") {
      registerLocale("pt-BR", pt_BR);
      setDefaultLocale("pt-BR");
    }
    handleData();
    return () => { isMountedRef.current = false; }
  }, [intl, id, preview, locale, handleData]);

  const handleSubmitForm = async (data: any) => {
    if (data !== '') {
      setDisableSubmit(true);
      const form = {
        store,
        protheusId,
        personKind,
        clientKind,
        document: documento,
        mr,
        sr,
        idCard,
        areaCodeId,
        phone,
        mobile,
        email,
        site,
        billingEmail,
        howKnowUs,
        contact,
        notes,
        discount: (discount <= 100 ? discount : 0),
        cnaeId,
        sellerId,
        hunterId,
        taxGroup,
        merchandiseDestination,
        isTaxPayerSt,
        isTaxPayerIcms,
        isNationalSimple,
        companyOrServiceSize,
        suframa,
        creditScore,
        creditLimit,
        creditLimitExpires,
        unpaidOrders,
        dangerLevel,
        latestOrder,
        companyName,
        fantasyName,
        address,
        addressNumber,
        addressComplement,
        addressNeighborhood,
        addressPostcode,
        addressStateId,
        addressCityId,
        billingAddress,
        billingAddressNumber,
        billingAddressComplement,
        billingAddressNeighborhood,
        billingAddressPostcode,
        billingAddressStateId,
        billingAddressCityId,
        deliveryAddress,
        deliveryAddressNumber,
        deliveryAddressComplement,
        deliveryAddressNeighborhood,
        deliveryAddressPostcode,
        deliveryAddressStateId,
        deliveryAddressCityId,
        isActivated,
        foundationDate,
      };

      if (!id) {
        await apollo.mutate({
          mutation: gql`
            mutation createClient($data: ClientInput!) {
              createClient(data: $data) {
                  id
              }
            }
          `,
          variables: {
            data: form
          },
        })
          .then(res => {
            toast.success(intl.formatMessage({ id: "flash.success.created" }));
            history.push(`/clients/manage/${res.data.createClient.id}`);
          })
          .catch(err => error(err));
      } else {
        await apollo.mutate({
          mutation: gql`
            mutation updateClient($id: String!, $data: ClientInput!) {
              updateClient(id: $id, data: $data) {
                  id
              }
            }
          `,
          variables: {
            id,
            data: form
          },
        })
          .then(res => {
            toast.success(intl.formatMessage({ id: "flash.success.updated" }));
            history.push(`/clients`);
          })
          .catch(err => error(err));
      }
      setDisableSubmit(false);
    } else {
      errors.showMessages();
    }
  };

  const handleSintegraApi = async (document: string) => {
    document = document.trim().replace(/\D/gm, "");
    if (document.length === 14) {
      await protheusService.get(`sintegra/${document}`)
        .then(async (res) => {
          let cnae: any = null;
          const cnaeCode = res.data?.atividade_principal[0]?.code?.replace(/\D/gm, "");
          const cnaeIndex = cnaes?.findIndex(e => e.code === cnaeCode);
          if (cnaeIndex !== -1)
            cnae = cnaes[cnaeIndex];

          if (cnae)
            setCnaeId(cnae.id);
          setAddressNeighborhood(res.data?.bairro || "");
          setAddressPostcode(res.data?.cep?.replace(/\D/gm, "") || "");
          setAddressComplement(res.data?.complemento || "");
          setAddress(res.data?.logradouro || "");
          setAddressNumber(res.data?.numero || "");
          if (res.data?.abertura) {
            const d = res.data.abertura;
            let dt = `${d.substr(6, 4)}-${d.substr(3, 2)}-${d.substr(0, 2)}`;
            setFoudationDate(convert_to_date(dt));
          }
          setFantasyName(res.data?.fantasia || "");
          setCompanyName(res.data?.nome || "");
          setEmail(res.data?.email || "");
          setPhone(res.data?.telefone || "");
          if (res.data?.stateId) {
            setAddressStateId(res.data.stateId);
            await handleCities(res.data.stateId);
            if (res.data?.cityId)
              setAddressCityId(res.data.cityId);
          }
          setPersonKind("J");
          setIsActivated(res.data.situacao === "ATIVA")
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  const handleCorreiosApi = async (postcode: string, addressType: "common" | "billing" | "delivery" = "common") => {
    postcode = postcode.trim().replace(/\D/gm, "");
    if (postcode.length === 8) {
      await correios.get(`${postcode}/json/`)
        .then(async (res) => {
          if (res.data) {
            if (addressType === "common") {
              if (res.data.bairro)
                setAddressNeighborhood(res.data.bairro);
              if (res.data.bairro)
                setAddressNeighborhood(res.data.bairro);
              if (res.data.complemento)
                setAddressComplement(res.data.complemento);
              if (res.data.logradouro)
                setAddress(res.data.logradouro);
              if (res.data.uf && states.length) {
                const state = states.filter(e => e.fc === res.data.uf).shift();
                if (state) {
                  setAddressStateId(state.id);
                  await handleCities(state.id);
                  if (cities.length && res.data.ibge.length === 7) {
                    const city = cities.filter(e => e.code === res.data.ibge.toString().substr(2, 5)).shift();
                    if (city)
                      setAddressCityId(city.id);
                  }
                }
              }
            } else if (addressType === "billing") {
              if (res.data.bairro)
                setBillingAddressNeighborhood(res.data.bairro);
              if (res.data.bairro)
                setBillingAddressNeighborhood(res.data.bairro);
              if (res.data.complemento)
                setBillingAddressComplement(res.data.complemento);
              if (res.data.logradouro)
                setBillingAddress(res.data.logradouro);
              if (res.data.uf && states.length) {
                const state = states.filter(e => e.fc === res.data.uf).shift();
                if (state) {
                  setBillingAddressStateId(state.id);
                  await handleBillingCities(state.id);
                  if (cities.length && res.data.ibge.length === 7) {
                    const city = cities.filter(e => e.code === res.data.ibge.toString().substr(2, 5)).shift();
                    if (city)
                      setBillingAddressCityId(city.id);
                  }
                }
              }
            } else if (addressType === "delivery") {
              if (res.data.bairro)
                setDeliveryAddressNeighborhood(res.data.bairro);
              if (res.data.bairro)
                setDeliveryAddressNeighborhood(res.data.bairro);
              if (res.data.complemento)
                setDeliveryAddressComplement(res.data.complemento);
              if (res.data.logradouro)
                setDeliveryAddress(res.data.logradouro);
              if (res.data.uf && states.length) {
                const state = states.filter(e => e.fc === res.data.uf).shift();
                if (state) {
                  setDeliveryAddressStateId(state.id);
                  await handleDeliveryCities(state.id);
                  if (cities.length && res.data.ibge.length === 7) {
                    const city = cities.filter(e => e.code === res.data.ibge.toString().substr(2, 5)).shift();
                    if (city)
                      setDeliveryAddressCityId(city.id);
                  }
                }
              }
            }
          }
        })
        .catch(err => {
          console.log(err);
          toast.error(err.message);
        });
    }
  }

  const syncProtheus = async () => {
    SweetAlert.fire({
      title: intl.formatMessage({ id: "general.confirmation" }),
      text: "Tem certeza que deseja sincronizar este cadastro com o Protheus? Este processo pode demorar um pouco.",
      icon: "question",
      cancelButtonText: intl.formatMessage({ id: "general.no" }),
      confirmButtonText: intl.formatMessage({ id: "general.yes" }),
      reverseButtons: true,
      showCancelButton: true,
    })
      .then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          let city: City = null;
          const cityIndex = cities?.findIndex(e => e.id === addressCityId);
          if (cityIndex !== -1)
            city = cities[cityIndex];

          let areaCode: StateAreaCode = null;
          const areaCodeIndex = areaCodes?.findIndex(e => e.id === areaCodeId);
          if (areaCodeIndex !== -1)
            areaCode = areaCodes[areaCodeIndex];

          const data = {
            codigo: protheusId || "",
            loja: store || "01",
            razaoSocial: companyName?.trim() || "",
            nomeFantasia: fantasyName?.trim() || "",
            tipoPessoa: personKind || "",
            tipoCliente: clientKind || "",
            cnpjCpf: documento?.trim().replace(/\D/g, "") || "",
            bairro: addressNeighborhood?.trim() || "S/B",
            cep: addressPostcode?.trim().replace(/\D/gm, "") || "",
            endereco: (`${address?.trim()}${addressNumber?.trim() ? ", " + addressNumber?.trim() : ""}`).trim() || "",
            municipio: city ? city.name : "",
            codmun: city ? city.code : "",
            uf: city ? city?.state?.fc : "",
            ddd: areaCode ? areaCode.code : "",
            tel: phone?.trim() || "",
            pais: "105",
            contato: contact?.trim() || "",
            inscricao: sr?.trim() || "ISENTO",
            email: email?.trim() || "",
            codpais: "01058",
            inciss: isTaxPayerSt ? "S" : "N",
            grupovendas: "000001",
            simples: isNationalSimple ? "1" : "2",
            contribuinte: isTaxPayerIcms ? "1" : "2",
            ecompra: billingEmail?.trim() || "",
            cel: mobile?.trim() || "",
            pfisica: personKind || "",
            inscrm: mr?.trim() || "",
            dtnasc: foundationDate ? date(foundationDate).format(DATE_FORMAT_BR) : "",
            hpage: site?.trim() || "",
            msblql: isActivated ? "2" : "1",
            grptrib: taxGroup?.trim() || "",
            suframa: suframa?.trim() || "",
            regiao: areaCode ? areaCode.protheusId.trim() : "",
            prfobs: notes?.trim() || "",
            lc: creditLimit || 1.00,
            venclc: creditLimitExpires ? date(creditLimitExpires).format(DATE_FORMAT_BR) : "",
          };

          if (!data.cnpjCpf) {
            toast.error("Por favor, informe o CPF/CNPJ.");
            return;
          }
          if (!data.razaoSocial) {
            toast.error("Por favor, informe a razão social.");
            return;
          }
          if (!data.nomeFantasia) {
            toast.error("Por favor, informe o nome fantasia.");
            return;
          }
          if (!data.tipoPessoa) {
            toast.error("Por favor, selecione o tipo pessoa.");
            return;
          }
          if (!data.tipoCliente) {
            toast.error("Por favor, selecione o tipo cliente.");
            return;
          }
          if (!data.cep) {
            toast.error("Por favor, selecione o CEP.");
            return;
          }
          if (!data.endereco) {
            toast.error("Por favor, selecione o endereço.");
            return;
          }
          if (!data.municipio) {
            toast.error("Por favor, selecione o município.");
            return;
          }
          if (!data.codmun) {
            toast.error("Por favor, verifique o município selecionado. Não foi encontrado código IBGE do mesmo, provavelmente se trata de distrito de outro município.");
            return;
          }
          if (!data.uf) {
            toast.error("Por favor, selecione o estado.");
            return;
          }

          setDisableSubmit(true);
          console.log(JSON.stringify(data));

          await protheusService.request({
            url: `protheus/clients/${id}`,
            method: protheusId.trim() ? "PUT" : "POST",
            data
          })
            .then(res => {
              setProtheusId(res.data.protheusId);
              SweetAlert.fire({
                title: "Sucesso!",
                text: "Cliente sincronizado com sucesso.",
                icon: "success",
              });
            })
            .catch(err => {
              console.log(err?.response?.data?.message || err);
              SweetAlert.fire({
                title: "Erro!",
                html: err?.response?.data?.message ? `<pre style="background-color: #fff; padding: 0; margin: 0; font-size: 16px; font-family: Rubik, sans-serif; color: #2b2b2b; text-align: left;">${err?.response?.data?.message}</pre>` : "Ocorreu um erro ao tentar sincronizar o cliente com o Protheus.",
                icon: "error",
              });
            });
          setDisableSubmit(false);
        }
      });
  };

  const handleSchedule = useCallback(async () => {
    if (id) {
      setClientId(id);
      setModal(!modal);
    }
  }, [id, modal]);

  const handleSubmitScheduleForm = useCallback(async () => {
    setDisableButton(true);
    const data = {
      title,
      start,
      end,
      allDay,
      clientId,
    };
    if (id) {
      await apollo.mutate({
        mutation: gql`
            mutation createCalendar($data: CalendarInput!) {
              createCalendar(data: $data) {
                id
                createdAt
                updatedAt
                title
                allDay
                start
                end
                client {
                  id
                  document
                  companyName
                  fantasyName
                  protheusId
                }
                user {
                  id
                  name
                  surname
                  protheusId
                }
              }
            }
          `,
        variables: {
          data
        },
      })
        .then(res => {
          if (isMountedRef.current) {
            const startDate = convert_to_date(res.data.createCalendar.start);
            const endDate = convert_to_date(res.data.createCalendar.end);
            const createdAt = convert_to_date(res.data.createCalendar.createdAt);
            const updatedAt = convert_to_date(res.data.createCalendar.updatedAt);
            res.data.createCalendar.start = startDate;
            res.data.createCalendar.end = endDate;
            res.data.createCalendar.createdAt = createdAt;
            res.data.createCalendar.updatedAt = updatedAt;
            toast.success("Agendamento criado com sucesso!");
            setModal(!modal);
          }
        })
        .catch(err => error(err));
    }
    setDisableButton(false);
  }, [allDay, apollo, clientId, end, error, id, start, title, modal]);

  return (
    <Fragment>
      <Breadcrumb
        parent="pages.clients.clients"
        title={`pages.clients.${preview ? "preview" : id ? "update" : "create"
          }`}
      />
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}><FormattedMessage id="pages.calendars.create" /></ModalHeader>
        <Form onSubmit={async (e) => {
          e.preventDefault();
          await handleSubmitScheduleForm();
        }}>
          <ModalBody>
            <Container fluid={true}>
              <Row>
                <Col>
                  <FormGroup className="form-row">
                    <Label className="col-form-label">
                      <FormattedMessage id="inputs.startDate" />
                    </Label>
                    <div className="datepicker-time">
                      <DatePicker
                        placeholderText={intl.formatMessage({
                          id: "inputs.startDate",
                        })}
                        className="form-control digits"
                        showPopperArrow={false}
                        selected={start}
                        disabled={disableDates}
                        onChange={(date: Date) => setStart(date)}
                        dateFormat="dd/MM/yyyy HH:mm"
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        autoComplete="off"
                        timeInputLabel="Hora"
                        required
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="form-row">
                    <Label className="col-form-label">
                      <FormattedMessage id="inputs.endDate" />
                    </Label>
                    <div className="datepicker-time">
                      <DatePicker
                        placeholderText={intl.formatMessage({
                          id: "inputs.endDate",
                        })}
                        className="form-control digits"
                        showPopperArrow={false}
                        selected={end}
                        disabled={disableDates}
                        onChange={(date: Date) => setEnd(date)}
                        dateFormat="dd/MM/yyyy HH:mm"
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        autoComplete="off"
                        timeInputLabel="Hora"
                        required
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label className="col-form-label">
                      <FormattedMessage id="inputs.title" />
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label className="col-form-label">
                      <FormattedMessage id="inputs.allDay" />?
                    </Label>
                    <Col className="checkbox">
                      <Input
                        key="allDay-input"
                        id="allDay"
                        type="checkbox"
                        checked={allDay}
                        value={1}
                        onChange={(e) => {
                          const _allDay = !allDay;
                          setAllDay(!allDay);
                          if (_allDay) {
                            setDisableDates(true);
                            const todayDate = date().format("YYYY-MM-DD");
                            const startDate = convert_to_date(`${todayDate} 00:00:00`);
                            const endDate = convert_to_date(`${todayDate} 23:59:59`);
                            setStart(startDate);
                            setEnd(endDate);
                          } else {
                            setDisableDates(false);
                          }
                        }}
                      />
                      <Label
                        key="allDay-label"
                        for="allDay"
                        className="d-block"
                      >Sim</Label>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" disabled={disableButton} color="primary">Salvar</Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>
                  <FormattedMessage
                    id={`pages.clients.${preview ? "preview" : id ? "update" : "create"
                      }`}
                  />
                </h5>
              </CardHeader>
              <CardBody className="tabbed-card">
                <div className="form-content">
                  <Form className="needs-validation" noValidate={false} onSubmit={handleSubmit(handleSubmitForm)}>
                    <Nav className="nav-pills nav-primary">
                      <NavItem>
                        <NavLink
                          className={activeTab === 1 ? "active" : ""}
                          onClick={() => setActiveTab(1)}
                        >
                          <i className="fa fa-file-text-o"></i>
                          <FormattedMessage id="tabs.data" />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={activeTab === 2 ? "active" : ""}
                          onClick={() => setActiveTab(2)}
                        >
                          <i className="fa fa-truck"></i>
                          <FormattedMessage id="tabs.addresses" />
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <Row className="m-b-30">
                      <Col>
                        <TabContent activeTab={activeTab.toString()}>
                          <TabPane tabId="1">
                            {loading ? <Loading /> : (
                              <React.Fragment>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.protheusId" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={protheusId}
                                        onChange={(e) => setProtheusId(e.target.value)}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label" htmlFor="personKind">
                                        <FormattedMessage id="inputs.personKind" />
                                      </Label>
                                      <Input
                                        type="select"
                                        name="personKind"
                                        className="form-control digits"
                                        value={personKind}
                                        disabled={preview}
                                        onChange={(e) =>
                                          setPersonKind(e.target.value)
                                        }
                                        innerRef={register({ required: true })}
                                      >
                                        <option value="">
                                          {intl.formatMessage({
                                            id: "inputs.selectAny",
                                          })}
                                        </option>
                                        <option value="F">
                                          {intl.formatMessage({
                                            id: "inputs.personKindOptions.f",
                                          })}
                                        </option>
                                        <option value="J">
                                          {intl.formatMessage({
                                            id: "inputs.personKindOptions.j",
                                          })}
                                        </option>
                                      </Input>
                                      <span>{errors.personKind && 'Tipo pessoa é obrigatório.'}</span>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.clientKind" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="select"
                                        value={clientKind}
                                        onChange={(e) =>
                                          setClientKind(e.target.value)
                                        }
                                        disabled={preview}
                                      >
                                        <option value="">
                                          {intl.formatMessage({
                                            id: "inputs.selectAny",
                                          })}
                                        </option>
                                        <option value="F">F - Consumidor final</option>
                                        <option value="L">L - Produtor rural</option>
                                        <option value="R">R - Revendedor</option>
                                        <option value="S">S - Solidário</option>
                                        <option value="X">X - Exportação</option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.store" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={store}
                                        onChange={(e) =>
                                          setStore(e.target.value)
                                        }
                                        disabled={preview}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.cpfCnpj" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={documento?.trim() ? formatDocument(documento) : ""}
                                        disabled={preview}
                                        maxLength={18}
                                        onChange={async (e) => {
                                          let value = e.target.value;
                                          value = value.trim().replace(/\D/gm, "");
                                          setDocument(formatDocument(value));
                                          if (value.length === 14)
                                            await handleSintegraApi(value);
                                        }}
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.sr" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={sr || "ISENTO"}
                                        disabled={preview}
                                        onChange={(e) =>
                                          setSr(e.target.value)
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.mr" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={mr}
                                        disabled={preview}
                                        onChange={(e) =>
                                          setMr(e.target.value)
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.idCard" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={idCard}
                                        disabled={preview}
                                        onChange={(e) => setIdcard(e.target.value)}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.companyName" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={companyName}
                                        disabled={preview}
                                        onChange={(e) =>
                                          setCompanyName(e.target.value)
                                        }
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.fantasyName" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={fantasyName}
                                        disabled={preview}
                                        onChange={(e) =>
                                          setFantasyName(e.target.value)
                                        }
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.contact" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={contact}
                                        disabled={preview}
                                        onChange={(e) => setContact(e.target.value)}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.foundationDate" />
                                      </Label>
                                      <DatePicker
                                        className="form-control"
                                        selected={foundationDate}
                                        onChange={(date: Date) => {
                                          setFoudationDate(date);
                                        }}
                                        dateFormat="dd/MM/yyyy"
                                        disabled={preview}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        Código de área
                                      </Label>
                                      <Input
                                        type="select"
                                        className="form-control digits"
                                        value={areaCodeId?.toString()}
                                        disabled={preview}
                                        onChange={(e) =>
                                          setAreaCodeId(e.target.value)
                                        }
                                      >
                                        <option value="">-</option>
                                        {areaCodes?.map((areaCode) => {
                                          return (
                                            <option
                                              value={areaCode.id}
                                              key={`areaCode-${areaCode.id}`}
                                            >
                                              {areaCode.code} - {areaCode.state.name} ({areaCode.state.fc})
                                            </option>
                                          );
                                        })}
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.phone" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={phone}
                                        disabled={preview}
                                        onChange={(e) =>
                                          setPhone(e.target.value)
                                        }
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.mobile" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={mobile}
                                        disabled={preview}
                                        onChange={(e) =>
                                          setMobile(e.target.value)
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.suframa" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={suframa}
                                        disabled={preview}
                                        onChange={(e) => setSuframa(e.target.value)}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.email" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={email}
                                        disabled={preview}
                                        onChange={(e) => setEmail(e.target.value)}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.site" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={site}
                                        disabled={preview}
                                        onChange={(e) => setSite(e.target.value)}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.billingEmail" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={billingEmail}
                                        disabled={preview}
                                        onChange={(e) =>
                                          setBillingEmail(e.target.value)
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.howKnowUs" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={howKnowUs}
                                        disabled={preview}
                                        onChange={(e) =>
                                          setHowKnowUs(e.target.value)
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.taxGroup" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={taxGroup}
                                        disabled={preview}
                                        onChange={(e) => setTaxGroup(e.target.value)}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.latestOrder" />
                                      </Label>
                                      <DatePicker
                                        className="form-control"
                                        selected={latestOrder || null}
                                        onChange={(date: Date) => {
                                          setLatestOrder(date);
                                        }}
                                        dateFormat="dd/MM/yyyy"
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.sellerId" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={sellerId}
                                        disabled
                                        onChange={(e) => setSellerId(e.target.value)}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.hunterId" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={hunterId}
                                        disabled
                                        onChange={(e) => setHunterId(e.target.value)}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.notes" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={notes}
                                        disabled={preview}
                                        onChange={(e) => setNotes(e.target.value)}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.creditScore" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="number"
                                        min={0}
                                        value={creditScore || 0}
                                        onChange={(e) => {
                                          let value = Number(e.target.value);
                                          if (isNaN(value))
                                            value = 0;
                                          setCreditScore(value)
                                        }}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.creditLimit" />
                                      </Label>
                                      <NumberFormat
                                        className="form-control"
                                        value={creditLimit || 0}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale
                                        allowNegative={false}
                                        thousandsGroupStyle="thousand"
                                        onValueChange={async ({ floatValue }) => {
                                          setCreditLimit(floatValue);
                                        }}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.creditLimitExpires" />
                                      </Label>
                                      <DatePicker
                                        className="form-control"
                                        selected={creditLimitExpires || null}
                                        onChange={(date: Date) => {
                                          setCreditLimitExpires(date);
                                        }}
                                        dateFormat="dd/MM/yyyy"
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.unpaidOrders" />
                                      </Label>
                                      <NumberFormat
                                        className="form-control"
                                        value={unpaidOrders || 0}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale
                                        allowNegative={false}
                                        thousandsGroupStyle="thousand"
                                        onValueChange={async ({ floatValue }) => {
                                          setUnpaidOrders(floatValue);
                                        }}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.dangerLevel" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="select"
                                        value={dangerLevel || ""}
                                        onChange={(e) => setDangerLevel(e.target.value)}
                                        disabled
                                      >
                                        <option value="">-</option>
                                        {clientDanger?.map(level => (<option key={level} value={level}>{level}</option>))}
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.merchandiseDestination" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="select"
                                        value={merchandiseDestination}
                                        disabled={preview}
                                        onChange={(e) => setMerchandiseDestination(e.target.value)}
                                      >
                                        <option value="">-</option>
                                        <option value="Revenda">Revenda</option>
                                        <option value="Consumo próprio">Consumo próprio</option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.economicActivity" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="select"
                                        value={cnaeId}
                                        disabled={preview}
                                        onChange={(e) => setCnaeId(e.target.value)}
                                      >
                                        <option value="">-</option>
                                        {cnaes?.map(cnae => <option key={cnae.id} value={cnae.id}>{cnae.code.substr(0, 4)}-{cnae.code.substr(4, 1)}/{cnae.code.substr(5, 2)} - {cnae.description}</option>)}
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.isTaxPayerSt" />?
                                      </Label>
                                      <Col className="checkbox">
                                        <Input
                                          key="isTaxPayerSt-input"
                                          id="isTaxPayerSt"
                                          type="checkbox"
                                          value={isTaxPayerSt ? 1 : 0}
                                          checked={isTaxPayerSt}
                                          disabled={preview}
                                          onChange={(e) => setIsTaxPayerSt(!isTaxPayerSt)}
                                        />
                                        <Label
                                          key="isTaxPayerSt-label"
                                          for="isTaxPayerSt"
                                          className="d-block"
                                        >Sim</Label>
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.isTaxPayerIcms" />?
                                      </Label>
                                      <Col className="checkbox">
                                        <Input
                                          key="isTaxPayerIcms-input"
                                          id="isTaxPayerIcms"
                                          type="checkbox"
                                          value={isTaxPayerIcms ? 1 : 0}
                                          checked={isTaxPayerIcms}
                                          disabled={preview}
                                          onChange={(e) => setIsTaxPayerIcms(!isTaxPayerIcms)}
                                        />
                                        <Label
                                          key="isTaxPayerIcms-label"
                                          for="isTaxPayerIcms"
                                          className="d-block"
                                        >Sim</Label>
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.isNationalSimple" />?
                                      </Label>
                                      <Col className="checkbox">
                                        <Input
                                          key="isNationalSimple-input"
                                          id="isNationalSimple"
                                          type="checkbox"
                                          value={isNationalSimple ? 1 : 0}
                                          checked={isNationalSimple}
                                          disabled={preview}
                                          onChange={(e) => setIsNationalSimple(!isNationalSimple)}
                                        />
                                        <Label
                                          key="isNationalSimple-label"
                                          for="isNationalSimple"
                                          className="d-block"
                                        >Sim</Label>
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.companyOrServiceSize" />
                                      </Label>
                                      <Col className="m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                        <div className="radio radio-primary">
                                          <Input id="small" type="radio" name="companyOrServiceSize" disabled={preview} value="Pequeno" onChange={(e) => setCompanyOrServiceSize(e.target.value)} checked={companyOrServiceSize === "Pequeno"} />
                                          <Label className="mb-0" for="small">Pequeno</Label>
                                        </div>
                                        <div className="radio radio-primary">
                                          <Input id="middle" type="radio" name="companyOrServiceSize" disabled={preview} value="Médio" onChange={(e) => setCompanyOrServiceSize(e.target.value)} checked={companyOrServiceSize === "Médio"} />
                                          <Label className="mb-0" for="middle">Médio</Label>
                                        </div>
                                        <div className="radio radio-primary">
                                          <Input id="big" type="radio" name="companyOrServiceSize" disabled={preview} value="Grande" onChange={(e) => setCompanyOrServiceSize(e.target.value)} checked={companyOrServiceSize === "Grande"} />
                                          <Label className="mb-0" for="big">Grande</Label>
                                        </div>
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.defaultDiscount" /> (%)
                                      </Label>
                                      <NumberFormat
                                        className="form-control"
                                        value={discount || 0}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale
                                        allowNegative={false}
                                        thousandsGroupStyle="thousand"
                                        disabled={preview}
                                        onValueChange={async ({ floatValue }) => {
                                          if (isNaN(floatValue))
                                            setDiscount(0);
                                          else if (floatValue < 0)
                                            setDiscount(0);
                                          else if (floatValue >= 100)
                                            setDiscount(100);
                                          else
                                            setDiscount(floatValue);
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.isActivated" />
                                      </Label>
                                      <Col className="checkbox">
                                        <Input
                                          key="isActivated-input"
                                          id="isActivated"
                                          type="checkbox"
                                          value={1}
                                          checked={isActivated}
                                          disabled={preview}
                                          onChange={(e) => setIsActivated(!isActivated)}
                                        />
                                        <Label
                                          key="isActivated-label"
                                          for="isActivated"
                                          className="d-block"
                                        >Sim</Label>
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </TabPane>
                          <TabPane tabId="2">
                            {loading ? <Loading /> : (
                              <React.Fragment>
                                <fieldset>
                                  <legend>
                                    <FormattedMessage id="inputs.addressTitle" />
                                  </legend>
                                  <Row>
                                    <Col>
                                      <FormGroup>
                                        <Label className="col-form-label">
                                          <FormattedMessage id="inputs.addressPostcode" />
                                        </Label>
                                        <Input
                                          className="form-control"
                                          type="text"
                                          value={addressPostcode?.trim()?.replace(/\D/gm, "")}
                                          disabled={preview}
                                          onChange={async (e) => {
                                            let value = e.target.value;
                                            value = value.trim().replace(/\D/gm, "");
                                            value = value.substr(0, 8);

                                            setAddressPostcode(value);
                                            await handleCorreiosApi(value, "common");
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col></Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <FormGroup>
                                        <Label className="col-form-label">
                                          <FormattedMessage id="inputs.address" />
                                        </Label>
                                        <Input
                                          className="form-control"
                                          type="text"
                                          value={address}
                                          disabled={preview}
                                          onChange={(e) =>
                                            setAddress(e.target.value)
                                          }
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col>
                                      <FormGroup>
                                        <Label className="col-form-label">
                                          <FormattedMessage id="inputs.addressNumber" />
                                        </Label>
                                        <Input
                                          className="form-control"
                                          type="text"
                                          value={addressNumber}
                                          disabled={preview}
                                          onChange={(e) =>
                                            setAddressNumber(e.target.value)
                                          }
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <FormGroup>
                                        <Label className="col-form-label">
                                          <FormattedMessage id="inputs.addressComplement" />
                                        </Label>
                                        <Input
                                          className="form-control"
                                          type="text"
                                          value={addressComplement}
                                          disabled={preview}
                                          onChange={(e) =>
                                            setAddressComplement(e.target.value)
                                          }
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col>
                                      <FormGroup>
                                        <Label className="col-form-label">
                                          <FormattedMessage id="inputs.addressNeighborhood" />
                                        </Label>
                                        <Input
                                          className="form-control"
                                          type="text"
                                          value={addressNeighborhood}
                                          disabled={preview}
                                          onChange={(e) =>
                                            setAddressNeighborhood(e.target.value)
                                          }
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <FormGroup>
                                        <Label className="col-form-label">
                                          <FormattedMessage id="inputs.state" />
                                        </Label>
                                        <Input
                                          type="select"
                                          className="form-control digits"
                                          value={addressStateId}
                                          disabled={preview}
                                          onChange={(e) =>
                                            handleCities(e.target.value)
                                          }
                                        >
                                          <option value="">-</option>
                                          {states?.map((estado) => {
                                            return (
                                              <option
                                                value={estado.id}
                                                key={`estado-${estado.id}`}
                                              >
                                                {estado.name}
                                              </option>
                                            );
                                          })}
                                        </Input>
                                      </FormGroup>
                                    </Col>
                                    <Col>
                                      <FormGroup>
                                        <Label className="col-form-label">
                                          <FormattedMessage id="inputs.city" />
                                        </Label>
                                        <Input
                                          type="select"
                                          className="form-control digits"
                                          value={addressCityId?.toString()}
                                          disabled={preview}
                                          onChange={(e) =>
                                            setAddressCityId(e.target.value)
                                          }
                                        >
                                          <option value="">-</option>
                                          {cities?.map((cidade) => {
                                            return (
                                              <option
                                                value={cidade.id}
                                                key={`cidade-${cidade.id}`}
                                              >
                                                {cidade.name}
                                              </option>
                                            );
                                          })}
                                        </Input>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </fieldset>
                                <fieldset>
                                  <legend>
                                    <FormattedMessage id="inputs.billingAddressTitle" />
                                  </legend>
                                  <Row>
                                    <Col>
                                      <FormGroup>
                                        <Label className="col-form-label">
                                          <FormattedMessage id="inputs.addressPostcode" />
                                        </Label>
                                        <Input
                                          className="form-control"
                                          type="text"
                                          value={billingAddressPostcode?.trim()?.replace(/\D/gm, "")}
                                          disabled={preview}
                                          onChange={async (e) => {
                                            let value = e.target.value;
                                            value = value.trim().replace(/\D/gm, "");
                                            value = value.substr(0, 8);

                                            setBillingAddressPostcode(value);
                                            await handleCorreiosApi(value, "billing");
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col></Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <FormGroup>
                                        <Label className="col-form-label">
                                          <FormattedMessage id="inputs.address" />
                                        </Label>
                                        <Input
                                          className="form-control"
                                          type="text"
                                          value={billingAddress}
                                          disabled={preview}
                                          onChange={(e) =>
                                            setBillingAddress(e.target.value)
                                          }
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col>
                                      <FormGroup>
                                        <Label className="col-form-label">
                                          <FormattedMessage id="inputs.addressNumber" />
                                        </Label>
                                        <Input
                                          className="form-control"
                                          type="text"
                                          value={billingAddressNumber}
                                          disabled={preview}
                                          onChange={(e) =>
                                            setBillingAddressNumber(e.target.value)
                                          }
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <FormGroup>
                                        <Label className="col-form-label">
                                          <FormattedMessage id="inputs.addressComplement" />
                                        </Label>
                                        <Input
                                          className="form-control"
                                          type="text"
                                          value={billingAddressComplement}
                                          disabled={preview}
                                          onChange={(e) =>
                                            setBillingAddressComplement(e.target.value)
                                          }
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col>
                                      <FormGroup>
                                        <Label className="col-form-label">
                                          <FormattedMessage id="inputs.addressNeighborhood" />
                                        </Label>
                                        <Input
                                          className="form-control"
                                          type="text"
                                          value={billingAddressNeighborhood}
                                          disabled={preview}
                                          onChange={(e) =>
                                            setBillingAddressNeighborhood(e.target.value)
                                          }
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <FormGroup>
                                        <Label className="col-form-label">
                                          <FormattedMessage id="inputs.state" />
                                        </Label>
                                        <Input
                                          type="select"
                                          className="form-control digits"
                                          value={billingAddressStateId}
                                          disabled={preview}
                                          onChange={(e) =>
                                            handleBillingCities(e.target.value)
                                          }
                                        >
                                          <option value="">-</option>
                                          {states?.map((estado) => {
                                            return (
                                              <option
                                                value={estado.id}
                                                key={`estado-cobranca-${estado.id}`}
                                              >
                                                {estado.name}
                                              </option>
                                            );
                                          })}
                                        </Input>
                                      </FormGroup>
                                    </Col>
                                    <Col>
                                      <FormGroup>
                                        <Label className="col-form-label">
                                          <FormattedMessage id="inputs.city" />
                                        </Label>
                                        <Input
                                          type="select"
                                          className="form-control digits"
                                          value={billingAddressCityId}
                                          disabled={preview}
                                          onChange={(e) =>
                                            setBillingAddressCityId(
                                              e.target.value
                                            )
                                          }
                                        >
                                          <option value="">-</option>
                                          {billingCities?.map((cidade) => {
                                            return (
                                              <option
                                                value={cidade.id}
                                                key={`cidade-cobranca-${cidade.id}`}
                                              >
                                                {cidade.name}
                                              </option>
                                            );
                                          })}
                                        </Input>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </fieldset>
                                <fieldset>
                                  <legend>
                                    <FormattedMessage id="inputs.deliveryAddressTitle" />
                                  </legend>
                                  <Row>
                                    <Col>
                                      <FormGroup>
                                        <Label className="col-form-label">
                                          <FormattedMessage id="inputs.addressPostcode" />
                                        </Label>
                                        <Input
                                          className="form-control"
                                          type="text"
                                          maxLength={8}
                                          value={deliveryAddressPostcode?.trim()?.replace(/\D/gm, "")}
                                          disabled={preview}
                                          onChange={async (e) => {
                                            let value = e.target.value;
                                            value = value.trim().replace(/\D/gm, "");
                                            value = value.substr(0, 8);

                                            setDeliveryAddressPostcode(value);
                                            await handleCorreiosApi(value, "delivery");
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col></Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <FormGroup>
                                        <Label className="col-form-label">
                                          <FormattedMessage id="inputs.address" />
                                        </Label>
                                        <Input
                                          className="form-control"
                                          type="text"
                                          value={deliveryAddress}
                                          disabled={preview}
                                          onChange={(e) =>
                                            setDeliveryAddress(e.target.value)
                                          }
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col>
                                      <FormGroup>
                                        <Label className="col-form-label">
                                          <FormattedMessage id="inputs.addressNumber" />
                                        </Label>
                                        <Input
                                          className="form-control"
                                          type="text"
                                          value={deliveryAddressNumber}
                                          disabled={preview}
                                          onChange={(e) =>
                                            setDeliveryAddressNumber(e.target.value)
                                          }
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <FormGroup>
                                        <Label className="col-form-label">
                                          <FormattedMessage id="inputs.addressComplement" />
                                        </Label>
                                        <Input
                                          className="form-control"
                                          type="text"
                                          value={deliveryAddressComplement}
                                          disabled={preview}
                                          onChange={(e) =>
                                            setDeliveryAddressComplement(e.target.value)
                                          }
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col>
                                      <FormGroup>
                                        <Label className="col-form-label">
                                          <FormattedMessage id="inputs.addressNeighborhood" />
                                        </Label>
                                        <Input
                                          className="form-control"
                                          type="text"
                                          value={deliveryAddressNeighborhood}
                                          disabled={preview}
                                          onChange={(e) =>
                                            setDeliveryAddressNeighborhood(e.target.value)
                                          }
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <FormGroup>
                                        <Label className="col-form-label">
                                          <FormattedMessage id="inputs.state" />
                                        </Label>
                                        <Input
                                          type="select"
                                          className="form-control digits"
                                          value={deliveryAddressStateId}
                                          disabled={preview}
                                          onChange={(e) =>
                                            handleBillingCities(e.target.value)
                                          }
                                        >
                                          <option value="">-</option>
                                          {states?.map((estado) => {
                                            return (
                                              <option
                                                value={estado.id}
                                                key={`estado-cobranca-${estado.id}`}
                                              >
                                                {estado.name}
                                              </option>
                                            );
                                          })}
                                        </Input>
                                      </FormGroup>
                                    </Col>
                                    <Col>
                                      <FormGroup>
                                        <Label className="col-form-label">
                                          <FormattedMessage id="inputs.city" />
                                        </Label>
                                        <Input
                                          type="select"
                                          className="form-control digits"
                                          value={deliveryAddressCityId}
                                          disabled={preview}
                                          onChange={(e) =>
                                            setDeliveryAddressCityId(
                                              e.target.value
                                            )
                                          }
                                        >
                                          <option value="">-</option>
                                          {deliveryCities?.map((cidade) => {
                                            return (
                                              <option
                                                value={cidade.id}
                                                key={`cidade-delivey-${cidade.id}`}
                                              >
                                                {cidade.name}
                                              </option>
                                            );
                                          })}
                                        </Input>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </fieldset>
                              </React.Fragment>
                            )}
                          </TabPane>
                        </TabContent>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          type="button"
                          color="danger"
                          onClick={() => history.push("/clients")}
                        >
                          <i className="fa fa-arrow-left"></i>
                          <FormattedMessage id="buttons.cancel" />
                        </Button>
                      </Col>
                      {canUpdate ? (
                        <Col className="text-right">
                          {id ? (
                            <React.Fragment>
                              <Button type="button" disabled={disableSubmit || loading} onClick={handleSchedule} color="info">
                                <i className="fa fa-calendar"></i>
                                Agendar
                              </Button>
                              {!protheusId?.trim() ? (
                                <Button type="button" disabled={disableSubmit || loading} onClick={syncProtheus} color="success">
                                  <i className="fa fa-refresh"></i>
                                  Sincronizar
                                </Button>
                              ) : ""}
                            </React.Fragment>
                          ) : ""}
                          {preview ? (
                            <Button
                              type="button"
                              color="primary"
                              disabled={loading}
                              onClick={() => setPreview(!preview)}
                            >
                              <i className="fa fa-edit"></i>
                              <FormattedMessage id="buttons.edit" />
                            </Button>
                          ) : (
                            <Button type="button" disabled={disableSubmit || loading} onClick={handleSubmitForm} color="primary">
                              <i className="fa fa-save"></i>
                              <FormattedMessage id="buttons.save" />
                            </Button>
                          )}
                        </Col>) : ""}
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Manage;
