import React, {
  ChangeEvent,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Tooltip,
} from "reactstrap";
import { Accordion } from "react-bootstrap";
import Breadcrumb from "../../layout/breadcrumb";
import { CrudParam } from "../../configs/route";
import { useApollo } from "../../services/apollo.service";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import pt_BR from "date-fns/locale/pt-BR";
import formatter, { capitalize } from "../../utils/formatter";
import { Eye, EyeOff } from "react-feather";
import { useSelector } from "react-redux";
import { ICombineReducers } from "../../redux";
import { useAuth } from "../../hooks/auth";
import Policy from "../../models/policy";
import Role from "../../models/role";
import { convert_to_date, format_date, PASS_PREFIX, SITE_TITLE } from "../../configs/constants";
import { toast } from "react-toastify";
import { gql } from "@apollo/client";
import { UserClaim } from "../../models/user";
import StateAreaCode from "../../models/stateAreaCode";
import City from "../../models/city";
import State from "../../models/state";
import Meta from "../../models/meta";
import SweetAlert from "sweetalert2";
import NumberFormat from "react-number-format";
import Loading from "../../components/loading";

const Manage: React.FC = () => {
  const isMountedRef = useRef(null);
  const { apollo, error } = useApollo();
  const locale = useSelector((state: ICombineReducers) => state.State.locale);
  const { id } = useParams<CrudParam>();
  const [activeTab, setActiveTab] = useState<number>(1);
  const [name, setName] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [protheusId, setProtheusId] = useState<string>("");
  const [areaCodeIds, setAreaCodeIds] = useState<string[]>([]);
  const [citiesIds, setCitiesIds] = useState<string[]>([]);
  const [documento, setDocumento] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [metas, setMetas] = useState<Meta[]>([]);
  const [isActivated, setIsActivated] = useState<boolean>(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
  const [roleIds, setRoleIds] = useState<string[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<any>({});
  const [claimIds, setClaimIds] = useState<string[]>([]);
  const [selectedClaims, setSelectedClaims] = useState<any>({});
  const [birthDate, setBirthDate] = useState<Date>();
  const [documentInvalido, setDocumentoInvalido] = useState<boolean>(false);
  const [exibirSenha, setExibirSenha] = useState<boolean>(false);
  const [tooltipOpen1, setTooltipOpen1] = useState<boolean>(false);
  const [tooltipOpen2, setTooltipOpen2] = useState<boolean>(false);
  const [claims, setClaims] = useState<Policy[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [areaCodes, setAreaCodes] = useState<StateAreaCode[]>([]);
  const [selectedAreaCodes, setSelectedAreaCodes] = useState<any>({});
  const [states, setStates] = useState<State[]>([]);
  const [stateId, setStateId] = useState<string>("");
  const [cities, setCities] = useState<City[]>([]);
  const [selectedCities, setSelectedCities] = useState<any>({});
  const { superAdmin, hasScope } = useAuth();
  const history = useHistory();
  const intl = useIntl();
  const [preview, setPreview] = useState<boolean>(() => { if (id) return true; else return false; });
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [canView] = useState<boolean>(() => {
    return hasScope("Users:User");
  });
  const [canCreate] = useState<boolean>(() => {
    return hasScope("Users:Create");
  });
  const [canUpdate] = useState<boolean>(() => {
    return hasScope("Users:Update");
  });

  const handleChange = (date: any) => {
    setBirthDate(date);
  };

  const formatDocument = (document: string): string => {
    document = document.trim().replace(/\D/gm, "");

    // Formata o documento do usuário
    if (document.length === 11) {
      document = formatter.formatCpf(document);
    } else if (document.length === 14) {
      document = formatter.formatCnpj(document);
    }
    return document;
  }

  const handleCities = useCallback(async (stateId: string) => {
    setStateId(stateId);
    await apollo.query({
      query: gql`
        query stateCities($id: String!) {
          stateCities(id: $id) {
              id
              name
              code
          }
        }
      `,
      variables: {
        id: stateId,
      },
    })
      .then(res => {
        setCities(res.data.stateCities);
      })
      .catch(err => error(err));
  }, [apollo, error]);

  const handleData = useCallback(async () => {
    if (id) {
      if (preview && !canView) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/users");
        return;
      } else if (!preview && !canUpdate) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/users");
        return;
      }
      setLoading(true);
      await Promise.all([
        apollo.query({
          query: gql`
            query policies {
              policies {
                module
                policies
              }
            }
          `,
        }),
        apollo.query({
          query: gql`
            query rolesDropdown {
              rolesDropdown {
                id
                name
                description
                claims {
                  claimType
                  claimValue
                }
              }
            }
          `,
        }),
        apollo.query({
          query: gql`
            query user($id: String!) {
              user(id: $id) {
                id
                name
                surname
                document
                birthDate
                email
                phone
                mobile
                isActivated
                isSuperAdmin
                protheusId
                path
                metas {
                  total
                  divider
                  name
                  startAt
                  endAt
                  isActivated
                }
                roles {
                  id
                  name
                  description
                }
                areaCodes {
                  id
                  code
                  protheusId
                }
                cities {
                  id
                  name
                  code
                  state {
                    id
                    name
                    fc
                    code
                  }
                }
                claims {
                  claimType
                  claimValue
                }
              }
            }
          `,
          variables: {
            id
          },
        }),
        apollo.query({
          query: gql`
            query countryStates($iso: String!) {
              countryStates(iso: $iso) {
                id
                name
                code
                fc
              }
            }
          `,
          variables: {
            iso: "BR"
          },
        }),
        apollo.query({
          query: gql`
            query stateAreaCodesDropdown {
              stateAreaCodesDropdown {
                id
                code
                protheusId
                state {
                  id
                  name
                  fc
                  code
                }
              }
            }
          `,
        }),
      ])
        .then(async (res) => {
          if (isMountedRef.current) {
            if (res[0]?.data.policies) {
              setClaims(res[0].data.policies);
            }
            if (res[1]?.data.rolesDropdown) {
              setRoles(res[1].data.rolesDropdown);
            }
            if (res[2]?.data.user) {
              setName(res[2].data.user.name || "");
              setSurname(res[2].data.user.surname || "");
              setIsActivated(res[2].data.user.protheusId || "");
              setDocumento(formatDocument(res[2].data.user.document) || "");
              setPhone(res[2].data.user.phone || "");
              setMobile(res[2].data.user.mobile || "");
              setMetas(res[2].data.user.metas || []);
              setIsActivated(res[2].data.user.isActivated || false);
              setIsSuperAdmin(res[2].data.user.isSuperAdmin || false);
              setEmail(res[2].data.user.email || "");
              setProtheusId(res[2].data.user.protheusId || "");
              setRoleIds(res[2].data.user.roles?.map((role: Role) => role.id));
              let roleIdsObj = {};
              for (let i = 0; i < res[2].data.user.roles?.length; i++) {
                roleIdsObj = { ...roleIdsObj, [res[2].data.user.roles[i].id]: true };
              }
              setSelectedRoles(roleIdsObj);
              if (res[2].data.user.claims?.length > 0) {
                let claimsObj = {};
                let claimsArray: string[] = [];
                for (const claim of res[2].data.user.claims) {
                  claimsArray.push(`${claim.claimType}:${claim.claimValue}`);
                  claimsObj = { ...claimsObj, [`${claim.claimType}:${claim.claimValue}`]: true };
                }
                setClaimIds(claimsArray);
                setSelectedClaims(claimsObj);
              }
              if (res[2].data.user.areaCodes.length) {
                setAreaCodeIds(res[2].data.user.areaCodes?.map((entity: StateAreaCode) => entity.id));
                let selectedAreaCodesObj = {};
                res[2].data.user.areaCodes?.map((entity: StateAreaCode) => {
                  selectedAreaCodesObj = { ...selectedAreaCodesObj, [entity.id]: true };
                  return entity;
                });
                setSelectedAreaCodes(selectedAreaCodesObj);
              }
              if (res[2].data.user.cities.length) {
                let selectedStateId = "";
                setCitiesIds(res[2].data.user.cities?.map((entity: City) => entity.id));
                let selectedCitiesObj = {};
                const selectedCities = res[2].data.user.cities?.map((entity: City) => {
                  selectedCitiesObj = { ...selectedCitiesObj, [entity.id]: true };
                  return entity.state.id;
                });
                if (selectedCities[0])
                  selectedStateId = selectedCities[0];
                setSelectedCities(selectedCitiesObj);
                await handleCities(selectedStateId);
              }
              if (res[2].data.user.birthDate !== null)
                setBirthDate(convert_to_date(res[2].data.user.birthDate));
            }
            if (res[3]?.data.countryStates)
              setStates(res[3].data.countryStates);
            if (res[4]?.data.stateAreaCodesDropdown)
              setAreaCodes(res[4].data.stateAreaCodesDropdown);
          }
        })
        .catch(err => error(err));
      setLoading(false);
    } else {
      if (!preview && !canCreate) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/users");
        return;
      }
      setLoading(true);
      await Promise.all([
        apollo.query({
          query: gql`
            query policies {
              policies {
                module
                policies
              }
            }
          `,
        }),
        apollo.query({
          query: gql`
            query rolesDropdown {
              rolesDropdown {
                id
                name
                description
                claims {
                  claimType
                  claimValue
                }
              }
            }
          `,
        }),
        apollo.query({
          query: gql`
            query countryStates($iso: String!) {
              countryStates(iso: $iso) {
                id
                name
                code
                fc
              }
            }
          `,
          variables: {
            iso: "BR"
          },
        }),
        apollo.query({
          query: gql`
            query stateAreaCodesDropdown {
              stateAreaCodesDropdown {
                id
                code
                protheusId
                state {
                  id
                  name
                  fc
                  code
                }
              }
            }
          `,
        }),
      ])
        .then((res) => {
          if (isMountedRef.current) {
            if (res[0]?.data.policies)
              setClaims(res[0].data.policies);
            if (res[1]?.data.rolesDropdown)
              setRoles(res[1].data.rolesDropdown);
            if (res[2]?.data.countryStates)
              setStates(res[2].data.countryStates);
            if (res[3]?.data.stateAreaCodesDropdown)
              setAreaCodes(res[3].data.stateAreaCodesDropdown);
          }
        })
        .catch(err => error(err));
      setLoading(false);
    }
  }, [intl, id, preview, apollo, error, history, canView, canCreate, canUpdate, handleCities]);

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: `pages.users.${preview ? "preview" : id ? "update" : "create"}`,
      })}`;
    if (locale === "pt") {
      registerLocale("pt-BR", pt_BR);
      setDefaultLocale("pt-BR");
    }
    handleData();
    return () => { isMountedRef.current = false; }
  }, [intl, locale, id, preview, handleData]);

  const handleSubmitForm = async () => {
    setDisableSubmit(true);
    let claims: UserClaim[] = [];
    if (claimIds.length) {
      for (const papel of claimIds) {
        const ex = papel.split(":");
        const claimType = ex[0];
        const claimValue = ex[1];
        if (claimType && claimValue)
          claims.push({
            claimType,
            claimValue,
          } as UserClaim);
      }
    }
    let metasArray: any[] = [];
    if (metas.length) {
      for (const meta of metas) {
        metasArray.push({
          name: meta.name,
          total: meta.total,
          divider: meta.divider,
          startAt: meta.startAt,
          endAt: meta.endAt,
          isActivated: meta.isActivated,
        })
      }
    }

    const data: any = {
      name,
      surname,
      document: documento.trim().replace(/\D/gm, ""),
      email,
      password,
      phone,
      mobile,
      isActivated,
      isSuperAdmin,
      protheusId,
      birthDate: "",
      roles: roleIds,
      claims,
      areaCodes: areaCodeIds,
      cities: citiesIds,
      metas: metasArray,
    };
    if (birthDate)
      data.birthDate = format_date(birthDate, "YYYY-MM-DD");

    if (password.trim() && password.trim().length < 6) {
      toast.error("A senha deve ter no mínimo 6 caracteres.");
      return;
    }

    if (!id) {
      await apollo.mutate({
        mutation: gql`
          mutation createUser($data: UserInput!) {
            createUser(data: $data) {
                id
            }
          }
        `,
        variables: {
          data
        },
      })
        .then(res => {
          toast.success(intl.formatMessage({ id: "flash.success.created" }));
          history.push(`/users/manage/${res.data.createUser.id}`);
        })
        .catch(err => error(err));
    } else {
      await apollo.mutate({
        mutation: gql`
          mutation updateUser($id: String!, $data: UserInput!) {
            updateUser(id: $id, data: $data) {
                id
            }
          }
        `,
        variables: {
          id,
          data
        },
      })
        .then(res => {
          toast.success(intl.formatMessage({ id: "flash.success.updated" }));
          history.push("/users");
        })
        .catch(err => error(err));
    }
    setDisableSubmit(false);
  };

  const handleDocument = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\D/, "");
    setDocumentoInvalido(false);

    if (!formatter.isCpfValid(value) || !formatter.isCnpjValid(value)) {
      setDocumentoInvalido(true);
    }

    if (value.length === 11) {
      value = formatter.formatCpf(value);
    } else if (value.length === 14) {
      value = formatter.formatCnpj(value);
    }

    setDocumento(value);
    handlePassword();
  };

  const handleEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    handlePassword();
  }

  const handlePassword = () => {
    if (!id) {
      if (email.length >= 3 && documento.length >= 5) {
        const _documento = documento.replace(/\D/, "").substr(0, 6);
        const _email = capitalize(email.substr(0, 3));
        const password = `$${PASS_PREFIX}${_email}@${_documento}#`;
        setPassword(password);
      }
    }
  }

  const handleSelectedAreaCodes = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const index = areaCodeIds.indexOf(value);
    if (index >= 0) {
      areaCodeIds.splice(index, 1);
      setAreaCodeIds(areaCodeIds);
    } else {
      areaCodeIds.push(value);
      setAreaCodeIds(areaCodeIds);
    }
    setSelectedAreaCodes({ ...selectedAreaCodes, [value]: e.target.checked });
  };

  const handleSelectedCities = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const index = citiesIds.indexOf(value);
    if (index >= 0) {
      citiesIds.splice(index, 1);
      setCitiesIds(citiesIds);
    } else {
      citiesIds.push(value);
      setCitiesIds(citiesIds);
    }
    setSelectedCities({ ...selectedCities, [value]: e.target.checked });
  };

  const handleRoles = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const index = roleIds.indexOf(value);
    if (index >= 0) {
      roleIds.splice(index, 1);
      setRoleIds(roleIds);
    } else {
      roleIds.push(value);
      setRoleIds(roleIds);
    }
    setSelectedRoles({ ...selectedRoles, [value]: e.target.checked });
  };

  const handleClaims = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const index = claimIds.indexOf(value);
    if (index >= 0) {
      claimIds.splice(index, 1);
      setClaimIds(claimIds);
    } else {
      claimIds.push(value);
      setClaimIds(claimIds);
    }
    setSelectedClaims({ ...selectedClaims, [value]: e.target.checked });
  };

  const addMetaLine = () => {
    let meta: Meta = {
      total: 0,
      divider: 1,
      name: "",
      startAt: null,
      endAt: null,
      isActivated: false,
    } as Meta;
    setMetas([
      ...metas,
      meta
    ]);
  };

  const handleDeleteMeta = (action: Meta, index: number) => {
    SweetAlert.fire({
      title: intl.formatMessage({ id: "general.exclusion" }),
      text: intl.formatMessage({ id: "flash.confirm.deleteOne" }),
      icon: "error",
      cancelButtonText: intl.formatMessage({ id: "general.no" }),
      confirmButtonText: intl.formatMessage({ id: "general.yes" }),
      reverseButtons: true,
      showCancelButton: true,
    })
      .then(({ isConfirmed }) => {
        if (isConfirmed) {
          toast.success("Meta removida com sucesso.");
          metas.splice(index, 1);
          setMetas([...metas]);
        }
      });
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="pages.users.users"
        title={`pages.users.${preview ? "preview" : id ? "update" : "create"}`}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>
                  <FormattedMessage
                    id={`pages.users.${preview ? "preview" : id ? "update" : "create"
                      }`}
                  />
                </h5>
              </CardHeader>
              <CardBody className="tabbed-card">
                <div className="form-content">
                  <Form onSubmit={(e) => e.preventDefault()}>
                    <Nav className="nav-pills nav-primary">
                      <NavItem>
                        <NavLink
                          className={activeTab === 1 ? "active" : ""}
                          onClick={() => setActiveTab(1)}
                        >
                          <i className="fa fa-file-text-o"></i>
                          <FormattedMessage id="tabs.data" />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={activeTab === 2 ? "active" : ""}
                          onClick={() => setActiveTab(2)}
                        >
                          <i className="fa fa-group"></i>
                          <FormattedMessage id="tabs.roles" />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={activeTab === 3 ? "active" : ""}
                          onClick={() => setActiveTab(3)}
                        >
                          <i className="icofont icofont-papers"></i>
                          <FormattedMessage id="tabs.claims" />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={activeTab === 4 ? "active" : ""}
                          onClick={() => setActiveTab(4)}
                        >
                          <i className="icofont icofont-globe"></i>
                          Regiões
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={activeTab === 5 ? "active" : ""}
                          onClick={() => setActiveTab(5)}
                        >
                          <i className="icofont icofont-flag"></i>
                          Cidades
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={activeTab === 6 ? "active" : ""}
                          onClick={() => setActiveTab(6)}
                        >
                          <i className="fa fa-bar-chart"></i>
                          Metas
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <Row className="m-b-30">
                      <Col>
                        <TabContent activeTab={activeTab.toString()}>
                          <TabPane tabId="1">
                            {loading ? <Loading /> : (
                              <React.Fragment>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.name" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        disabled={preview}
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.surname" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={surname}
                                        onChange={(e) =>
                                          setSurname(e.target.value)
                                        }
                                        disabled={preview}
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.birthDate" />
                                      </Label>
                                      <DatePicker
                                        className="form-control"
                                        selected={birthDate}
                                        onChange={handleChange}
                                        dateFormat="dd/MM/yyyy"
                                        disabled={preview}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.cpfCnpj" />
                                      </Label>
                                      <Input
                                        className={
                                          documentInvalido ? "input-error" : ""
                                        }
                                        type="text"
                                        value={documento ? formatDocument(documento) : ""}
                                        onChange={handleDocument}
                                        maxLength={18}
                                        disabled={preview}
                                        autoComplete="off"
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.email" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="email"
                                        value={email}
                                        disabled={preview}
                                        onChange={handleEmail}
                                        autoComplete="off"
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.password" />
                                      </Label>
                                      <InputGroup>
                                        <Input
                                          className="form-control"
                                          type={exibirSenha ? "text" : "password"}
                                          value={password}
                                          onChange={(e) => setPassword(e.target.value)}
                                          disabled={preview}
                                        />
                                        <InputGroupAddon addonType="append">
                                          <InputGroupText>
                                            <Eye
                                              onClick={() => {
                                                return !preview
                                                  ? setExibirSenha(!exibirSenha)
                                                  : null;
                                              }}
                                              style={{ cursor: "pointer" }}
                                              className={
                                                exibirSenha ? "hidden" : ""
                                              }
                                              id="display-password1"
                                            />
                                            <EyeOff
                                              onClick={() => setExibirSenha(!exibirSenha)}
                                              style={{ cursor: "pointer" }}
                                              className={
                                                !exibirSenha ? "hidden" : ""
                                              }
                                              id="display-password2"
                                            />
                                            <Tooltip
                                              placement="top"
                                              isOpen={tooltipOpen1}
                                              target="display-password1"
                                              toggle={() =>
                                                setTooltipOpen1(!tooltipOpen1)
                                              }
                                            >
                                              <FormattedMessage id="pages.users.showPassword" />
                                            </Tooltip>
                                            <Tooltip
                                              placement="top"
                                              isOpen={tooltipOpen2}
                                              target="display-password2"
                                              toggle={() =>
                                                setTooltipOpen2(!tooltipOpen2)
                                              }
                                            >
                                              <FormattedMessage id="pages.users.hidePassword" />
                                            </Tooltip>
                                          </InputGroupText>
                                        </InputGroupAddon>
                                      </InputGroup>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.phone" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={phone}
                                        disabled={preview}
                                        onChange={(e) =>
                                          setPhone(e.target.value)
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.mobile" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={mobile}
                                        disabled={preview}
                                        onChange={(e) =>
                                          setMobile(e.target.value)
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.protheusId" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={protheusId}
                                        onChange={(e) =>
                                          setProtheusId(e.target.value)
                                        }
                                        disabled={preview}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.isActivated" />
                                      </Label>
                                      <Col className="checkbox">
                                        <Input
                                          key="isActivated-input"
                                          id="isActivated"
                                          type="checkbox"
                                          value={1}
                                          checked={isActivated}
                                          disabled={preview}
                                          onChange={(e) => setIsActivated(!isActivated)}
                                        />
                                        <Label
                                          key="isActivated-label"
                                          for="isActivated"
                                          className="d-block"
                                        >Sim</Label>
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    {superAdmin ? (
                                      <FormGroup>
                                        <Label className="col-form-label">
                                          <FormattedMessage id="inputs.isSuperAdmin" />
                                        </Label>
                                        <Col className="checkbox">
                                          <Input
                                            key="isSuperAdmin-input"
                                            id="isSuperAdmin"
                                            type="checkbox"
                                            value={1}
                                            checked={isSuperAdmin}
                                            disabled={preview}
                                            onChange={(e) =>
                                              setIsSuperAdmin(!isSuperAdmin)
                                            }
                                          />
                                          <Label
                                            key="isSuperAdmin-label"
                                            for="isSuperAdmin"
                                            className="d-block"
                                          ></Label>
                                        </Col>
                                      </FormGroup>
                                    ) : (
                                      ""
                                    )}
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </TabPane>
                          <TabPane tabId="2">
                            {loading ? <Loading /> : (
                              <React.Fragment>
                                <Row>
                                  {roles?.map((role) => {
                                    return (
                                      <Col
                                        md="3"
                                        key={`col-role-${role.id}`}
                                        className="checkbox m-b-5"
                                      >
                                        <Input
                                          key={`input-role-${role.id}`}
                                          id={`role-${role.id}`}
                                          type="checkbox"
                                          value={role.id}
                                          checked={
                                            selectedRoles.hasOwnProperty(
                                              role.id
                                            )
                                              ? selectedRoles[role.id]
                                              : false
                                          }
                                          disabled={preview}
                                          onChange={handleRoles}
                                        />
                                        <Label
                                          key={`label-role-${role.id}`}
                                          className="d-block"
                                          for={`role-${role.id}`}
                                        >
                                          {role.description || role.name}
                                        </Label>
                                      </Col>
                                    );
                                  })}
                                </Row>
                              </React.Fragment>
                            )}
                          </TabPane>
                          <TabPane tabId="3">
                            {loading ? <Loading /> : (
                              <Accordion defaultActiveKey="0">
                                <div className="default-according" id="accordion">
                                  {claims?.map(
                                    (item, index) => {
                                      return (
                                        <Card key={item.module}>
                                          <CardHeader>
                                            <h5 className="mb-0">
                                              <Accordion.Toggle
                                                className="btn btn-link"
                                                color="default"
                                                eventKey={index.toString()}
                                              >
                                                <FormattedMessage
                                                  id={`app.modules.${item.module.toLowerCase()}`}
                                                />
                                              </Accordion.Toggle>
                                            </h5>
                                          </CardHeader>
                                          <Accordion.Collapse
                                            eventKey={index.toString()}
                                          >
                                            <CardBody>
                                              <Row>
                                                {item?.policies?.map(
                                                  (policy, ind) => {
                                                    return (
                                                      <Col
                                                        key={`col-claim-${ind}`}
                                                        md="3"
                                                        className="checkbox m-b-5"
                                                      >
                                                        <Input
                                                          key={`input-claim-${ind}`}
                                                          id={`${item.module}:${policy}`}
                                                          type="checkbox"
                                                          value={`${item.module}:${policy}`}
                                                          disabled={preview}
                                                          checked={
                                                            selectedClaims.hasOwnProperty(
                                                              `${item.module}:${policy}`
                                                            )
                                                              ? selectedClaims[
                                                              `${item.module}:${policy}`
                                                              ]
                                                              : false
                                                          }
                                                          onChange={handleClaims}
                                                        />
                                                        <Label
                                                          key={`label-claim-${ind}`}
                                                          className="d-block"
                                                          for={`${item.module}:${policy}`}
                                                        >
                                                          <FormattedMessage
                                                            id={`app.policies.${policy.toLowerCase()}`}
                                                          />
                                                        </Label>
                                                      </Col>
                                                    );
                                                  }
                                                )}
                                              </Row>
                                            </CardBody>
                                          </Accordion.Collapse>
                                        </Card>
                                      );
                                    }
                                  )}
                                </div>
                              </Accordion>
                            )}
                          </TabPane>
                          <TabPane tabId="4">
                            {loading ? <Loading /> : (
                              <Row>
                                {areaCodes?.map((areaCode) => {
                                  return (
                                    <Col
                                      md="3"
                                      key={`col-areaCode-${areaCode.id}`}
                                      className="checkbox m-b-5"
                                    >
                                      <Input
                                        key={`input-areaCode-${areaCode.id}`}
                                        id={`areaCode-${areaCode.id}`}
                                        type="checkbox"
                                        value={areaCode.id}
                                        checked={
                                          selectedAreaCodes.hasOwnProperty(
                                            areaCode.id
                                          )
                                            ? selectedAreaCodes[areaCode.id]
                                            : false
                                        }
                                        disabled={preview}
                                        onChange={handleSelectedAreaCodes}
                                      />
                                      <Label
                                        key={`label-areaCode-${areaCode.id}`}
                                        className="d-block"
                                        for={`areaCode-${areaCode.id}`}
                                      >
                                        {areaCode.code} - {areaCode.state.name} ({areaCode.state.fc})
                                      </Label>
                                    </Col>
                                  );
                                })}
                              </Row>
                            )}
                          </TabPane>
                          <TabPane tabId="5">
                            {loading ? <Loading /> : (
                              <React.Fragment>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.state" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="select"
                                        value={stateId}
                                        onChange={async (e) =>
                                          await handleCities(e.target.value)
                                        }
                                        disabled={preview}
                                      >
                                        <option value="">-</option>
                                        {states?.map(state => <option key={state.id} value={state.id}>{state.fc} - {state.name}</option>)}
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  {(stateId && cities?.length) ? cities?.map((city: City) => {
                                    return (
                                      <Col
                                        md="3"
                                        key={`col-city-${city.id}`}
                                        className="checkbox m-b-5"
                                      >
                                        <Input
                                          key={`input-city-${city.id}`}
                                          id={`city-${city.id}`}
                                          type="checkbox"
                                          value={city.id}
                                          checked={
                                            selectedCities.hasOwnProperty(
                                              city.id
                                            )
                                              ? selectedCities[city.id]
                                              : false
                                          }
                                          disabled={preview}
                                          onChange={handleSelectedCities}
                                        />
                                        <Label
                                          key={`label-city-${city.id}`}
                                          className="d-block"
                                          for={`city-${city.id}`}
                                        >
                                          {city.name}
                                        </Label>
                                      </Col>
                                    );
                                  }) : ""}
                                </Row>
                              </React.Fragment>
                            )}
                          </TabPane>
                          <TabPane tabId="6">
                            {loading ? <Loading /> : (
                              <React.Fragment>
                                <Row>
                                  <Col>
                                    {metas?.map((meta, index) => {
                                      return (
                                        <Card key={index}>
                                          <CardBody>
                                            <Row>
                                              <Col>
                                                <FormGroup>
                                                  <Label className="col-form-label">
                                                    <FormattedMessage id="inputs.name" />
                                                  </Label>
                                                  <Input
                                                    className="form-control"
                                                    type="text"
                                                    value={meta.name}
                                                    disabled={preview}
                                                    onChange={(e) => {
                                                      metas[index].name = e.target.value;
                                                      setMetas([
                                                        ...metas
                                                      ])
                                                    }}
                                                    required
                                                  />
                                                </FormGroup>
                                              </Col>
                                              <Col>
                                                <FormGroup>
                                                  <Label className="col-form-label">
                                                    <FormattedMessage id="inputs.total" />
                                                  </Label>
                                                  <NumberFormat
                                                    className="form-control"
                                                    value={meta.total}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    allowNegative={false}
                                                    thousandsGroupStyle="thousand"
                                                    disabled={preview}
                                                    onValueChange={async ({ floatValue }) => {
                                                      metas[index].total = floatValue;
                                                      setMetas([
                                                        ...metas
                                                      ])
                                                    }}
                                                    required
                                                  />
                                                </FormGroup>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col>
                                                <FormGroup>
                                                  <Label className="col-form-label">
                                                    <FormattedMessage id="inputs.startAt" />
                                                  </Label>
                                                  <DatePicker
                                                    className="form-control"
                                                    selected={meta.startAt ? convert_to_date(meta.startAt) : null}
                                                    onChange={(date: Date) => {
                                                      metas[index].startAt = date;
                                                      setMetas([
                                                        ...metas
                                                      ])
                                                    }}
                                                    dateFormat="dd/MM/yyyy"
                                                    disabled={preview}
                                                  />
                                                </FormGroup>
                                              </Col>
                                              <Col>
                                                <FormGroup>
                                                  <Label className="col-form-label">
                                                    <FormattedMessage id="inputs.endAt" />
                                                  </Label>
                                                  <DatePicker
                                                    className="form-control"
                                                    selected={meta.endAt ? convert_to_date(meta.endAt) : null}
                                                    onChange={(date: Date) => {
                                                      metas[index].endAt = date;
                                                      setMetas([
                                                        ...metas
                                                      ])
                                                    }}
                                                    dateFormat="dd/MM/yyyy"
                                                    disabled={preview}
                                                  />
                                                </FormGroup>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col>
                                                <FormGroup>
                                                  <Label className="col-form-label">
                                                    <FormattedMessage id="inputs.divider" />
                                                  </Label>
                                                  <Input
                                                    className="form-control"
                                                    type="number"
                                                    value={meta.divider}
                                                    disabled={preview}
                                                    onChange={(e) => {
                                                      let value = Number(e.target.value);
                                                      if (isNaN(value))
                                                        value = 0;
                                                      metas[index].divider = value;
                                                      setMetas([
                                                        ...metas
                                                      ])
                                                    }}
                                                    required
                                                  />
                                                </FormGroup>
                                              </Col>
                                              <Col>
                                                <FormGroup>
                                                  <Label className="col-form-label">
                                                    <FormattedMessage id="inputs.isActivated" />
                                                  </Label>
                                                  <Col className="checkbox">
                                                    <Input
                                                      key={`isAcivated-input-${index}`}
                                                      id={`isActivated-${index}`}
                                                      type="checkbox"
                                                      value={1}
                                                      checked={metas[index].isActivated}
                                                      disabled={preview}
                                                      onChange={(e) => {
                                                        metas[index].isActivated = e.target.checked;
                                                        setMetas([
                                                          ...metas
                                                        ])
                                                      }}
                                                    />
                                                    <Label
                                                      key={`isActivated-label-${index}`}
                                                      for={`isActivated-${index}`}
                                                      className="d-block"
                                                    ></Label>
                                                  </Col>
                                                </FormGroup>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col className="text-right">
                                                <span className="task-action-btn">
                                                  <Button type="button" disabled={preview} className="danger action-box large delete-btn" title="Remover meta" onClick={() => handleDeleteMeta(metas[index], index)} >
                                                    <i className="icon"><i className="icon-trash"></i></i>
                                                  </Button>
                                                </span>
                                              </Col>
                                            </Row>
                                          </CardBody>
                                        </Card>
                                      );
                                    })}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <Button type="button" disabled={preview} onClick={addMetaLine} color="primary">
                                      <i className="icon-plus"></i>&nbsp;&nbsp;
                                      Adicionar meta
                                    </Button>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </TabPane>
                        </TabContent>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          type="button"
                          color="danger"
                          onClick={() => history.push("/users")}
                        >
                          <i className="fa fa-arrow-left"></i>
                          <FormattedMessage id="buttons.cancel" />
                        </Button>
                      </Col>
                      {canUpdate ? (<Col className="text-right">
                        {preview ? (
                          <Button
                            type="button"
                            color="primary"
                            disabled={loading}
                            onClick={() => setPreview(!preview)}
                          >
                            <i className="fa fa-edit"></i>
                            <FormattedMessage id="buttons.edit" />
                          </Button>
                        ) : (
                          <Button type="button" disabled={disableSubmit || loading} onClick={handleSubmitForm} color="primary">
                            <i className="fa fa-save"></i>
                            <FormattedMessage id="buttons.save" />
                          </Button>
                        )}
                      </Col>) : ""}
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Manage;
