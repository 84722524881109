import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Breadcrumb from "../../layout/breadcrumb";
import { CrudParam } from "../../configs/route";
import { useApollo } from "../../services/apollo.service";
import { SITE_TITLE } from "../../configs/constants";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/auth";
import { gql } from "@apollo/client";
import Loading from "../../components/loading";

const Manage: React.FC = () => {
  const isMountedRef = useRef(null);
  const { apollo, error } = useApollo();
  const { id } = useParams<CrudParam>();
  const [name, setNome] = useState<string>("");
  const [alias, setAlias] = useState<string>("");
  const [phone1, setPhone1] = useState<string>("");
  const [phone2, setPhone2] = useState<string>("");
  const [site, setSite] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [protheusId, setProtheusId] = useState<string>("");
  const [isActivated, setAtivado] = useState<boolean>(false);
  const [preview, setPreview] = useState<boolean>(() => { if (id) return true; else return false; });
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const intl = useIntl();

  const { hasScope } = useAuth();
  const [canView] = useState<boolean>(() => {
    return hasScope("Carriers:Carrier");
  });
  const [canCreate] = useState<boolean>(() => {
    return hasScope("Carriers:Create");
  });
  const [canUpdate] = useState<boolean>(() => {
    return hasScope("Carriers:Update");
  });

  const handleData = useCallback(async () => {
    if (id) {
      if (preview && !canView) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/carriers");
        return;
      } else if (!preview && !canUpdate) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/carriers");
        return;
      }
      setLoading(true);
      await apollo.query({
        query: gql`
          query carrier($id: String!) {
            carrier(id: $id) {
              id
              createdAt
              updatedAt
              name
              alias
              phone1
              phone2
              email
              site
              isActivated
              protheusId
            }
          }
        `,
        variables: {
          id
        },
      })
        .then(res => {
          if (isMountedRef.current) {
            setNome(res.data.carrier.name || "");
            setAlias(res.data.carrier.alias || "");
            setPhone1(res.data.carrier.phone1 || "");
            setPhone2(res.data.carrier.phone2 || "");
            setEmail(res.data.carrier.email || "");
            setSite(res.data.carrier.site || "");
            setProtheusId(res.data.carrier.protheusId || "");
            setAtivado(res.data.carrier.isActivated || false);
          }
        })
        .catch(err => error(err));
      setLoading(false);
    } else {
      if (!preview && !canCreate) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/carriers");
        return;
      }
    }
  }, [intl, id, preview, apollo, error, history, canView, canCreate, canUpdate]);

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: `pages.carriers.${preview ? "preview" : id ? "update" : "create"}`,
      })}`;
    handleData();
    return () => { isMountedRef.current = false; }
  }, [intl, id, preview, handleData]);

  const handleSubmitForm = async () => {
    setDisableSubmit(true);
    const data = {
      name,
      alias,
      phone1,
      phone2,
      email,
      site,
      isActivated,
      protheusId,
    };

    if (!id) {
      await apollo.mutate({
        mutation: gql`
          mutation createCarrier($data: CarrierInput!) {
            createCarrier(data: $data) {
                id
            }
          }
        `,
        variables: {
          data
        },
      })
        .then(res => {
          toast.success(intl.formatMessage({ id: "flash.success.created" }));
          history.push(`/carriers/manage/${res.data.createCarrier.id}`);
        })
        .catch(err => error(err));
    } else {
      await apollo.mutate({
        mutation: gql`
          mutation updateCarrier($id: String!, $data: CarrierInput!) {
            updateCarrier(id: $id, data: $data) {
                id
            }
          }
        `,
        variables: {
          id,
          data
        },
      })
        .then(res => {
          toast.success(intl.formatMessage({ id: "flash.success.updated" }));
          history.push(`/carriers`);
        })
        .catch(err => error(err));
    }
    setDisableSubmit(false);
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="pages.carriers.carriers"
        title={`pages.carriers.${preview ? "preview" : id ? "update" : "create"}`}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>
                  <FormattedMessage
                    id={`pages.carriers.${preview ? "preview" : id ? "update" : "create"
                      }`}
                  />
                </h5>
              </CardHeader>
              <CardBody className="tabbed-card">
                <div className="form-content">
                  <Form onSubmit={(e) => e.preventDefault()}>
                    <Row className="m-b-30">
                      <Col>
                        {loading ? <Loading /> : (
                          <React.Fragment>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.name" />
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={name}
                                    disabled={preview}
                                    onChange={(e) => setNome(e.target.value)}
                                    required
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.alias" />
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={alias}
                                    disabled={preview}
                                    onChange={(e) => setAlias(e.target.value)}
                                    required
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.phone" />
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={phone1}
                                    disabled={preview}
                                    onChange={(e) => setPhone1(e.target.value)}
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.telex" />
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={phone2}
                                    disabled={preview}
                                    onChange={(e) => setPhone2(e.target.value)}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.email" />
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={email}
                                    disabled={preview}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.site" />
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={site}
                                    disabled={preview}
                                    onChange={(e) => setSite(e.target.value)}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.protheusId" />
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={protheusId}
                                    disabled={preview}
                                    onChange={(e) =>
                                      setProtheusId(e.target.value)
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.isActivated" />
                                  </Label>
                                  <Col className="checkbox">
                                    <Input
                                      key="isActivated-input"
                                      id="isActivated"
                                      type="checkbox"
                                      value={isActivated ? 1 : 0}
                                      checked={isActivated}
                                      disabled={preview}
                                      onChange={(e) => setAtivado(!isActivated)}
                                    />
                                    <Label
                                      key="isActivated-label"
                                      for="isActivated"
                                      className="d-block"
                                    >Sim</Label>
                                  </Col>
                                </FormGroup>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          type="button"
                          color="danger"
                          onClick={() => history.push("/carriers")}
                        >
                          <i className="fa fa-arrow-left"></i>
                          <FormattedMessage id="buttons.cancel" />
                        </Button>
                      </Col>
                      {canUpdate ? (
                        <Col className="text-right">
                          {preview ? (
                            <Button
                              type="button"
                              color="primary"
                              disabled={loading}
                              onClick={() => setPreview(!preview)}
                            >
                              <i className="fa fa-edit"></i>
                              <FormattedMessage id="buttons.edit" />
                            </Button>
                          ) : (
                            <Button type="button" disabled={disableSubmit || loading} onClick={handleSubmitForm} color="primary">
                              <i className="fa fa-save"></i>
                              <FormattedMessage id="buttons.save" />
                            </Button>
                          )}
                        </Col>) : ""}
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Manage;
