import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Breadcrumb from "../../layout/breadcrumb";
import { CrudParam } from "../../configs/route";
import { useApollo } from "../../services/apollo.service";
import { SITE_TITLE } from "../../configs/constants";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/auth";
import { gql } from "@apollo/client";
import Loading from "../../components/loading";

const Manage: React.FC = () => {
  const isMountedRef = useRef(null);
  const { apollo, error } = useApollo();
  const { id } = useParams<CrudParam>();
  const [name, setName] = useState<string>("");
  const [description1, setDescription1] = useState<string>("");
  const [description2, setDescription2] = useState<string>("");
  const [protheusId, setProtheusId] = useState<string>("");
  const [isActivated, setIsActivated] = useState<boolean>(false);
  const [preview, setPreview] = useState<boolean>(() => { if (id) return true; else return false; });
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const intl = useIntl();

  const { hasScope } = useAuth();
  const [canView] = useState<boolean>(() => {
    return hasScope("PaymentConditions:PaymentCondition");
  });
  const [canCreate] = useState<boolean>(() => {
    return hasScope("PaymentConditions:Create");
  });
  const [canUpdate] = useState<boolean>(() => {
    return hasScope("PaymentConditions:Update");
  });

  const handleData = useCallback(async () => {
    if (id) {
      if (preview && !canView) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/payment-conditions");
        return;
      } else if (!preview && !canUpdate) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/payment-conditions");
        return;
      }
      setLoading(true);
      await apollo.query({
        query: gql`
          query paymentCondition($id: String!) {
            paymentCondition(id: $id) {
              id
              createdAt
              updatedAt
              name
              description1
              description2
              isActivated
              protheusId
            }
          }
        `,
        variables: {
          id
        },
      })
        .then(res => {
          if (isMountedRef.current) {
            setName(res.data.paymentCondition.name || "");
            setDescription1(res.data.paymentCondition.description1 || "");
            setDescription2(res.data.paymentCondition.description2 || "");
            setProtheusId(res.data.paymentCondition.protheusId || "");
            setIsActivated(res.data.paymentCondition.isActivated || false);
          }
        })
        .catch(err => error(err));
      setLoading(false);
    } else {
      if (!preview && !canCreate) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/payment-conditions");
        return;
      }
    }
  }, [intl, id, preview, apollo, error, history, canView, canCreate, canUpdate]);

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: `pages.paymentConditions.${preview ? "preview" : id ? "update" : "create"}`,
      })}`;
    handleData();
    return () => { isMountedRef.current = false; }
  }, [intl, id, preview, handleData]);

  const handleSubmitForm = async () => {
    setDisableSubmit(true);
    const data = {
      name,
      description1,
      description2,
      protheusId,
      isActivated,
    };

    if (!id) {
      await apollo.mutate({
        mutation: gql`
          mutation createPaymentCondition($data: PaymentConditionInput!) {
            createPaymentCondition(data: $data) {
                id
            }
          }
        `,
        variables: {
          data
        },
      })
        .then(res => {
          toast.success(intl.formatMessage({ id: "flash.success.created" }));
          history.push(`/payment-conditions/manage/${res.data.createPaymentCondition.id}`);
        })
        .catch(err => error(err));
    } else {
      await apollo.mutate({
        mutation: gql`
          mutation updatePaymentCondition($id: String!, $data: PaymentConditionInput!) {
            updatePaymentCondition(id: $id, data: $data) {
                id
            }
          }
        `,
        variables: {
          id,
          data
        },
      })
        .then(res => {
          toast.success(intl.formatMessage({ id: "flash.success.updated" }));
          history.push(`/payment-conditions`);
        })
        .catch(err => error(err));
    }
    setDisableSubmit(false);
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="pages.paymentConditions.paymentConditions"
        title={`pages.paymentConditions.${preview ? "preview" : id ? "update" : "create"}`}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>
                  <FormattedMessage
                    id={`pages.paymentConditions.${preview ? "preview" : id ? "update" : "create"
                      }`}
                  />
                </h5>
              </CardHeader>
              <CardBody className="tabbed-card">
                <div className="form-content">
                  <Form onSubmit={(e) => e.preventDefault()}>
                    <Row className="m-b-30">
                      <Col>
                        {loading ? <Loading /> : (
                          <React.Fragment>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.protheusId" />
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={protheusId}
                                    disabled={preview}
                                    onChange={(e) =>
                                      setProtheusId(e.target.value)
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.name" />
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={name}
                                    disabled={preview}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.description1" />
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={description1}
                                    disabled={preview}
                                    onChange={(e) =>
                                      setDescription1(e.target.value)
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.description2" />
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={description2}
                                    disabled={preview}
                                    onChange={(e) => setDescription2(e.target.value)}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.isActivated" />
                                  </Label>
                                  <Col className="checkbox">
                                    <Input
                                      key="isActivated-input"
                                      id="isActivated"
                                      type="checkbox"
                                      value={isActivated ? 1 : 0}
                                      checked={isActivated}
                                      disabled={preview}
                                      onChange={(e) => setIsActivated(!isActivated)}
                                    />
                                    <Label
                                      key="isActivated-label"
                                      for="isActivated"
                                      className="d-block"
                                    >Sim</Label>
                                  </Col>
                                </FormGroup>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          type="button"
                          color="danger"
                          onClick={() => history.push("/payment-conditions")}
                        >
                          <i className="fa fa-arrow-left"></i>
                          <FormattedMessage id="buttons.cancel" />
                        </Button>
                      </Col>
                      {canUpdate ? (
                        <Col className="text-right">
                          {preview ? (
                            <Button
                              type="button"
                              color="primary"
                              disabled={loading}
                              onClick={() => setPreview(!preview)}
                            >
                              <i className="fa fa-edit"></i>
                              <FormattedMessage id="buttons.edit" />
                            </Button>
                          ) : (
                            <Button type="button" disabled={disableSubmit || loading} onClick={handleSubmitForm} color="primary">
                              <i className="fa fa-save"></i>
                              <FormattedMessage id="buttons.save" />
                            </Button>
                          )}
                        </Col>) : ""}
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Manage;
