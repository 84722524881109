import React from "react";
import styled from "styled-components";

const Div = styled.div`
  font-size: .7rem;
  padding: 30px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
`;

const Loading: React.FC = () => (<Div>
  <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
  <span className="sr-only">Carregando...</span>
</Div>);

export default Loading;
