import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import Breadcrumb from "../layout/breadcrumb";
import { Container, Row, Col, Card, CardBody, CardHeader, Table, Input } from "reactstrap";
import { useIntl } from "react-intl";
import { convert_to_date, DATE_FORMAT_BR, format_date, GRAPHQL_SERVER, SITE_TITLE, TIME_FORMAT_BR } from "../configs/constants";
import { Clock } from "react-feather";
import { useSelector } from "react-redux";
import { ICombineReducers } from "../redux";
import userIcon from "../assets/images/user/user.svg";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { useApollo } from "../services/apollo.service";
import { gql } from "@apollo/client";
import Notification from "../models/notification";
import ReactMarkdown from "react-markdown";
import pt_BR from "date-fns/locale/pt-BR";
import Loading from "../components/loading";
import { Credentials } from "../redux/types";

const Home: React.FC = () => {
  const { apollo, error } = useApollo();
  const isMountedRef = useRef(null);
  const intl = useIntl();
  const locale = useSelector((state: ICombineReducers) => state.State.locale);
  const credentials: Credentials = useSelector((state: ICombineReducers) => state.DataSet.credentials);

  const [daytimes, setDayTimes] = useState("")
  const today = new Date()
  const curHr = today.getHours()
  const curMi = today.getMinutes()
  const [meridiem, setMeridiem] = useState("AM")
  const [loadingNotifications, setLoadingNotifications] = useState<boolean>(false);

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [notificationsBy, setNotificationsBy] = useState<string>("today");

  const handleNotifications = useCallback(async () => {
    setLoadingNotifications(true);
    await apollo.query({
      query: gql`
        query notifications($sortDir: String, $sortBy: String, $perPage: Int, $page: Int, $filterDateBy: String) {
          notifications(sortDir: $sortDir, sortBy: $sortBy, perPage: $perPage, page: $page, filterDateBy: $filterDateBy) {
            paging {
              total
              pages
              perPage
              currentPage
            }
            list {
              id
              createdAt
              updatedAt
              subject
              isFeatured
              description
              notificatedAt
            }
          }
        }
      `,
      variables: {
        page: 1,
        perPage: 15,
        sortBy: "notificatedAt",
        sortDir: "DESC",
        filterDateBy: notificationsBy
      }
    })
      .then(res => {
        if (isMountedRef.current) {
          setNotifications(res.data.notifications.list || []);
        }
      })
      .catch(err => error(err));
    setLoadingNotifications(false);
  }, [apollo, error, notificationsBy]);

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: "general.home",
      })}`;
    if (locale === "pt") {
      registerLocale("pt-BR", pt_BR);
      setDefaultLocale("pt-BR");
    }
    if (curHr < 12) {
      setDayTimes('Bom dia')
    } else if (curHr < 18) {
      setDayTimes('Boa tarde')
    } else {
      setDayTimes('Boa noite')
    }

    if (curHr >= 12) {
      setMeridiem('PM')
    } else {
      setMeridiem('AM')
    }

    handleNotifications();
    return () => { isMountedRef.current = false; }
  }, [intl, curHr, handleNotifications, locale]);

  return (
    <Fragment>
      <Breadcrumb parent="general.dashboards" title="general.home" />
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update">
          <Col xl="4 xl-50" lg="12" className="morning-sec box-col-12">
            <Card className="o-hidden profile-greeting">
              <CardBody>
                <div className="media">
                  <div className="badge-groups w-100">
                    <div className="badge f-12">
                      <Clock style={{ width: "16px", height: "16px" }} className="mr-1" />
                      <span id="txt">{curHr}:{curMi < 10 ? "0" + curMi : curMi} {meridiem}</span>
                    </div>
                  </div>
                </div>
                <div className="greeting-user text-center">
                  <div className="profile-vector">
                    {credentials?.user?.path ? (<img className="img-fluid" src={`${GRAPHQL_SERVER}/upload/${credentials?.user?.path}`} width="100" alt={credentials?.user?.name} />) : (<img className="img-fluid" width="100" src={userIcon} alt={credentials?.user?.name} />)}
                  </div>
                  <h4 className="f-w-600"><span id="greeting">{daytimes} {credentials?.user?.name}</span> <span className="right-circle"><i className="fa fa-check-circle f-14 middle"></i></span></h4>
                  <p><span> &nbsp;</span></p>
                  <br />
                </div>
              </CardBody>
            </Card>
          </Col>
          {credentials?.user?.metas?.length || credentials?.user?.saleTeams?.length ? (
            <Col xl="4 xl-50" className="metas box-col-6">
              <Card>
                <CardHeader className="card-no-border">
                  <div className="header-top">
                    <h5 className="m-0">Metas</h5>
                  </div>
                </CardHeader>
                <CardBody className="pt-0">
                  {credentials?.user?.metas?.length ? (
                    <Fragment>
                      <strong>METAS PESSOAIS</strong><br /><br />
                      <div className="table-responsive">
                        <Table>
                          <thead>
                            <tr>
                              <th scope="col">Descrição</th>
                              <th scope="col">Período</th>
                              <th scope="col">Montante</th>
                            </tr>
                          </thead>
                          <tbody>
                            {credentials.user.metas.map(meta => (
                              <tr key={meta.id}>
                                <td>{meta.name}</td>
                                <td>{format_date(meta.startAt, DATE_FORMAT_BR)} à {format_date(meta.endAt, DATE_FORMAT_BR)}</td>
                                <td>R${intl.formatNumber(meta.total, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                      <br />
                    </Fragment>
                  ) : ""}
                  {credentials?.user?.saleTeams?.length ? (
                    <Fragment>
                      <strong>METAS EM  DE GRUPO</strong><br /><br />
                      <div className="table-responsive">
                        <Table>
                          <thead>
                            <tr>
                              <th scope="col">Descrição</th>
                              <th scope="col">Período</th>
                              <th scope="col">Montante</th>
                            </tr>
                          </thead>
                          <tbody>
                            {credentials.user.saleTeams.map(team => {
                              if (team.metas.length) {
                                return team.metas.map(meta => (
                                  <tr key={meta.id}>
                                    <td>{meta.name}</td>
                                    <td>{format_date(meta.startAt, DATE_FORMAT_BR)} à {format_date(meta.endAt, DATE_FORMAT_BR)}</td>
                                    <td>R${intl.formatNumber(meta.total, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                                  </tr>
                                ));
                              }
                              return "";
                            })}
                          </tbody>
                        </Table>
                      </div>
                      <br />
                    </Fragment>
                  ) : ""}
                </CardBody>
              </Card>
            </Col>
          ) : ""}
          <Col xl="4 xl-50" className="notification box-col-6">
            <Card>
              <CardHeader className="card-no-border">
                <div className="header-top">
                  <h5 className="m-0">Avisos</h5>
                  <div className="card-header-right-icon">
                    <Input
                      type="select"
                      onChange={async e => {
                        setNotificationsBy(e.target.value);
                        await handleNotifications();
                      }}
                      value={notificationsBy}
                      className="button btn btn-primary">
                      <option value="today">Hoje</option>
                      <option value="tomorrow">Amanhã</option>
                      <option value="yesterday">Ontem</option>
                    </Input>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                {loadingNotifications ? <Loading /> : (notifications?.length ? notifications?.map((notification) => (
                  <div key={notification.id} className="media">
                    <div className="media-body">
                      <p>{format_date(convert_to_date(notification.notificatedAt), DATE_FORMAT_BR)} <span>{format_date(convert_to_date(notification.notificatedAt), TIME_FORMAT_BR)}</span>{notification.isFeatured ? <span className="badge badge-secondary">Novo</span> : ""}</p>
                      <h6>{notification.subject}</h6>
                      <span><ReactMarkdown>{notification.description}</ReactMarkdown></span>
                    </div>
                  </div>
                )) : <div style={{ padding: "10px" }}>Não existem avisos para o período selecionado.</div>)}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment >
  );
};

export default Home;
