import React, {
  Fragment,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Input, Button } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { IDataTableColumn } from "react-data-table-component";
import SweetAlert from "sweetalert2";
import { useApollo } from "../../services/apollo.service";
import { useHistory } from "react-router-dom";
import Order from "../../models/order";
import Listing from "../../components/listing";
import { DATE_FORMAT_BR, format_date, RECORDS_PER_PAGE, SITE_TITLE } from "../../configs/constants";
import { useAuth } from "../../hooks/auth";
import { gql } from "@apollo/client";
import { useDispatch } from "react-redux";
import { dataTableLoading } from "../../redux/actions";
import Client from "../../models/client";

interface OrdersFiler {
  code?: string;
  client?: string;
  step?: string;
}

const Orders: React.FC = () => {
  const isMountedRef = useRef(null);
  const dispatch = useDispatch();
  const { apollo, error } = useApollo();
  const intl = useIntl();
  const history = useHistory();
  const [selectedRows, setSelectedRows] = useState<Order[]>([]);
  const [toggleCleared, setToggleCleared] = useState<boolean>(false);
  const [disableSubmitSearch, setDisableSubmitSearch] = useState<boolean>(false);
  const [data, setData] = useState<Order[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(RECORDS_PER_PAGE);
  const [sortBy, setSortBy] = useState<string>("createdAt");
  const [sortDir, setSortDir] = useState<string>("DESC");
  const [clients, setClients] = useState<Client[]>([]);
  const [filters, setFilters] = useState<OrdersFiler>({});
  const [filters2, setFilters2] = useState<OrdersFiler>({});

  const { hasScope } = useAuth();

  const handleSearch = () => {
    setFilters2(filters);
  }

  const handleData = useCallback(async () => {
    dispatch(dataTableLoading(true));
    await Promise.all([
      apollo.query({
        query: gql`
          query clientsDropdown ($withBudgets: Boolean) {
            clientsDropdown(withBudgets: $withBudgets) {
              id
              companyName
              protheusId
            }
          }
        `,
        variables: {
          withBudgets: true
        }
      }),
      apollo.query({
        query: gql`
          query orders($sortDir: String, $sortBy: String, $perPage: Int, $page: Int, $filterByCode: String, $filterByClient: String, $filterByStep: String) {
            orders(sortDir: $sortDir, sortBy: $sortBy, perPage: $perPage, page: $page, filterByCode: $filterByCode, filterByClient: $filterByClient, filterByStep: $filterByStep) {
              paging {
                total
                pages
                perPage
                currentPage
              }
              list {
                id
                createdAt
                updatedAt
                storeId
                clientId
                step
                stepDescription
                client {
                  id
                  companyName
                }
                fiscalNote
                total
                quantityTotal
              }
            }
          }
        `,
        variables: {
          page,
          perPage,
          sortBy,
          sortDir,
          filterByCode: filters2?.code,
          filterByClient: filters2?.client,
          filterByStep: filters2?.step
        }
      })
    ])
      .then(res => {
        if (isMountedRef.current) {
          setClients(res[0].data.clientsDropdown || []);
          setTotal(res[1].data.orders.paging.total);
          setPage(res[1].data.orders.paging.currentPage);
          setPerPage(res[1].data.orders.paging.perPage);
          setData(res[1].data.orders.list);
        }
      })
      .catch(err => error(err));
    dispatch(dataTableLoading(false));
  }, [apollo, error, page, perPage, sortBy, sortDir, dispatch, filters2]);

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: "pages.orders.orders",
      })}`;
    handleData();
    return () => { isMountedRef.current = false; }
  }, [intl, handleData]);

  const tableColumns: IDataTableColumn<Order>[] = [
    {
      name: intl.formatMessage({ id: "inputs.protheusId" }),
      selector: "id",
      sortable: true,
      center: false,
      width: "100px",
    },
    {
      name: intl.formatMessage({ id: "inputs.client" }),
      selector: "client",
      sortable: true,
      center: false,
      format: (row) => {
        return row.client?.companyName ? `${row.clientId} - ${row.client.companyName}` : row.clientId
      },
    },
    {
      name: intl.formatMessage({ id: "inputs.step" }),
      selector: "stepDescription",
      sortable: true,
      center: true,
      width: "120px",
    },
    {
      name: intl.formatMessage({ id: "inputs.qty" }),
      selector: "quantityTotal",
      sortable: false,
      center: true,
      width: "100px",
    },
    {
      name: intl.formatMessage({ id: "inputs.total" }),
      selector: "total",
      sortable: false,
      right: true,
      width: "150px",
      format: (row) => {
        return intl.formatNumber(row.total, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
      }
    },
    {
      name: intl.formatMessage({ id: "inputs.createdAt" }),
      selector: "createdAt",
      sortable: true,
      center: false,
      width: "180px",
      format: (row) => {
        return format_date(row.createdAt, DATE_FORMAT_BR)
      }
    },
  ];

  const handleUpdateRecord = (row: Order) => {
    if (hasScope("Orders:Order"))
      history.push(`/orders/manage/${row.id}`);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      SweetAlert.fire({
        title: intl.formatMessage({ id: "general.exclusion" }),
        text: intl.formatMessage({ id: "flash.confirm.delete" }),
        icon: "error",
        cancelButtonText: intl.formatMessage({ id: "general.no" }),
        confirmButtonText: intl.formatMessage({ id: "general.yes" }),
        reverseButtons: true,
        showCancelButton: true,
      })
        .then(async ({ isConfirmed }) => {
          if (isConfirmed) {
            for (let row of selectedRows) {
              await apollo.mutate({
                mutation: gql`
                  mutation deleteOrder($id: String!) {
                    deleteOrder(id: $id) {
                        id
                    }
                  }
                `,
                variables: {
                  id: row.id
                },
              })
                .then(res => {
                  toast.success(
                    intl.formatMessage(
                      { id: "flash.success.deletedParam" },
                      { description: row.id }
                    )
                  );
                })
                .catch(err => error(err));
            }
            setToggleCleared(!toggleCleared);
            handleData();
          }
        });
    };

    return (
      <button key="delete" className="btn btn-danger" onClick={handleDelete}>
        <FormattedMessage id="buttons.delete" />
      </button>
    );
  }, [selectedRows, apollo, error, toggleCleared, intl, handleData]);

  return (
    <Fragment>
      <Breadcrumb parent="general.home" title="pages.orders.orders" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>
                  <FormattedMessage id="pages.orders.list" />
                </h5>
              </CardHeader>
              <CardBody>
                <Form onSubmit={async (e) => {
                  e.preventDefault();
                  setDisableSubmitSearch(true);
                  await handleSearch();
                  setDisableSubmitSearch(false);
                }}>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label">
                          <FormattedMessage id="inputs.code" />
                        </Label>
                        <Input
                          key="code"
                          className="form-control"
                          type="text"
                          value={filters?.code || ""}
                          disabled={disableSubmitSearch}
                          onChange={(e) => {
                            setFilters({ ...filters, code: e.target.value });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="col-form-label">
                          <FormattedMessage id="inputs.client" />
                        </Label>
                        <Input
                          key="client"
                          className="form-control"
                          type="select"
                          value={filters?.client || ""}
                          disabled={disableSubmitSearch}
                          onChange={(e) => {
                            setFilters({ ...filters, client: e.target.value });
                          }}
                        >
                          <option value="">-</option>
                          {clients?.map((client: Client) => <option key={client.id} value={client.protheusId}>{client.protheusId} - {client.companyName}</option>)}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col className="text-right">
                      <FormGroup>
                        <Label className="col-form-label" style={{ display: "block", width: "100%" }}>
                          &nbsp;
                        </Label>
                        <div>
                          <Button type="submit" disabled={disableSubmitSearch} color="secondary">
                            <i className="fa fa-search"></i> Filtrar
                          </Button>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                <Listing
                  data={data}
                  total={total}
                  rowsPerPage={perPage}
                  onChangePage={(page: number) => {
                    setPage(page);
                  }}
                  onChangeRowsPerPage={(rowsPerPage: number) => {
                    setPage(1);
                    setPerPage(rowsPerPage);
                  }}
                  onSort={(
                    column,
                    sortDir
                  ) => {
                    setSortBy(column.selector.toString());
                    setSortDir(sortDir);
                  }}
                  columns={tableColumns}
                  onRowClicked={handleUpdateRecord}
                  contextActions={contextActions}
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleCleared}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Orders;
