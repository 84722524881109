import React, {
  Fragment,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Form, Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, Input, Label } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { useApollo } from "../../services/apollo.service";
import { convert_to_date, date, now, SITE_TITLE } from "../../configs/constants";
import { useAuth } from "../../hooks/auth";
import { gql } from "@apollo/client";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment-timezone';
import 'moment/locale/pt-br';
import Schedule from "../../models/schedule";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import pt_BR from "date-fns/locale/pt-BR";
import Client from "../../models/client";
import Autosuggest from "react-autosuggest";
import { useSelector } from "react-redux";
import { ICombineReducers } from "../../redux";
import Loading from "../../components/loading";

const Calendars: React.FC = () => {
  const isMountedRef = useRef(null);
  const { apollo, error } = useApollo();
  const intl = useIntl();
  const { hasScope } = useAuth();
  const locale = useSelector((state: ICombineReducers) => state.State.locale);

  const [modal, setModal] = useState<boolean>(false);
  const [data, setData] = useState<Schedule[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [start, setStart] = useState<Date>();
  const [end, setEnd] = useState<Date>();
  const [disableDates, setDisableDates] = useState<boolean>(false);
  const [allDay, setAllDay] = useState<boolean>(false);
  const [id, setId] = useState<string>("");
  const [clientId, setClientId] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [suggestionValue, setSuggestionValue] = useState<string>("");
  const [suggestions, setSuggestions] = useState<Client[]>([]);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const toggle = () => setModal(!modal);

  const [canCreate] = useState<boolean>(() => {
    return hasScope("Calendars:Create");
  });
  const [canUpdate] = useState<boolean>(() => {
    return hasScope("Calendars:Update");
  });
  const [canDelete] = useState<boolean>(() => {
    return hasScope("Calendars:Delete");
  });

  const handleSchedule = useCallback(async (id: string) => {
    setId(id);
    setDisableButton(true);
    await apollo.query({
      query: gql`
        query calendar ($id: String!) {
          calendar (id: $id) {
              id
              createdAt
              updatedAt
              title
              allDay
              start
              end
              client {
                id
                document
                companyName
                fantasyName
                protheusId
              }
              user {
                id
                name
                surname
                protheusId
              }
          }
        }
      `,
      variables: {
        id
      }
    })
      .then(res => {
        if (isMountedRef.current) {
          const startDate = convert_to_date(res.data.calendar.start);
          const endDate = convert_to_date(res.data.calendar.end);
          setStart(startDate);
          setEnd(endDate);
          setAllDay(res.data.calendar.allDay);
          setTitle(res.data.calendar.title);
          setClientId(res.data.calendar.client?.id || "");
          setDisableDates(res.data.calendar.allDay);
          if (res.data.calendar.client?.id) {
            setSuggestions([res.data.calendar.client]);
            setSuggestionValue(`${res.data.calendar.client.protheusId} - ${res.data.calendar.client.companyName}`);
          }
          setModal(!modal);
        }
      })
      .catch(err => error(err));
    setDisableButton(false);
  }, [apollo, error, modal]);

  const handleData = useCallback(async () => {
    setLoading(true);
    await Promise.all([
      apollo.query({
        query: gql`
        query calendars {
          calendars {
              id
              createdAt
              updatedAt
              title
              allDay
              start
              end
              client {
                id
                document
                companyName
                fantasyName
                protheusId
              }
              user {
                id
                name
                surname
                protheusId
              }
          }
        }
      `,
      }),
      apollo.query({
        query: gql`
        query clientsDropdown {
          clientsDropdown {
            id
            document
            companyName
            fantasyName
            protheusId
          }
        }
      `,
      }),
    ])
      .then(res => {
        if (isMountedRef.current) {
          let dataArray: Schedule[] = [];
          if (res[0].data.calendars.length) {
            for (let item of res[0].data.calendars) {
              const startDate = convert_to_date(item.start);
              const endDate = convert_to_date(item.end);
              const createdAt = convert_to_date(item.createdAt);
              const updatedAt = convert_to_date(item.updatedAt);
              item.start = startDate;
              item.end = endDate;
              item.createdAt = createdAt;
              item.updatedAt = updatedAt;
              dataArray.push(item);
            }
          }
          setData(dataArray);
          setClients(res[1].data.clientsDropdown);
        }
      })
      .catch(err => error(err));
    setLoading(false);
  }, [apollo, error]);

  const getSuggestions = (value: string) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength === 0)
      return [];

    const test1 = clients.filter(client =>
      client.protheusId.slice(0, inputLength) === inputValue);
    const test2 = clients.filter(client =>
      client.document.slice(0, inputLength) === inputValue);
    const test3 = clients.filter(client =>
      client.companyName.toLowerCase().slice(0, inputLength) === inputValue);

    return test1.length ? test1 : (test2.length ? test2 : (test3.length ? test3 : []));
  };

  const localizer = momentLocalizer(moment);

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: "pages.calendars.calendars",
      })}`;
    if (locale === "pt") {
      registerLocale("pt-BR", pt_BR);
      setDefaultLocale("pt-BR");
    }
    handleData();
    return () => { isMountedRef.current = false; }
  }, [intl, handleData, locale]);

  const handleDelete = useCallback(async () => {
    setModal(!modal);
    setDisableButton(true);
    SweetAlert.fire({
      title: intl.formatMessage({ id: "general.exclusion" }),
      text: intl.formatMessage({ id: "flash.confirm.delete" }),
      icon: "error",
      cancelButtonText: intl.formatMessage({ id: "general.no" }),
      confirmButtonText: intl.formatMessage({ id: "general.yes" }),
      reverseButtons: true,
      showCancelButton: true,
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed)
        await apollo.mutate({
          mutation: gql`
            mutation deleteCalendar($id: String!) {
              deleteCalendar(id: $id) {
                  id
              }
            }
          `,
          variables: {
            id
          },
        })
          .then(async res => {
            await handleData();
            toast.success(intl.formatMessage({ id: "flash.success.deleted" }));
          })
          .catch(err => error(err));
    });
    setDisableButton(false);
  }, [apollo, error, id, intl, modal, handleData]);

  const handleSubmitForm = useCallback(async () => {
    setDisableButton(true);
    const submitData = {
      title,
      start,
      end,
      allDay,
      clientId,
    };
    if (!id) {
      await apollo.mutate({
        mutation: gql`
            mutation createCalendar($data: CalendarInput!) {
              createCalendar(data: $data) {
                id
                createdAt
                updatedAt
                title
                allDay
                start
                end
                client {
                  id
                  document
                  companyName
                  fantasyName
                  protheusId
                }
                user {
                  id
                  name
                  surname
                  protheusId
                }
              }
            }
          `,
        variables: {
          data: submitData
        },
      })
        .then(res => {
          if (isMountedRef.current) {
            const startDate = convert_to_date(res.data.createCalendar.start);
            const endDate = convert_to_date(res.data.createCalendar.end);
            const createdAt = convert_to_date(res.data.createCalendar.createdAt);
            const updatedAt = convert_to_date(res.data.createCalendar.updatedAt);
            res.data.createCalendar.start = startDate;
            res.data.createCalendar.end = endDate;
            res.data.createCalendar.createdAt = createdAt;
            res.data.createCalendar.updatedAt = updatedAt;
            data.push(res.data.createCalendar);
            setData(data);
            toast.success(intl.formatMessage({ id: "flash.success.created" }));
            setModal(!modal);
          }
        })
        .catch(err => error(err));
    } else {
      await apollo.mutate({
        mutation: gql`
          mutation updateCalendar($id: String!, $data: CalendarInput!) {
            updateCalendar(id: $id, data: $data) {
              id
              createdAt
              updatedAt
              title
              allDay
              start
              end
              client {
                id
                document
                companyName
                fantasyName
                protheusId
              }
              user {
                id
                name
                surname
                protheusId
              }
            }
          }
        `,
        variables: {
          id,
          data: submitData
        },
      })
        .then(res => {
          if (isMountedRef.current) {
            const index = data.findIndex(e => e.id === res.data.updateCalendar.id);
            console.log(index);
            if (index !== -1) {
              const startDate = convert_to_date(res.data.updateCalendar.start);
              const endDate = convert_to_date(res.data.updateCalendar.end);
              const createdAt = convert_to_date(res.data.updateCalendar.createdAt);
              const updatedAt = convert_to_date(res.data.updateCalendar.updatedAt);
              res.data.updateCalendar.start = startDate;
              res.data.updateCalendar.end = endDate;
              res.data.updateCalendar.createdAt = createdAt;
              res.data.updateCalendar.updatedAt = updatedAt;
              data[index] = res.data.updateCalendar;
              setData(data);
              toast.success(intl.formatMessage({ id: "flash.success.updated" }));
            }
            setModal(!modal);
          }
        })
        .catch(err => error(err));
    }
    setDisableButton(false);
  }, [allDay, apollo, clientId, end, error, id, intl, start, title, modal, data]);

  return (
    <Fragment>
      <Breadcrumb parent="general.home" title="pages.calendars.calendars" />
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}><FormattedMessage id="pages.calendars.create" /></ModalHeader>
        <Form onSubmit={async (e) => {
          e.preventDefault();
          await handleSubmitForm();
        }}>
          <ModalBody>
            <Container fluid={true}>
              <Row>
                <Col>
                  <FormGroup className="form-row">
                    <Label className="col-form-label">
                      <FormattedMessage id="inputs.startDate" />
                    </Label>
                    <div className="datepicker-time">
                      <DatePicker
                        placeholderText={intl.formatMessage({
                          id: "inputs.startDate",
                        })}
                        className="form-control digits"
                        showPopperArrow={false}
                        selected={start}
                        disabled={disableDates}
                        onChange={(date: Date) => setStart(date)}
                        dateFormat="dd/MM/yyyy HH:mm"
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        autoComplete="off"
                        timeInputLabel="Hora"
                        required
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="form-row">
                    <Label className="col-form-label">
                      <FormattedMessage id="inputs.endDate" />
                    </Label>
                    <div className="datepicker-time">
                      <DatePicker
                        placeholderText={intl.formatMessage({
                          id: "inputs.endDate",
                        })}
                        className="form-control digits"
                        showPopperArrow={false}
                        selected={end}
                        disabled={disableDates}
                        onChange={(date: Date) => setEnd(date)}
                        dateFormat="dd/MM/yyyy HH:mm"
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        autoComplete="off"
                        timeInputLabel="Hora"
                        required
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label className="col-form-label">
                      <FormattedMessage id="inputs.title" />
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label className="col-form-label">
                      <FormattedMessage id="inputs.client" />
                    </Label>
                    <div>
                      <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={({ value }) => {
                          setSuggestions(getSuggestions(value));
                        }}
                        onSuggestionsClearRequested={() => {
                          setSuggestions([]);
                        }}
                        getSuggestionValue={(suggestion) => {
                          setClientId(suggestion.id);
                          return `${suggestion.protheusId} - ${suggestion.companyName}`;
                        }}
                        renderSuggestion={(suggestion) => {
                          return (
                            <div>
                              {suggestion.protheusId} - {suggestion.companyName}
                            </div>
                          );
                        }}
                        inputProps={{
                          placeholder: 'Digite a razão social, CPF/CNPJ ou código do cliente.',
                          value: suggestionValue,
                          className: "form-control",
                          onChange: (event, { newValue }) => {
                            setSuggestionValue(newValue);
                          }
                        }}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label className="col-form-label">
                      <FormattedMessage id="inputs.allDay" />?
                    </Label>
                    <Col className="checkbox">
                      <Input
                        key="allDay-input"
                        id="allDay"
                        type="checkbox"
                        checked={allDay}
                        value={1}
                        onChange={(e) => {
                          const _allDay = !allDay;
                          setAllDay(!allDay);
                          if (_allDay) {
                            setDisableDates(true);
                            const todayDate = date().format("YYYY-MM-DD");
                            const startDate = convert_to_date(`${todayDate} 00:00:00`);
                            const endDate = convert_to_date(`${todayDate} 23:59:59`);
                            setStart(startDate);
                            setEnd(endDate);
                          } else {
                            setDisableDates(false);
                          }
                        }}
                      />
                      <Label
                        key="allDay-label"
                        for="allDay"
                        className="d-block"
                      >Sim</Label>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            {(id && canDelete) ? <Button color="danger" disabled={disableButton} onClick={handleDelete}>Excluir</Button> : ""}
            <Button type="submit" disabled={disableButton} color="primary">Salvar</Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>
                  <FormattedMessage id="pages.calendars.list" />
                </h5>
              </CardHeader>
              <CardBody>
                {loading ? <Loading /> : (
                  <React.Fragment>
                    <Calendar
                      localizer={localizer}
                      culture="pt-BR"
                      messages={{
                        date: "Data",
                        time: "Hora",
                        event: "Evento",
                        allDay: "Dia todo",
                        week: "Semana",
                        work_week: "Dias úteis",
                        day: "Dia",
                        month: "Mês",
                        previous: "Anterior",
                        next: "Próximo",
                        yesterday: "Ontem",
                        tomorrow: "Amanhã",
                        today: "Hoje",
                        agenda: "Agendado",
                        showMore: (count: number) => `Exibir mais ${count}`,
                        noEventsInRange: "Não há nenhum agendamento cadastrado no intervalo.",
                      }}
                      defaultDate={convert_to_date(now())}
                      onSelectEvent={async (e) => {
                        if (canUpdate)
                          await handleSchedule(e.id);
                      }}
                      events={data}
                      scrollToTime={convert_to_date(now())}
                      onSelectSlot={({
                        start,
                        end,
                      }) => {
                        if (canCreate) {
                          const today = convert_to_date(now());
                          const todayDate = date().format("YYYY-MM-DD");
                          const startTodayDate = date(start).format("YYYY-MM-DD");
                          let startDate = convert_to_date(start);
                          let endDate = convert_to_date(end);

                          if (todayDate === startTodayDate && (startDate.getTime() <= today.getTime())) {
                            startDate = today;
                            endDate = convert_to_date(date().add(30, "minutes").format("YYYY-MM-DD HH:mm:ss"));
                          }
                          if (startDate.getTime() < today.getTime()) {
                            toast.error("Você não pode fazer um agendamento retroativo!");
                          } else {
                            setId("");
                            setSuggestionValue("");
                            setSuggestions([]);
                            setStart(startDate);
                            setEnd(endDate);
                            setAllDay(false);
                            setTitle("");
                            setClientId("");
                            setDisableDates(false);
                            toggle();
                          }
                        }

                      }}
                      selectable
                      showMultiDayTimes
                      step={60}
                      startAccessor="start"
                      endAccessor="end"
                      defaultView="month"
                    />
                  </React.Fragment>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Calendars;
