import React, { useState, createContext, useEffect } from "react";
import { IntlProvider } from "react-intl";
import { MessageFormatElement } from "intl-messageformat-parser";
import flatten from "flat";

import en from "../../lang/en.json";
import pt from "../../lang/pt.json";
import { useDispatch } from "react-redux";
import { changeLocale } from "../../redux/actions";
import { DEFAULT_LOCALE } from "../../configs/constants";

export const menuMessages: any = {
  en: flatten(en),
  pt: flatten(pt),
};

export const localeArray: any = {
  en: "English",
  pt: "Português (Brasil)",
};

export interface IIntlContext {
  locale: string;
  messages: Record<string, string> | Record<string, MessageFormatElement[]>;
  localesArray: any;
  switchLanguage(lang: string): void;
}

const IntlContext = createContext<IIntlContext>({
  locale: DEFAULT_LOCALE,
  messages: menuMessages[DEFAULT_LOCALE],
  localesArray: localeArray,
  switchLanguage: (lang: string) => { },
} as IIntlContext);

const IntlProviderWrapper: React.FC = ({ children }) => {
  const localesArray: any = localeArray;
  const [locale, setLocale] = useState<string>(() => {
    return localStorage.getItem("lang") || DEFAULT_LOCALE;
  });
  const [messages, setMessages] = useState<any>(menuMessages[DEFAULT_LOCALE]);
  const dispatch = useDispatch();
  const switchLanguage = (lang: string): void => {
    localStorage.setItem("lang", lang);
    setLocale(lang);
    setMessages(menuMessages[lang]);
    dispatch(changeLocale(lang));
  };
  useEffect(() => {
    dispatch(changeLocale(locale));
  }, [locale, dispatch]);
  return (
    <IntlContext.Provider
      value={{
        locale,
        messages,
        localesArray,
        switchLanguage,
      }}
    >
      <IntlProvider
        key={locale}
        locale={locale}
        messages={messages}
        defaultLocale={DEFAULT_LOCALE}
      >
        {children}
      </IntlProvider>
    </IntlContext.Provider>
  );
};

export { IntlProviderWrapper, IntlContext };
