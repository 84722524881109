import React, { createContext, useState, useContext } from "react";
import { toast } from "react-toastify";
import User from "../models/user";
import jwt from "jsonwebtoken";
import Token from "../models/token";
import { useDispatch, useSelector } from "react-redux";
import { AUTH_SECRET, IS_DEVELOPMENT, WEB_PORT } from "../configs/constants";
import { loadCredentials } from "../redux/actions";
import { ICombineReducers } from "../redux";
import { useApollo } from "../services/apollo.service";
import { gql } from "@apollo/client";
import pwdStrength from "check-password-strength";

interface IAuthContext {
  logged: boolean;
  superAdmin: boolean;
  user: User | null;
  token: Token | null;
  signIn(
    email: string,
    password: string,
    remember: boolean,
  ): Promise<boolean>;
  signOut(message?: string): void;
  signUp(
    name: string,
    surname: string,
    document: string,
    phone: string | null,
    mobile: string | null,
    birthDate: Date | null,
    email: string,
    password: string,
    passwordConfirm: string
  ): Promise<boolean>;
  recoverPassword(email: string): Promise<boolean>;
  resetPassword(
    email: string,
    password: string,
    passwordConfirm: string,
    code: string
  ): Promise<boolean>;
  hasScope(scope: string): boolean;
  hasScopes(scopes: string[]): boolean;
  hasAnyScopes(scopes: string[]): boolean;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

const AuthProvider: React.FC = ({ children }) => {
  const { apollo, error } = useApollo();
  const dispatch = useDispatch();
  const credentials = useSelector((state: ICombineReducers) => state.DataSet.credentials);

  const [logged, setLogged] = useState<boolean>(() => {
    const user = credentials.user;
    const token = credentials.token.token;
    if (user && token) {
      try {
        const tokenData = jwt.verify(token, AUTH_SECRET) as Token;
        const dataExpiracao = new Date(tokenData.exp * 1000);
        if (dataExpiracao > new Date()) return true;
      } catch {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        dispatch(loadCredentials({
          user: null,
          token: {
            token: null,
            data: null
          }
        }));
      }
    }
    return false;
  });
  const [user, setUser] = useState<User | null>(() => {
    const user = credentials.user;
    if (user) {
      try {
        return user;
      } catch { }
    }
    return null;
  });
  const [token, setToken] = useState<Token | null>(() => {
    const token = credentials.token.token;
    if (token) {
      try {
        const tokenData = jwt.verify(token, AUTH_SECRET) as Token;
        const dataExpiracao = new Date(tokenData.exp * 1000);
        if (dataExpiracao > new Date()) return tokenData;
      } catch { }
    }
    return null;
  });
  const [superAdmin, setSuperAdmin] = useState<boolean>(() => {
    const token = credentials.token.token;
    if (token) {
      try {
        const tokenData = jwt.verify(token, AUTH_SECRET) as Token;
        const dataExpiracao = new Date(tokenData.exp * 1000);
        if (dataExpiracao > new Date())
          return tokenData.spa;
      } catch { }
    }
    return null;
  });

  const signIn = async (
    email: string,
    password: string,
    remember: boolean = false,
  ): Promise<boolean> => {
    if (!email.trim()) {
      toast.error("E-mail não informado.");
      return false;
    }
    if (!password.trim()) {
      toast.error("Senha não informada.");
      return false;
    }
    if (password.trim().length < 6) {
      toast.error("A senha deve ter no mínimo 6 caracteres.");
      return false;
    }

    return await apollo.query({
      query: gql`
        query login($email: String!, $password: String!, $remember: Boolean) {
          login(email: $email, password: $password, remember: $remember) {
            user {
              id
              createdAt
              updatedAt
              deletedAt
              name
              surname
              document
              birthDate
              email
              phone
              mobile
              isActivated
              isSuperAdmin
              protheusId
              path
              metas {
                id
                sortOrder
                total
                divider
                name
                startAt
                endAt
                isActivated
              }
              saleTeams {
                id
                name
                isActivated
                metas {
                  id
                  sortOrder
                  total
                  divider
                  name
                  startAt
                  endAt
                  isActivated
                }
              }
              roles {
                id
                name
                description
                maximumDiscount
                paymentConditions {
                  id
                  name
                  description1
                  description2
                  protheusId
                }
              }
              claims {
                id
                claimType
                claimValue
              }
              areaCodes {
                id
                code
                protheusId
                state {
                  id
                  code
                }
              }
              cities {
                name
                code
                state {
                  id
                  name
                }
              }
            }
            token
            expires
          }
        }
      `,
      variables: {
        email,
        password,
        remember,
      },
    })
      .then(res => {
        try {
          localStorage.setItem("user", JSON.stringify(res.data.login.user));
          localStorage.setItem("token", res.data.login.token);
          setUser(res.data.login.user);
          setLogged(true);
          const token = jwt.verify(res.data.login.token, AUTH_SECRET) as Token;
          dispatch(loadCredentials({
            user: res.data.login.user,
            token: {
              token: res.data.login.token,
              data: token
            },
          }));
          setToken(token);
          setSuperAdmin(token.spa);
          return true;
        } catch (err) {
          error(err);
          return false;
        }
      })
      .catch(err => {
        error(err);
        return false;
      });
  };

  const signOut = (message?: string) => {
    if (message) toast.success(message);
    setLogged(false);
    setUser(null);
    setToken(null);
    dispatch(loadCredentials({
      user: null,
      token: {
        token: null,
        data: null
      },
    }));
    localStorage.removeItem("user");
    localStorage.removeItem("token");
  };

  const signUp = async (
    name: string,
    surname: string,
    document: string,
    phone: string | null,
    mobile: string | null,
    birthDate: Date | null,
    email: string,
    password: string,
    passwordConfirm: string
  ): Promise<boolean> => {
    const hasEmptyField =
      name.trim() === "" ||
      document.trim() === "" ||
      email.trim() === "" ||
      password.trim() === "" ||
      passwordConfirm.trim() === "";
    if (hasEmptyField) {
      toast.error("Por favor, preêncha todos os campos.");
      return false;
    }
    if (password !== passwordConfirm) {
      toast.error("Senhas não conferem.");
      return false;
    }
    if (password.trim().length < 6) {
      toast.error("A senha deve ter no mínimo 6 caracteres.");
      return false;
    }
    const passwordStrength = pwdStrength(password.trim());
    if (passwordStrength.id < 2) {
      toast.error(
        "Senha inválida, por favor, forneça uma senha com letras maiúsculas, minúsculas, números e caracteres especiais."
      );
      return false;
    }

    return await apollo.mutate({
      mutation: gql`
        mutation register($data: RegisterInput!) {
          register(data: $data) {
            id
            createdAt
            updatedAt
            deletedAt
            name
            surname
            document
            birthDate
            email
            phone
            mobile
            isActivated
            isSuperAdmin
            protheusId
            path
          }
        }
      `,
      variables: {
        data: { name, surname, document: document.trim().replace(/\D/gm, ""), birthDate, phone, mobile, email, password }
      },
    })
      .then(res => {
        if (res.data?.register)
          return true;

        return false;
      })
      .catch(err => {
        error(err);
        return false;
      });

  };

  const recoverPassword = async (
    email: string
  ): Promise<boolean> => {
    const hasEmptyField = email.trim() === "";
    if (hasEmptyField) {
      toast.error("Por favor, preêncha todos os campos.");
      return false;
    }

    return await apollo.query({
      query: gql`
        query forgotPassword($url: String! $email: String!){
          forgotPassword(url: $url, email: $email) {
            code
            url
            expires
          }
        }
      `,
      variables: {
        email,
        url: `${window.location.protocol}//${window.location.hostname}${(IS_DEVELOPMENT ? ":" + WEB_PORT : "")}/reset-password?code={code}&email={email}`,
      },
    })
      .then(res => {
        if (res.data?.forgotPassword)
          return true;

        return false;
      })
      .catch(err => {
        error(err);
        return false;
      });
  };

  const resetPassword = async (
    email: string,
    password: string,
    passwordConfirm: string,
    code: string
  ): Promise<boolean> => {
    const hasEmptyField =
      email.trim() === "" ||
      password.trim() === "" ||
      passwordConfirm.trim() === "" ||
      code.trim() === "";
    if (hasEmptyField) {
      toast.error("Por favor, preêncha todos os campos.");
      return false;
    }
    if (password.trim().length < 6) {
      toast.error("A senha deve ter no mínimo 6 caracteres.");
      return false;
    }
    const passwordStrength = pwdStrength(password.trim());
    if (passwordStrength.id < 2) {
      toast.error(
        "Senha inválida, por favor, forneça uma senha com letras maiúsculas, minúsculas, números e caracteres especiais."
      );
      return false;
    }
    if (password !== passwordConfirm) {
      toast.error("Senhas não conferem.");
      return false;
    }

    return await apollo.mutate({
      mutation: gql`
        mutation resetPassword($password: String!, $code: String!, $email: String!) {
          resetPassword(password: $password, code: $code, email: $email) {
            id
            createdAt
            updatedAt
            deletedAt
            name
            surname
            document
            birthDate
            email
            phone
            mobile
            isActivated
            isSuperAdmin
            protheusId
            path
          }
        }
      `,
      variables: {
        email,
        password,
        code,
      },
    })
      .then(res => {
        if (res.data?.resetPassword)
          return true;

        return false;
      })
      .catch(err => {
        error(err);
        return false;
      });
  };

  const hasScope = (scope: string): boolean => {
    if (!!user && !!token) {
      const isAdmin = token.rol.includes("admin");
      const isSuperAdmin = token.spa;
      const hasScope = token.prm.includes(scope);
      if (isSuperAdmin || isAdmin || hasScope) return true;
    }
    return false;
  };

  const hasScopes = (scopes: string[]): boolean => {
    let hasAllScopes = true;
    for (let scope of scopes) if (!hasScope(scope)) hasAllScopes = false;
    return hasAllScopes;
  };

  const hasAnyScopes = (scopes: string[]): boolean => {
    let hasAllScopes = false;
    for (let scope of scopes) if (hasScope(scope)) hasAllScopes = true;
    return hasAllScopes;
  };

  return (
    <AuthContext.Provider
      value={{
        logged,
        signIn,
        signOut,
        signUp,
        recoverPassword,
        resetPassword,
        user,
        token,
        hasScope,
        hasScopes,
        hasAnyScopes,
        superAdmin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContext {
  const context = useContext(AuthContext);

  return context;
}

export { AuthProvider, useAuth };
