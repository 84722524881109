import xlsx from "node-xlsx";

export const arrayToExcel = (function () {
  //STEP 2: Append Table data to Spreadsheet XML Template.
  // eslint-disable-next-line
  const createXMLTable = (table, fileName) => {
    const xmlTable = `
      <html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel"
          xmlns="http://www.w3.org/TR/REC-html40"
      >
         <meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"/>
         <head>
            <xml>
              <x:ExcelWorkbook>
                  <x:ExcelWorksheets>
                      <x:ExcelWorksheet>
                          <x:Name>${fileName}</x:Name>
                          <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions>
                      </x:ExcelWorksheet>
                  </x:ExcelWorksheets>
              </x:ExcelWorkbook>
            </xml>
         </head>
         <body>
           ${table}
         </body>
      </html> `;
    return xmlTable;
  };

  //STEP 3: Create fileURL from XML template for download
  const createFileUrl = (xmlTable) => {
    const tableBlob = new Blob([xmlTable], {
      type: "application/vnd.ms-excel;base64,",
    });
    const downloadURL = URL.createObjectURL(tableBlob);
    return downloadURL;
  };

  //STEP 5: Create download link on button click
  const downloadFile = (downloadURL, fileName) => {
    const downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    downloadLink.download = fileName;
    downloadLink.href = downloadURL;
    downloadLink.click();
  };

  //STEP 1: Convert Array to HTML Table.
  return {
    convertArrayToTable: async (apiArray, fileName) => {
      const data = [
        {
          name: "Plan1",
          data: [
            Object.keys(apiArray[0]).map((key) =>
              key !== "__typename" ? key : null
            ),
            ...apiArray.map((obj) =>
              Object.keys(obj).map((key) =>
                key !== "__typename"
                  ? obj[key] === null || obj[key] === ""
                    ? null
                    : typeof obj[key] === "object"
                    ? JSON.stringify(obj[key])
                    : obj[key]
                  : null
              )
            ),
          ],
        },
      ];
      var buffer = xlsx.build(data);

      const downloadURL = createFileUrl(buffer);
      downloadFile(downloadURL, fileName);
    },
  };
})();
