import axios from "axios";
import { PROTHEUS_API_PASS, PROTHEUS_API_SERVER, PROTHEUS_API_USER } from "../configs/constants";

const protheusService = axios.create({
  baseURL: PROTHEUS_API_SERVER,
  auth: {
    username: PROTHEUS_API_USER,
    password: PROTHEUS_API_PASS,
  }
});

export default protheusService;
