import { gql } from "@apollo/client";
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { SITE_TITLE } from "../../configs/constants";
import { useAuth } from "../../hooks/auth";
import Breadcrumb from "../../layout/breadcrumb";
import ProductGroup from "../../models/productGroup";
import { CrudParam } from "../../configs/route";
import { useApollo } from "../../services/apollo.service";
import NumberFormat from "react-number-format";
import Loading from "../../components/loading";

const Manage: React.FC = () => {
  const isMountedRef = useRef(null);
  const { apollo, error } = useApollo();
  const { id } = useParams<CrudParam>();
  const [name, setName] = useState<string>("");
  const [protheusId, setProtheusId] = useState<string>("");
  const [barCode, setBarCode] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [unit, setUnit] = useState<string>("");
  const [multiplier, setMultiplier] = useState<number>(0);
  const [boxes, setBoxes] = useState<number>(0);
  const [boxSize, setBoxSize] = useState<number>(0);
  const [boxCubic, setBoxCubic] = useState<number>(0);
  const [cartoon, setCartoon] = useState<number>(0);
  const [cartoonSize, setCartoonSize] = useState<number>(0);
  const [cartoonCubic, setCartoonCubic] = useState<number>(0);
  const [liquidWeight, setLiquidWeight] = useState<number>(0);
  const [grossWeight, setGrossWeight] = useState<number>(0);
  const [lastSalePrice, setLastSalePrice] = useState<number>(0);
  const [isActivated, setIsActivated] = useState<boolean>(false);
  const [icmsRate, setIcmsRate] = useState<number>(0);
  const [ipiRate, setIpiRate] = useState<number>(0);
  const [taxGroup, setTaxGroup] = useState<string>("");
  const [groupId, setGroupId] = useState<string>("");
  const [groups, setGroups] = useState<ProductGroup[]>([]);
  const [preview, setPreview] = useState<boolean>(() => { if (id) return true; else return false; });
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const intl = useIntl();

  const { hasScope } = useAuth();
  const [canView] = useState<boolean>(() => {
    return hasScope("Products:Product");
  });
  const [canUpdate] = useState<boolean>(() => {
    return hasScope("Products:Update");
  });

  const handleData = useCallback(async () => {
    if (id) {
      if (preview && !canView) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/products");
        return;
      } else if (!preview && !canUpdate) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/products");
        return;
      }
      setLoading(true);
      await Promise.all([
        apollo.query({
          query: gql`
            query productGroupsDropdown {
              productGroupsDropdown {
                id
                name
                protheusId
              }
            }
          `,
          variables: {
            id
          },
        }),
        apollo.query({
          query: gql`
            query product($id: String!) {
              product(id: $id) {
                id
                createdAt
                updatedAt
                name
                barCode
                notes
                unit
                stock
                boxes
                boxSize
                boxCubic
                cartoon
                cartoonSize
                cartoonCubic
                multiplier
                lastSalePrice
                lastCostPrice
                path
                cmn
                icmsRate
                ipiRate
                taxGroup
                protheusId
                isActivated
                grossWeight
                liquidWeight
                group {
                  id
                  name
                  protheusId
                }
              }
            }
          `,
          variables: {
            id
          },
        }),
      ])
        .then(res => {
          if (isMountedRef.current) {
            if (res[0].data)
              setGroups(res[0].data.productGroupsDropdown);
            if (res[1].data) {
              setName(res[1].data.product.name || "");
              setProtheusId(res[1].data.product.protheusId || "");
              setBarCode(res[1].data.product.barCode || "");
              setUnit(res[1].data.product.unit || "");
              setNotes(res[1].data.product.notes || "");
              setGroupId(res[1].data.product.group?.id || "");
              setMultiplier(res[1].data.product.multiplier || 1);
              setIpiRate(res[1].data.product.ipiRate || 0);
              setIcmsRate(res[1].data.product.icmsRate || 0);
              setGrossWeight(res[1].data.product.grossWeight || 0);
              setLiquidWeight(res[1].data.product.liquidWeight || 0);
              setTaxGroup(res[1].data.product.taxGroup || "");
              setBoxes(Number(res[1].data.product.boxes || 1));
              setBoxSize(Number(res[1].data.product.boxSize || 0));
              setBoxCubic(Number(res[1].data.product.boxCubic || 0));
              setCartoon(Number(res[1].data.product.cartoon || 0));
              setCartoonSize(Number(res[1].data.product.cartoonSize || 0));
              setCartoonCubic(Number(res[1].data.product.cartoonCubic || 0));
              setLastSalePrice(res[1].data.product.lastSalePrice || 0);
              setIsActivated(res[1].data.product.isActivated || false);
            }
          }
        })
        .catch(err => error(err));
      setLoading(false);
    }
  }, [intl, id, preview, apollo, error, history, canView, canUpdate]);

  const handleSubmitForm = async () => {
    setDisableSubmit(true);
    const data = {
      name,
      barCode,
      notes,
      unit,
      boxes,
      boxSize,
      boxCubic,
      cartoon,
      cartoonSize,
      cartoonCubic,
      multiplier,
      lastSalePrice,
      icmsRate,
      ipiRate,
      liquidWeight,
      grossWeight,
      taxGroup,
      protheusId,
      isActivated,
      groupId,
    };

    if (id) {
      await apollo.mutate({
        mutation: gql`
          mutation updateProduct($id: String!, $data: ProductInput!) {
            updateProduct(id: $id, data: $data) {
                id
            }
          }
        `,
        variables: {
          id,
          data
        },
      })
        .then(res => {
          toast.success(intl.formatMessage({ id: "flash.success.updated" }));
          history.push(`/products`);
        })
        .catch(err => error(err));
    }
    setDisableSubmit(false);
  };

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: `pages.products.${preview ? "preview" : id ? "update" : "create"}`,
      })}`;
    handleData();
    return () => { isMountedRef.current = false; }
  }, [intl, id, preview, handleData]);

  return (
    <Fragment>
      <Breadcrumb
        parent="pages.products.products"
        title={`pages.products.${preview ? "preview" : id ? "update" : "create"
          }`}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>
                  <FormattedMessage
                    id={`pages.products.${preview ? "preview" : id ? "update" : "create"
                      }`}
                  />
                </h5>
              </CardHeader>
              <CardBody className="tabbed-card">
                <div className="form-content">
                  <Form onSubmit={(e) => e.preventDefault()}>
                    <Row className="m-b-30">
                      <Col>
                        {loading ? <Loading /> : (
                          <React.Fragment>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.protheusId" />
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={protheusId}
                                    disabled={preview}
                                    onChange={(e) => setProtheusId(e.target.value)}
                                    required
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.name" />
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={name}
                                    disabled={preview}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.barCode" />
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={barCode}
                                    disabled={preview}
                                    onChange={(e) =>
                                      setBarCode(e.target.value)
                                    }
                                    required
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.unit" />
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={unit}
                                    disabled={preview}
                                    onChange={(e) => setUnit(e.target.value)}
                                    required
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.lastSalePrice" />
                                  </Label>
                                  <NumberFormat
                                    className="form-control"
                                    value={lastSalePrice}
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    allowNegative={false}
                                    thousandsGroupStyle="thousand"
                                    disabled={preview}
                                    onValueChange={async ({ floatValue }) => {
                                      setLastSalePrice(floatValue);
                                    }}
                                    required
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.taxGroup" />
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={taxGroup}
                                    disabled={preview}
                                    onChange={(e) =>
                                      setTaxGroup(e.target.value)
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.liquidWeight" />
                                  </Label>
                                  <NumberFormat
                                    className="form-control"
                                    value={liquidWeight}
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    allowNegative={false}
                                    thousandsGroupStyle="thousand"
                                    disabled={preview}
                                    onValueChange={async ({ floatValue }) => {
                                      setLiquidWeight(floatValue);
                                    }}
                                    required
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.grossWeight" />
                                  </Label>
                                  <NumberFormat
                                    className="form-control"
                                    value={grossWeight}
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    allowNegative={false}
                                    thousandsGroupStyle="thousand"
                                    disabled={preview}
                                    onValueChange={async ({ floatValue }) => {
                                      setGrossWeight(floatValue);
                                    }}
                                    required
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={10}>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        Caixa qtd.
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="number"
                                        min="1"
                                        value={boxes}
                                        disabled={preview}
                                        onChange={(e) =>
                                          setBoxes(Number(e.target.value))
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        Caixa tam.
                                      </Label>
                                      <NumberFormat
                                        className="form-control"
                                        value={boxSize}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale
                                        allowNegative={false}
                                        thousandsGroupStyle="thousand"
                                        disabled={preview}
                                        onValueChange={async ({ floatValue }) => {
                                          setBoxSize(floatValue);
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        Caixa cub.
                                      </Label>
                                      <NumberFormat
                                        className="form-control"
                                        value={boxCubic}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale
                                        allowNegative={false}
                                        thousandsGroupStyle="thousand"
                                        disabled={preview}
                                        onValueChange={async ({ floatValue }) => {
                                          setBoxCubic(floatValue);
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        Cartoon qtd.
                                      </Label>
                                      <NumberFormat
                                        className="form-control"
                                        value={cartoon}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale
                                        allowNegative={false}
                                        thousandsGroupStyle="thousand"
                                        disabled={preview}
                                        onValueChange={async ({ floatValue }) => {
                                          setCartoon(floatValue);
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        Cartoon tam.
                                      </Label>
                                      <NumberFormat
                                        className="form-control"
                                        value={cartoonSize}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale
                                        allowNegative={false}
                                        thousandsGroupStyle="thousand"
                                        disabled={preview}
                                        onValueChange={async ({ floatValue }) => {
                                          setCartoonSize(floatValue);
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        Cartoon cub.
                                      </Label>
                                      <NumberFormat
                                        className="form-control"
                                        value={cartoonCubic}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale
                                        allowNegative={false}
                                        thousandsGroupStyle="thousand"
                                        disabled={preview}
                                        onValueChange={async ({ floatValue }) => {
                                          setCartoonCubic(floatValue);
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={2}>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.multiplier" />
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="number"
                                    min="1"
                                    value={multiplier}
                                    disabled={preview}
                                    onChange={(e) =>
                                      setMultiplier(Number(e.target.value))
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.group" />
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="select"
                                    value={groupId}
                                    disabled={preview}
                                    onChange={(e) => setGroupId(e.target.value)}
                                    required
                                  >
                                    <option value="">-</option>
                                    {groups?.map(group => group.name.trim() ? <option key={group.id} value={group.id}>{group.name}</option> : "")}
                                  </Input>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.notes" />
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    value={notes}
                                    disabled={preview}
                                    onChange={(e) => setNotes(e.target.value)}
                                    required
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.ipiRate" />
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="number"
                                    min="0"
                                    value={ipiRate}
                                    disabled={preview}
                                    onChange={(e) =>
                                      setIpiRate(Number(e.target.value))
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.icmsRate" />
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="number"
                                    min="0"
                                    value={icmsRate}
                                    disabled={preview}
                                    onChange={(e) =>
                                      setIcmsRate(Number(e.target.value))
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.isActivated" />
                                  </Label>
                                  <Col className="checkbox">
                                    <Input
                                      type="checkbox"
                                      value={isActivated ? 1 : 0}
                                      checked={isActivated}
                                      disabled={preview}
                                      onChange={(e) => setIsActivated(!isActivated)}
                                    />
                                    <Label
                                      key="isActivated"
                                      for="isActivated"
                                      className="d-block"
                                    >Sim</Label>
                                  </Col>
                                </FormGroup>
                              </Col>
                              <Col></Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          type="button"
                          color="danger"
                          onClick={() => history.push("/products")}
                        >
                          <i className="fa fa-arrow-left"></i>
                          <FormattedMessage id="buttons.cancel" />
                        </Button>
                      </Col>
                      {canUpdate ? (
                        <Col className="text-right">
                          {preview ? (
                            <Button
                              type="button"
                              color="primary"
                              disabled={loading}
                              onClick={() => setPreview(!preview)}
                            >
                              <i className="fa fa-edit"></i>
                              <FormattedMessage id="buttons.edit" />
                            </Button>
                          ) : (
                            <Button type="button" disabled={disableSubmit || loading} onClick={handleSubmitForm} color="primary">
                              <i className="fa fa-save"></i>
                              <FormattedMessage id="buttons.save" />
                            </Button>
                          )}
                        </Col>) : ""}
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Manage;
