import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Progress,
  Row,
  Tooltip,
} from "reactstrap";
import SweetAlert from "sweetalert2";
import Breadcrumb from "../../layout/breadcrumb";
import { convert_to_date, format_date, GRAPHQL_SERVER, MAX_UPLOAD_SIZE, MAX_UPLOAD_SIZE_BYTES, SITE_TITLE } from '../../configs/constants';
import { useAuth } from '../../hooks/auth';
import { useApollo } from '../../services/apollo.service';
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import pt_BR from "date-fns/locale/pt-BR";
import formatter from "../../utils/formatter";
import { Eye, EyeOff } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { ICombineReducers } from "../../redux";
import styled from 'styled-components';
import { loadCredentials } from '../../redux/actions';
import { gql, useApolloClient, useMutation } from '@apollo/client';
import Loading from '../../components/loading';

const AvatarImage = styled.img`
  max-width: 100%;
  border-radius: 50%;
`;

const StyledDiv = styled.div`
  margin-top: 15px;
  width: 75px;
  max-width: 100%;
`;

const StyledDivProgress = styled.div`
  margin-top: 15px;
`;

const StyledDivNoPhoto = styled.div`
  margin-top: 15px;
`;

const StyledBtnDelete = styled.button`
  position: absolute;
  width: 32px;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
`;

const SINGLE_UPLOAD_MUTATION = gql`
  mutation profileAvatarUpload ($file: Upload!) {
    profileAvatarUpload (file: $file) {
      path
    }
  }
`;

const Profile: React.FC = () => {
  const isMountedRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const credentials = useSelector((state: ICombineReducers) => state.DataSet.credentials);
  const { apollo, error } = useApollo();
  const customState = useSelector((state: ICombineReducers) => state.State);
  const [uploadFileMutation] = useMutation(SINGLE_UPLOAD_MUTATION);
  const apolloClient = useApolloClient();

  const [name, setName] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [documento, setDocumento] = useState<string>("");
  const [birthDate, setBirthDate] = useState<Date>(null);
  const [phone, setPhone] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [exibirSenha, setExibirSenha] = useState<boolean>(false);
  const [tooltipOpen1, setTooltipOpen1] = useState<boolean>(false);
  const [tooltipOpen2, setTooltipOpen2] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [fileTarget, setFileTarget] = useState<EventTarget & HTMLInputElement>();
  const [data, getFile] = useState({ name: "", path: "" });
  const [progress, setProgess] = useState<number>(0);
  const [submitDisable, setSubmitDisable] = useState<boolean>(false);
  const [uploadDisable, setUploadDisable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const el = useRef(); // accesing input element

  const { hasScope } = useAuth();
  const [canAccess] = useState<boolean>(() => {
    return hasScope("Users:Profile");
  });

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: "pages.users.profile",
      })}`;
    if (customState.locale === "pt") {
      registerLocale("pt-BR", pt_BR);
      setDefaultLocale("pt-BR");
    }
    if (!canAccess) {
      toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
      history.push("/");
      return;
    }
    if (credentials.user) {
      setLoading(true);
      if (isMountedRef.current) {
        setName(credentials.user.name || "");
        setSurname(credentials.user.surname || "");
        setDocumento(credentials.user.document || "");
        setBirthDate(convert_to_date(credentials.user.birthDate) || null);
        setEmail(credentials.user.email || "");
        setPhone(credentials.user.phone || "");
        setMobile(credentials.user.mobile || "");
        if (credentials?.user?.path)
          getFile({
            name: credentials.user.path,
            path: GRAPHQL_SERVER + "/upload/" + credentials.user.path
          })
      }
      setLoading(false);
    }
    return () => { isMountedRef.current = false; }
  }, [intl, customState, credentials, history, canAccess]);

  const handleSubmitForm = async () => {
    setSubmitDisable(true);
    let data = {
      name,
      surname,
      phone,
      mobile,
      email,
      document: documento,
      birthDate: "",
      password,
    };

    if (birthDate)
      data.birthDate = format_date(birthDate, "YYYY-MM-DD");

    if (!name || !surname || !email || !email) {
      toast.error("Por favor, informe os campos de Nome, Sobrenome, CPF/CNPJ e E-mail.");
      return;
    }

    if (password.trim() && password.trim().length < 6) {
      toast.error("A senha deve ter no mínimo 6 caracteres.");
      return;
    }

    await apollo.mutate({
      mutation: gql`
        mutation profile($data: ProfileInput!) {
          profile(data: $data) {
            id
            createdAt
            updatedAt
            deletedAt
            name
            surname
            document
            birthDate
            email
            phone
            mobile
            isActivated
            isSuperAdmin
            protheusId
            path
            roles {
              id
              name
              description
            }
            areaCodes {
              id
              code
              protheusId
            }
            cities {
              id
              name
              code
            }
            claims {
              claimType
              claimValue
            }
          }
        }
      `,
      variables: {
        data
      },
    })
      .then(res => {
        toast.success(intl.formatMessage({ id: "flash.success.profile" }));
        dispatch(loadCredentials({
          ...credentials,
          user: res.data.profile
        }))
      })
      .catch(err => error(err));
    setSubmitDisable(false);
  };

  const handleDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\D/, "");
    // setDocumentoInvalido(false);

    if (!formatter.isCpfValid(value) || !formatter.isCnpjValid(value)) {
      // setDocumentoInvalido(true);
    }

    if (value.length === 11) {
      value = formatter.formatCpf(value);
    } else if (value.length === 14) {
      value = formatter.formatCnpj(value);
    }

    setDocumento(value);
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProgess(0)
    const file = e.target.files[0]; // accesing file
    setFileTarget(e.target)
    if (file.type.startsWith("image/")) {
      setFile(file); // storing file
    } else if (file.size > MAX_UPLOAD_SIZE_BYTES) {
      toast.error(`Tamanho do arquivo excede o tamanho máximo permitido de ${MAX_UPLOAD_SIZE}MB.`);
      e.target.value = "";
    } else if (!file.type.startsWith("image/")) {
      toast.error("O tipo de arquivo selecionado não é permitido.");
      e.target.value = "";
    }
  }

  const uploadFile = async () => {
    if (!file) {
      toast.error("Você deve selecionar um arquivo primeiro.");
      setUploadDisable(false);
      return;
    }

    setUploadDisable(true);
    await uploadFileMutation({ variables: { file } })
      .then(res => {
        apolloClient.resetStore();

        toast.success("Avatar atualizado com sucesso!");
        dispatch(loadCredentials({
          ...credentials,
          user: {
            ...credentials.user,
            path: res.data.profileAvatarUpload.path
          }
        }));
        getFile({
          name: res.data.profileAvatarUpload.path,
          path: GRAPHQL_SERVER + "/upload/" + res.data.profileAvatarUpload.path
        });
        fileTarget.value = "";
      })
      .catch(err => {
        fileTarget.value = "";
        error(err);
      });
    setUploadDisable(false);
  }

  const handleDeleteAvatar = () => {
    SweetAlert.fire({
      title: intl.formatMessage({ id: "general.exclusion" }),
      text: intl.formatMessage({ id: "flash.confirm.deleteProfilePhoto" }),
      icon: "error",
      cancelButtonText: intl.formatMessage({ id: "general.no" }),
      confirmButtonText: intl.formatMessage({ id: "general.yes" }),
      reverseButtons: true,
      showCancelButton: true,
    })
      .then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          await apollo.mutate({
            mutation: gql`
                mutation deleteProfilePhoto {
                  deleteProfilePhoto {
                    path
                }
              }
            `,
          })
            .then(res => {
              dispatch(loadCredentials({
                ...credentials,
                user: {
                  ...credentials.user,
                  path: null
                }
              }));
              getFile({
                name: "",
                path: ""
              })
            })
            .catch(err => error(err));
        }
      });
  }

  return (
    <Fragment>
      <Breadcrumb
        parent="pages.dashboards.home"
        title={`pages.users.profile`}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>
                  <FormattedMessage id="pages.users.profile" />
                </h5>
              </CardHeader>
              <CardBody className="tabbed-card">
                <div className="form-content">
                  <Form onSubmit={(e) => e.preventDefault()}>
                    <Row className="m-b-30">
                      <Col>
                        {loading ? <Loading /> : (
                          <React.Fragment>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.name" />
                                  </Label>
                                  <Input
                                    name="name"
                                    className="form-control"
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value.trim())}
                                    required
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.surname" />
                                  </Label>
                                  <Input
                                    name="surname"
                                    className="form-control"
                                    type="text"
                                    value={surname}
                                    onChange={(e) =>
                                      setSurname(e.target.value.trim())
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.phone" />
                                  </Label>
                                  <Input
                                    name="phone"
                                    className="form-control"
                                    type="text"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value.trim())}
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.mobile" />
                                  </Label>
                                  <Input
                                    name="mobile"
                                    className="form-control"
                                    type="text"
                                    value={mobile}
                                    onChange={(e) =>
                                      setMobile(e.target.value.trim())
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.birthDate" />
                                  </Label>
                                  <DatePicker
                                    name="birthDate"
                                    className="form-control"
                                    selected={birthDate}
                                    onChange={(e: Date) => setBirthDate(e)}
                                    dateFormat="dd/MM/yyyy"
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.cpfCnpj" />
                                  </Label>
                                  <Input
                                    name="document"
                                    type="text"
                                    value={documento}
                                    onChange={handleDocument}
                                    maxLength={18}
                                    autoComplete="off"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.email" />
                                  </Label>
                                  <Input
                                    name="email"
                                    type="email"
                                    className="form-control"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value.trim())}
                                    required
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.password" />
                                  </Label>
                                  <InputGroup>
                                    <Input
                                      name="password"
                                      className="form-control"
                                      type={exibirSenha ? "text" : "password"}
                                      value={password}
                                      onChange={(e) => setPassword(e.target.value.trim())}
                                    />
                                    <InputGroupAddon addonType="append">
                                      <InputGroupText>
                                        <Eye
                                          onClick={() => setExibirSenha(!exibirSenha)}
                                          style={{ cursor: "pointer" }}
                                          className={
                                            exibirSenha ? "hidden" : ""
                                          }
                                          id="display-password1"
                                        />
                                        <EyeOff
                                          onClick={() => setExibirSenha(!exibirSenha)}
                                          style={{ cursor: "pointer" }}
                                          className={
                                            !exibirSenha ? "hidden" : ""
                                          }
                                          id="display-password2"
                                        />
                                        <Tooltip
                                          placement="top"
                                          isOpen={tooltipOpen1}
                                          target="display-password1"
                                          toggle={() =>
                                            setTooltipOpen1(!tooltipOpen1)
                                          }
                                        >
                                          <FormattedMessage id="pages.users.showPassword" />
                                        </Tooltip>
                                        <Tooltip
                                          placement="top"
                                          isOpen={tooltipOpen2}
                                          target="display-password2"
                                          toggle={() =>
                                            setTooltipOpen2(!tooltipOpen2)
                                          }
                                        >
                                          <FormattedMessage id="pages.users.hidePassword" />
                                        </Tooltip>
                                      </InputGroupText>
                                    </InputGroupAddon>
                                  </InputGroup>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.uploadProfilePhoto" />
                                  </Label>
                                  <InputGroup>
                                    <Input
                                      ref={el}
                                      type="file"
                                      accept=".png,.jpg,.jpeg"
                                      className="form-control"
                                      onChange={handleChangeFile}
                                    />
                                    <InputGroupAddon addonType="append">
                                      <Button type="button" disabled={uploadDisable} onClick={uploadFile} color="primary">
                                        <i className="fa fa-upload"></i>
                                        Upload
                                      </Button>
                                    </InputGroupAddon>
                                  </InputGroup>
                                  {progress > 0 ? (<StyledDivProgress><Progress animated color="success" value={progress.toString()} /></StyledDivProgress>) : ""}
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <FormattedMessage id="inputs.profilePhoto" />
                                  </Label>
                                  {data.path ? <StyledDiv><AvatarImage src={data.path} alt={`${name} ${surname}`} /><StyledBtnDelete className="btn-danger" type="button" onClick={handleDeleteAvatar}><i className="fa fa-times"></i></StyledBtnDelete></StyledDiv> : <StyledDivNoPhoto>Nenhuma foto foi enviada ainda.</StyledDivNoPhoto>}
                                </FormGroup>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                      </Col>
                      <Col className="text-right">
                        <Button type="button" disabled={submitDisable || loading} onClick={handleSubmitForm} color="primary">
                          <i className="fa fa-save"></i>
                          <FormattedMessage id="buttons.save" />
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment >
  );
}

export default Profile;
