import produce from "immer";
import ConfigDB from "../../configs/theme";
import { CustomizerState, ApplicationAction } from "../types";

export const customizer = ConfigDB.data;

export const initialCustomizerState: CustomizerState = {
  loading: false,
  customizer,
  layout: customizer.settings.layout_type,
  sidebar_types: customizer.settings.sidebar,
  settings: customizer.settings.sidebar_setting,
  color: customizer.color,
  mix_background_layout: customizer.color.mix_background_layout,
  animation: customizer.router_animation,
};

const reducer = (
  state: CustomizerState = initialCustomizerState,
  action: ApplicationAction
): CustomizerState => {
  switch (action.type) {
    case "ADD_CUSTOMIZER":
      return produce(state, (draft) => {
        draft.loading = false;
        draft.customizer = customizer;
      });
    case "ADD_LAYOUT":
      return produce(state, (draft) => {
        draft.loading = true;
        draft.layout = action.layout;
      });
    case "ADD_SIDEBAR_TYPES":
      return produce(state, (draft) => {
        draft.loading = true;
        draft.sidebar_types = action.sidebar_types;
      });
    case "ADD_SIDEBAR_SETTINGS":
      return produce(state, (draft) => {
        draft.loading = true;
        draft.settings = action.settings;
      });
    case "ADD_COLOR":
      return produce(state, (draft) => {
        draft.loading = true;
        draft.color = action.color;
      });
    case "ADD_MIX_BACKGROUND_LAYOUT":
      return produce(state, (draft) => {
        draft.loading = true;
        draft.mix_background_layout = action.mix_background_layout;
      });
    case "ROUTER_ANIMATION":
      return produce(state, (draft) => {
        draft.loading = true;
        draft.animation = action.animation;
      });
    default:
      return { ...state };
  }
};

export default reducer;
