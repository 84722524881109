import React, { createContext, useContext } from "react";
import {
  ApolloProvider as BaseApolloProvider,
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  ApolloLink,
  split
} from "@apollo/client";
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from "@apollo/client/link/context";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { AUTH_SECRET, GRAPHQL_SERVER, IS_DEVELOPMENT } from "../configs/constants";
import { ICombineReducers } from "../redux";
import { createUploadLink } from "apollo-upload-client";
import jwt from "jsonwebtoken";

interface IApolloContext {
  apollo: ApolloClient<NormalizedCacheObject>;
  error(err: any): void;
}

const ApolloContext = createContext<IApolloContext>({} as IApolloContext);

const ApolloProvider: React.FC = ({ children }) => {
  //const dispatch = useDispatch();
  const credentials = useSelector((state: ICombineReducers) => state.DataSet.credentials);
  const getToken = () => {
    let token = null;
    if (credentials.token.token)
      token = credentials.token.token;
    else if (!!localStorage.getItem("token"))
      token = localStorage.getItem("token");

    if (token) {
      try {
        jwt.verify(token, AUTH_SECRET);
        return token;
      } catch (err) {
        console.log(err);
      }
    }

    return token;
  };

  const token = getToken();

  // Initilize required constructor fields
  const httpLink = createUploadLink({
    uri: `${GRAPHQL_SERVER}/graphql`,
  });

  // Initilize websocket
  const wsLink = new WebSocketLink({
    uri: `${window.location.protocol === "https:" ? "wss" : "ws"}://${GRAPHQL_SERVER.substr(7)}/subscriptions`,
    options: {
      reconnect: true
    },
    connectionParams: {
      authToken: token ? `Bearer ${token}` : "",
    },
  });

  const authLink: ApolloLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    if (!token)
      return headers;
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : "",
      }
    };
  });

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    authLink.concat(httpLink),
  );

  const apollo: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    link,
    cache: new InMemoryCache(),
    connectToDevTools: IS_DEVELOPMENT,
    name: "Stark",
    version: "v1",
    ssrMode: false,
    defaultOptions: {
      query: {
        fetchPolicy: "no-cache"
      },
      mutate: {
        fetchPolicy: "no-cache"
      }
    }
  });

  const error = (error: any) => {
    if (error?.graphQLErrors?.length)
      error?.graphQLErrors?.map((err: any) => {
        if (
          typeof err?.extensions?.exception?.validationErrors !== "undefined"
        ) {
          err?.extensions?.exception?.validationErrors.map((val: any) => {
            if (typeof val.constraints !== "undefined") {
              Object.keys(val.constraints).forEach((key) => {
                toast.error(val.constraints[key]);
              });
            } else {
              toast.error(error.message);
            }
            return val;
          });
        } else {
          toast.error(error.message);
        }
        return err;
      });
    else if (error?.response?.data?.message) toast.error(error.response?.data?.message);
    else if (error?.message) toast.error(error?.message);
    else toast.error(error);
  };

  return (
    <ApolloContext.Provider
      value={{
        apollo,
        error
      }}
    >
      <BaseApolloProvider client={apollo}>
        {children}
      </BaseApolloProvider>
    </ApolloContext.Provider>
  );
}

function useApollo(): IApolloContext {
  const context = useContext(ApolloContext);

  return context;
}

export { ApolloProvider, useApollo };
