import produce from "immer";
import { DEFAULT_LOCALE } from "../../configs/constants";
import { ApplicationAction, CommonState } from "../types";

export const initialCommonState: CommonState = {
  loading: false,
  locale: DEFAULT_LOCALE,
  responsiveSearch: false,
};

const reducer = (
  state: CommonState = initialCommonState,
  action: ApplicationAction
): CommonState => {
  switch (action.type) {
    case "TOGGLE_RESPONSIVE_SEARCH":
      return produce(state, (draft) => {
        draft.responsiveSearch = action.responsiveSearch;
      });
    case "CHANGE_LOCALE":
      return produce(state, (draft) => {
        draft.locale = action.locale;
      });
    case "CHANGE_LOADING":
      return produce(state, (draft) => {
        draft.loading = action.payload;
      });
    default:
      return { ...state };
  }
};

export default reducer;
