import { GRAPHQL_SERVER } from "./configs/constants";

/**
 * checks if Push notification and service workers are supported by your browser
 */
function isPushNotificationSupported() {
  return "serviceWorker" in navigator && "PushManager" in window;
}

/**
 * asks user consent to receive push notifications and returns the response of the user, one of granted, default, denied
 */
function initializePushNotifications() {
  // request user grant to show notification
  return Notification.requestPermission(function (result) {
    return result;
  });
}

/**
 * shows a notification
 */
function sendNotification(
  tag: string,
  title: string,
  body: string,
  image?: string
) {
  const options: NotificationOptions = {
    body,
    vibrate: [200, 100, 200],
    tag,
    icon: "/logo192.png",
    // actions: [
    //   {
    //     action: "Details",
    //     title: "Visualizar",
    //   },
    // ],
  };
  if (image) options.image = `${GRAPHQL_SERVER}${image}`;

  navigator.serviceWorker.ready.then(function (serviceWorker) {
    serviceWorker.showNotification(title, options);
  });
}

export {
  isPushNotificationSupported,
  initializePushNotifications,
  sendNotification,
};
