import React from "react";
import moment from "moment-timezone";

export const ENVIRONMENT = (process.env.NODE_ENV || "development")?.trim();
export const IS_PRODUCTION = ENVIRONMENT === "production";
export const IS_DEVELOPMENT = ENVIRONMENT === "development";
export const SITE_TITLE = process.env.REACT_APP_SITE_TITLE || "";
export const PASS_PREFIX = process.env.REACT_APP_PASS_PREFIX || "Stk";
export const SITE_LOGO = process.env.REACT_APP_SITE_LOGO || "";
export const SITE_LOGO_DARK = process.env.REACT_APP_SITE_LOGO_DARK || "";
export const SITE_LOGO_FAVICON = process.env.REACT_APP_SITE_LOGO_FAVICON || "";
export const DEFAULT_LOCALE = process.env.REACT_APP_DEFAULT_LOCALE || "en";
export const DISPLAY_LOCALE =
  (process.env.REACT_APP_DISPLAY_LOCALE?.toLowerCase() === "true"
    ? true
    : process.env.REACT_APP_DISPLAY_LOCALE?.toLowerCase() === "false"
      ? false
      : false) || false;
export const MAINTENANCE_MODE =
  (process.env.REACT_APP_MAINTENANCE_MODE?.toLowerCase() === "true"
    ? true
    : process.env.REACT_APP_MAINTENANCE_MODE?.toLowerCase() === "false"
      ? false
      : false) || false;
export const HIDE_CUSTOMIZER =
  (process.env.REACT_APP_HIDE_CUSTOMIZER?.toLowerCase() === "true"
    ? true
    : process.env.REACT_APP_HIDE_CUSTOMIZER?.toLowerCase() === "false"
      ? false
      : false) || false;
export const RECORDS_PER_PAGE =
  Number(process.env.REACT_APP_RECORDS_PER_PAGE) || 15;
export const PROTHEUS_API_SERVER =
  process.env.REACT_APP_PROTHEUS_API_SERVER || "";
export const PROTHEUS_API_USER =
  process.env.REACT_APP_PROTHEUS_API_USER || "";
export const PROTHEUS_API_PASS =
  process.env.REACT_APP_PROTHEUS_API_PASS || "";
export const CORREIOS_API_SERVER =
  process.env.REACT_APP_CORREIOS_API_SERVER || "";
export const SINTEGRA_API_SERVER =
  process.env.REACT_APP_SINTEGRA_API_SERVER || "";
export const GRAPHQL_SERVER =
  process.env.REACT_APP_GRAPHQL_SERVER || "http://localhost:5000";
export const WEB_PORT = Number(process.env.REACT_APP_WEB_PORT) || 3000;
export const CRYPTR_KEY = process.env.REACT_APP_CRYPTR_KEY || "abc123";
export const AUTH_SECRET = process.env.REACT_APP_AUTH_SECRET || "abc123";
export const DATATABLE_ROWS_PER_PAGE = [15, 30, 50, 100];
export const IS_PREVIEW = () => {
  const path = window.location.pathname.slice(1).split("/");
  const currentPath = path.length >= 2 ? path[1] : null;
  return currentPath === "preview";
};

export const MAX_UPLOAD_SIZE = Number(process.env.REACT_APP_MAX_UPLOAD_SIZE) || 5;
export const MAX_UPLOAD_SIZE_BYTES = MAX_UPLOAD_SIZE * 1024 * 1024;

export const BUDGET_SYNC_WITH_ID = process.env.REACT_APP_BUDGET_SYNC_WITH_ID?.trim() || "";

export const DATE_FORMAT = process.env.REACT_APP_DATE_FORMAT || "YYYY-MM-DD";
export const DATE_FORMAT_BR = process.env.REACT_APP_DATE_FORMAT_BR || "DD/MM/YYYY";
export const TIME_FORMAT = process.env.REACT_APP_TIME_FORMAT || "HH:mm:ss";
export const TIME_FORMAT_BR = process.env.REACT_APP_TIME_FORMAT_BR || "HH:mm";
export const DATE_TIME_FORMAT =
  process.env.REACT_APP_DATE_TIME_FORMAT || "YYYY-MM-DD HH:mm:ss";
export const DATE_TIME_FORMAT_BR =
  process.env.REACT_APP_DATE_TIME_FORMAT_BR || "DD/MM/YYYY HH:mm";
export const LIST_DATE_TIME_FORMAT =
  process.env.REACT_APP_LIST_DATE_TIME_FORMAT || "DD/MM/YYYY HH:mm";
export const TIMEZONE = process.env.REACT_APP_TIMEZONE || "America/Sao_Paulo";
export const escapedNewLineToLineBreakTag = (str: string) => {
  return str.split("\\n").map((item, index) => {
    return (index === 0) ? item : [<br key={index} />, item]
  })
}
export const format_date = (
  date: Date | string,
  format?: "YYYY-MM-DD HH:mm:ss" | "YYYY-MM-DD" | "HH:mm:ss" | "DD/MM/YYYY HH:mm:ss" | "DD/MM/YYYY HH:mm" | typeof DATE_FORMAT | typeof DATE_FORMAT_BR | typeof DATE_TIME_FORMAT | typeof DATE_TIME_FORMAT_BR | typeof LIST_DATE_TIME_FORMAT
) => {
  const dt = moment(date);
  const formatedDate = dt.tz(TIMEZONE).format(format || DATE_TIME_FORMAT);
  return formatedDate;
};

export const convert_to_date = (date: any): Date => {
  const dt = moment(date);
  const formatedDate = dt.tz(TIMEZONE).format(DATE_TIME_FORMAT);
  return new Date(formatedDate);
};

export const now = (
  format?: "YYYY-MM-DD HH:mm:ss" | "YYYY-MM-DD" | "HH:mm:ss" | "DD/MM/YYYY HH:mm:ss" | "DD/MM/YYYY HH:mm" | typeof DATE_FORMAT | typeof DATE_FORMAT_BR | typeof DATE_TIME_FORMAT | typeof DATE_TIME_FORMAT_BR | typeof LIST_DATE_TIME_FORMAT
) => {
  const date = moment();
  const currentDateTime = date.tz(TIMEZONE).format(format || DATE_TIME_FORMAT);
  return currentDateTime;
};

export const date = (date?: any) => {
  if (date)
    date = moment(date);
  else
    date = moment();
  return date.tz(TIMEZONE);
};
