import React, {
  Fragment,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { IDataTableColumn } from "react-data-table-component";
import SweetAlert from "sweetalert2";
import { useHistory } from "react-router-dom";
import Funnel from "../../models/funnel";
import Listing from "../../components/listing";
import { RECORDS_PER_PAGE, SITE_TITLE } from "../../configs/constants";
import { useAuth } from "../../hooks/auth";
import { useApollo } from "../../services/apollo.service";
import { gql } from "@apollo/client";
import { useDispatch } from "react-redux";
import { dataTableLoading } from "../../redux/actions";

const Funnels: React.FC = () => {
  const isMountedRef = useRef(null);
  const dispatch = useDispatch();
  const { apollo, error } = useApollo();
  const intl = useIntl();
  const history = useHistory();
  const [selectedRows, setSelectedRows] = useState<Funnel[]>([]);
  const [toggleCleared, setToggleCleared] = useState<boolean>(false);
  const [data, setData] = useState<Funnel[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(RECORDS_PER_PAGE);
  const [sortBy, setSortBy] = useState<string>("sortOrder");
  const [sortDir, setSortDir] = useState<string>("ASC");

  const { hasScope } = useAuth();
  const [canDelete] = useState<boolean>(() => {
    return hasScope("Funnel:Delete");
  });

  const handleData = useCallback(async () => {
    dispatch(dataTableLoading(true));
    await apollo.query({
      query: gql`
        query funnels($sortDir: String, $sortBy: String, $perPage: Int, $page: Int) {
          funnels(sortDir: $sortDir, sortBy: $sortBy, perPage: $perPage, page: $page) {
            paging {
              total
              pages
              perPage
              currentPage
            }
            list {
              id
              createdAt
              updatedAt
              deletedAt
              name
              sortOrder
              isHidden
              actions {
                id
                name
                type
                sortOrder
                isActivated
              }
            }
          }
        }
      `,
      variables: {
        page, perPage, sortBy, sortDir
      }
    })
      .then(res => {
        if (isMountedRef.current) {
          setTotal(res.data.funnels.paging.total);
          setPage(res.data.funnels.paging.currentPage);
          setPerPage(res.data.funnels.paging.perPage);
          setData(res.data.funnels.list);
        }
      })
      .catch(err => error(err));
    dispatch(dataTableLoading(false));
  }, [apollo, error, page, perPage, sortBy, sortDir, dispatch]);

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: "pages.funnels.funnels",
      })}`;
    handleData();
    return () => { isMountedRef.current = false; }
  }, [intl, apollo, error, handleData]);

  const tableColumns: IDataTableColumn<Funnel>[] = [
    {
      name: intl.formatMessage({ id: "inputs.name" }),
      selector: "name",
      sortable: true,
      center: false,
    },
    {
      name: intl.formatMessage({ id: "inputs.sortOrder" }),
      selector: "sortOrder",
      sortable: true,
      center: false,
    },
    {
      name: intl.formatMessage({ id: "inputs.isHidden" }),
      selector: "isHidden",
      format: (row) => {
        return row.isHidden
          ? intl.formatMessage({ id: "general.yes" })
          : intl.formatMessage({ id: "general.no" });
      },
      sortable: true,
      center: false,
    },
  ];

  const handleUpdateRecord = (row: Funnel) => {
    if (hasScope("Funnel:Funnel"))
      history.push(`/funnels/manage/${row.id}`);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      SweetAlert.fire({
        title: intl.formatMessage({ id: "general.exclusion" }),
        text: intl.formatMessage({ id: "flash.confirm.delete" }),
        icon: "error",
        cancelButtonText: intl.formatMessage({ id: "general.no" }),
        confirmButtonText: intl.formatMessage({ id: "general.yes" }),
        reverseButtons: true,
        showCancelButton: true,
      })
        .then(async ({ isConfirmed }) => {
          if (isConfirmed) {
            for (let row of selectedRows) {
              await apollo.mutate({
                mutation: gql`
                  mutation deleteFunnel($id: String!) {
                    deleteFunnel(id: $id) {
                        id
                        name
                    }
                  }
                `,
                variables: {
                  id: row.id
                },
              })
                .then(res => {
                  toast.success(
                    intl.formatMessage(
                      { id: "flash.success.deletedParam" },
                      { description: row.name }
                    )
                  );
                })
                .catch(err => error(err));
            }
            setToggleCleared(!toggleCleared);
            handleData();
          }
        });
    };

    return (
      <button key="delete" className="btn btn-danger" onClick={handleDelete}>
        <FormattedMessage id="buttons.delete" />
      </button>
    );
  }, [selectedRows, apollo, error, toggleCleared, intl, handleData]);

  return (
    <Fragment>
      <Breadcrumb parent="general.home" title="pages.funnels.funnels" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>
                  <FormattedMessage id="pages.funnels.list" />
                </h5>
              </CardHeader>
              <CardBody>
                <Listing
                  data={data}
                  total={total}
                  selectable={canDelete}
                  rowsPerPage={perPage}
                  onChangePage={(page: number) => {
                    setPage(page);
                  }}
                  onChangeRowsPerPage={(rowsPerPage: number) => {
                    setPage(1);
                    setPerPage(rowsPerPage);
                  }}
                  onSort={(
                    column,
                    sortDir
                  ) => {
                    setSortBy(column.selector.toString());
                    setSortDir(sortDir);
                  }}
                  columns={tableColumns}
                  onRowClicked={handleUpdateRecord}
                  contextActions={contextActions}
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleCleared}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Funnels;
