import React, { useState } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import cloneDeep from "lodash.clonedeep";
import { arrayToExcel } from '../../utils/arrayToExcel';
import { ICombineReducers } from '../../redux';
import { useSelector } from 'react-redux';

interface ExportToExcelProps {
  data: object[];
  disabled?: boolean;
  fileName: string;
  buttonIcon?: string | null;
  buttonColor?: string | null;
  buttonTitle?: string | null;
}

const ExportToExcel: React.FC<ExportToExcelProps> = ({ data, disabled, fileName, buttonIcon, buttonColor, buttonTitle }) => {
  const loading = useSelector((state: ICombineReducers) => state.State.loading);
  const [columnsType, setColumnsType] = useState<string>("");
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [modal, setModal] = useState<boolean>(false);
  const [isDisabled] = useState<boolean>(() => disabled ? disabled : loading ? loading : false);
  const toggle = () => setModal(!modal);

  const updateSelectedColumns = (e, column) => {
    if (e.target.checked) {
      setSelectedColumns([...selectedColumns, column]);
    } else {
      setSelectedColumns(selectedColumns.filter(value => value !== column));
    }
  }

  const apiArrayToExcel = () => {
    if (columnsType === "1") {
      arrayToExcel.convertArrayToTable(data, fileName)
    } else {
      const customArray = cloneDeep(data);
      customArray.map(obj => Object.keys(obj).forEach((key) => {
        if (!selectedColumns.includes(key)) {
          delete obj[key];
        }
      }))
      arrayToExcel.convertArrayToTable(customArray, fileName)
      setSelectedColumns([]);
    }
  }

  return (
    <>
      <Button
        type="button"
        onClick={toggle}
        disabled={isDisabled}
        color={buttonColor || "primary"}
      >
        <i className={buttonIcon || "fa fa-cloud-download"}></i>
        {buttonTitle || "Exportar"}
      </Button>
      <Modal isOpen={modal} toggle={toggle} centered size="lg">
        <ModalHeader toggle={toggle}>{buttonTitle || "Exportar"}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label className="col-form-label">
              Selecione o tipo de download:
            </Label>
            <Input
              type="select"
              onChange={(e) => setColumnsType(e.target.value)}
              className="form-control"
            >
              <option value="">-</option>
              <option value="1">Todas as colunas</option>
              <option value="2">Colunas personalizadas</option>
            </Input>
          </FormGroup>
          {columnsType === "1" &&
            <Row>
              {data ? Object.keys(data[0]).map((key, index) => (key !== "__typename" ? <Col md="3" key={index}>{key}</Col> : "")) : ""}
            </Row>
          }
          {columnsType === "2" &&
            <Row>
              {data ? Object.keys(data[0]).map((key, index) => {
                if (key !== "__typename") return (
                  <Col key={`col-${key}`} md="3">
                    <Col key={`col-chk-${key}`} className="checkbox">
                      <Input
                        key={`input-${key}`}
                        id={`input-${key}`}
                        type="checkbox"
                        value={key}
                        onChange={(e) => updateSelectedColumns(e, key)}
                      />
                      <Label
                        key={`label-${key}`}
                        for={`input-${key}`}
                        className="d-block"
                      >
                        {key}
                      </Label>
                    </Col>
                  </Col>
                );
                return "";
              }) : ""}
            </Row>
          }
        </ModalBody>
        <ModalFooter>
          <Button color="secundary" onClick={toggle}>Cancelar</Button>
          <Button color="primary" disabled={!columnsType} onClick={() => apiArrayToExcel()}>Download</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ExportToExcel;
