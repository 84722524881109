import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Breadcrumb from "../../layout/breadcrumb";
import { CrudParam } from "../../configs/route";
import { useApollo } from "../../services/apollo.service";
import { format_date, LIST_DATE_TIME_FORMAT, SITE_TITLE } from "../../configs/constants";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/auth";
import { gql } from "@apollo/client";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import pt_BR from "date-fns/locale/pt-BR";
import { ICombineReducers } from "../../redux";
import { useSelector } from "react-redux";
import Funnel from "../../models/funnel";
import User from "../../models/user";
import Client from "../../models/client";
import Loading from "../../components/loading";

const Manage: React.FC = () => {
  const locale = useSelector((state: ICombineReducers) => state.State.locale);
  const isMountedRef = useRef(null);
  const { apollo, error } = useApollo();
  const { id } = useParams<CrudParam>();
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [utmSource, setUtmSource] = useState<string>("");
  const [utmMedium, setUtmMedium] = useState<string>("");
  const [utmContent, setUtmContent] = useState<string>("");
  const [utmCampaign, setUtmCampaign] = useState<string>("");
  const [utmTerm, setUtmTerm] = useState<string>("");
  const [referral, setReferral] = useState<string>("");
  const [ipAddress, setIpAddress] = useState<string>("");
  const [userAgent, setUserAgent] = useState<string>("");
  const [createdAt, setCreatedAt] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [howKnowUs, setHowKnowUs] = useState<string>("");
  const [funnelId, setFunnelId] = useState<string>("");
  const [funnels, setFunnels] = useState<Funnel[]>([]);
  const [userId, setUserId] = useState<string>("");
  const [users, setUsers] = useState<User[]>([]);
  const [clientId, setClientId] = useState<string>("");
  const [clients, setClients] = useState<Client[]>([]);
  const [preview, setPreview] = useState<boolean>(() => { if (id) return true; else return false; });
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(1);
  const history = useHistory();
  const intl = useIntl();

  const { hasScope } = useAuth();
  const [canView] = useState<boolean>(() => {
    return hasScope("Leads:Lead");
  });
  const [canCreate] = useState<boolean>(() => {
    return hasScope("Leads:Create");
  });
  const [canUpdate] = useState<boolean>(() => {
    return hasScope("Leads:Update");
  });

  const handleData = useCallback(async () => {
    if (id) {
      if (preview && !canView) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/leads");
        return;
      } else if (!preview && !canUpdate) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/leads");
        return;
      }
      setLoading(true);
      await Promise.all([
        apollo.query({
          query: gql`
            query funnelsDropdown {
              funnelsDropdown {
                id
                name
              }
            }
          `,
        }),
        apollo.query({
          query: gql`
            query usersDropdown {
              usersDropdown {
                id
                name
                surname
              }
            }
          `,
        }),
        apollo.query({
          query: gql`
            query clientsDropdown {
              clientsDropdown {
                id
                protheusId
                companyName
              }
            }
          `,
        }),
        apollo.query({
          query: gql`
            query lead($id: String!) {
              lead(id: $id) {
                id
                createdAt
                updatedAt
                name
                email
                phone
                mobile
                utmSource
                utmMedium
                utmContent
                utmCampaign
                utmTerm
                referral
                ipAddress
                userAgent
                notes
                howKnowUs
                funnel {
                  id
                  name
                }
                user {
                  id
                  name
                  surname
                }
                client {
                  id
                  companyName
                  protheusId
                }
              }
            }
          `,
          variables: {
            id
          },
        }),
      ])
        .then(res => {
          if (isMountedRef.current) {
            setFunnels(res[0].data.funnelsDropdown);
            setUsers(res[1].data.usersDropdown);
            setClients(res[2].data.clientsDropdown);
            setName(res[3].data.lead.name || "");
            setEmail(res[3].data.lead.email || "");
            setPhone(res[3].data.lead.phone || "");
            setMobile(res[3].data.lead.mobile || "");
            setUtmSource(res[3].data.lead.utmSource || "");
            setUtmMedium(res[3].data.lead.utmMedium || "");
            setUtmContent(res[3].data.lead.utmContent || "");
            setUtmCampaign(res[3].data.lead.utmCampaign || "");
            setUtmTerm(res[3].data.lead.utmTerm || "");
            setReferral(res[3].data.lead.referral || "");
            setIpAddress(res[3].data.lead.ipAddress || "");
            setUserAgent(res[3].data.lead.userAgent || "");
            setNotes(res[3].data.lead.notes || "");
            setHowKnowUs(res[3].data.lead.howKnowUs || "");
            setFunnelId(res[3].data.lead.funnel?.id || "");
            setUserId(res[3].data.lead.user?.id || "");
            setClientId(res[3].data.lead.client?.id || "");
            setCreatedAt(format_date(res[3].data.lead.createdAt, LIST_DATE_TIME_FORMAT) || "");
          }
        })
        .catch(err => error(err));
      setLoading(false);
    } else {
      if (!preview && !canCreate) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/leads");
        return;
      }

      setLoading(true);
      await Promise.all([
        apollo.query({
          query: gql`
            query funnelsDropdown {
              funnelsDropdown {
                id
                name
              }
            }
          `,
        }),
        apollo.query({
          query: gql`
            query usersDropdown {
              usersDropdown {
                id
                name
                surname
              }
            }
          `,
        }),
        apollo.query({
          query: gql`
            query clientsDropdown {
              clientsDropdown {
                id
                protheusId
                companyName
              }
            }
          `,
        }),
      ])
        .then(res => {
          if (isMountedRef.current) {
            setFunnels(res[0].data.funnelsDropdown);
            setUsers(res[1].data.usersDropdown);
            setClients(res[2].data.clientsDropdown);
          }
        })
        .catch(err => error(err));
      setLoading(false);
    }
  }, [intl, id, preview, apollo, error, history, canView, canCreate, canUpdate]);

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: `pages.leads.${preview ? "preview" : id ? "update" : "create"}`,
      })}`;
    if (locale === "pt") {
      registerLocale("pt-BR", pt_BR);
      setDefaultLocale("pt-BR");
    }
    handleData();
    return () => { isMountedRef.current = false; }
  }, [intl, id, preview, handleData, locale]);

  const handleSubmitForm = async () => {
    setDisableSubmit(true);
    const data = {
      name,
      email,
      phone,
      mobile,
      notes,
      howKnowUs,
      funnelId,
      userId,
      clientId,
    };

    if (!id) {
      await apollo.mutate({
        mutation: gql`
          mutation createLead($data: LeadInput!) {
            createLead(data: $data) {
                id
            }
          }
        `,
        variables: {
          data
        },
      })
        .then(res => {
          toast.success(intl.formatMessage({ id: "flash.success.created" }));
          history.push(`/leads/manage/${res.data.createLead.id}`);
        })
        .catch(err => error(err));
    } else {
      await apollo.mutate({
        mutation: gql`
          mutation updateLead($id: String!, $data: LeadInput!) {
            updateLead(id: $id, data: $data) {
                id
            }
          }
        `,
        variables: {
          id,
          data
        },
      })
        .then(res => {
          toast.success(intl.formatMessage({ id: "flash.success.updated" }));
          history.push(`/leads`);
        })
        .catch(err => error(err));
    }
    setDisableSubmit(false);
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="pages.leads.leads"
        title={`pages.leads.${preview ? "preview" : id ? "update" : "create"}`}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>
                  <FormattedMessage
                    id={`pages.leads.${preview ? "preview" : id ? "update" : "create"
                      }`}
                  />
                </h5>
              </CardHeader>
              <CardBody className="tabbed-card">
                <div className="form-content">
                  <Form onSubmit={(e) => e.preventDefault()}>
                    <Nav className="nav-pills nav-primary">
                      <NavItem>
                        <NavLink
                          className={activeTab === 1 ? "active" : ""}
                          onClick={() => setActiveTab(1)}
                        >
                          <i className="fa fa-file-text-o"></i>
                          <FormattedMessage id="tabs.data" />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={activeTab === 2 ? "active" : ""}
                          onClick={() => setActiveTab(2)}
                        >
                          <i className="fa fa-compass"></i>
                          Campanha
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <Row className="m-b-30">
                      <Col>
                        <TabContent activeTab={activeTab.toString()}>
                          <TabPane tabId="1">
                            {loading ? <Loading /> : (
                              <React.Fragment>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.name" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={name}
                                        disabled={preview}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.email" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={email}
                                        disabled={preview}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.phone" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={phone}
                                        disabled={preview}
                                        onChange={(e) => setPhone(e.target.value)}
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.mobile" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={mobile}
                                        disabled={preview}
                                        onChange={(e) => setMobile(e.target.value)}
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.howKnowUs" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={howKnowUs}
                                        disabled={preview}
                                        onChange={(e) => setHowKnowUs(e.target.value)}
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.notes" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="textarea"
                                        rows="3"
                                        value={notes}
                                        disabled={preview}
                                        onChange={(e) => setNotes(e.target.value)}
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.funnel" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="select"
                                        value={funnelId}
                                        disabled={preview}
                                        onChange={(e) => setFunnelId(e.target.value)}
                                        required
                                      >
                                        <option value="">-</option>
                                        {funnels?.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.user" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="select"
                                        value={userId}
                                        disabled={preview}
                                        onChange={(e) => setUserId(e.target.value)}
                                      >
                                        <option value="">-</option>
                                        {users?.map(item => <option key={item.id} value={item.id}>{item.name} {item.surname}</option>)}
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.client" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="select"
                                        value={clientId}
                                        disabled={preview}
                                        onChange={(e) => setClientId(e.target.value)}
                                      >
                                        <option value="">-</option>
                                        {clients?.map(item => <option key={item.id} value={item.id}>{item.protheusId} - {item.companyName}</option>)}
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col></Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </TabPane>
                          <TabPane tabId="2">
                            {loading ? <Loading /> : (
                              <React.Fragment>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.utmSource" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={utmSource}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.utmMedium" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={utmMedium}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.utmContent" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={utmContent}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.utmCampaign" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={utmCampaign}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.utmTerm" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={utmTerm}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.referral" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={referral}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.ipAddress" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={ipAddress}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.userAgent" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={userAgent}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.createdAt" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={createdAt}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </TabPane>
                        </TabContent>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          type="button"
                          color="danger"
                          onClick={() => history.push("/leads")}
                        >
                          <i className="fa fa-arrow-left"></i>
                          <FormattedMessage id="buttons.cancel" />
                        </Button>
                      </Col>
                      {canUpdate ? (
                        <Col className="text-right">
                          {preview ? (
                            <Button
                              type="button"
                              color="primary"
                              disabled={loading}
                              onClick={() => setPreview(!preview)}
                            >
                              <i className="fa fa-edit"></i>
                              <FormattedMessage id="buttons.edit" />
                            </Button>
                          ) : (
                            <Button type="button" disabled={disableSubmit || loading} onClick={handleSubmitForm} color="primary">
                              <i className="fa fa-save"></i>
                              <FormattedMessage id="buttons.save" />
                            </Button>
                          )}
                        </Col>) : ""}
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Manage;
