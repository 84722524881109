import React, { Fragment } from "react";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import { FormattedMessage, useIntl } from "react-intl";
import { DATATABLE_ROWS_PER_PAGE } from "../../configs/constants";
import styled from 'styled-components';
import { useSelector } from "react-redux";
import { ICombineReducers } from "../../redux";
import "./listing.scss";

export interface ExpandedComponentProps<T = any> {
  data?: T;
  children?: React.ReactNode;
}

export function ExpandedComponent<T = any>(props: ExpandedComponentProps<T>): React.ReactElement {
  return <Fragment>{props.children}</Fragment>;
};

function ExpandedComponentDefault<T = any>(props: ExpandedComponentProps<T>): React.ReactElement {
  return <Fragment><pre>{JSON.stringify(props.data, null, 2)}</pre></Fragment>;
};

const NoData = styled.div`
  font-size: 0.9rem;
  padding: 24px;
  text-align: center;
  display: block;
  width: 100%;
`;

export interface TableProps<T = any> {
  title?: React.ReactNode;
  data: T[];
  total: number;
  rowsPerPage: number;
  disabled?: boolean;
  progressPending?: boolean;
  columns: IDataTableColumn<T>[];
  selectable?: boolean;
  sortServer?: boolean;
  paginationServer?: boolean;
  onSort?: (
    column: IDataTableColumn<T>,
    sortDirection: 'asc' | 'desc'
  ) => void;
  sortFunction?: (
    rows: T[],
    field: string,
    sortDirection: 'asc' | 'desc'
  ) => T[];
  defaultSortField?: string;
  defaultSortAsc?: boolean;
  onChangePage?: (page: number, totalRows: number) => void;
  onChangeRowsPerPage?: (
    currentRowsPerPage: number,
    currentPage: number
  ) => void;
  onRowClicked?: (row: T, e: MouseEvent) => void;
  onRowDoubleClicked?: (row: T, e: MouseEvent) => void;
  contextActions?: React.ReactNode | React.ReactNode[];
  onSelectedRowsChange?: (selectedRowState: {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: T[];
  }) => void;
  clearSelectedRows?: boolean;
  expandableRows?: boolean;
  expandableRowsComponent?: React.ReactNode;
  subHeader?: React.ReactNode | React.ReactNode[];
  subHeaderAlign?: string;
  subHeaderWrap?: boolean;
  subHeaderComponent?: React.ReactNode | React.ReactNode[];
  children?: React.ReactNode;
}


const Processing: React.FC = () => {
  return <div className="data-table-loading">
    <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
    <span className="sr-only">Carregando...</span>
  </div>;
}

const Nodata: React.FC = () => (
  <NoData className="data-table-no-data">
    <FormattedMessage id="dataTable.noData" />
  </NoData>
);

export default function Listing<T = any>(
  props: TableProps<T>
): React.ReactElement {
  const intl = useIntl();
  const loading = useSelector((state: ICombineReducers) => state.State.loading);

  return (
    <Fragment>
      <DataTable
        title={props.title}
        data={props.data}
        columns={props.columns}
        striped
        disabled={props.disabled}
        responsive
        pointerOnHover
        highlightOnHover
        noDataComponent={<Nodata />}
        onRowClicked={props.onRowClicked}
        onRowDoubleClicked={props.onRowDoubleClicked}
        selectableRows={props.selectable}
        fixedHeader
        progressPending={props.progressPending || loading}
        progressComponent={<Processing />}
        persistTableHead
        subHeader={props.subHeader}
        subHeaderComponent={props.subHeaderComponent}
        subHeaderAlign={props.subHeaderAlign}
        subHeaderWrap={props.subHeaderWrap}
        onSort={props.onSort}
        sortFunction={props.sortFunction}
        defaultSortField={props.defaultSortField}
        defaultSortAsc={props.defaultSortAsc}
        sortServer={props.sortServer || true}
        pagination
        paginationServer={props.paginationServer || true}
        direction="auto"
        expandableRows={props.expandableRows}
        expandableRowsComponent={props.expandableRowsComponent || <ExpandedComponentDefault />}
        paginationTotalRows={props.total}
        paginationPerPage={props.rowsPerPage}
        paginationRowsPerPageOptions={DATATABLE_ROWS_PER_PAGE}
        paginationComponentOptions={{
          noRowsPerPage: false,
          rowsPerPageText: intl.formatMessage({
            id: "dataTable.rowsPerPageText",
          }),
          rangeSeparatorText: intl.formatMessage({
            id: "dataTable.rangeSeparatorText",
          }),
          selectAllRowsItem: false,
          selectAllRowsItemText: intl.formatMessage({
            id: "dataTable.selectAllRowsItemText",
          }),
        }}
        onChangePage={props.onChangePage}
        onChangeRowsPerPage={props.onChangeRowsPerPage}
        contextActions={props.contextActions}
        contextMessage={{
          singular: intl.formatMessage({
            id: "dataTable.contextMessageSingular",
          }),
          plural: intl.formatMessage({
            id: "dataTable.contextMessagePlural",
          }),
          message: intl.formatMessage({
            id: "dataTable.contextMessage",
          }),
        }}
        onSelectedRowsChange={props.onSelectedRowsChange}
        clearSelectedRows={props.clearSelectedRows}
      />
      {props.children}
    </Fragment>
  );
}
