import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Breadcrumb from "../../layout/breadcrumb";
import { CrudParam } from "../../configs/route";
import { useApollo } from "../../services/apollo.service";
import { DATE_FORMAT_BR, format_date, RECORDS_PER_PAGE, SITE_TITLE } from "../../configs/constants";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/auth";
import { gql } from "@apollo/client";
import OrderItem from "../../models/orderItem";
import { IDataTableColumn } from "react-data-table-component";
import Listing from "../../components/listing";
import Loading from "../../components/loading";

const Manage: React.FC = () => {
  const isMountedRef = useRef(null);
  const { apollo, error } = useApollo();
  const { id } = useParams<CrudParam>();
  const [client, setClient] = useState<string>("");
  const [orderKind, setOrderKind] = useState<string>("");
  const [carrier, setCarrier] = useState<string>("");
  const [stepDescription, setStepDescription] = useState<string>("");
  const [paymentCondition, setPaymentCondition] = useState<string>("");
  const [seller, setSeller] = useState<string>("");
  const [createdAt, setCreatedAt] = useState<string>("");
  const [quantityTotal, setQuantityTotal] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [freight, setFreight] = useState<number>(0);
  const [freightKind, setFreightKind] = useState<string>("");
  const [items, setItems] = useState<OrderItem[]>([]);
  const [preview] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const intl = useIntl();
  // eslint-disable-next-line
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(RECORDS_PER_PAGE);
  // eslint-disable-next-line
  const [sortBy, setSortBy] = useState<string>("C5_EMISSAO");
  // eslint-disable-next-line
  const [sortDir, setSortDir] = useState<string>("DESC");

  const { hasScope } = useAuth();
  const [canView] = useState<boolean>(() => {
    return hasScope("Orders:Order");
  });
  const [canCreate] = useState<boolean>(() => {
    return hasScope("Orders:Create");
  });
  const [canUpdate] = useState<boolean>(() => {
    return hasScope("Orders:Update");
  });

  const tableColumns: IDataTableColumn<OrderItem>[] = [
    {
      name: intl.formatMessage({ id: "inputs.protheusId" }),
      selector: "productId",
      sortable: true,
      center: false,
      width: "100px",
    },
    {
      name: intl.formatMessage({ id: "inputs.name" }),
      selector: "name",
      sortable: true,
      center: true,
    },
    {
      name: intl.formatMessage({ id: "inputs.unit" }),
      selector: "unit",
      sortable: true,
      center: true,
      width: "100px",
    },
    {
      name: intl.formatMessage({ id: "inputs.qty" }),
      selector: "qty",
      sortable: true,
      center: true,
      width: "100px",
    },
    {
      name: intl.formatMessage({ id: "inputs.salePrice" }),
      selector: "salePrice",
      sortable: true,
      right: true,
      width: "180px",
      format: (row) => {
        return intl.formatNumber(row.salePrice, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
      }
    },
    {
      name: intl.formatMessage({ id: "inputs.total" }),
      selector: "total",
      sortable: true,
      right: true,
      width: "180px",
      format: (row) => {
        return intl.formatNumber(row.total, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
      }
    },
  ];

  const handleData = useCallback(async () => {
    if (id) {
      if (preview && !canView) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/orders");
        return;
      } else if (!preview && !canUpdate) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/orders");
        return;
      }
      setLoading(true);
      await apollo.query({
        query: gql`
          query order($id: String!) {
            order(id: $id) {
              id
              createdAt
              storeId
              sellerId
              fiscalNote
              quantityTotal
              total
              freight
              clientId
              clientKind
              step
              stepDescription
              orderKind
              carrierId
              paymentConditionId
              freightKind
              priceList
              budgetId
              items {
                productId
                sortOrder
                unit
                qty
                salePrice
                unitPrice
                total
                fiscalNote
                name
                orderId
              }
              paymentConditionId
              paymentCondition {
                id
                name
                description1
                description2
              }
              carrier {
                id
                name
              }
              client {
                id
                companyName
              }
              user {
                id
                name
                surname
                protheusId
              }
            }
          }
        `,
        variables: {
          id
        },
      })
        .then(res => {
          if (isMountedRef.current) {
            setTotal(res.data.order.total || 0);
            setQuantityTotal(res.data.order.quantityTotal || 0);
            setClient(res.data.order.client?.companyName || "");
            setOrderKind(res.data.order.orderKind || "");
            setCarrier(res.data.order.carrier?.name || "");
            setStepDescription(res.data.order.stepDescription || "");
            setPaymentCondition(res.data.order.paymentCondition?.name || "");
            setSeller(res.data.order.user?.name ? `${res.data.order.user.name} ${res.data.order.user.surname}` : "");
            setCreatedAt(format_date(res.data.order.createdAt, DATE_FORMAT_BR) || "");
            setFreight(res.data.order.freight || 0);
            setFreightKind(res.data.order.freightKind || "");
            setItems(res.data.order.items || []);
          }
        })
        .catch(err => error(err));
      setLoading(false);
    } else {
      if (!preview && !canCreate) {
        toast.error(intl.formatMessage({ id: "flash.error.accessDenied" }));
        history.push("/orders");
        return;
      }
    }
  }, [intl, id, preview, apollo, error, history, canView, canCreate, canUpdate]);

  useEffect(() => {
    isMountedRef.current = true;
    document.title = `${SITE_TITLE
      } :: ${intl.formatMessage({
        id: `pages.orders.${preview ? "preview" : id ? "update" : "create"}`,
      })}`;
    handleData();
    return () => { isMountedRef.current = false; }
  }, [intl, id, preview, handleData]);

  return (
    <Fragment>
      <Breadcrumb
        parent="pages.orders.orders"
        title={`pages.orders.${preview ? "preview" : id ? "update" : "create"}`}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>
                  <FormattedMessage
                    id={`pages.orders.${preview ? "preview" : id ? "update" : "create"
                      }`}
                  />
                </h5>
              </CardHeader>
              <CardBody className="tabbed-card">
                <div className="form-content">
                  <Form onSubmit={(e) => e.preventDefault()}>
                    <Nav className="nav-pills nav-primary">
                      <NavItem>
                        <NavLink
                          className={activeTab === 1 ? "active" : ""}
                          onClick={() => setActiveTab(1)}
                        >
                          <i className="fa fa-file-text-o"></i>
                          <FormattedMessage id="tabs.data" />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={activeTab === 2 ? "active" : ""}
                          onClick={() => setActiveTab(2)}
                        >
                          <i className="fa fa-cubes"></i>
                          Produtos
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <Row className="m-b-30">
                      <Col>
                        <TabContent activeTab={activeTab.toString()}>
                          <TabPane tabId="1">
                            {loading ? <Loading /> : (
                              <React.Fragment>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.code" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={id}
                                        disabled={preview}
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.orderKind" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={orderKind}
                                        disabled={preview}
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.client" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={client}
                                        disabled={preview}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.carrier" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={carrier}
                                        disabled={preview}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.freight" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={intl.formatNumber(freight, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                        disabled={preview}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.freightKind" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={freightKind}
                                        disabled={preview}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.paymentCondition" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={paymentCondition}
                                        disabled={preview}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.seller" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={seller}
                                        disabled={preview}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.step" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={stepDescription}
                                        disabled={preview}
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label className="col-form-label">
                                        <FormattedMessage id="inputs.createdAt" />
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="text"
                                        value={createdAt}
                                        disabled={preview}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </TabPane>
                          <TabPane tabId="2">
                            {loading ? <Loading /> : (
                              <Row>
                                <Col>
                                  <Listing
                                    data={items}
                                    total={items.length}
                                    rowsPerPage={perPage}
                                    sortServer={false}
                                    paginationServer={false}
                                    onChangePage={(page: number) => {
                                      setPage(page);
                                    }}
                                    onChangeRowsPerPage={(rowsPerPage: number) => {
                                      setPage(1);
                                      setPerPage(rowsPerPage);
                                    }}
                                    onSort={(
                                      column,
                                      sortDir
                                    ) => {
                                      setSortBy(column.selector.toString());
                                      setSortDir(sortDir);
                                    }}
                                    columns={tableColumns}
                                  >
                                    <div style={{ paddingTop: "20px" }}>
                                      <div><strong>Quantidade total:</strong> {intl.formatNumber(quantityTotal, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}</div>
                                      <div><strong>Total:</strong> R${intl.formatNumber(total, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                    </div>
                                  </Listing>
                                </Col>
                              </Row>
                            )}
                          </TabPane>
                        </TabContent>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          type="button"
                          color="danger"
                          onClick={() => history.push("/orders")}
                        >
                          <i className="fa fa-arrow-left"></i>
                          <FormattedMessage id="buttons.cancel" />
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Manage;
