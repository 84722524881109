import produce from "immer";
import User from "../../models/user";
import jwt from "jsonwebtoken";
import Token from "../../models/token";
import {
  ApplicationAction,
  DataSetState,
  Credentials,
  TokenCredentials,
} from "../types";
import { AUTH_SECRET } from "../../configs/constants";

let credentials = {
  user: null,
  token: {
    token: null,
    data: null
  } as TokenCredentials
} as Credentials;

const stringUser = localStorage.getItem("user");
const token = localStorage.getItem("token");
if (!!stringUser) {
  try {
    const user: User = JSON.parse(stringUser);
    if (user?.id) {
      credentials.user = user;
    }
  } catch (err) {
    console.log(err);
  }
}
if (!!token) {
  credentials.token.token = token;
  try {
    const data = jwt.verify(token, AUTH_SECRET) as Token;
    credentials.token.data = data;
  } catch (err) {
    console.log(err);
  }
}

export const initialDataSetState: DataSetState = {
  credentials
};

const reducer = (
  state: DataSetState = initialDataSetState,
  action: ApplicationAction
): DataSetState => {
  switch (action.type) {
    case "LOAD_CREDENTIALS":
      return produce(state, (draft) => {
        draft.credentials = action.payload;
      });
    default:
      return { ...state };
  }
};

export default reducer;
